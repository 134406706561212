import React, { useEffect, useMemo, useState } from "react";
import styles from "./knowledgeHub.module.scss";
import { googlePageView, googleEvent } from "../../analytics/google";
import GAEvent from "../../analytics/events";
import SiteHeader from "../../Shared/site-header/siteHeader";
import SiteFooter from "../../Shared/site-footer/siteFooter";
import SharedStyles from "../../Shared/shared.module.scss";
import BannerCard from "../../Shared/banner-card/bannerCard";
import BannerTickWhite from "../../assets/images/bannerTickWhite.svg";
import {
  BannerCardButtonTriggered,
  PageScrollTriggered,
  BlogButtonTriggered,
} from "../../Shared/eventsNames";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { motion } from "framer-motion";
import { getBlogs } from "../../api/blog";
import { BlogDetail } from "../../types/blog";
import moment from "moment";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import useBlogStore from "../../store/blog";
import BlogGridSekeleton from "./blogGridSkeletion";
import { makeArrayBlogCategory } from "../../utils/helper";

const cardVariants = {
  offscreen: {
    y: 50,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,

    transition: {
      duration: 0.3,
    },
  },
};

export default function KnowledgeHub() {
  googlePageView("/knowledge-hub", "KnowledgeHubPage");
  const navigate = useNavigate();
  const location = useLocation();

  const {
    blogs,
    setBlogs,
    categories,
    setCategories,
    blogLoading,
    setBlogLoading,
  } = useBlogStore();

  const [searchParams, setSearchParams] = useSearchParams(location.search);
  const [selectedCategory, setSelectedCategory] = useState(
    searchParams.get("category") || "all"
  );
  const [randomizedBlogs, setRandomizedBlogs] = useState<BlogDetail[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recentBlogs, setRecentBlogs] = useState(0);
  const blogsPerPage = 6;

  useEffect(() => {
    const handleScroll = () => {
      PageScrollTriggered({ properties: "Knowledge Hub Page Scrolled" });
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const fetchBlogs = async () => {
    setBlogLoading(true);
    try {
      const res = await getBlogs();
      if (res.data) {
        setBlogs(res.data.zoopStackBlogs);
        const categories = makeArrayBlogCategory(res.data.zoopStackBlogs);
        setCategories(["All", ...categories]);
      }
    } catch (err: any) {
      console.log(err);
    } finally {
      setBlogLoading(false);
    }
  };

  useEffect(() => {
    if (blogs?.length === 0) fetchBlogs();
  }, []);

  const filteredBlog = useMemo(() => {
    if (selectedCategory === "all") return blogs;
    return blogs?.filter((blog) =>
      blog.category
        .toLowerCase()
        .includes(selectedCategory.replaceAll("_", " "))
    );
  }, [selectedCategory, blogs]);

  // Calculate total pages based on paginated blogs
  const totalBlogs = useMemo(() => {
    return filteredBlog.length;
  }, [filteredBlog]);

  const totalPages = Math.ceil(totalBlogs / blogsPerPage);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  useEffect(() => {
    // Update the paginated blogs when the page changes
    const newStartIndex = (currentPage - 1) * blogsPerPage;
    const newEndIndex = newStartIndex + blogsPerPage;
    const randomizedPaginatedBlogs = filteredBlog
      .slice(newStartIndex, newEndIndex)
      .sort(() => Math.random() - 0.5); // Custom sorting function for randomization
    setRandomizedBlogs(randomizedPaginatedBlogs);
  }, [currentPage, filteredBlog]);
  const startIndex = (currentPage - 1) * blogsPerPage;
  const endIndex = startIndex + blogsPerPage;
  const paginatedBlogs = filteredBlog.slice(startIndex, endIndex);
  const recentblogs = filteredBlog.slice(1, 4);
  const firstBlog = filteredBlog.slice(0, 1);
  useEffect(() => {
    // Update the paginated blogs when the page changes
    const newStartIndex = (currentPage - 1) * blogsPerPage;
    const newEndIndex = newStartIndex + blogsPerPage;
    // setPaginatedBlogs(filteredBlog.slice(newStartIndex, newEndIndex));
  }, [currentPage, filteredBlog]);
  useEffect(() => {
    // Update pagination when switching categories or fetching new blogs
    setCurrentPage(1);
  }, [selectedCategory, blogs]);
  const handlePageClick = (pageNumber: any) => {
    setCurrentPage(pageNumber);
  };
  const handleButtonClick = () => {
    BannerCardButtonTriggered({
      properties: "BannerCard Button on Blogs/Knowledge Hub",
    });
  };
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>ZOOPSTACK</title>
          <meta
            name="description"
            content="Get amazing insights about the recent trends in the fintech industry through our blog post. Read our free blog and stay updated. "
          />
          <meta
            name="title"
            content="Explore Various Blog Post on Fintech Industry "
          />
          <meta name="Keywords" content="blog post, free blog, blog ideas" />
        </Helmet>
      </HelmetProvider>
      <div className={SharedStyles.mainContainer}>
        <SiteHeader page="knowledgeHub" />

        <div className={styles.main}>
          <h1 className={styles.heading}>
            A Collection of Innovative Articles
          </h1>
          <p className={styles.subHeading}>
            Great things in business are never done by one person. They’re done
            by a team of people. We have that dynamic group of people!
          </p>
        </div>

        <h1 className={`mt-4 ${styles.headingBlogs}`}>Recent Blogs</h1>
        <div className={styles.recentBlogs}>
          {firstBlog.map((blog: BlogDetail, index: number) => (
            <div
              key={blog.id}
              className={`card ${styles.firstRecentBlog}`}
              onClick={() => {
                navigate(
                  `/blogs/${blog.id}/${blog.title
                    .trim()
                    .toLowerCase()
                    .replaceAll(" ", "-")}`
                );
                BlogButtonTriggered();
                googleEvent(
                  "Blog_Article_Viewed",
                  `Blog_Viewed: ${blog.title}`,
                  `Blog_Viewed: ${blog.title}`
                );
              }}
            >
              <LazyLoadImage
                src={`https://cms.zoop.one${blog?.cover_banner?.url}`}
                style={{
                  width: "666px",
                  height: "500px",
                  borderRadius: "20px",
                  backgroundSize: "cover",
                }}
                alt="blog-image"
                effect="blur"
                className="card-img-top"
              />
              <div className={`pl-3 ${styles["blogCard__card-body"]}`}>
                <p className={styles["blogCard__card-date"]}>
                  <small className="text-body-secondary">{`Posted on ${moment(
                    blog.published_at
                  ).format("MMMM DD, YYYY")}`}</small>
                </p>
                <p className={styles["blogCard__card-title"]}>{blog.title}</p>
              </div>
            </div>
          ))}
          <div className={styles.otherRecentBlogs}>
            {recentblogs.map((blog: BlogDetail, index: number) => (
              <div className={styles.otherDetailRecentBlogs}>
                <LazyLoadImage
                  src={`https://cms.zoop.one${blog?.cover_banner?.url}`}
                  style={{
                    width: "260px",
                    height: "195px",
                    // borderRadius: "20px",
                    backgroundSize: "cover",
                  }}
                  alt="blog-image"
                  effect="blur"
                  className="card-img-top"
                />
                <div
                  key={blog.id}
                  className={`${styles.otherRecentBlogs}`}
                  onClick={() => {
                    navigate(
                      `/blogs/${blog.id}/${blog.title
                        .trim()
                        .toLowerCase()
                        .replaceAll(" ", "-")}`
                    );
                    BlogButtonTriggered();
                    googleEvent(
                      "Blog_Article_Viewed",
                      `Blog_Viewed: ${blog.title}`,
                      `Blog_Viewed: ${blog.title}`
                    );
                  }}
                >
                  <div className={`p-2 ${styles["blogCard__card-body"]}`}>
                    <p className={styles["blogCard__card-date"]}>
                      <small className="text-body-secondary ml-4">{`Posted on ${moment(
                        blog.published_at
                      ).format("MMMM DD, YYYY")}`}</small>
                    </p>
                    <p className={`ml-4 ${styles["blogCard__card-title"]}`}>
                      {blog.title}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <h1 className={`mt-4 ${styles.headingBlogs}`}>Blog Articles</h1>
        <div className={styles.blogs}>
          {blogLoading ? (
            <BlogGridSekeleton />
          ) : paginatedBlogs.length > 0 ? (
            <>
              <motion.div
                viewport={{ once: true }}
                initial="offscreen"
                whileInView="onscreen"
                className={styles.grid}
                key={selectedCategory}
              >
                {randomizedBlogs.map((blog: BlogDetail) => (
                  <div
                    key={blog.id}
                    className={`card ${styles.blogCard}`}
                    onClick={() => {
                      navigate(
                        `/blogs/${blog.id}/${blog.title
                          .trim()
                          .toLowerCase()
                          .replaceAll(" ", "-")}`
                      );
                      BlogButtonTriggered();
                      googleEvent(
                        "Blog_Article_Viewed",
                        `Blog_Viewed: ${blog.title}`,
                        `Blog_Viewed: ${blog.title}`
                      );
                    }}
                  >
                    <LazyLoadImage
                      src={`https://cms.zoop.one${blog?.cover_banner?.url}`}
                      style={{
                        width: "400px",
                        height: "300px",
                        borderRadius: "20px",
                        backgroundSize: "cover",
                      }}
                      alt="blog-image"
                      effect="blur"
                      className="card-img-top"
                    />
                    <div className={styles["blogCard__card-body"]}>
                      <p className={styles["blogCard__card-title"]}>
                        {blog.title}
                      </p>
                      <p className={styles["blogCard__card-date"]}>
                        <small className="text-body-secondary">{`Posted on ${moment(
                          blog.published_at
                        ).format("MMMM DD, YYYY")}`}</small>
                      </p>
                    </div>
                  </div>
                ))}
              </motion.div>
              <div className={styles.blogsCategory}>
                <h1 className={`${styles.headingCategory}`}>Categories</h1>
                <div>
                  {categories.map((category: string, i: number) => (
                    <span
                      key={`${category}_${i}`}
                      onClick={() => {
                        setSearchParams({
                          category: category.toLowerCase().replaceAll(" ", "_"),
                        });
                        setSelectedCategory(category.toLowerCase());
                      }}
                      className={`${styles["blogsCategory__categoryName"]} ${
                        selectedCategory === category.toLowerCase()
                          ? `${styles["blogsCategory__categoryName__selected"]}`
                          : ""
                      }`}
                    >
                      {category}
                    </span>
                  ))}
                </div>
              </div>
            </>
          ) : (
            <div className={styles.emptyBlog}>
              <span>No blog found</span>
            </div>
          )}
        </div>
        <div className={styles.pagination}>
          <button
            onClick={handlePreviousPage}
            className={styles.paginationButton}
          >
            <svg
              width="8"
              height="9"
              viewBox="0 0 8 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.17451 5.34349C1.18258 5.34849 1.19333 5.35049 1.2014 5.35549L6.38704 8.84649C6.67879 9.04149 7.15205 9.04149 7.4438 8.84649C7.44649 8.84449 7.44783 8.84249 7.44917 8.84049C7.51982 8.7971 7.57687 8.74277 7.61635 8.68128C7.65583 8.61979 7.67678 8.55263 7.67773 8.48449L7.67773 1.50149C7.67623 1.4321 7.65425 1.3638 7.61337 1.30143C7.57248 1.23905 7.51367 1.18411 7.44111 1.14049L7.4438 1.13849C7.297 1.04412 7.10945 0.992188 6.91542 0.992188C6.72139 0.992188 6.53384 1.04412 6.38704 1.13849L1.17451 4.63749C1.10586 4.68105 1.05073 4.73517 1.01293 4.79611C0.975132 4.85705 0.955555 4.92337 0.955555 4.99049C0.955555 5.0576 0.975132 5.12392 1.01293 5.18487C1.05073 5.24581 1.10586 5.29993 1.17451 5.34349Z"
                fill="#5580E9"
              />
            </svg>
          </button>
          {Array.from({ length: Math.min(totalPages, 5) }).map((_, index) => (
            <button
              key={index}
              onClick={() => handlePageClick(index + 1)}
              className={`${
                currentPage === index + 1 ? styles.activePage : ""
              } ${styles.paginationButtonNumber}`}
            >
              {index + 1}
            </button>
          ))}
          {totalPages > 5 && (
            <>
              <span>...</span>
              <button onClick={() => handlePageClick(totalPages)}>
                {totalPages}
              </button>
            </>
          )}
          <button onClick={handleNextPage} className={styles.paginationButton}>
            <svg
              width="8"
              height="9"
              viewBox="0 0 8 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.07744 3.65651C7.06937 3.65151 7.05862 3.64951 7.05055 3.64451L1.86491 0.153512C1.57316 -0.0414877 1.09991 -0.0414877 0.808157 0.153512C0.805468 0.155512 0.804124 0.157512 0.802779 0.159512C0.732135 0.202898 0.675079 0.257234 0.635601 0.318724C0.596123 0.380214 0.575174 0.447374 0.574219 0.515512L0.574219 7.49851C0.575726 7.5679 0.5977 7.6362 0.638587 7.69857C0.679475 7.76095 0.73828 7.81589 0.810846 7.85951L0.808157 7.86151C0.954954 7.95588 1.14251 8.00781 1.33654 8.00781C1.53057 8.00781 1.71812 7.95588 1.86491 7.86151L7.07744 4.36251C7.14609 4.31895 7.20122 4.26483 7.23902 4.20389C7.27682 4.14295 7.2964 4.07663 7.2964 4.00951C7.2964 3.9424 7.27682 3.87608 7.23902 3.81513C7.20122 3.75419 7.14609 3.70007 7.07744 3.65651Z"
                fill="#5580E9"
              />
            </svg>
          </button>
        </div>
        {/* {BannerCard} */}
        <BannerCard
          text={"Ready to take your business to new heights?"}
          button={{ text: "Get free Demo", onClick: handleButtonClick }}
          tickImage={BannerTickWhite}
          backGroundClassName={styles.bannerBackground}
        />

        <SiteFooter />
      </div>
    </>
  );
}
