import React, { useEffect, useState } from "react";
import Styles from "./persona.module.scss";
import LockImage from "../../../assets/images/products/persona/unlock.gif";
import HamburgerMenuicon from "../../../assets/images/icons/hamburgerMenuWhite.svg";
import { googlePageView } from "../../../analytics/google";
import personImage from "../../../assets/images/products/persona/persona.png";
import Sidebar from "../../../Shared/side-bar/sidebar";
import { PageScrollTriggered } from "../../../Shared/eventsNames";
import { Helmet, HelmetProvider } from "react-helmet-async";
import useScreen from "../../../utils/useScreen";
import logo from "../../../assets/images/zoopStackWhiteLogo.svg";

const Persona = () => {
  googlePageView("/products/persona", "PersonaPage");
  const { isMobile } = useScreen();

  useEffect(() => {
    const handleScroll = () => {
      PageScrollTriggered({ properties: "Persona Page Scrolled" });
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>ZOOPSTACK</title>
          <meta
            name="description"
            content="Ensure authenticity with seamless student verification, degree verification, and degree certificate verification. Trust ZOOPSTACK for quick verification."
          />
          <meta
            name="title"
            content="Student Verification API - Fast & Secure Student Status Verification"
          />
          <meta
            name="Keywords"
            content="student verification, degree verification, degree certificate verification, enrollment verification, verify enrollment id, university verification"
          />
        </Helmet>
      </HelmetProvider>
      <div className={Styles.mainContainer}>
        <div className={Styles.hambugerMenuIcon}>
          <a href="\home" onClick={() => {}}>
            <img src={logo} alt="logo" />
          </a>
          {!isSidebarOpen && (
            <img
              src={HamburgerMenuicon}
              alt="Menu"
              width="50px"
              height="20px"
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            />
          )}
          {isSidebarOpen && <Sidebar />}
        </div>

        <div className={`${Styles.container} container h-100`}>
          <div className={"row h-100"}>
            <div className="col-12 col-md-6 ">
              <div className={`${Styles.lockContainer}`}>
                <img src={LockImage} alt="Unlock Persona" />
                <div className={Styles.lockTitle}>
                  Unlock{" "}
                  {!isMobile && (
                    <>
                      <br />
                    </>
                  )}
                  Personas
                </div>
                <div className={Styles.popoverKnowMore}>
                  <a href="/persona/unlock">Know More &#8599;</a>
                </div>
              </div>
            </div>

            {/* Popover Images */}
            <div
              className={`col-12 col-md-6 ${Styles.popoverContainer}`}
              onClick={() => setIsSidebarOpen(false)}
            >
              {/* <div className="col-12 col-md-6"> */}
              {/* <div className={Styles.cardsContainer}>
                  <div className={Styles.cardsContainer1}>
                    <a
                      href="/persona/unlock"
                      onClick={() =>
                        PersonaKnowMoreTriggered({
                          properties: "Persona know more card clicked",
                        })
                      }
                    >
                      <div className={Styles.popoverImageContainer}>
                        <img src={popoverImage1} alt="Persona for Doctors" />
                        <div className={Styles.popoverKnowMore}>
                          Know More &#8599;
                        </div>
                      </div>
                    </a>
                    <a
                      href="/persona/unlock"
                      onClick={() =>
                        PersonaKnowMoreTriggered({
                          properties: "Persona know more card clicked",
                        })
                      }
                    >
                      <div className={Styles.popoverImageContainer}>
                        <img src={popoverImage2} alt="Persona for Doctors" />
                        <div className={Styles.popoverKnowMore}>
                          Know More &#8599;
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className={Styles.cardsContainer2}>
                    <a
                      href="/persona/unlock"
                      onClick={() =>
                        PersonaKnowMoreTriggered({
                          properties: "Persona know more card clicked",
                        })
                      }
                    >
                      <div className={Styles.popoverImageContainer}>
                        <img src={popoverImage3} alt="Persona for Doctors" />
                        <div className={Styles.popoverKnowMore}>
                          Know More &#8599;
                        </div>
                      </div>
                    </a>
                    <a
                      href="/persona/unlock"
                      onClick={() =>
                        PersonaKnowMoreTriggered({
                          properties: "Persona know more card clicked",
                        })
                      }
                    >
                      <div className={Styles.popoverImageContainer}>
                        <img src={popoverImage4} alt="Persona for Doctors" />
                        <div className={Styles.popoverKnowMore}>
                          Know More &#8599;
                        </div>
                      </div>
                    </a>
                  </div>
                </div> */}
              {/* </div> */}
            </div>
          </div>
        </div>

        {isMobile && (
          <img
            src={personImage}
            className={Styles.personImage}
            alt="person"
            width={"100%"}
          />
        )}
      </div>
    </>
  );
};
export default Persona;
