import React from "react";

export default function ColorBiometric() {
  return (
    <svg
      width="27"
      height="30"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.44435 2.50229C3.49 2.47525 8.07133 -0.173607 12.8306 2.50589C12.88 2.53373 12.9341 2.54701 12.9873 2.54701C13.0992 2.54701 13.2077 2.48838 13.2665 2.38394C13.3534 2.22977 13.2987 2.03451 13.1446 1.94774C8.06884 -0.91047 3.16699 1.92225 3.1179 1.95134C2.96593 2.04138 2.91557 2.23776 3.00564 2.38988C3.09539 2.542 3.29176 2.59282 3.44435 2.50229Z"
        fill="#00006E"
        stroke="#00006E"
        stroke-width="0.4"
      />
      <path
        d="M0.889065 6.39643C0.791506 6.54387 0.832156 6.74258 0.979458 6.84014C1.03385 6.87595 1.09513 6.89315 1.1558 6.89315C1.25961 6.89315 1.36156 6.84263 1.42317 6.74947C2.86344 4.57058 5.16177 3.28243 7.72934 3.21551C10.4476 3.14093 12.9917 4.43768 14.7094 6.76321C14.8144 6.90535 15.0149 6.93599 15.1571 6.83044C15.2994 6.72538 15.3297 6.52493 15.2247 6.38266C13.3807 3.88656 10.6468 2.49881 7.71245 2.57527C4.93319 2.6478 2.44599 4.04056 0.889065 6.39643Z"
        fill="#0075F3"
        stroke="#0075F3"
        stroke-width="0.4"
      />
      <path
        d="M14.3539 12.0538C15.4796 10.5531 14.0759 7.97647 14.0049 7.84968C12.8645 6.07137 11.0706 4.89314 9.08369 4.61686C7.10901 4.34294 5.16152 4.95237 3.59991 6.33404C0.514207 9.06342 2.13524 13.8188 2.20591 14.02C2.26437 14.1867 2.44669 14.2751 2.61398 14.2162C2.78095 14.1576 2.86853 13.975 2.81004 13.808C2.79442 13.7628 1.25751 9.26088 4.02394 6.81371C5.44388 5.55791 7.21033 5.0032 8.99551 5.25116C10.797 5.50164 12.4264 6.57481 13.4552 8.17754C13.4677 8.20054 14.7188 10.4999 13.8417 11.6694C13.3958 12.2639 12.6656 12.5719 11.9777 12.4538C11.3335 12.343 10.8573 11.893 10.6366 11.1866C10.1897 9.75604 9.68564 8.57186 8.02396 8.57186H8.02332C7.12464 8.61033 5.7003 9.20101 5.69093 10.6208C5.66341 10.9634 5.41482 15.3748 10.4202 16.6626C10.4467 16.6695 10.4736 16.6727 10.5002 16.6727C10.6428 16.6727 10.7729 16.5769 10.8101 16.4323C10.8542 16.2611 10.751 16.0865 10.5796 16.0425C5.9442 14.8497 6.31162 10.8392 6.32946 10.6696C6.3307 10.6582 6.33134 10.6464 6.33134 10.6349C6.33134 9.61534 7.48987 9.23572 8.03709 9.21194C9.16657 9.21194 9.54837 9.85033 10.0255 11.3777C10.317 12.3113 10.989 12.9335 11.8692 13.085C12.7895 13.2432 13.7654 12.8386 14.3539 12.0538Z"
        fill="#00006E"
        stroke="#00006E"
        stroke-width="0.4"
      />
      <path
        d="M8.16745 10.293C7.98765 10.2734 7.8341 10.4027 7.81566 10.5785C7.69058 11.7861 8.22998 13.0381 9.25877 13.9274C10.0952 14.6504 11.1199 15.0385 12.129 15.0385C12.4452 15.0385 12.7597 15.0005 13.0665 14.9226C13.2378 14.8791 13.3417 14.705 13.2982 14.5335C13.2544 14.362 13.0796 14.2583 12.9089 14.3019C11.826 14.5772 10.6177 14.2556 9.67779 13.4429C8.80692 12.6903 8.34913 11.6441 8.45294 10.6444C8.47108 10.4685 8.34319 10.3113 8.16745 10.293Z"
        fill="#00006E"
        stroke="#00006E"
        stroke-width="0.4"
      />
      <path
        d="M8.10925 7.25564H8.30627C10.0742 7.25564 11.6049 8.57445 11.8663 10.3234L11.9464 10.8581C11.9726 11.0329 12.1321 11.1548 12.3107 11.1273C12.4855 11.1012 12.6059 10.9381 12.5799 10.7633L12.4998 10.2286C12.1918 8.16873 10.3888 6.61523 8.30627 6.61523H8.10925C6.67147 6.61523 5.34315 7.26817 4.46476 8.40652C3.58579 9.5457 3.2909 10.9975 3.65614 12.3898C4.11111 14.1247 5.02263 15.4505 6.36536 16.3301C6.41945 16.3656 6.48042 16.3825 6.54046 16.3825C6.6449 16.3825 6.74716 16.3315 6.80874 16.2377C6.90539 16.0898 6.8641 15.8914 6.71619 15.7945C5.51012 15.0043 4.68897 13.8042 4.27559 12.2274C3.96101 11.0287 4.21492 9.77865 4.97166 8.79772C5.72777 7.81771 6.87129 7.25564 8.10925 7.25564Z"
        fill="#0075F3"
        stroke="#0075F3"
        stroke-width="0.4"
      />
    </svg>
  );
}
