import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Styles from "./blogLandingPage.module.scss";
import SiteHeader from "../../Shared/site-header/siteHeader";
import SiteFooter from "../../Shared/site-footer/siteFooter";
import BannerCard from "../../Shared/banner-card/bannerCard";
import BannerTickWhite from "../../assets/images/bannerTickWhite.svg";
import { BannerCardButtonTriggered } from "../../Shared/eventsNames";
import { useNavigate, useParams } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import useBlogStore from "../../store/blog";
import moment from "moment";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReactMarkdown from "react-markdown";
import { getBlogs } from "../../api/blog";
import BlogPageSkeleton from "./blogPageSkeletion";
import gfm from "remark-gfm";
import { googlePageView } from "../../analytics/google";
import { makeArrayBlogCategory, makeArrayBlogTags } from "../../utils/helper";

export default function BlogLandingPage() {
  googlePageView("/blog-landing-page", "Blogs Landing Page");
  const params = useParams();
  const navigate = useNavigate();
  const { blogs, categories, setCategories, setBlogs } = useBlogStore();
  const [isLoading, setIsLoading] = useState(true);
  const filteredTags = makeArrayBlogTags(blogs);
  const blog = blogs.find((blog) => blog.id === params.id);
  const handleButtonClick = () => {
    BannerCardButtonTriggered({
      properties: "BannerCard Button on Blogs Landing Page",
    });
  };

  const fetchBlogDetails = async () => {
    try {
      const res = await getBlogs();
      if (res.data) {
        setBlogs(res.data.zoopStackBlogs);
        const categories = makeArrayBlogCategory(res.data.zoopStackBlogs);
        setCategories(["All", ...categories]);
      }
    } catch (err) {
      console.log("Error in fetching blog", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!blog) {
      fetchBlogDetails();
    } else {
      setIsLoading(false);
    }
  }, [blog]);
  return (
    <div>
      <Helmet>
        <title>{blog?.seo_title || blog?.title}</title>
        <meta name="description" content={blog?.seo_description || ""} />
        <meta property="og:title" content={blog?.seo_title || blog?.title} />
        <meta property="og:description" content={blog?.seo_description || ""} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://stack.zoop.one/blogs/${blog?.id}/${blog?.title}`}
        />
        <meta
          property="og:image"
          content={`https://cms.zoop.one${blog?.cover_banner.url}`}
        />
        <meta name="keywords" content={blog?.seo_keywords || ""} />
      </Helmet>
      <SiteHeader page="blogLanding" />
      {isLoading ? (
        <BlogPageSkeleton />
      ) : (
        <>
          <div className={`${Styles.mainHeader} row`}>
            <div className={`${Styles.heading} col-lg-10 col-12`}>
              {blog?.title}
            </div>
            <div
              className={`${Styles.headerDate} col-lg-2 col-12`}
            >{`Posted on ${moment(blog?.published_at).format(
              "MMMM DD, YYYY"
            )}`}</div>
          </div>

          <div className={Styles.section1}>
            <div className={Styles.headerImage}>
              <LazyLoadImage
                src={`https://cms.zoop.one${blog?.cover_banner.url}`}
                alt="blog-image"
                style={{ width: "100%", height: "100%" }}
                effect="blur"
              />
              {/* <img src="" /> */}
              <div className={Styles.detailTextContainer}>
                <ReactMarkdown
                  children={blog?.description as any}
                  remarkPlugins={[gfm]}
                  rehypePlugins={[rehypeRaw]}
                  skipHtml={false}
                  components={{
                    img: (props: any) => (
                      <LazyLoadImage
                        {...props}
                        src={`https://cms.zoop.one${props.src}`}
                        alt={props.alt || "blog-image"}
                        style={{ width: "100%", height: "100%" }}
                        effect="blur"
                      />
                    ),
                  }}
                />
              </div>

              <div
                className={`${Styles.tagsDetailContainer} ${Styles.pageTagContainer}`}
              >
                {blog?.tags.split(",").map((pageTag, index) => (
                  <div key={index} className={Styles.tagsDetail}>
                    <div>{pageTag}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className={Styles.categoryContainer}>
              <div className={Styles.headingContainer}>
                <p className={Styles.categoryHeading}>Categories</p>
                <div className={Styles.line}></div>
              </div>
              <div className={Styles.categoryDetailContainer}>
                {categories.map((category, index) => (
                  <div key={index} className={Styles.categoryDetail}>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(
                          `/blogs?category=${category
                            .toLowerCase()
                            .replaceAll(" ", "_")}`
                        )
                      }
                    >
                      {category}
                    </div>
                    {/* <div>
                      {category.toLowerCase() === "all"
                        ? blogs.length
                        : blogs.filter((blog) =>
                            blog.category.includes(category)
                          ).length}
                    </div> */}
                  </div>
                ))}
              </div>
              {/* <div className={Styles.headingContainer2}>
                <p className={Styles.categoryHeading}>Tags Cloud</p>
                <div className={Styles.line}></div>
              </div> */}
              {/* <div className={Styles.tagsDetailContainer}>
                {filteredTags.map((tag, index) => (
                  <div key={index} className={Styles.tagsDetail}>
                    <div>{tag}</div>
                  </div>
                ))}
              </div> */}
            </div>
          </div>
        </>
      )}
      <div className="container">
        <BannerCard
          tickImage={BannerTickWhite}
          text={"Ready to take your business to new heights?"}
          button={{ text: "Get free Demo", onClick: handleButtonClick }}
        />
      </div>

      <SiteFooter />
    </div>
  );
}
