import React from "react";
import Styles from "../product-verify/productVerify.module.scss";
import Button from "../../Shared/button/button";
import { MEDIUM } from "../../Shared/buttonSize";

export interface productVerifyProps {
  heading: string;
  contentText: string;
  buttonText: string;
  onButtonClick: () => void;
  imgSrc: string;
  link: string;
}
export default function productVerify({
  heading,
  contentText,
  buttonText,
  onButtonClick,
  imgSrc,
  link,
}: productVerifyProps) {
  return (
    <div className={Styles.productVerifyMainContainer}>
      {/* content */}
      <div className={Styles.main}>
        <div className={Styles.productVerifyTextContainer}>
          <h1 className={Styles.productVerifyHeading}>{heading}</h1>
          <p className={Styles.productVerifyContentText}>{contentText}</p>
        </div>
        <a href={link}>
          <Button
            onClick={onButtonClick}
            className={Styles.mainBtn}
            size={MEDIUM}
            type="button"
          >
            {buttonText}
          </Button>
        </a>
      </div>
      <div className={Styles.productVerifyImageContainer}>
        <img className={Styles.productVerifyImg} src={imgSrc} />
      </div>
    </div>
  );
}
