import React from "react";

export default function ColorTravel() {
  return (
    <svg
      width="25"
      height="27"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3333 16.7359H3C2.46957 16.7359 1.96086 16.528 1.58579 16.158C1.21071 15.788 1 15.2861 1 14.7628V2.92427C1 2.40097 1.21071 1.8991 1.58579 1.52908C1.96086 1.15905 2.46957 0.951172 3 0.951172H12.3333C12.8638 0.951172 13.3725 1.15905 13.7475 1.52908C14.1226 1.8991 14.3333 2.40097 14.3333 2.92427V14.7628C14.3333 15.2861 14.1226 15.788 13.7475 16.158C13.3725 16.528 12.8638 16.7359 12.3333 16.7359ZM3 2.26657C2.82319 2.26657 2.65362 2.33586 2.5286 2.4592C2.40357 2.58255 2.33333 2.74983 2.33333 2.92427V14.7628C2.33333 14.9373 2.40357 15.1046 2.5286 15.2279C2.65362 15.3512 2.82319 15.4205 3 15.4205H12.3333C12.5101 15.4205 12.6797 15.3512 12.8047 15.2279C12.9298 15.1046 13 14.9373 13 14.7628V2.92427C13 2.74983 12.9298 2.58255 12.8047 2.4592C12.6797 2.33586 12.5101 2.26657 12.3333 2.26657H3Z"
        fill="#00006E"
        stroke="white"
        stroke-width="0.3"
      />
      <path
        d="M12.0038 6.16351C11.7069 5.84373 10.9302 6.04931 10.6333 6.30056C10.3364 6.55182 8.32631 7.89947 8.32631 7.89947C8.32631 7.89947 6.93298 7.87663 6.27058 7.87663C5.58533 7.87663 5.60817 8.33346 5.97364 8.42482C6.3391 8.51619 6.77309 8.79029 6.77309 8.79029L5.31123 9.79532C5.31123 9.79532 4.51178 9.61258 3.73517 9.45269C2.95856 9.2928 2.82151 9.72679 3.20981 9.97805C3.59812 10.2293 5.26555 11.2115 5.26555 11.2115L7.48118 10.0694C7.48118 10.0694 7.52686 10.6861 7.52686 11.0059C7.52686 11.3257 7.86948 11.8054 8.16642 11.2343C8.46336 10.6633 9.10293 9.11007 9.10293 9.11007C9.10293 9.11007 11.1815 7.53401 11.5698 7.21422C11.9353 6.8716 12.2779 6.50614 12.0038 6.16351V6.16351ZM11.25 6.84876C10.8846 7.16854 8.82883 8.74461 8.80599 8.74461L8.71462 8.81313L8.66894 8.92734C8.66894 8.95018 8.28063 9.88668 7.96085 10.5491C7.93801 10.275 7.91517 10.0237 7.91517 10.0009L7.84664 9.36133L5.26555 10.6861C4.99145 10.5262 4.48894 10.2293 4.07779 9.97805C4.69451 10.0923 5.19703 10.2293 5.21987 10.2293L5.42544 10.275L7.61823 8.76745L7.02435 8.40198C7.00151 8.40198 6.97866 8.37914 6.91014 8.33346C7.57254 8.33346 8.32631 8.3563 8.32631 8.3563H8.46336L8.57757 8.26493C8.78314 8.12789 10.6105 6.89444 10.9074 6.64319C10.9759 6.57466 11.2043 6.4833 11.4328 6.46045H11.6155C11.5927 6.52898 11.5013 6.64319 11.25 6.84876V6.84876Z"
        fill="#0075F3"
        stroke="#0075F3"
        stroke-width="0.2"
      />
    </svg>
  );
}
