import {
  SUCCESS,
  DANGER,
  WHITE,
  ACCENTCOLOR,
  ACCENTDARKCOLOR,
  LINKHOVERBACKGROUND,
  SUCCESSDARKCOLOR,
  DANGERDARKCOLOR,
  DISABLEDCOLOR,
  PRIMARYCOLOR,
  GRAY,
  DARKBLUE,
} from "./colors";

/** PRIMARY BUTTON */

export const primaryButtonStyle = {
  borderRadius: "50px",
  backgroundColor: DARKBLUE,
  color: WHITE,
  border: 0,
};

export const primaryButtonHoverStyle = {
  borderRadius: "50px",
  backgroundColor: WHITE,
  color: DARKBLUE,
  border: 0,
  transition: "all 0.3s ease",
};

/** PRIMARY LIGHT BUTTON */
export const primaryLightButtonStyle = {
  background: WHITE,
  color: DARKBLUE,
  border: `1px solid ${DARKBLUE}`,
};

export const primaryLightButtonHoveredStyle = {
  background: DARKBLUE,
  color: WHITE,
  transition: "all 0.3s ease",
  border: `1px solid ${DARKBLUE}`,
};

/** PRIMARY LIGHT TRANSLUCENT BUTTON */

export const primaryLightTransButtonStyle = {
  background: "white",
  color: DARKBLUE,
  border: "none",
  outline: "none",
};

export const primaryLightWhiteTransButtonStyle = {
  background: "rgba(255, 255, 255, 0.0)",
  color: WHITE,
  border: "1px solid white",
  outline: "none",
};

export const primaryLightTransButtonHoveredStyle = {
  background: WHITE,
  color: DARKBLUE,
  transition: "all 0.3s ease",
  border: "none",
};

export const submitButtonStyle = {
  borderRadius: "8px",
  backgroundColor: GRAY,
  buttonTextColor: WHITE,
  border: 0,
};

export const submitButtonHoverStyle = {
  borderRadius: "8px",
  backgroundColor: GRAY,
  buttonTextColor: WHITE,
  border: 0,
};

export const borderButtonStyle = {
  borderRadius: "8px",
  buttonTextColor: ACCENTCOLOR,
  background: "transparent",
  border: `2px solid ${ACCENTCOLOR}`,
};
export const borderButtonHoverStyle = {
  borderRadius: "8px",
  buttonTextColor: ACCENTCOLOR,
  background: LINKHOVERBACKGROUND,
  border: `2px solid ${ACCENTCOLOR}`,
};
export const cancelButtonStyle = {
  borderRadius: "8px",
  background: "#DDECFC",
  color: ACCENTCOLOR,
  buttonTextColor: ACCENTCOLOR,
  boxShadow: "none",
  border: "none",
};
export const cancelButtonHoverStyle = {
  borderRadius: "8px",
  background: "#DDECFC",
  border: "none",
  buttonTextColor: WHITE,
  boxShadow: "none",
};
export const successButtonStyle = {
  borderRadius: "8px",
  backgroundColor: SUCCESS,
  buttonTextColor: WHITE,
  border: 0,
};
export const successButtonHoverStyle = {
  borderRadius: "8px",
  backgroundColor: SUCCESSDARKCOLOR,
  buttonTextColor: WHITE,
  border: 0,
};
export const dangerButtonStyle = {
  borderRadius: "8px",
  backgroundColor: DANGER,
  buttonTextColor: WHITE,
  border: 0,
};
export const dangerButtonHoverStyle = {
  borderRadius: "8px",
  backgroundColor: DANGERDARKCOLOR,
  buttonTextColor: WHITE,
  border: 0,
};

export const disabledButtonStyle = {
  borderRadius: "8px",
  backgroundColor: DISABLEDCOLOR,
  buttonTextColor: PRIMARYCOLOR,
  border: 0,
};

//For popups in zpin flow and demo center exclusively
export const submitButtonStylePopup = {
  borderRadius: "8px",
  backgroundColor: ACCENTCOLOR,
  buttonTextColor: WHITE,
  border: 0,
  marginBottom: "20px",
};

export const submitButtonHoverStylePopup = {
  borderRadius: "8px",
  backgroundColor: ACCENTDARKCOLOR,
  buttonTextColor: WHITE,
  border: 0,
  marginBottom: "20px",
};

export const primaryButtonStylePopup = {
  borderRadius: "8px",
  backgroundColor: ACCENTCOLOR,
  buttonTextColor: WHITE,
  border: 0,
  marginBottom: "20px",
  minWidth: "190px",
  fontFamily: "'Outfit' sans-serif",
  fontWeight: "500",
};

export const primaryButtonHoverStylePopup = {
  borderRadius: "8px",
  backgroundColor: ACCENTDARKCOLOR,
  buttonTextColor: WHITE,
  border: 0,
  marginBottom: "20px",
  minWidth: "190px",
  fontFamily: "'Outfit' sans-serif",
  fontWeight: "500",
};

export const disabledButtonStylePopup = {
  borderRadius: "8px",
  backgroundColor: ACCENTCOLOR,
  buttonTextColor: WHITE,
  border: 0,
  marginBottom: "20px",
  minWidth: "190px",
};

export const disabledButtonHoverStylePopup = {
  borderRadius: "8px",
  backgroundColor: ACCENTDARKCOLOR,
  buttonTextColor: WHITE,
  border: 0,
  marginBottom: "20px",
  minWidth: "190px",
};

export const disabledButtonStyleOkyc = {
  borderRadius: "8px",
  outline: "none",
  backgroundColor: ACCENTDARKCOLOR,
  buttonTextColor: WHITE,
  border: 0,
  marginBottom: "20px",
  minWidth: "130px",
  minHeight: "44px",
  cursor: "not-allowed",
};

export const disabledButtonHoverStyleOkyc = {
  borderRadius: "8px",
  outline: "none",
  backgroundColor: ACCENTDARKCOLOR,
  buttonTextColor: WHITE,
  border: 0,
  marginBottom: "20px",
  minWidth: "130px",
  minHeight: "44px",
  cursor: "not-allowed",
};

export const primaryButtonStyleOkyc = {
  borderRadius: "8px",
  outline: "none",
  backgroundColor: ACCENTCOLOR,
  buttonTextColor: WHITE,
  border: 0,
  marginBottom: "20px",
  minWidth: "100px",
  minHeight: "44px",
  fontFamily: "'Outfit' sans-serif",
  fontWeight: "500",
};

export const primaryButtonHoverStyleOkyc = {
  outline: "none",
  borderRadius: "8px",
  backgroundColor: ACCENTDARKCOLOR,
  buttonTextColor: WHITE,
  border: 0,
  marginBottom: "20px",
  minWidth: "130px",
  fontFamily: "'Outfit' sans-serif",
  fontWeight: "500",
  minHeight: "44px",
};
