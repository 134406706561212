import React from "react";

export default function ColorRupee() {
  return (
    <svg
      width="27"
      height="30"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.18954 0.298872C3.77697 0.298872 0.199219 3.87663 0.199219 8.2927C0.199219 10.4113 1.04104 12.4457 2.53879 13.9434C5.65354 17.0722 10.7115 17.0863 13.8403 13.9715C16.9691 10.8568 16.9831 5.79879 13.8683 2.67001C13.8578 2.65949 13.8473 2.64897 13.8403 2.64195C12.346 1.13368 10.3116 0.291856 8.18954 0.298872ZM8.18954 15.5815C4.16983 15.5815 0.897232 12.3124 0.897232 8.2927C0.897232 4.27299 4.16983 1.00039 8.18954 1.00039C12.2093 1.00039 15.4783 4.26948 15.4783 8.28919C15.4783 12.3089 12.2093 15.5815 8.18954 15.5815Z"
        fill="#00006E"
        stroke="#00006E"
        stroke-width="0.4"
      />
      <path
        d="M10.914 5.63456C10.914 5.41446 10.7339 5.23438 10.5138 5.23438H6.46391C6.24381 5.23438 6.06373 5.41446 6.06373 5.63456C6.06373 5.85466 6.24381 6.03475 6.46391 6.03475H7.62446C8.20473 6.03875 8.71697 6.40692 8.90105 6.95518H6.46391C6.24381 6.95518 6.06373 7.13526 6.06373 7.35537C6.06373 7.57547 6.24381 7.75555 6.46391 7.75555H8.90105C8.83702 7.96365 8.72097 8.15574 8.5689 8.31181C8.32078 8.56793 7.98062 8.712 7.62446 8.716H6.46391C6.25182 8.708 6.07173 8.87608 6.06373 9.08817C6.05973 9.20023 6.10375 9.31228 6.18779 9.38831L9.08514 12.1256C9.24521 12.2777 9.50133 12.2697 9.6494 12.1056C9.79747 11.9535 9.79347 11.7094 9.6414 11.5613C9.6374 11.5573 9.63339 11.5573 9.63339 11.5533L7.46838 9.51637H7.62045C8.18872 9.51237 8.73297 9.28427 9.13316 8.88008C9.4413 8.57593 9.6494 8.18375 9.72143 7.75555H10.5138C10.7339 7.75555 10.914 7.57547 10.914 7.35537C10.914 7.13526 10.7339 6.95518 10.5138 6.95518H9.72143C9.66541 6.61502 9.51334 6.29487 9.28523 6.03475H10.5098C10.7339 6.03475 10.914 5.85466 10.914 5.63456Z"
        fill="#0075F3"
        stroke="#0075F3"
        stroke-width="0.4"
      />
    </svg>
  );
}
