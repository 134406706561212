import React, { useEffect, useRef, useState } from "react";
import Styles from "./gaming.module.scss";
import SiteHeader from "../../../Shared/site-header/siteHeader";
import { googlePageView, googleEvent } from "../../../analytics/google";
import GAEvent from "../../../analytics/events";
import SiteFooter from "../../../Shared/site-footer/siteFooter";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import HomeInfoCarousel from "../../../Shared/home-info-carousel/homeInfoCarousel";
import BrandMarquee from "../../../Shared/brand-marquee/brandMarquee";
import DividerImage from "../../../assets/images/industry/gaming/divider.svg";
import GamingDisplayImage1 from "../../../assets/images/industry/gaming/gaming_asset1.webp";
import GamingDisplayImage2 from "../../../assets/images/industry/gaming/gaming_asset2.webp";
import GamingDisplayImage3 from "../../../assets/images/industry/gaming/gaming_asset3.webp";
import GamingDisplayImage4 from "../../../assets/images/industry/gaming/gaming_asset4.webp";
import BannerCard from "../../../Shared/banner-card/bannerCard";
import BannerTickMark from "../../../assets/images/bannerTickWhite.svg";
import ImpactCards, {
  StatsCardContainer,
} from "../../impact-cards/impactCardComp";
import Clients from "../../../assets/images/clients.svg";
import DocumentVerified from "../../../assets/images/documentVerified.svg";
import IncreaseInSales from "../../../assets/images/increase_in_sales.svg";
import FraudLossesImage from "../../../assets/images/fraud_losses.svg";
import SharedStyles from "../../../Shared/shared.module.scss";
import Line1 from "../../../assets/images/industry/gaming/line1.svg";
import Line2 from "../../../assets/images/industry/gaming/line2.svg";
import Line3 from "../../../assets/images/industry/gaming/line3.svg";
import Button from "../../../Shared/button/button";
import { LARGE } from "../../../Shared/buttonSize";
import {
  primaryLightButtonHoveredStyle,
  primaryLightTransButtonStyle,
} from "../../../Shared/buttonStyles";
import {
  BannerCardButtonTriggered,
  PageScrollTriggered,
} from "../../../Shared/eventsNames";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function Gaming() {
  googlePageView("/gaming", "Gaming");
  const sliderRef = useRef<Slider>(null);
  const [activeDotIndex, setActiveDotIndex] = useState<number>(0);
  const handleBeforeChange = (currentSlide: number, nextSlide: number) => {
    setActiveDotIndex(nextSlide);
  };
  useEffect(() => {
    const handleScroll = () => {
      PageScrollTriggered({ properties: "Gaming Page Scrolled" });
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleButtonClick = () => {
    BannerCardButtonTriggered({
      properties: "BannerCard Button on Gaming Page",
    });
  };
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    beforeChange: handleBeforeChange,
  };

  const slidesData = [
    {
      heading: "Gaming Platform",
      headingClassName: Styles.heading1,
    },
  ];
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>ZOOPSTACK</title>
          <meta
            name="description"
            content="Powering the Indian Gaming Industry with Cutting-Edge Gaming API. Explore the thriving Indian gaming industry with our advanced gaming API solutions."
          />
          <meta
            name="title"
            content="Use Best Gaming Customer Verification API for Indian Gaming Industry  "
          />
          <meta
            name="Keywords"
            content="indian gaming industry, gaming api, secure gaming, game apis"
          />
        </Helmet>
      </HelmetProvider>
      <div className={SharedStyles.mainContainer}>
        <SiteHeader page="gaming" />
        <div className={Styles.fullBleedContainer}>
          <div className={Styles.homeInfoMainContainer}>
            <Slider {...settings} ref={sliderRef}>
              {slidesData.map((slide, index) => (
                <HomeInfoCarousel
                  key={index}
                  heading={slide.heading}
                  headingClassName={slide.headingClassName}
                />
              ))}
            </Slider>
            <div className={` ${Styles.homeInfoContent}`}>
              Create a secure gaming environment, improve sign-up rates and beat
              cybercriminals with ease. ZOOPSTACK’s APIs help prevent
              unauthorized access and protect user accounts, ensuring that only
              authorized individuals have access to gaming services and
              features.
            </div>
            <a style={{ marginTop: "50px" }} href="/contactus">
              <Button
                hoveredStyle={primaryLightButtonHoveredStyle}
                size={LARGE}
                style={primaryLightTransButtonStyle}
                onCllck={() =>
                  googleEvent(
                    "Button_Click",
                    GAEvent.GetAPIKEYS,
                    GAEvent.GetAPIKEYS
                  )
                }
              >
                Get API Keys
              </Button>
            </a>
          </div>
        </div>
        <div className={Styles.brandMarqueeContainer}>
          <BrandMarquee
            colorState={Styles.retailMarqueecolor}
            faderColorL={Styles.retailMarqueeL}
            faderColorR={Styles.retailMarqueeR}
            // page="gaming"
          />
        </div>
        <div className={Styles.gamingBackground}>
          <div className={Styles.reinventHeadingContainer}>
            <div className={Styles.reinventHeading}>Reinvent Onboarding</div>
            <div className={Styles.reinventContent}>
              Create a secure gaming environment, improve sign-up rates and beat
              cyber criminals with ease
            </div>
          </div>

          <div className={Styles.section1}>
            <div className={Styles.sectionTextContainer}>
              <div className={Styles.sectionHeading}>
                Up to 79% Faster, Frictionless Consumer Onboarding
              </div>
              <div className={Styles.sectionContent}>
                Sports Betting and iGaming platforms often suffer from high
                onboarding abandonment rates
              </div>
              <div className={Styles.sectionContent}>
                ZOOPSTACK helps in onboarding completion rates and reduces fraud
                by providing advanced APIs
              </div>
            </div>
            <div className={Styles.sectionImage1}>
              <img src={GamingDisplayImage1} />
            </div>
          </div>

          <div className={Styles.section2}>
            <div className={Styles.section2Images}>
              <img src={Line1} className={Styles.section2Line3} />
              <div className={Styles.sectionImage2}>
                <img src={GamingDisplayImage2} />
              </div>
            </div>
            <div className={Styles.sectionTextContainer2}>
              <div className={Styles.sectionHeading}>
                Protection from Fraud and Illegal Activities
              </div>
              <div className={Styles.sectionContent}>
                Protect your business and gamers from fraud and other illegal
                activities. Ensure the credibility of your customers
              </div>
            </div>
          </div>

          <div className={Styles.section3}>
            <div className={Styles.sectionTextContainer3}>
              <div className={Styles.sectionHeading}>
                Adhere to AML/KYC Compliance for Gaming Sites
              </div>
              <div className={Styles.sectionContent}>
                Accelerate regulatory compliance including Know Your Customer
                (KYC) and Anti-Money Laundering (AML) in near real-time
              </div>
            </div>
            <div>
              <div className={Styles.section3LineContainer}>
                <img className={Styles.section3Line2} src={Line2} />
              </div>
              <div className={Styles.sectionImage3}>
                <img src={GamingDisplayImage3} />
              </div>
            </div>
          </div>

          <div className={Styles.section4}>
            <div className={Styles.section4Images}>
              <img className={Styles.section4Line3} src={Line3} />
              <div className={Styles.sectionImage4}>
                <img src={GamingDisplayImage4} />
              </div>
            </div>
            <div className={Styles.sectionTextContainer4}>
              <div className={Styles.sectionHeading}>Onboard Right Gamers</div>
              <div className={Styles.sectionContent}>
                Verifying the date of birth of a player reduces underage
                gambling
              </div>
              <div className={Styles.sectionContent}>
                Ensure a low-friction onboarding process by abiding by the
                geographic jurisdiction and age verification rules requirements
              </div>
            </div>
          </div>
          <div
            className={`${Styles.retailImpactCard} ${Styles.impactCardsContainer} container`}
          >
            <StatsCardContainer
              backgroundClassName={"col-12 col-sm-6 col-md-3 gy-3"}
              headingDisplay={Styles.impactHeading}
              isTransparent
              impactCardsData={[
                {
                  image: Clients,
                  alt: "Drop Off Rate",
                  title: "300+",
                  subtitle: "Number of Clients",
                },
                {
                  image: DocumentVerified,
                  alt: "Reduction in Turn Around Time",
                  title: "27M+",
                  subtitle: "Document Verified",
                },
                {
                  image: IncreaseInSales,
                  alt: "Saved In Fraud Losses",
                  title: "99.8%",
                  subtitle: "Success Rate",
                },
                {
                  image: FraudLossesImage,
                  alt: "Increase In Productivity of Sales",
                  title: "46%",
                  subtitle: "Increase in Productivity of Sales",
                },
              ]}
            />
          </div>
          <div className="container">
            <BannerCard
              text={"Ready to take your business to new heights?"}
              button={{ text: "Get free Demo", onClick: handleButtonClick }}
              backGroundClassName={Styles.bannerBackGroundcolor}
              headingClassName={Styles.bannerHeading}
              tickMarkTextClassName={Styles.bannerTickText}
              tickImage={BannerTickMark}
            />
          </div>
        </div>
        <SiteFooter footerBackground={Styles.footeBackgroundColor} />
      </div>
    </>
  );
}
