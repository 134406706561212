import React, { useEffect } from "react";
import SiteHeader from "../../Shared/site-header/siteHeader";
import Styles from "../contact-us/contactUs.module.scss";
import { googlePageView, googleEvent } from "../../analytics/google";
import ContactUsImage2 from "../../assets/images/ContactUsImage1.png";
import ContactUsImage1 from "../../assets/images/ContactUsImage2.png";
import ContactUsForm from "../../Shared/contact-us-form/contactUsForm";
import SharedStyles from "../../Shared/shared.module.scss";
import BannerCard from "../../Shared/banner-card/bannerCard";
import BusinessCardComp from "../../Shared/business-card/businessCardComp";
import SiteFooter from "../../Shared/site-footer/siteFooter";
import BannerTickWhite from "../../assets/images/bannerTickWhite.svg";
import ContactUsBackground from "../../assets/images/BackgrounImages/contact_us_bg.webp";
import {
  BannerCardButtonTriggered,
  PageScrollTriggered,
} from "../../Shared/eventsNames";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function ContactUs() {
  googlePageView("/contact-us", "ContactUs");
  googleEvent("ContactUs Page Clicked", "ContactUs", "ContactUs Page Clicked");
  useEffect(() => {
    const handleScroll = () => {
      PageScrollTriggered({ properties: "ContactUs Page Scrolled" });
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleButtonClick = () => {
    BannerCardButtonTriggered({ properties: "BannerCard Button on ContactUs" });
  };
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>ZOOPSTACK</title>
          <meta
            name="description"
            content="Have questions or need assistance? Contact us directly through our website. We are here to help and provide prompt assistance. "
          />
          <meta name="title" content="Contact Us Page | Contact Us " />
          <meta name="Keywords" content="contact us , contact us page" />
        </Helmet>
      </HelmetProvider>
      <div className={SharedStyles.mainContainer}>
        <SiteHeader />
        <div className={`${Styles.contactUsBackground}`}>
          <div
            className={`${Styles.contactUsBackgroundImage}  ${Styles.formContainer}`}
          >
            {/* <div
              // src={ContactUsBackground}
              className={Styles.contactUsBackgroundImage}
            > */}
            <div className={Styles.contactUsForm}>
              <ContactUsForm />
            </div>
          </div>

          {/* Cards */}
          <div className={Styles.contactUsCards}>
            <BusinessCardComp
              image={ContactUsImage1}
              cardTitle={"Zoop Headquartes"}
              cardSubTitle={
                "Tower B, Panchsheel Business Park, Clover Park,Viman Nagar, Pune, Maharashtra 411014"
              }
            />
            <BusinessCardComp
              image={ContactUsImage2}
              cardTitle={"Email Us at"}
              cardSubTitle={"hey@zoop.one"}
              cardSubTitle2={"sales@zoop.one"}
            />
            <BusinessCardComp
              image={ContactUsImage1}
              cardTitle={"Business Hours"}
              cardSubTitle={"Monday to Friday 9am to 6pm"}
            />
          </div>
          <BannerCard
            text={"Ready to take your business to new heights?"}
            button={{ text: "Get free Demo", onClick: handleButtonClick }}
            tickImage={BannerTickWhite}
            backGroundClassName={Styles.bannerBackground}
          />
          <SiteFooter />
        </div>
      </div>
    </>
  );
}
