import { create } from "zustand";
import { BlogDetail } from "../types/blog";

type BlogState = {
  blogs: BlogDetail[];
  categories: string[];
  setBlogs: (e: BlogDetail[]) => void;
  setCategories: (e: string[]) => void;
  blogLoading: boolean;
  setBlogLoading: (e: boolean) => void;
};
const useBlogStore = create<BlogState>((set) => ({
  blogs: [],
  categories: [],
  blogLoading: false,
  setBlogs: (blogList: BlogDetail[]) => set(() => ({ blogs: blogList })),
  setCategories: (categories: string[]) =>
    set(() => ({ categories: categories })),
  setBlogLoading: (blogLoading) => set(() => ({ blogLoading })),
}));

export default useBlogStore;
