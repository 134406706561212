import React from "react";

export default function ColorContract() {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9091 16H5.09091C4.92364 15.9956 4.86909 15.9731 4.77527 15.928C4.32945 15.7135 4.22982 14.992 4.66982 14.6793C4.792 14.5927 4.94182 14.5498 5.09091 14.5455H10.9091C10.9229 14.5462 10.936 14.5462 10.9498 14.5469C11.4829 14.5913 11.848 15.3135 11.4516 15.7571C11.3331 15.8895 11.1658 15.976 10.9905 15.9956C10.9542 15.9993 10.9455 15.9993 10.9091 16ZM15.2727 13.0909H0.727273C0.336727 13.0742 0.0167273 12.7549 0 12.3636V0.727273C0.0167273 0.336727 0.336 0.0167273 0.727273 0H15.2727C15.2938 0.000727273 15.3149 0.00218195 15.336 0.00290923C15.6989 0.050182 15.984 0.356364 16 0.727273V9.45455C15.9978 9.51055 15.9985 9.52509 15.9891 9.58109C15.8895 10.1469 14.9789 10.4 14.6429 9.81818C14.5847 9.71782 14.5542 9.66109 14.5455 9.45455V1.45455H1.45455V11.6364H15.2727C15.2727 11.6364 15.6313 11.6596 15.8298 11.896C16.0604 12.1709 16.0436 12.6211 15.7869 12.8778C15.6822 12.9825 15.5455 13.0538 15.3993 13.08C15.3433 13.0895 15.3287 13.0887 15.2727 13.0909Z"
        fill="#00006E"
      />
      <path
        d="M3.63643 7.27216H6.54552C6.5877 7.2707 6.59861 7.27143 6.6408 7.26561C6.85243 7.23798 7.0597 7.05907 7.0597 7.05907L7.8008 6.31798L8.80443 8.32452C8.8248 8.36307 8.82916 8.37325 8.85389 8.40961C8.98334 8.5987 9.20661 8.72089 9.43571 8.7267C9.71716 8.73398 9.9688 8.51361 9.9688 8.51361L11.2102 7.27216H12.347C12.3906 7.2707 12.4023 7.27143 12.4459 7.26561C12.5608 7.24961 12.6713 7.20598 12.7659 7.13907C13.1913 6.8387 13.115 6.1238 12.6815 5.89907C12.5848 5.84889 12.5302 5.82416 12.347 5.81761H10.9092C10.867 5.81907 10.8561 5.81834 10.8139 5.82416C10.6022 5.85179 10.395 6.0307 10.395 6.0307L9.65389 6.7718L8.65025 4.76525C8.53025 4.54343 8.31425 4.39798 8.06771 4.36598C7.80807 4.3507 7.48589 4.57616 7.48589 4.57616L6.24443 5.81761H3.63643C3.59207 5.81907 3.58116 5.81834 3.53752 5.82416C3.42261 5.84016 3.31207 5.88379 3.2168 5.9507C2.7768 6.26125 2.86916 6.9667 3.30189 7.1907C3.39861 7.24089 3.45243 7.26561 3.63643 7.27216Z"
        fill="#0075F3"
      />
    </svg>
  );
}
