import React from "react";
import Styles from "../persona/persona.module.scss";
import Button from "../../../Shared/button/button";
import { googleEvent } from "../../../analytics/google";
import GAEvent from "../../../analytics/events";
import {
  primaryLightTransButtonStyle,
  primaryLightWhiteTransButtonStyle,
} from "../../../Shared/buttonStyles";
import { MEDIUM } from "../../../Shared/buttonSize";
import { googlePageView } from "../../../analytics/google";
import StudentIconImage from "../../../assets/images/products/persona/student.png";
import EmployeeIconImage from "../../../assets/images/products/persona/employee.png";
import DoctorIconImage from "../../../assets/images/products/persona/doctor-nurses.png";
import ArmedIconImage from "../../../assets/images/products/persona/armed_forces.png";
import SeniorIconImage from "../../../assets/images/products/persona/senior_citizen.png";
import logo from "../../../assets/images/zoopStackWhiteLogo.svg";
import { TryZoopStackTriggered } from "../../../Shared/eventsNames";

const PersonaDetails = () => {
  googlePageView("/products/persona/unlock", "PersonaPage");
  const menuItems = [
    {
      title: "Persona Verification",
      link: "/persona",
      GAEvent: GAEvent.PesonaVerficationClick,
    },
    {
      title: "Use-Cases",
      link: "/persona/unlock",
      GAEvent: GAEvent.PesonauseCaseClick,
    },
    {
      title: "Blogs",
      link: "/blogs",
      GAEvent: GAEvent.BlogClick,
    },
  ];
  return (
    <div className={Styles.detailsMainContainer}>
      <div className={Styles.sideBarContainer}>
        <a
          style={{ textDecoration: "none", color: "white" }}
          href="../persona/unlock"
        >
          {"< back"}
        </a>
        <br />
        <br />
        <a href="\home">
          <img src={logo} alt="logo" />
        </a>
        <div className={Styles.menuItemsContainer}>
          {menuItems.map((item, index) => (
            <div
              className={Styles.menuLinks}
              onClick={() => {
                googleEvent("Menu_Click", item.GAEvent, item.GAEvent);
              }}
              key={index}
            >
              <a href={item.link}>{item.title}</a>
            </div>
          ))}
          <a href="/contactus">
            <Button
              btnTextClass={"btnLight"}
              hoveredStyle={primaryLightTransButtonStyle}
              size={MEDIUM}
              style={primaryLightWhiteTransButtonStyle}
              type="button"
              onClick={() =>
                TryZoopStackTriggered({
                  properties: "Try ZoopStack button on persona",
                })
              }
            >
              &#9654;&nbsp;&nbsp;&nbsp;Try ZOOPSTACK
            </Button>
          </a>
        </div>
      </div>
      <div className={Styles.contentMainContainer}>
        <div className={Styles.contentTitle}>
          India’s First Persona Verification Platform
        </div>
        <div className={Styles.contentInfo}>
          ZOOPSTACK’s PERSONA API is an outcome of 7+ years and 140 million plus
          monthly verifications enabling 250+ clients across industry
          verticals.Persona marketing with ZOOPSTACK is not just easy but also
          great in terms of technology integration.Persona marketing helps in
          37% faster customer acquisition.
        </div>
        <div className={Styles.contentSubTitle}>What is a Persona?</div>
        <div className={Styles.contentInfo}>
          <p>
            Personas are ‘Created’ mindsets to consider the goals, desires and
            limitations of your customers. They are used to guide decisions
            about a service, product, interaction, or feature.
          </p>
          <p>
            Persona Marketing is not for a single user. It is a representation
            of the goals and behaviours of a hypothesised group of
            users.Marketing teams must validate personas as imaging is always
            limited.
          </p>
        </div>
        <div className={Styles.contentSubTitle}>One Platform for All</div>

        <div className={Styles.profilePicsContainer}>
          <div className={Styles.profilePicsCard}>
            <div className={Styles.profilePic}>
              <img src={StudentIconImage} alt="Student Persona" />
            </div>
            <div className={Styles.title}>Students</div>
            <div className={`${Styles.subTitle} ${Styles.active}`}>Live</div>
          </div>

          <div className={Styles.profilePicsCard}>
            <div className={Styles.profilePic}>
              <img src={EmployeeIconImage} alt="Student Persona" />
            </div>
            <div className={Styles.title}>Corporate Employees</div>
            <div className={`${Styles.subTitle}`}>Upcoming</div>
          </div>

          <div className={Styles.profilePicsCard}>
            <div className={Styles.profilePic}>
              <img src={DoctorIconImage} alt="Student Persona" />
            </div>
            <div className={Styles.title}>Doctors/Nurses</div>
            <div className={`${Styles.subTitle}`}>Upcoming</div>
          </div>

          <div className={Styles.profilePicsCard}>
            <div className={Styles.profilePic}>
              <img src={ArmedIconImage} alt="Student Persona" />
            </div>
            <div className={Styles.title}>Armed Forces</div>
            <div className={`${Styles.subTitle}`}>Upcoming</div>
          </div>

          <div className={Styles.profilePicsCard}>
            <div className={Styles.profilePic}>
              <img src={SeniorIconImage} alt="Student Persona" />
            </div>
            <div className={Styles.title}>Senior Citizen</div>
            <div className={`${Styles.subTitle}`}>Upcoming</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonaDetails;
