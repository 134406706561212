import React, { useState } from "react";
import Styles from "../button-carousel/buttonCarousel.module.scss";
import PAN from "../../assets/images/PAN.svg";

type ButtonCarouselProps = {
  buttonImgSrc: string;
  btnText: string;
  onButtonClick: (no: number) => void;
};

export default function ButtonCarousel(props: ButtonCarouselProps) {
  const { buttonImgSrc, btnText, onButtonClick } = props;
  return (
    <div className={Styles.btnContent} onClick={() => onButtonClick(1)}>
      <img className={Styles.buttonImg} src={buttonImgSrc} />
      <div className={Styles.btnText}>{btnText}</div>
    </div>
  );
}
