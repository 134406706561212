import React, { useEffect, useRef, useState } from "react";
import SiteHeader from "../../../Shared/site-header/siteHeader";
import SiteFooter from "../../../Shared/site-footer/siteFooter";
import { googlePageView, googleEvent } from "../../../analytics/google";
import GAEvent from "../../../analytics/events";

import Styles from "./travel.module.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Clients from "../../../assets/images/clientsBlack.svg";
import DocumentVerified from "../../../assets/images/documentVerifiedBlack.svg";
import IncreaseInSales from "../../../assets/images/increaseSalesBlack.svg";
import FraudLossesImage from "../../../assets/images/successRateBlack.svg";
import HomeInfoCarousel from "../../../Shared/home-info-carousel/homeInfoCarousel";
import BrandMarquee from "../../../Shared/brand-marquee/brandMarquee";
import TravelAsset1 from "../../../assets/images/industry/Travel/travelAsset1.webp";
import TravelAsset2 from "../../../assets/images/industry/Travel/travelAsset2.webp";
import TravelAsset4 from "../../../assets/images/industry/Travel/travelAsset4.webp";
import TravelAsset5 from "../../../assets/images/industry/Travel/travelAsset5.png";
import TravelAsset6 from "../../../assets/images/industry/Travel/travelAsset6.webp";
import TravelAsset7 from "../../../assets/images/industry/Travel/travelAsset7.webp";
import TravelCircle from "../../../assets/images/industry/Travel/travelCircle.webp";
import BannerTickMark from "../../../assets/images/bannerTickWhite.svg";
import ImpactCards, {
  StatsCardContainer,
} from "../../impact-cards/impactCardComp";
import BannerCard from "../../../Shared/banner-card/bannerCard";
import SharedStyles from "../../../Shared/shared.module.scss";
import Button from "../../../Shared/button/button";
import { LARGE } from "../../../Shared/buttonSize";
import {
  primaryLightButtonHoveredStyle,
  primaryLightTransButtonStyle,
} from "../../../Shared/buttonStyles";
import {
  BannerCardButtonTriggered,
  PageScrollTriggered,
} from "../../../Shared/eventsNames";
import { Helmet, HelmetProvider } from "react-helmet-async";
import useScreen from "../../../utils/useScreen";

export default function Travel() {
  googlePageView("/industry/travel", "Travel");
  const { isMobile } = useScreen();
  const sliderRef = useRef<Slider>(null);
  const [activeDotIndex, setActiveDotIndex] = useState<number>(0);

  const handleBeforeChange = (currentSlide: number, nextSlide: number) => {
    setActiveDotIndex(nextSlide);
  };
  useEffect(() => {
    const handleScroll = () => {
      PageScrollTriggered({ properties: "Travel Page Scrolled" });
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleButtonClick = () => {
    BannerCardButtonTriggered({
      properties: "BannerCard Button on Travel Page",
    });
  };
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    beforeChange: handleBeforeChange,
  };

  const slidesData = [
    {
      heading: "Travel & Hospitality",
      headingClassName: Styles.heading1,
      contentClassName: Styles.slideContent,
    },
  ];
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>ZOOPSTACK</title>
          <meta
            name="description"
            content="Revolutionising the Travel Industry with Seamless Travel API Solutions. Discover the future of travel technology with our robust travel API. "
          />
          <meta name="title" content="ZOOPSTACK's Robust Travel API" />
          <meta
            name="Keywords"
            content="smart verify vendor, customer verification, travel api, travel api provider in india, travel api integration"
          />
        </Helmet>
      </HelmetProvider>
      <div className={SharedStyles.mainContainer}>
        <SiteHeader />
        <div className={Styles.travelBackground}>
          <div className={Styles.homeInfoMainContainer}>
            <Slider {...settings} ref={sliderRef}>
              {slidesData.map((slide, index) => (
                <HomeInfoCarousel
                  key={index}
                  heading={slide.heading}
                  headingClassName={slide.headingClassName}
                />
              ))}
            </Slider>
            <div className={` ${Styles.homeInfoContent}`}>
              Distances are no more concern now. The travel industry is
              expanding and so are the expectations of the businesses and the
              customers. To ensure the credibility of both, we have advanced
              Travel API for verification.
            </div>
            <a style={{ marginTop: "50px" }} href="/contactus">
              <Button
                hoveredStyle={primaryLightButtonHoveredStyle}
                size={LARGE}
                style={primaryLightTransButtonStyle}
                onCllck={() =>
                  googleEvent(
                    "Button_Click",
                    GAEvent.GetAPIKEYS,
                    GAEvent.GetAPIKEYS
                  )
                }
              >
                Get API Keys
              </Button>
            </a>
          </div>
        </div>
        <div className={Styles.brandMarqueeContainer}>
          <BrandMarquee page="travel" colorState={Styles.brandMarquee} />
        </div>
        <div className={Styles.travelAssetImage}>
          <div className={Styles.travelMobileImages}>
            <img className={Styles.image1} src={TravelAsset1} />
            {isMobile && <img src={TravelAsset2} alt="girl" />}
          </div>
          <div className={Styles.travelSection1}>
            <div className={Styles.travelSection2}>
              <div
                className={`${Styles.section2WidthHeading} ${Styles.section2Heading}`}
              >
                Verify your Customers
              </div>
              <div
                className={`${Styles.section2WidthContent} ${Styles.section2Content}`}
              >
                Be sure that only legit customers are using your services:
                buying tickets, getting aboard, or staying at your hotel with
                our travel API. ZOOPSTACK’s face liveness detection API, face
                match API, ensure you are dealing with a real person with
                genuine documents that belong to them.
              </div>
            </div>
            {!isMobile && (
              <img className={Styles.personImage} src={TravelAsset2} />
            )}
          </div>
        </div>
        <div className={Styles.travelSection3Main}>
          <img src={TravelAsset4} />
          {isMobile && (
            <img
              src={TravelCircle}
              alt="circle"
              className={Styles.travelCircle}
            />
          )}
          <div className={Styles.travelSection3}>
            {!isMobile && (
              <img
                src={TravelCircle}
                alt="circle"
                className={Styles.travelCircle}
              />
            )}
            <div className={Styles.section2Heading}>
              We Verify Businesses too
            </div>
            <div className={Styles.section2Content}>
              For businesses, it is essential that they offer authentic options
              to the customers for building trust. Only then the customers would
              stay hooked to their platform. We help brands verify the travel
              businesses with our GST API, CIN API, FSSAI API, Udyam Aadhar API,
              who wish to get listed on their website/application.
            </div>
          </div>
        </div>
        <div className={Styles.travelSection4}>
          <div className={Styles.section4Heading}>
            Smoother Check-ins, Easy Bookings
          </div>
          <div className={Styles.section4Content}>
            Give your customers a hassle-free travel experience. Onboard
            customers instantly with a few clicks with ZOOSTACK’s travel APIs
            and eliminate the long lines at the front desk.
          </div>
          <div className="position-relative d-flex justify-content-center align-items-center">
            <img className={Styles.image4} src={TravelAsset5} />
            <div className={Styles.imageBorder}></div>
          </div>
        </div>
        <div className={Styles.travelSection5}>
          {!isMobile && <img className={Styles.image5} src={TravelAsset6} />}
          <div className={Styles.section5Container}>
            <div className={Styles.section5TextContainer}>
              <div className={Styles.section2Heading}>
                Ride Sharing & Rental
              </div>
              <div className={Styles.section2Content}>
                Identifying the authenticity of the drivers and the passengers
                to assure safety and increase the level of trust. Also, identity
                verification in the travel industry assures pleasant and
                stress-free of the people by hospitality companies. Verify
                Vendors with our identity verification APIs such as PAN card
                verification API, aadhar Card verification API etc.
              </div>
            </div>
            <div>
              <img className={Styles.image7} src={TravelAsset7} />
            </div>
          </div>
        </div>
        <div className={`${Styles.retailImpactCard} container`}>
          <StatsCardContainer
            backgroundClassName={"col-12 col-sm-6 col-md-3 gy-3 mb-5"}
            headingDisplay={Styles.impactHeading}
            dancingBarColor={Styles.dancingBarColor}
            isTransparent
            impactCardsData={[
              {
                image: Clients,
                alt: "Drop Off Rate",
                title: "300+",
                subtitle: "Number of Clients",
              },
              {
                image: DocumentVerified,
                alt: "Reduction in Turn Around Time",
                title: "27M+",
                subtitle: "Document Verified",
              },
              {
                image: IncreaseInSales,
                alt: "Saved In Fraud Losses",
                title: "99.8%",
                subtitle: "Success Rate",
              },
              {
                image: FraudLossesImage,
                alt: "Increase In Productivity of Sales",
                title: "46%",
                subtitle: "Increase in Productivity of Sales",
              },
            ]}
          />
        </div>
        <div className="container">
          <BannerCard
            text={"Ready to take your business to new heights?"}
            button={{ text: "Get free Demo", onClick: handleButtonClick }}
            backGroundClassName={Styles.bannerBackGroundcolor}
            headingClassName={Styles.bannerHeading}
            tickMarkTextClassName={Styles.bannerTickText}
            tickImage={BannerTickMark}
          />
        </div>
        <SiteFooter footerBackground={Styles.footeBackgroundColor} />
      </div>
    </>
  );
}
