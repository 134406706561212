import React from "react";
import Styles from "../business-card/businessCardComp.module.scss";

interface BusinessCardsProps {
  image: string;
  cardTitle: string;
  cardSubTitle: string;
  link?: string;
  linkText?: string;
  headClassName?: string;
  subHeadClassName?: string;
  cardSubTitle2?: string;
  flatCard?: string;
}

const BusinessCards: React.FC<BusinessCardsProps> = ({
  image,
  cardTitle,
  cardSubTitle2,
  cardSubTitle,
  headClassName,
  subHeadClassName,
  flatCard,
}) => {
  return (
    <div className={`${Styles.flatCard} ${flatCard}`}>
      <img src={image} />
      <div className={`${Styles.flatCardHeading} ${headClassName} `}>
        {cardTitle}
      </div>
      <p className={`${subHeadClassName} ${Styles.flatCardSubHeading}`}>
        {cardSubTitle}
        <br />
        {cardSubTitle2}
      </p>
    </div>
  );
};

export default BusinessCards;
