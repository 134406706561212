import React, { useEffect, useState } from "react";
import SharedStyles from "../../Shared/shared.module.scss";
import Styles from "../product-identity/productIdentity.module.scss";
import SiteHeader from "../../Shared/site-header/siteHeader";
import SiteFooter from "../../Shared/site-footer/siteFooter";
import BannerCard from "../../Shared/banner-card/bannerCard";
import { FaqList } from "../../Shared/faq/faq";
import { googlePageView, googleEvent } from "../../analytics/google";
import ButtonCarousel from "../../Shared/button-carousel/buttonCarousel";
import ProductVerifyDetail from "../../Shared/product-verify-detail/productVerifyDetail";
import BusinessCardComp from "../../Shared/business-card/businessCardComp";
import AutomateWork from "../../assets/images/automateWork.svg";
import StopFraud from "../../assets/images/stopFraud.svg";
import ReliableVerify from "../../assets/images/reliableVerify.svg";
import RealTimeVerify from "../../assets/images/realTimeVerify.svg";
import ProductVerify from "../../Shared/product-verify/productVerify";
import BannerTickWhite from "../../assets/images/bannerTickWhite.svg";
import IdentityBackground from "../../assets/images/stack-offerings/identity.webp";
import AddOns from "../../Shared/add-ons/addOns";
import BrandMarquee from "../../Shared/brand-marquee/brandMarquee";
import UsecaseProduct from "../../Shared/usecase-product/usecaseProduct";
import AccountOpening from "../../assets/images/accountOpening.svg";
import Lending from "../../assets/images/lending.svg";
import BgVerification from "../../assets/images/bg_verification.svg";
import CustomerKYC from "../../assets/images/customer_kyc.svg";
import KYCCompliance from "../../assets/images/kyc_compliance.svg";
import AadhaarProductImage from "../../assets/images/products/identity/aadhaar.webp";
import DrivingLicenseProductImage from "../../assets/images/products/identity/driving_license.webp";
import EPFOProductImage from "../../assets/images/products/identity/epfo.webp";
import PanProductImage from "../../assets/images/products/identity/pan.webp";
import Pan206ProductImage from "../../assets/images/products/identity/pan206ab.webp";
import VoterIdProductImage from "../../assets/images/products/identity/voter_id.webp";
import PassportProductImage from "../../assets/images/products/identity/passport.webp";
import useCaseImage1 from "../../assets/images/usecaseImages/account_opening.webp";
import useCaseImage2 from "../../assets/images/usecaseImages/bg_verification.webp";
import useCaseImage3 from "../../assets/images/usecaseImages/customer_kyc.webp";
import useCaseImage4 from "../../assets/images/usecaseImages/house-bills-elements-arrangement 1.webp";
import useCaseImage5 from "../../assets/images/usecaseImages/kyc_compliance.webp";
import menuIcon1 from "../../assets/images/menu_slider_icons/blackIcons/identity/pan.svg";
import menuIcon2 from "../../assets/images/menu_slider_icons/blackIcons/identity/aadhaar.svg";
import menuIcon3 from "../../assets/images/menu_slider_icons/blackIcons/identity/voter.svg";
import menuIcon4 from "../../assets/images/menu_slider_icons/blackIcons/identity/driving_license.svg";
import menuIcon5 from "../../assets/images/menu_slider_icons/blackIcons/identity/passport.svg";
import menuIcon6 from "../../assets/images/menu_slider_icons/blackIcons/identity/epfo.svg";
import menuIcon1White from "../../assets/images/menu_slider_icons/whiteIcons/identity/pan.svg";
import menuIcon2White from "../../assets/images/menu_slider_icons/whiteIcons/identity/aadhaar_white.svg";
import menuIcon3White from "../../assets/images/menu_slider_icons/whiteIcons/identity/voter_white.svg";
import menuIcon4White from "../../assets/images/menu_slider_icons/whiteIcons/identity/driving_license_white.svg";
import menuIcon5White from "../../assets/images/menu_slider_icons/whiteIcons/identity/passport_white.svg";
import menuIcon6White from "../../assets/images/menu_slider_icons/whiteIcons/identity/epfo_white.svg";
import {
  BannerCardButtonTriggered,
  GetAPIKeysTriggered,
  MenuSliderButtonTriggered,
  PageScrollTriggered,
} from "../../Shared/eventsNames";
import HeadSeo from "../../Shared/head-seo/headSeo";
import { Helmet, HelmetProvider } from "react-helmet-async";
import useScreen from "../../utils/useScreen";

export default function ProductIdentity() {
  googlePageView("/products/identity", "ProductIdentity");
  googleEvent("Products", "ProductIdentity-Clicked", "ProductIdentity-Clicked");
  const { isMobile } = useScreen();
  const [visibleFaqs, setVisibleFaqs] = useState(5);
  const loadMoreFaqs = () => {
    setVisibleFaqs(visibleFaqs + 5);
  };
  const [refreshFaqList, setRefreshFaqList] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      PageScrollTriggered({ properties: "Identity Page Scrolled" });
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [metaDescription, setMetaDescription] = useState({
    title: "Verify PAN Card | PAN Verification with ZOOPSTACK ",
    desc: "Quickly verify PAN card with our PAN Verification API. Seamlessly integrate our pan card verification solution ensuring secure verification.",
    keyWords:
      "pan verification, verify pan card, pan card verification, pan verification online, pan card verification online",
  });
  const useCases = [
    {
      image: AccountOpening,
      title: "Account Opening",
    },
    {
      image: Lending,
      title: "Lending",
    },
    {
      image: BgVerification,
      title: "Background Verification",
    },
    {
      image: CustomerKYC,
      title: "Customer KYC",
    },
    {
      image: KYCCompliance,
      title: "KYC Compliance",
    },
  ];
  let newPathname = null;
  const pathname = window.location.pathname;
  const useCaseImages = [
    {
      mainImage: useCaseImage1,
      image1: useCaseImage4,
      image2: useCaseImage2,
      mainImageClassName: Styles.mainImage,
      image1ClassName: Styles.image1,
      image2ClassName: Styles.image2,
    },
    {
      image1: useCaseImage3,
      image2: useCaseImage5,
      image1ClassName: Styles.image2,
      image2ClassName: Styles.image2,
    },
  ];

  const [FaqProps, setFaqProps] = useState({
    faqs: [
      {
        question: "What is a PAN Card Verification API? ",
        answer:
          "The PAN Card Verification API is a powerful tool that allows businesses and organizations to verify the authenticity and validity of PAN (Permanent Account Number) cards issued by the Indian government. By integrating this API into their systems, businesses can easily validate PAN details and ensure secure and accurate identity verification.",
      },
      {
        question: "How does the PAN Card Verification API work?",
        answer:
          "The PAN Card Verification API works by accepting PAN card details, such as the PAN number and the cardholder's name, as input. It then connects to the government's PAN database and retrieves the relevant information associated with the provided PAN number. The API then validates the data, ensuring it matches the records maintained by the government, and returns the verification status to the requesting application.",
      },
      {
        question:
          "Is the PAN Card Verification API secure and compliant with data privacy regulations? ",
        answer:
          "Yes, the PAN Card Verification API is designed to prioritize data security and compliance. It operates over encrypted connections (HTTPS) to protect the sensitive information transmitted during verification. Moreover, it adheres strictly to data privacy regulations to safeguard user data and maintain the highest standards of confidentiality",
      },
      {
        question:
          "How fast are the responses from the PAN Card Verification API?",
        answer:
          "The PAN Card Verification API provides real-time responses, ensuring rapid verification results. Once the PAN details are submitted, the API quickly processes the request and delivers the verification status within seconds. This swift response time enhances user experience and streamlines identity verification processes for businesses.",
      },
      {
        question:
          "Can the PAN Card Verification API handle bulk verification requests?",
        answer:
          "Yes, our PAN Card Verification API is designed to handle bulk verification requests efficiently. Businesses can submit multiple PAN card details in a single API call, saving time and resources. Bulk verification capabilities are especially useful for large-scale identity verification processes, such as employee onboarding, customer registration, or compliance checks.",
      },
      {
        question:
          "What information can be verified using the PAN Card Verification API?",
        answer:
          "The PAN Card Verification API can verify details such as the cardholder's name, PAN status (active, inactive, or not found), and other related information. It provides a quick and reliable way to validate PAN card authenticity.",
      },
      {
        question:
          "How accurate is the information retrieved by the PAN Card Verification API? ",
        answer:
          "Our PAN Card Verification API provides real-time connections to authoritative databases, ensuring accurate information retrieval.",
      },
      {
        question:
          "How do I get started with the PAN Card Verification API integration?",
        answer:
          "To get started, you typically need to sign up with a provider offering the API, obtain the necessary API credentials, review the documentation, and follow the integration guidelines provided to start making API calls from your application.",
      },
      {
        question:
          "What is the expected response time of the PAN Card Verification API?",
        answer:
          "The response time of the PAN Card Verification API can vary based on factors like the provider's server load and your internet connection. Generally, reputable providers aim for quick response times to ensure a smooth user experience.",
      },
      {
        question:
          "What kind of support is available for PAN Card Verification API integration issues?",
        answer:
          "ZOOPSTACK offers developer support to assist with integration challenges, technical issues, and troubleshooting related to PAN Card Verification API.",
      },
    ],
  });

  const [activeButton, setActiveButton] = useState(0);

  const handleButtonClick = () => {
    BannerCardButtonTriggered({
      properties: "BannerCard Button Clicked on ProductIdentity Page",
    });
  };

  const [productVerifyDetailProps, setProductVerifyDetailProps] = useState({
    imageSrc: PanProductImage,
    heading: "How does PAN Verification API work?",
    points: [
      "Sign up on the ZOOPSTACK dashboard",
      "Subscribe to PAN Verification API in the products section on our dashboard.",
      "Provide PAN no. as API input.",
      "Instant PAN verification is done on a real-time basis.",
    ],
    buttonText: "Explore",
    productHeading: "Integrate PAN Verification API",
    useCaseHeading: "Use cases of PAN Verification API",
  });

  const [productVerifyProps, setProductVerifyProps] = useState({
    heading: "PAN Verification API",
    subHeading:
      "The Income Tax Department advises online PAN verification API to know the customers’ credibility. ZOOPSTACK’s PAN verification API is easy to use and delivers 100% accurate results. PAN verification API helps in knowing the legitimacy of the customers hence controlling fraud. ",
    buttonText: "Get API Keys",
    imgSrc: IdentityBackground,
  });

  const [businessCardProps, setBusinessCardProps] = useState([
    {
      image: AutomateWork,
      cardTitle: "Automate Workflows",
      cardSubTitle:
        "No more reconciling data between multiple documents manually",
    },
    {
      image: StopFraud,
      cardTitle: "Stop Fraudsters",
      cardSubTitle:
        "Let only authentic customers access your services. Ensure your safety and prevent fraudulent activities",
    },
    {
      image: ReliableVerify,
      cardTitle: "Reliable Verification",
      cardSubTitle:
        "Verifies all the information provided by the customer and onboard the legit and accurate customers",
    },
    {
      image: RealTimeVerify,
      cardTitle: "Real-time Verification",
      cardSubTitle:
        "Save your time and effort by integrating the right APIs. The data is processed in a second, hence no more manual reviewing",
    },
  ]);

  const changeContent = (index: number) => {
    setRefreshFaqList(true);
    switch (index) {
      case 0:
        setMetaDescription({
          title: "PAN Verification API | Verify PAN Card with ZOOPSTACK ",
          desc: "Our PAN Verification API will help you onboard authentic customers instantly. Seamlessly integrate our pan card verification solution ensuring secure verification.",
          keyWords:
            "pan verification, verify pan card, pan card verification, pan verification online, pan card verification online",
        });
        MenuSliderButtonTriggered({
          properties: "MenuSlider Pan Verification Button of Identity Page ",
        });
        newPathname =
          pathname.substring(0, pathname.lastIndexOf("/")) +
          "/pan-verification-api";
        window.history.replaceState({}, "", `${newPathname}`);
        console.log(
          window.history.replaceState({}, "", `${newPathname}`),
          "===="
        );
        setProductVerifyDetailProps({
          imageSrc: PanProductImage,
          heading: "How does PAN Verification API work?",
          points: [
            "Sign up on the ZOOPSTACK dashboard",
            "Subscribe to PAN Verification API in the products section on our dashboard.",
            "Provide PAN no. as API input.",
            "Instant PAN verification is done on a real-time basis.",
          ],
          buttonText: "Explore",
          productHeading: "Integrate PAN Verification API",
          useCaseHeading: "Use cases of PAN Verification API",
        });
        setProductVerifyProps({
          heading: "PAN Verification API",
          subHeading:
            "The Income Tax Department advises online PAN verification API to know the customers’ credibility. ZOOPSTACK’s PAN verification API is easy to use and delivers 100% accurate results. PAN verification API helps in knowing the legitimacy of the customers hence controlling fraud. ",
          buttonText: "Get API Keys",
          imgSrc: IdentityBackground,
        });
        setFaqProps({
          faqs: [
            {
              question: "What is a PAN Card Verification API? ",
              answer:
                "The PAN Card Verification API is a powerful tool that allows businesses and organizations to verify the authenticity and validity of PAN (Permanent Account Number) cards issued by the Indian government. By integrating this API into their systems, businesses can easily validate PAN details and ensure secure and accurate identity verification.",
            },
            {
              question: "How does the PAN Card Verification API work?",
              answer:
                "The PAN Card Verification API works by accepting PAN card details, such as the PAN number and the cardholder's name, as input. It then connects to the government's PAN database and retrieves the relevant information associated with the provided PAN number. The API then validates the data, ensuring it matches the records maintained by the government, and returns the verification status to the requesting application.",
            },
            {
              question:
                "Is the PAN Card Verification API secure and compliant with data privacy regulations? ",
              answer:
                "Yes, the PAN Card Verification API is designed to prioritize data security and compliance. It operates over encrypted connections (HTTPS) to protect the sensitive information transmitted during verification. Moreover, it adheres strictly to data privacy regulations to safeguard user data and maintain the highest standards of confidentiality",
            },
            {
              question:
                "How fast are the responses from the PAN Card Verification API?",
              answer:
                "The PAN Card Verification API provides real-time responses, ensuring rapid verification results. Once the PAN details are submitted, the API quickly processes the request and delivers the verification status within seconds. This swift response time enhances user experience and streamlines identity verification processes for businesses.",
            },
            {
              question:
                "Can the PAN Card Verification API handle bulk verification requests?",
              answer:
                "Yes, our PAN Card Verification API is designed to handle bulk verification requests efficiently. Businesses can submit multiple PAN card details in a single API call, saving time and resources. Bulk verification capabilities are especially useful for large-scale identity verification processes, such as employee onboarding, customer registration, or compliance checks.",
            },
            {
              question:
                "What information can be verified using the PAN Card Verification API?",
              answer:
                "The PAN Card Verification API can verify details such as the cardholder's name, PAN status (active, inactive, or not found), and other related information. It provides a quick and reliable way to validate PAN card authenticity.",
            },
            {
              question:
                "How accurate is the information retrieved by the PAN Card Verification API? ",
              answer:
                "Our PAN Card Verification API provides real-time connections to authoritative databases, ensuring accurate information retrieval.",
            },
            {
              question:
                "How do I get started with the PAN Card Verification API integration?",
              answer:
                "To get started, you typically need to sign up with a provider offering the API, obtain the necessary API credentials, review the documentation, and follow the integration guidelines provided to start making API calls from your application.",
            },
            {
              question:
                "What is the expected response time of the PAN Card Verification API?",
              answer:
                "The response time of the PAN Card Verification API can vary based on factors like the provider's server load and your internet connection. Generally, reputable providers aim for quick response times to ensure a smooth user experience.",
            },
            {
              question:
                "What kind of support is available for PAN Card Verification API integration issues?",
              answer:
                "ZOOPSTACK offers developer support to assist with integration challenges, technical issues, and troubleshooting related to PAN Card Verification API.",
            },
          ],
        });
        break;
      case 1:
        setMetaDescription({
          title: "Know about 206CCA & 206AB",
          desc: "Ease your tax compliance with the ZOOPSTACK’s 206AB and 206CCA API. Simplify the process and know about 206CCA & 206AB.",
          keyWords: "",
        });
        MenuSliderButtonTriggered({
          properties:
            "MenuSlider 206CCA & 206AB Verification Button of Identity Page ",
        });
        newPathname =
          pathname.substring(0, pathname.lastIndexOf("/")) +
          "/compliancecheckforsection206ab&206cca";
        window.history.replaceState({}, "", `${newPathname}`);
        setProductVerifyDetailProps({
          imageSrc: Pan206ProductImage,
          heading: "How does PAN 206AB & 206CCA Verification API work?",
          points: [
            "Sign up on the ZOOPSTACK dashboard",
            "Subscribe to PAN 206AB Verification API in the products section on our dashboard.",
            "Provide PAN no. as API input.",
            "Instant PAN 206AB verification is done on a real-time basis.",
          ],
          buttonText: "Explore",
          productHeading: "Integrate PAN 206AB & PAN 206CCA Verification API",
          useCaseHeading: "Uses cases of PAN 206AB & 206CCA Verification API",
        });
        setProductVerifyProps({
          heading: "Verify PAN 206AB & 206CCA",
          subHeading:
            "The 206AB and 206CCA API simplifies tax compliance by identifying individuals subject to higher tax deduction rates. Businesses can efficiently verify taxpayer status, ensuring accurate deduction or collection of taxes. With real-time responses, data security, and bulk verification support, this API enhances tax deduction efficiency and fosters compliance.",
          buttonText: "Get API Keys",
          imgSrc: IdentityBackground,
        });
        setFaqProps({
          faqs: [
            {
              question: "What is the purpose of the 206AB & 206CCA API?",
              answer:
                "The Section 206AB & Section 206CCA API serves to facilitate tax compliance by identifying individuals subject to higher tax deduction and collection rates under the respective sections of the Income Tax Act.",
            },
            {
              question: "How does the 206AB & 206CCA API work?",
              answer:
                "The 206AB & 206CCA API API accepts taxpayer details, connects to the tax department's database, verifies their status under Sections 206AB and 206CCA, and returns the applicable tax rates.",
            },
            {
              question: "Why were 206AB and 206CCA introduced?",
              answer:
                "Sections 206AB and 206CCA were introduced to ensure higher tax deduction and collection rates for specified persons who have not filed their income tax returns, encouraging tax compliance.",
            },
            {
              question: "Who needs to use the 206AB & 206CCA API?",
              answer:
                "Entities responsible for deducting or collecting TDS/TCS are required to implement the 206AB & 206CCA API. This includes businesses, financial institutions, and government bodies.",
            },
            {
              question:
                "Can the 206AB & 206CCA API handle bulk verification requests?",
              answer:
                "Yes, the 206AB & 206CCA API API supports bulk verification, allowing efficient processing of multiple taxpayer details simultaneously.",
            },
            {
              question:
                "Is data transmission secure with the 206AB & 206CCA API?",
              answer:
                "Absolutely! The 206AB & 206CCA API API operates over encrypted connections (HTTPS), ensuring the privacy and security of data during transmission.",
            },
            {
              question:
                "How does the 206AB & 206CCA API benefit businesses and organizations?",
              answer:
                "The  206AB & 206CCA API streamlines tax compliance, enabling accurate deduction or collection of taxes, saving time, and ensuring adherence to tax laws.",
            },
            {
              question:
                "Where can I find more information on the 206AB & 206CCA API?",
              answer:
                "For detailed guidelines and technical specifications regarding the 206AB & 206CCA API, refer to the official resources provided by the Income Tax Department or their designated service providers.",
            },
            {
              question: "When did the 206AB & 206CCA API come into effect?",
              answer:
                "The 206AB & 206CCA API came into effect in the Financial Year 2021-2022, in line with the Finance Act, 2021.",
            },
            {
              question: "How can I access the 206AB & 206CCA API?",
              answer:
                "You can contact our team to access the 206AB & 206CCA API. We will offer access and integration support for your organization.",
            },
            {
              question: "Is the use of the 206AB & 206CCA API mandatory?",
              answer:
                "Yes, as per the Finance Act, 2021, the use of the 206AB & 206CCA API is mandatory for entities responsible for TDS and TCS to ensure compliance with the higher rates outlined in these sections.",
            },
          ],
        });
        break;
      case 2:
        setMetaDescription({
          title:
            "Aadhaar Verification API -  Aadhaar Verify Online with ZOOPSTACK",
          desc: "Get aadhaar verification done online with ZOOPSTACK Aadhaar API Verification. Integrate our powerful solution enabling Aadhaar card verification.",
          keyWords:
            "aadhaar verify, Aadhaar card verification, Aadhaar card verify, aadhaar verification online",
        });
        MenuSliderButtonTriggered({
          properties:
            "MenuSlider Aadhaar Verification Button of Identity Page ",
        });
        newPathname =
          pathname.substring(0, pathname.lastIndexOf("/")) +
          "/aadhaar-api-verification";
        window.history.replaceState({}, "", `${newPathname}`);
        setProductVerifyDetailProps({
          imageSrc: AadhaarProductImage,
          heading: "How does Aadhaar Verification API work?",
          points: [
            "Sign up on the ZOOPSTACK dashboard",
            "Subscribe to Aadhaar Verification API in the products section on our dashboard.",
            "Provide Aadhaar number and OTP as input.",
            "Instant Aadhaar verification is done on a real-time basis.",
          ],
          buttonText: "Explore",
          productHeading: "Integrate Aadhaar Verification API",
          useCaseHeading: "Use cases of Aadhaar Verification API",
        });
        setProductVerifyProps({
          heading: "Aadhaar Verification API",
          subHeading:
            "Aadhaar Card verification API helps in verifying the customers swiftly and safely. It is important for businesses and financial institutions to determine the credibility of the customers to avoid any fraudulent activity. Aadhaar card is issued by the Government Of India, it consists of Full Name, Address, Mobile Number and other data which is vital to verify an individual.",
          buttonText: "Get API Keys",
          imgSrc: IdentityBackground,
        });
        setFaqProps({
          faqs: [
            {
              question: "What is an Aadhaar Card Verification API? ",
              answer:
                "The Aadhaar Card Verification API is a robust solution that enables organizations to verify the authenticity and validity of Aadhaar cards issued by the Unique Identification Authority of India (UIDAI). By integrating this API into their systems, businesses can efficiently authenticate Aadhaar details and ensure secure identity verification.",
            },
            {
              question: "How does the Aadhaar Card Verification API work? ",
              answer:
                "The Aadhaar Card Verification API functions by accepting Aadhaar card details, such as the Aadhaar number and the cardholder's name, as input. It then connects to the UIDAI's Aadhaar database and retrieves the relevant information associated with the provided Aadhaar number. The API performs data validation to ensure accuracy, and then sends back the verification status to the requesting application.",
            },
            {
              question:
                "Is the Aadhaar Card Verification API compliant with data protection laws?",
              answer:
                "Yes, the Aadhaar Card Verification API is designed to comply with data protection laws and regulations, ensuring the confidentiality and security of user information. It operates over encrypted connections (HTTPS) to safeguard sensitive data during transmission, and adheres to strict data privacy guidelines set by the UIDAI and relevant authorities.",
            },
            {
              question:
                "Does the Aadhaar Card Verification API offer real-time verification responses? ",
              answer:
                "Absolutely! The Aadhaar Card Verification API provides real-time responses, ensuring swift and efficient verification results. When businesses submit Aadhaar card details through the API, they receive verification status within seconds, enabling instant decision-making and enhancing user experience.",
            },
            {
              question:
                "What information can be verified using the Aadhaar Card Verification API?",
              answer:
                "The Aadhaar Card Verification API can verify details such as the cardholder's name, Aadhaar status (active, inactive, or not found), and other relevant data. It provides a reliable method to validate the authenticity of Aadhaar cards.",
            },
            {
              question: "How secure is the Aadhaar Card Verification API? ",
              answer:
                "Our Aadhaar Card Verification API provides implement security measures like encryption and authentication to ensure secure data transfer between your application and the API. Data privacy and security are our top priority.",
            },
            {
              question:
                "Can I use the Aadhaar Card Verification API for bulk Aadhaar verifications?",
              answer:
                "We offer bulk Aadhaar Card Verification API, allowing you to verify multiple Aadhaar card numbers in a single request. This is particularly useful for businesses requiring verification at scale.",
            },
            {
              question:
                "What happens if the Aadhaar Card Verification API cannot verify an Aadhaar card?",
              answer:
                "If the Aadhaar Card Verification API cannot verify an Aadhaar card, it will likely return an appropriate error message or response indicating that the Aadhaar number is invalid or not present in the database.",
            },
          ],
        });
        break;
      case 3:
        setMetaDescription({
          title:
            "Voter ID Verification API - Instant Voter ID verification with ZOOPSTACK",
          desc: "ZOOPSTACK's voter ID verification API streamlines the process of voter ID verification. ZOOPSTACK performs Voter id verification online in just few seconds.",
          keyWords:
            "Voter Id verification, Voter id verification online, Voter ID card verify ",
        });
        MenuSliderButtonTriggered({
          properties:
            "MenuSlider Voter ID Verification Button of Identity Page ",
        });
        newPathname =
          pathname.substring(0, pathname.lastIndexOf("/")) +
          "/voterid-verification-api";
        window.history.replaceState({}, "", `${newPathname}`);
        setProductVerifyDetailProps({
          imageSrc: VoterIdProductImage,
          heading: "How does Voter ID Verification API work?",
          points: [
            "Sign up on the ZOOPSTACK dashboard",
            "Subscribe to Voter ID Verification API in the products section on our dashboard.",
            "Provide Voter ID as input.",
            "Instant Voter ID verification is done on a real-time basis.",
          ],
          buttonText: "Explore",
          productHeading: "Integrate Voter ID Verification API",
          useCaseHeading: "Use cases of Voter ID Verification API",
        });
        setProductVerifyProps({
          heading: "Voter ID Verification API",
          subHeading:
            "Voter ID cards are issued by the Election Commission of India (EPIC), to all citizens of age 18 years and above. It is also referred to as Electors Photo Identity Cards. ZOOPSTACK’s Voter ID verification API extracts data from voter ID cards instantly and accurately. Various businesses and financial institutions are advised to verify the names, ages, and addresses of the customer available on the voter ID card. ",
          buttonText: "Get API Keys",
          imgSrc: IdentityBackground,
        });
        setFaqProps({
          faqs: [
            {
              question: "What is a Voter ID Verification API?",
              answer:
                "The Voter ID Verification API is a tool that enables organizations to authenticate the validity of voter identity cards issued by the Election Commission of India. By integrating this API into their systems, businesses can efficiently verify voter ID details and ensure secure identity validation.",
            },
            {
              question: "How does the Voter ID Verification API work?",
              answer:
                "The Voter ID Verification API functions by accepting voter ID details, such as the voter ID number and the cardholder's name, as input. It connects to the Election Commission's database and retrieves the relevant information associated with the provided voter ID number. The API then verifies the data and returns the verification status to the requesting application.",
            },
            {
              question:
                "Is the Voter ID Verification API compliant with data protection regulations? ",
              answer:
                "Yes, the Voter ID Verification API prioritizes data protection and complies with relevant data privacy regulations. It operates over secure, encrypted connections (HTTPS) to safeguard sensitive information during transmission, ensuring the confidentiality of user data.",
            },
            {
              question:
                "Can the Voter ID Verification API handle bulk verification requests? ",
              answer:
                "Yes, ZOOPSTACK Voter ID Verification API supports bulk verification, allowing businesses to verify multiple voter ID details in a single API call. This feature streamlines identity validation processes, making it ideal for large-scale voter verification projects and registration drives.",
            },
            {
              question:
                "Is online Voter ID Verification faster than offline voter ID verification?",
              answer:
                "Yes, the Voter ID Verification API is faster than offline voter ID verification. The API provides real-time responses, offering swift verification results within seconds. This makes it a more efficient alternative to traditional manual verification processes.",
            },
            {
              question:
                "What information can be verified using the Voter ID Verification API? ",
              answer:
                "The Voter ID Verification API can typically verify details such as the voter's name, address, photograph, and unique Voter ID number. The extent of verification depends on the capabilities of the API.",
            },
            {
              question: "Who can use the Voter ID Verification API?",
              answer:
                "Developers, businesses, government agencies, and organizations that need to verify voter identities for various purposes, such as online services, elections, or membership validations, can use the Voter ID Verification API.",
            },
            {
              question:
                "What are the benefits of using a Voter ID Verification API?",
              answer:
                "Using a Voter ID Verification API streamlines the verification process, reduces manual errors, enhances accuracy, and ensures a consistent standard of verification across applications. It can also improve efficiency in managing voter data.",
            },
            {
              question:
                "Can the Voter ID Verification API be used for real-time verifications and batch processing?",
              answer:
                "ZOOPSTACK Voter ID Verification API offers real-time verification for individual requests and batch processing for multiple voter ID verifications in one go.",
            },
            {
              question:
                "Can the Voter ID Verification API detect potential cases of voter fraud?",
              answer:
                "While the Voter ID Verification API can verify the accuracy of voter details, it may not inherently detect cases of fraud. However, it can help flag discrepancies that might warrant further investigation.",
            },
          ],
        });
        break;
      case 4:
        setMetaDescription({
          title:
            "Driving License Verification API -  DL Verification with ZOOPSTACK",
          desc: "With ZOOPSTACK's DL Verification API now it is super easy to get online driving license check of the customers. Ensure compliance, take assitance of ZOOPSTACK's DL verification API, we will check driving licence number of your customers quickly. ",
          keyWords:
            "driving licence check online, check driving licence number, dl verification",
        });
        MenuSliderButtonTriggered({
          properties:
            "MenuSlider Driving License Verification Button of Identity Page ",
        });
        newPathname =
          pathname.substring(0, pathname.lastIndexOf("/")) +
          "/driving-license-verification-api";
        window.history.replaceState({}, "", `${newPathname}`);
        setProductVerifyDetailProps({
          imageSrc: DrivingLicenseProductImage,
          heading: "How does Driving License Verification API work?",
          points: [
            "Sign up on the ZOOPSTACK dashboard",
            "Subscribe to Driving License Verification API in the products section on our dashboard.",
            "Provide Driving License as input.",
            "Instant Driving License verification is done on a real-time basis.",
          ],
          buttonText: "Explore",
          productHeading: "Integrate Driving License Verification API",
          useCaseHeading: "Use cases of Driving License Verification API",
        });
        setProductVerifyProps({
          heading: "Driving License Verification API",
          subHeading:
            "ZOOPSTACK’s Driving License verification API is easy to use and delivers 100% accurate results. Businesses and financial institutions are advised to verify their customers’ driving licences to determine their legitimacy while preventing any fraudulent activities. ",
          buttonText: "Get API Keys",
          imgSrc: IdentityBackground,
        });
        setFaqProps({
          faqs: [
            {
              question:
                "How does the Driving License Verification API ensure data security?",
              answer:
                "The Driving License API operates over encrypted connections (HTTPS), safeguarding sensitive information during transmission.",
            },
            {
              question:
                "Is the Driving License Verification API suitable for ridesharing businesses? ",
              answer:
                "Absolutely! Driving License Verification API streamlines driver onboarding and ensures compliance with licensing regulations",
            },
            {
              question:
                "What is the typical response time of the Driving License Verification API? ",
              answer:
                "The Driving License Verification API provides real-time responses, delivering verification results within seconds.",
            },
            {
              question:
                "Does the Driving License Verification API offer support for bulk verification of driving licenses?",
              answer:
                "Yes, ZOOPSTACK’s Driving License Verification API allow bulk verification, perfect for managing a large number of licenses efficiently.",
            },
            {
              question: "How does the Driving License Verification API work? ",
              answer:
                "The Driving License Verification API processes driving license details submitted by users, such as the license number and other relevant information. It then communicates with official databases to validate the accuracy and legitimacy of the provided details.",
            },
            {
              question:
                "Who can benefit from using the Driving License Verification API? ",
              answer:
                "Businesses, organizations, and platforms that require verification of driving licenses, such as ride-sharing services, car rental companies, and identity verification providers, can benefit from using the Driving License Verification API.",
            },
            {
              question:
                "What types of information can be verified using the Driving License Verification API? ",
              answer:
                "The Driving License Verification API can typically verify details such as the license holder's name, date of birth, license number, expiration date, and any endorsements or restrictions associated with the license.",
            },
            {
              question: "Is the Driving License Verification API secure?",
              answer:
                "Yes, reputable Driving License Verification API use encryption and security protocols to protect the user's personal information during the verification process.",
            },
            {
              question:
                "What is the benefit of using a Driving License Verification API?",
              answer:
                "Utilizing the Driving License Verification API streamlines the verification process, reduces manual errors, enhances accuracy, and ensures a standardized verification process across applications and platforms.",
            },
            {
              question:
                "Can the Driving License Verification API be used for age verification purposes?",
              answer:
                "Yes, the Driving License Verification API capabilities can extend to verifying an individual's age if it's part of the driving license information.",
            },
          ],
        });
        break;
      case 5:
        setMetaDescription({
          title: "Easy Pancard Verification | Check Pan Card",
          desc: "Get pancard verification done in seconds, check PAN card with ZOOPSTACK. Seamlessly integrate our reliable solution seamless PAN card verification",
          keyWords:
            "pancard verification, check pan card, pan card apply, pan status check",
        });
        MenuSliderButtonTriggered({
          properties:
            "MenuSlider Passport Verification Button of Identity Page ",
        });
        newPathname =
          pathname.substring(0, pathname.lastIndexOf("/")) +
          "/passport-verification-api";
        window.history.replaceState({}, "", `${newPathname}`);
        setProductVerifyDetailProps({
          imageSrc: PassportProductImage,
          heading: "How does Passport Verification API work?",
          points: [
            "Sign up on the ZOOPSTACK dashboard",
            "Subscribe to Passport Verification API in the products section on our dashboard.",
            "Provide Passport details as input.",
            "Instant Passport verification is done on a real-time basis.",
          ],
          buttonText: "Explore",
          productHeading: "Integrate Passport Verification API",
          useCaseHeading: "Use cases of Passport Verification API",
        });
        setProductVerifyProps({
          heading: "Passport Verification API",
          subHeading:
            "Make the onboarding process safer, faster, and smarter with ZOOPSTACK’s Passport Verification API. Financial Institutions and Businesses must verify their customers’ Passport for Identification. It is important for businesses and financial institutions to determine the credibility of the customers to avoid any fraudulent activity.",
          buttonText: "Get API Keys",
          imgSrc: IdentityBackground,
        });
        setFaqProps({
          faqs: [
            {
              question: "What is a Passport Verification API?",
              answer:
                "The Passport Verification API enables the automated verification of passport details, such as validity, authenticity, and the identity of the passport holder.",
            },
            {
              question: "How does the Passport Verification API work? ",
              answer:
                "The Passport Verification API works by accepting input parameters, usually the passport details, and then communicating with a trusted database or service to validate the information provided. The API then returns a response indicating the verification result.",
            },
            {
              question:
                "What information can be verified using the Passport Verification API?",
              answer:
                "With the help of Passport Verification API you can verify various details, including passport number, issue and expiry dates, country of issuance, passport holder's name, and sometimes additional personal information.",
            },
            {
              question:
                "What are the use cases for the Passport Verification API?",
              answer:
                "The Passport Verification API can be used in various scenarios, including identity verification for online services, travel and border control, compliance and KYC (Know Your Customer) processes, and fraud prevention.",
            },
            {
              question:
                "Can the Passport Detection API detect fraudulent or tampered passports? ",
              answer:
                "ZOOPSTACK’s Passport Verification APIs have built-in algorithms to detect signs of fraudulent or tampered passports. However, the level of detection may vary among providers.",
            },
            {
              question:
                "Can the Passport Verification API detect forged or tampered passports?",
              answer:
                "While the Passport Verification API can verify the accuracy of provided passport details, it may not inherently detect physical forgery or tampering. However, it can help flag inconsistencies that might require further investigation.",
            },
            {
              question:
                "Can businesses integrate the Passport Verification API for customer identity verification?",
              answer:
                "Yes, businesses can integrate the Passport Verification API for customer identity verification, especially in industries like finance and online services where KYC (Know Your Customer) processes are crucial.",
            },
            {
              question:
                "Does the Passport Verification API support real-time verification?",
              answer:
                "Yes, many Passport Verification API support real-time verification, allowing users to receive instant validation results.",
            },
          ],
        });
        break;
      case 6:
        setMetaDescription({
          title: "EPFO API | Employee Verification with ZOOPSTACK",
          desc: "Want to do EPFO check of employees? Take assitance of ZOOPSTACK's EPFO API. Integrate our powerful solution to streamline employee verification.  ",
          keyWords:
            "epfo check, employee verification, employee background verification",
        });
        MenuSliderButtonTriggered({
          properties: "MenuSlider EPFO Verification Button of Identity Page ",
        });
        newPathname =
          pathname.substring(0, pathname.lastIndexOf("/")) +
          "/epfo-verification-api";
        window.history.replaceState({}, "", `${newPathname}`);
        setProductVerifyDetailProps({
          imageSrc: EPFOProductImage,
          heading: "How does EPFO Verification API work?",
          points: [
            "Sign up on the ZOOPSTACK dashboard",
            "Subscribe to EPFO Verification API in the products section on our dashboard.",
            "Provide Mobile number as input.",
            "Instant EPFO verification is done on a real-time basis.",
          ],
          buttonText: "Explore",
          productHeading: "Easily Integrate EPFO Verification API",
          useCaseHeading: "Use cases of EPFO Verification API",
        });
        setProductVerifyProps({
          heading: "EPFO Verification API",
          subHeading:
            "EPFO employee verification API helps to know whether the employee has worked in an organisation during the claimed period of time. ZOOPSTACK’s EPFO employee verification API helps in identifying that the claim made by an individual of working with their previous employers are legitimate.",
          buttonText: "Get API Keys",
          imgSrc: IdentityBackground,
        });
        setFaqProps({
          faqs: [
            {
              question: "What is an EPFO API?",
              answer:
                "The EPFO API is a tool that allows businesses to verify the authenticity and validity of EPF (Employees' Provident Fund) account numbers issued by EPFO (Employees' Provident Fund Organization). It performs employee background verification for secure employee identity verification.",
            },
            {
              question: "How does the EPFO API work?",
              answer:
                "The API accepts EPF account numbers, connects to EPFO's database, retrieves relevant information, validates it, and returns the verification status to the application to do proper employee background verification.",
            },
            {
              question:
                "Can the EPFO Verification API handle bulk verification requests?",
              answer:
                "Yes, ZOOPSTACK’s EPFO Verification API supports bulk verification, streamlining identity validation for HR departments and organizations with a large workforce.",
            },
            {
              question:
                "Is data transmission secure with the EPFO Verification API?",
              answer:
                "Yes, EPFO Verification API operates over encrypted connections (HTTPS), ensuring data privacy during transmission.",
            },
            {
              question:
                "How fast are the responses from the EPFO Verification API? ",
              answer:
                "The EPFO Verification API provides real-time responses, delivering swift verification results within seconds.",
            },
            {
              question: "What benefits does the EPFO Verification API offer?",
              answer:
                "The benefits are manifold. The EPFO Verification API eliminates manual data entry, slashes processing time, minimizes errors, and ensures compliance with EPFO regulations. It streamlines your HR processes, boosts efficiency, and enhances accuracy in record-keeping.",
            },
            {
              question:
                "Can the EPFO Verification API be scaled for businesses of different sizes? ",
              answer:
                "Absolutely. Whether you're a small startup or a large enterprise, the EPFO Verification API is scalable to accommodate your needs. It grows with your business, adapting to your evolving verification requirements.",
            },
            {
              question:
                "How do the EPFO Verification API handle updates and changes in EPFO regulations?",
              answer:
                "Our  EPFO Verification API is built with flexibility in mind. It can be updated to accommodate changes in EPFO regulations, ensuring that your verification process remains compliant and up-to-date at all times.",
            },
            {
              question:
                "Can the EPFO Verification API be scaled for businesses of different sizes? ",
              answer:
                "Absolutely. Whether you're a small startup or a large enterprise, the EPFO Verification API is scalable to accommodate your needs. It grows with your business, adapting to your evolving verification requirements.",
            },
            {
              question:
                "How can my business get started with the EPFO Verification API?",
              answer:
                "Getting started is easy. Reach out to our team to discuss your business's needs, and we'll guide you through the integration process of  EPFO Verification API.",
            },
          ],
        });
        break;
    }
  };

  useEffect(() => {
    if (pathname.includes("pan")) {
      setActiveButton(0);
      changeContent(0);
    } else if (pathname.includes("compliancecheck")) {
      setActiveButton(1);
      changeContent(1);
    } else if (pathname.includes("aadhaar")) {
      setActiveButton(2);
      changeContent(2);
    } else if (pathname.includes("voterid")) {
      setActiveButton(3);
      changeContent(3);
    } else if (pathname.includes("driving")) {
      setActiveButton(4);
      changeContent(4);
    } else if (pathname.includes("passport")) {
      setActiveButton(5);
      changeContent(5);
    } else if (pathname.includes("epfo")) {
      setActiveButton(6);
      changeContent(6);
    }
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>ZOOPSTACK</title>
          <meta name="description" content={metaDescription.desc} />
          <meta name="title" content={metaDescription.title} />
          <meta name="Keywords" content={metaDescription.keyWords} />
        </Helmet>
      </HelmetProvider>

      <div className={SharedStyles.mainContainer}>
        <HeadSeo
          title={metaDescription.title}
          description={metaDescription.desc}
          keyWords={metaDescription.keyWords}
        />
        <SiteHeader />
        <div className={Styles.productIdentityBackground}>
          <div className={`${SharedStyles.menu}`}>
            <div className={`${Styles.navigation} js-btn-slide-container`}>
              {/* <span
                className={`${Styles["navigation__active-state"]} js-btn-slide-active`}
                role="presentation"
              ></span> */}
              <div
                className={`${Styles.navigation__btn} ${
                  activeButton === 0
                    ? `${Styles["navigation__btn--active"]} ${Styles.selected}`
                    : ""
                } js-btn-slide`}
                onClick={() => setActiveButton(0)}
                role="button"
              >
                <ButtonCarousel
                  buttonImgSrc={activeButton === 0 ? menuIcon1White : menuIcon1}
                  btnText="PAN"
                  onButtonClick={() => changeContent(0)}
                />
              </div>
              <div
                className={`${Styles.navigation__btn} ${
                  activeButton === 1
                    ? `${Styles["navigation__btn--active"]} ${Styles.selected}`
                    : ""
                } js-btn-slide`}
                onClick={() => setActiveButton(1)}
                role="button"
              >
                <ButtonCarousel
                  buttonImgSrc={activeButton === 1 ? menuIcon2White : menuIcon2}
                  btnText={"PAN 206AB"}
                  onButtonClick={() => changeContent(1)}
                />
              </div>
              <div
                className={`${Styles.navigation__btn} ${
                  activeButton === 2
                    ? `${Styles["navigation__btn--active"]} ${Styles.selected}`
                    : ""
                } js-btn-slide`}
                onClick={() => setActiveButton(2)}
                role="button"
              >
                <ButtonCarousel
                  buttonImgSrc={activeButton === 2 ? menuIcon2White : menuIcon2}
                  btnText={"Aadhaar"}
                  onButtonClick={() => changeContent(2)}
                />
              </div>

              <div
                className={`${Styles.navigation__btn} ${
                  activeButton === 3
                    ? `${Styles["navigation__btn--active"]} ${Styles.selected}`
                    : ""
                } js-btn-slide`}
                onClick={() => setActiveButton(3)}
                role="button"
              >
                <ButtonCarousel
                  buttonImgSrc={activeButton === 3 ? menuIcon3White : menuIcon3}
                  btnText={"Voter ID"}
                  onButtonClick={() => changeContent(3)}
                />
              </div>

              <div
                className={`${Styles.navigation__btn} ${
                  activeButton === 4
                    ? `${Styles["navigation__btn--active"]} ${Styles.selected}`
                    : ""
                } js-btn-slide`}
                onClick={() => setActiveButton(4)}
                role="button"
              >
                <ButtonCarousel
                  buttonImgSrc={activeButton === 4 ? menuIcon4White : menuIcon4}
                  btnText={isMobile ? "DL" : "Driving License"}
                  onButtonClick={() => changeContent(4)}
                />
              </div>
              <div
                className={`${Styles.navigation__btn} ${
                  activeButton === 5
                    ? `${Styles["navigation__btn--active"]} ${Styles.selected}`
                    : ""
                } js-btn-slide`}
                onClick={() => setActiveButton(5)}
                role="button"
              >
                <ButtonCarousel
                  buttonImgSrc={activeButton === 5 ? menuIcon5White : menuIcon5}
                  btnText="Passport"
                  onButtonClick={() => changeContent(5)}
                />
              </div>
              <div
                className={`${Styles.navigation__btn} ${
                  activeButton === 6
                    ? `${Styles["navigation__btn--active"]} ${Styles.selected}`
                    : ""
                } js-btn-slide`}
                onClick={() => setActiveButton(6)}
                role="button"
              >
                <ButtonCarousel
                  buttonImgSrc={activeButton === 6 ? menuIcon6White : menuIcon6}
                  btnText="EPFO Check"
                  onButtonClick={() => changeContent(6)}
                />
              </div>
            </div>
          </div>
          <div className={Styles.productVerifySection}>
            {/* {first component} */}
            <ProductVerify
              heading={productVerifyProps.heading}
              contentText={productVerifyProps.subHeading}
              buttonText={productVerifyProps.buttonText}
              onButtonClick={() =>
                GetAPIKeysTriggered({
                  properties: `GetAPIKeys on ProductIdentity Page for ${productVerifyProps.heading}`,
                })
              }
              imgSrc={IdentityBackground}
              link={"/contactus"}
            />
          </div>
        </div>

        {/* {Product Verify Detail Card} */}
        <div className={`${isMobile ? "pb-2" : "pb-5"} pt-4`}>
          <ProductVerifyDetail
            imageSrc={productVerifyDetailProps.imageSrc}
            heading={productVerifyDetailProps.heading}
            points={[
              productVerifyDetailProps.points[0],
              productVerifyDetailProps.points[1],
              productVerifyDetailProps.points[2],
              productVerifyDetailProps.points[3],
            ]}
            buttonText={productVerifyDetailProps.buttonText}
            onButtonClick={handleButtonClick}
          />
        </div>
        <div className={"mt-4"}>
          <BrandMarquee />
        </div>

        <div className={SharedStyles.section}>
          <div className={SharedStyles.sectionHeading}>
            {productVerifyDetailProps.productHeading}
          </div>
          <div className={SharedStyles.sectionSubHeading}>
            Sign up on our dashboard and test our API instantly for free
          </div>
        </div>

        {/* {businessCards} */}
        <div className={Styles.businessCards}>
          <BusinessCardComp
            image={businessCardProps[0].image}
            cardTitle={businessCardProps[0].cardTitle}
            cardSubTitle={businessCardProps[0].cardSubTitle}
          />
          <BusinessCardComp
            image={businessCardProps[1].image}
            cardTitle={businessCardProps[1].cardTitle}
            cardSubTitle={businessCardProps[1].cardSubTitle}
          />
          <BusinessCardComp
            image={businessCardProps[2].image}
            cardTitle={businessCardProps[2].cardTitle}
            cardSubTitle={businessCardProps[2].cardSubTitle}
          />
          <BusinessCardComp
            image={businessCardProps[3].image}
            cardTitle={businessCardProps[3].cardTitle}
            cardSubTitle={businessCardProps[3].cardSubTitle}
          />
        </div>

        {/* {Usecase cards} */}
        <div className={SharedStyles.section}>
          <div className={SharedStyles.sectionHeading}>
            {productVerifyDetailProps.useCaseHeading}
          </div>
        </div>
        <UsecaseProduct useCases={useCases} useCaseImages={useCaseImages} />

        {/* Add-ons */}
        {!pathname.includes("compliancecheck") &&
          !pathname.includes("epfo") && (
            <AddOns
              heading={"Add-ons"}
              content={
                "ZOOPSTACK’s advanced APIs are optimised for best user experience. Extract data and verify identity of the customers effortlessly."
              }
              features={[
                ["Name Match", "Face Liveness"],
                ["Face Match", "OCR"],
              ]}
            />
          )}
        {/* {FAQ} */}
        <div className={SharedStyles.faq}>
          <h1 className={SharedStyles.sectionHeading}>
            Frequently Asked Questions
          </h1>
          {/* <p style={{ color: "#9A9AA3", marginTop: "8px" }}>
          Sign up on our dashboard and test our APIs instantly for free.
        </p> */}
        </div>

        <div className="container">
          <div style={{ marginBottom: isMobile ? "50px" : "100px" }}>
            <FaqList
              faqs={FaqProps.faqs}
              visibleFaqs={visibleFaqs}
              onLoadMore={loadMoreFaqs}
            />
          </div>

          {/* {BannerCard} */}
          <BannerCard
            text={"Ready to take your business to new heights?"}
            button={{ text: "Get free Demo", onClick: handleButtonClick }}
            tickImage={BannerTickWhite}
            backGroundClassName={Styles.bannerBackground}
          />
        </div>
        {/* {Footer} */}
        <SiteFooter />
      </div>
    </>
  );
}
