import React from "react";
import iso1 from "../../assets/svg/ISO27001_2022.svg";
import iso2 from "../../assets/svg/ISO27017_2015.svg";
import iso3 from "../../assets/svg/ISO27018_2019.svg";
import iso4 from "../../assets/svg/ISO27701_2019.svg";
import Styles from "./isoBanner.module.scss";

export default function isoBanner() {
  return (
    <div className={`${Styles.isoContainer}`}>
      <div className={`container ${Styles.isoBannerMainContainer}`}>
        <div className={Styles.isoTextContainer}>
          <p className={Styles.isoText}>
            We are compliant with applicable laws & standards
          </p>
        </div>
        <div className={Styles.isoImages}>
          <img src={iso1} />
          <img src={iso2} />
          <img src={iso3} />
          <img src={iso4} />
        </div>
      </div>
    </div>
  );
}
