import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import Styles from "./carouselSlider.module.scss";
import { googleEvent } from "../../analytics/google";
import GAEvent from "../../analytics/events";
import CarouselImage1 from "../../assets/images/carouselImages/CarouselImage1.webp";
import CarouselImage2 from "../../assets/images/carouselImages/CarouselImage3.webp";
import CarouselImage3 from "../../assets/images/carouselImages/CarouselImage2.webp";
import CarouselImage4 from "../../assets/images/carouselImages/CarouselImage4.webp";
import CarouseBoxIconDashboard from "../../assets/images/dashboard2.svg";
import CarouseBoxIconProducts from "../../assets/images/products.svg";
import CarouseBoxIconApps from "../../assets/images/apps.svg";
import CarouseBoxIconTrailCenter from "../../assets/images/trail_center.svg";
import Button from "../button/button";
import {
  primaryLightButtonHoveredStyle,
  primaryLightButtonStyle,
} from "../buttonStyles";
import { MEDIUM } from "../buttonSize";
import SharedStyles from "../shared.module.scss";

import {
  DashboardExploreTriggered,
  CarouselButtonTriggered,
} from "../eventsNames";
const carouselData = [
  {
    image: CarouselImage1,
    heading: "Dashboard",
    description:
      "From product usage to transaction stats, control, track, and observe every step with comprehensive diligence reports.",
    icon: CarouseBoxIconDashboard,
  },
  {
    image: CarouselImage2,
    heading: "Products",
    description:
      "Onboard users instantly across the globe with our numerous simple automated, plug-and-play APIs.",
    icon: CarouseBoxIconProducts,
  },
  {
    image: CarouselImage3,
    heading: "Apps",
    description:
      "Create and customize unique journeys to suit the different needs of your business.",
    icon: CarouseBoxIconApps,
  },
  {
    image: CarouselImage4,
    heading: "Trial Center",
    description:
      "Test us before you choose us. Sign and experience the accuracy and speed of our APIs from the trail centre.",
    icon: CarouseBoxIconTrailCenter,
  },
];

export default function CarouseSlider() {
  const sliderRef = useRef<Slider>(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  const goToSlide = (index: number) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
    setCurrentSlide(index);
  };

  useEffect(() => {
    let intervalId = setInterval(() => {
      if (window.innerWidth >= 767) {
        let nextSlide = currentSlide + 1;
        if (nextSlide >= carouselData.length) {
          nextSlide = 0;
        }
        goToSlide(nextSlide);
      }
    }, 4000);

    const handleResize = () => {
      setCurrentSlide(0);
      clearInterval(intervalId);
      if (window.innerWidth >= 767) {
        setCurrentSlide(0);
        intervalId = setInterval(() => {
          let nextSlide = currentSlide + 1;
          if (nextSlide >= carouselData.length) {
            nextSlide = 0;
          }
          goToSlide(nextSlide);
        }, 4000);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      clearInterval(intervalId);
      window.removeEventListener("resize", handleResize);
    };
  }, [currentSlide, carouselData]);
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 0,
    autoplay: !isMobile,
    autoplaySpeed: 4000,
    afterchange: (oldIndex: number, newIndex: number) =>
      setCurrentSlide(newIndex),
  };

  return (
    <div style={{ marginTop: "50px" }}>
      <div className={SharedStyles.section}>
        <div className={SharedStyles.sectionHeadingContainer}>
          <p className={SharedStyles.sectionHeading}>Use Our Trusted APIs</p>
          <p className={SharedStyles.sectionSubHeading}>
            Sign up on our dashboard to test our APIs instantly for free.
          </p>
        </div>
      </div>
      <div className={Styles.MainSlider}>
        <div className={Styles.sliderCarousel}>
          <Slider {...settings} ref={sliderRef}>
            {carouselData.map((item, index) => (
              <div key={index}>
                <img
                  className={`d-block w-100 img1 ${Styles.carouselImage}`}
                  src={item.image}
                  alt="Slide"
                />
              </div>
            ))}
          </Slider>
          <div className={Styles.mainLines}>
            {carouselData.map((item, index) => (
              <div
                key={index}
                className={`${Styles.lines} ${
                  currentSlide === index ? Styles.activeLine : ""
                }`}
                onClick={() => {
                  setCurrentSlide(index);
                  if (sliderRef.current) {
                    sliderRef.current.slickGoTo(index);
                  }
                }}
              ></div>
            ))}
          </div>
        </div>
        <div>
          <div className={Styles.boxContainerMain}>
            {carouselData.map((item, index) => (
              <React.Fragment key={index}>
                <div
                  className={`${Styles.boxContent} ${
                    currentSlide === index
                      ? Styles.boxSelected
                      : Styles.boxNotSelected
                  }`}
                  onClick={() => goToSlide(index)}
                >
                  <div
                    onClick={() =>
                      CarouselButtonTriggered({
                        properties: "Carousel Button Clicked",
                      })
                    }
                  >
                    <div className={Styles.carouselBoxHeadingContainer}>
                      <div>
                        <img
                          className={Styles.img}
                          src={item.icon}
                          alt={item.heading}
                        />
                      </div>
                      <div>
                        <h3 className={`${Styles.carouselBoxHeading}`}>
                          {item.heading}
                        </h3>
                      </div>
                    </div>
                    <p className={Styles.boxDesc}>{item.description}</p>
                    <img
                      src={item.image}
                      className={`${Styles.mobImg} ${
                        currentSlide === index ? Styles.mobImgSelected : " "
                      }`}
                    />
                  </div>
                </div>
              </React.Fragment>
            ))}
            <div
              className={Styles.btnCarousel}
              onClick={() =>
                DashboardExploreTriggered({
                  properties: "DashBoard Explore Button Triggered",
                })
              }
            >
              <a href="https://dashboard.zoop.one">
                <Button
                  hoveredStyle={primaryLightButtonHoveredStyle}
                  size={MEDIUM}
                  style={primaryLightButtonStyle}
                  type="button"
                  className={"expandedWith"}
                  onClick={() =>
                    googleEvent(
                      "Button_Click",
                      GAEvent.ExploreBTN,
                      GAEvent.ExploreBTN
                    )
                  }
                >
                  Explore Dashboard
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
