import React, { useEffect, useState } from "react";
import Styles from "./productFinancial.module.scss";
import SiteHeader from "../../Shared/site-header/siteHeader";
import SiteFooter from "../../Shared/site-footer/siteFooter";
import ProductVerify from "../../Shared/product-verify/productVerify";
import { googlePageView } from "../../analytics/google";

import ButtonCarousel from "../../Shared/button-carousel/buttonCarousel";
import ProductVerifyDetail from "../../Shared/product-verify-detail/productVerifyDetail";
import SharedStyles from "../../Shared/shared.module.scss";
import BusinessCardComp from "../../Shared/business-card/businessCardComp";
import AutomateWork from "../../assets/images/automateWork.svg";
import StopFraud from "../../assets/images/stopFraud.svg";
import ReliableVerify from "../../assets/images/reliableVerify.svg";
import RealTimeVerify from "../../assets/images/realTimeVerify.svg";
import { FaqList } from "../../Shared/faq/faq";
import BannerCard from "../../Shared/banner-card/bannerCard";
import UsecaseProduct from "../../Shared/usecase-product/usecaseProduct";
import GamingUseCase from "../../assets/images/gamingUseCase.svg";
import LendingContract from "../../assets/images/landingContractUsecase.svg";
import Partnership from "../../assets/images/partnerContractingUsecase.svg";
import vendor from "../../assets/images/vendorUsecase.svg";
import BannerTickWhite from "../../assets/images/bannerTickWhite.svg";
import ProductFinanceBackground from "../../assets/images/stack-offerings/financial.webp";
import BankAccountImage from "../../assets/images/products/financial/bav.webp";
import IfscImage from "../../assets/images/products/financial/ifsc.webp";
import UpiImage from "../../assets/images/products/financial/upi.webp";
import UseCaseImage1 from "../../assets/images/usecaseImages/gaming.webp";
import UseCaseImage2 from "../../assets/images/usecaseImages/house-bills-elements-arrangement 1.webp";
import UseCaseImage3 from "../../assets/images/usecaseImages/aml.webp";
import UseCaseImage4 from "../../assets/images/usecaseImages/payouts.webp";
import menuIcon1 from "../../assets/images/menu_slider_icons/blackIcons/financial/bav.svg";
import menuIcon2 from "../../assets/images/menu_slider_icons/blackIcons/financial/ifsc.svg";
import menuIcon3 from "../../assets/images/menu_slider_icons/blackIcons/financial/upi.svg";
import menuIcon1White from "../../assets/images/menu_slider_icons/whiteIcons/financial/bav.svg";
import menuIcon2White from "../../assets/images/menu_slider_icons/whiteIcons/financial/ifsc.svg";
import menuIcon3White from "../../assets/images/menu_slider_icons/whiteIcons/financial/upi.svg";
import BrandMarquee from "../../Shared/brand-marquee/brandMarquee";
import {
  BannerCardButtonTriggered,
  GetAPIKeysTriggered,
  MenuSliderButtonTriggered,
  PageScrollTriggered,
} from "../../Shared/eventsNames";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function ProductFinancial() {
  googlePageView("/products/financial", "FinancialProduct");
  let newPathname = null;
  const pathname = window.location.pathname;
  const [visibleFaqs, setVisibleFaqs] = useState(5);
  const loadMoreFaqs = () => {
    setVisibleFaqs(visibleFaqs + 5);
  };
  const [refreshFaqList, setRefreshFaqList] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      PageScrollTriggered({ properties: "Financial Page Scrolled" });
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const useCases = [
    {
      image: GamingUseCase,
      title: "Online Gaming Platforms",
    },
    {
      image: LendingContract,
      title: "Lending",
    },
    {
      image: Partnership,
      title: "AML",
    },
    {
      image: vendor,
      title: "Payouts / Disbursements",
    },
  ];

  const useCaseImages = [
    {
      mainImage: UseCaseImage1,
      image1: UseCaseImage2,
      image2: UseCaseImage3,
      mainImageClassName: Styles.mainImage,
      image1ClassName: Styles.image1,
      image2ClassName: Styles.image2,
    },
    {
      image1: UseCaseImage4,
      image1ClassName: Styles.image3,
      // image2: "Hello4",
    },
  ];
  const [FaqProps, setFaqProps] = useState({
    faqs: [
      {
        question: "What is a Bank Account Verification API? ",
        answer:
          "It is a tool that verifies the authenticity and validity of bank account details provided by users.",
      },
      {
        question: "How does the Bank Account Verification API work?",
        answer:
          "The Bank Account Verification API accepts bank account details, connects to banks' databases, validates the information, and returns the verification status.",
      },
      {
        question:
          "Can the Bank Account Verification API handle bulk verification requests?",
        answer:
          "Yes, Bank Account Verification API supports bulk verification, making it ideal for businesses with a large number of accounts.",
      },
      {
        question:
          "Is data transmission secure with the Bank Account Verification API?",
        answer:
          "Yes, Bank Account Verification operates over encrypted connections (HTTPS), ensuring data privacy during transmission.",
      },
      {
        question:
          "How fast are the responses from the Bank Account Verification API?",
        answer:
          "The Bank Account Verification API provides real-time responses, delivering swift verification results.",
      },
      {
        question:
          "What are the key benefits of the Bank Account Verification API?",
        answer:
          "The benefits are extensive. The Bank Account Verification API eliminates manual entry errors, reduces the risk of failed transactions, speeds up processing times, and enhances the accuracy of your financial records.",
      },
      {
        question:
          "Is the Bank Account Verification API suitable for businesses of all sizes?",
        answer:
          "Absolutely. Whether you're a small business or a large enterprise, the Bank Account Verification API scalability ensures that it can accommodate your verification needs and grow with your business.",
      },
      {
        question:
          "How does the Bank Account Verification API handle updates in banking regulations? ",
        answer:
          "Our Bank Account Verification API is built with adaptability in mind. It can be updated to accommodate changes in banking regulations, ensuring that your verification process remains compliant and up-to-date.",
      },
      {
        question:
          "What types of bank account information can be verified using the Bank Account Verification API?",
        answer:
          "The Bank Account Verification API can verify various aspects, including account numbers, account holders' names, account status, and even transaction history, depending on the depth of integration and the services offered.",
      },
      {
        question:
          "How can our business start utilizing the Bank Account Verification API?",
        answer:
          "Getting started is simple. Reach out to our team to discuss your specific needs, and we'll guide you through the integration process of Bank Account Verification API. ",
      },
    ],
  });
  const [metaDescription, setMetaDescription] = useState({
    title: "Verify Bank Account | Bank Account Verification ",
    desc: "Verify bank account details accurately and efficiently with our Bank Account Verification API. Ensure the authenticity of bank account information. ",
    keyWords:
      "Verify Bank Account, bank account verification, personal financial management",
  });
  const [productVerifyProps, setProductVerifyProps] = useState({
    heading: "Bank Account Verification API",
    subHeading:
      "Bank Account Verification API are helpful in structuring and managing financial services and also nullifying fraud. ZOOPSTACK has got various financial verification APIs to aid financial institutions. Increase revenue, and conversation rates while reducing operational cost.",
    buttonText: "Get API Keys",
    imgSrc: ProductFinanceBackground,
  });

  const [productVerifyDetailProps, setProductVerifyDetailProps] = useState({
    imageSrc: BankAccountImage,
    heading: "How does Bank Account Verification API work?",
    points: [
      "Sign up on the ZOOPSTACK dashboard",
      "Subscribe to Back Account Verification API in the products section on our dashboard.",
      "Provide Account no. and IFSC code as API input.",
      "Instant Bank Account verification is done on a real-time basis.",
    ],
    buttonText: "Explore",
    productHeading: "Integrate Bank Account Verification API",
    useCaseHeading: "Use cases of Bank Account Verification API",
  });

  const [businessCardProps, setBusinessCardProps] = useState([
    {
      image: AutomateWork,
      cardTitle: "Automate Workflows",
      cardSubTitle:
        "No more reconciling data between multiple documents manually",
    },
    {
      image: StopFraud,
      cardTitle: "Stop Fraudsters",
      cardSubTitle:
        "Let only authentic customers access your services. Ensure your safety and prevent fraudulent activities",
    },
    {
      image: ReliableVerify,
      cardTitle: "Reliable Verification",
      cardSubTitle:
        "Verifies all the information provided by the customer and onboard the legit and accurate customers",
    },
    {
      image: RealTimeVerify,
      cardTitle: "Real-time Verification",
      cardSubTitle:
        "Save your time and effort by integrating the right APIs. The data is processed in a second, hence no more manual reviewing",
    },
  ]);

  const handleButtonClick = () => {
    BannerCardButtonTriggered({
      properties: "BannerCard Button on ProductFinancial Page",
    });
  };

  const [activeButton, setActiveButton] = useState(0);

  useEffect(() => {
    if (pathname.includes("bank")) {
      setActiveButton(0);
      changeContent(0);
    } else if (pathname.includes("upi")) {
      setActiveButton(1);
      changeContent(1);
    } else if (pathname.includes("ifsc")) {
      setActiveButton(2);
      changeContent(2);
    }
  }, []);

  const changeContent = (index: number) => {
    switch (index) {
      case 0:
        setMetaDescription({
          title:
            "Bank Account Verification API | Verify Bank Account with ZOOPSTACK ",
          desc: "Verify bank account details accurately and efficiently with our Bank Account Verification API. Ensure the authenticity of bank account information. ",
          keyWords:
            "Verify Bank Account, bank account verification, personal financial management",
        });
        MenuSliderButtonTriggered({
          properties:
            "MenuSlider Bank Account Verification Button of Financial Page ",
        });
        newPathname =
          pathname.substring(0, pathname.lastIndexOf("/")) +
          "/bank-account-verification-api";
        window.history.replaceState({}, "", `${newPathname}`);
        setProductVerifyDetailProps({
          imageSrc: BankAccountImage,
          heading: "How does Bank Account Verification API work?",
          points: [
            "Sign up on the ZOOPSTACK dashboard",
            "Subscribe to Back Account Verification API in the products section on our dashboard.",
            "Provide Account no. and IFSC code as API input.",
            "Instant Bank Account verification is done on a real-time basis.",
          ],
          buttonText: "Explore",
          productHeading: "Integrate Bank Account Verification API",
          useCaseHeading: "Use cases of Bank Account Verification API",
        });
        setProductVerifyProps({
          heading: "Bank Account Verification API",
          subHeading:
            "Bank account verification is a must to know the credibility of the customers. The regulatory bodies advise financial institutions to opt for online bank account verification. This helps in preventing fraudulent activities and assures that the account is valid. ZOOPSTACK’s Bank Account Verification API is reliable and makes the entire process swift.",
          buttonText: "Get API Keys",
          imgSrc: ProductFinanceBackground,
        });
        setFaqProps({
          faqs: [
            {
              question: "What is a Bank Account Verification API? ",
              answer:
                "It is a tool that verifies the authenticity and validity of bank account details provided by users.",
            },
            {
              question: "How does the Bank Account Verification API work?",
              answer:
                "The Bank Account Verification API accepts bank account details, connects to banks' databases, validates the information, and returns the verification status.",
            },
            {
              question:
                "Can the Bank Account Verification API handle bulk verification requests?",
              answer:
                "Yes, Bank Account Verification API supports bulk verification, making it ideal for businesses with a large number of accounts.",
            },
            {
              question:
                "Is data transmission secure with the Bank Account Verification API?",
              answer:
                "Yes, Bank Account Verification operates over encrypted connections (HTTPS), ensuring data privacy during transmission.",
            },
            {
              question:
                "How fast are the responses from the Bank Account Verification API?",
              answer:
                "The Bank Account Verification API provides real-time responses, delivering swift verification results.",
            },
            {
              question:
                "What are the key benefits of the Bank Account Verification API?",
              answer:
                "The benefits are extensive. The Bank Account Verification API eliminates manual entry errors, reduces the risk of failed transactions, speeds up processing times, and enhances the accuracy of your financial records.",
            },
            {
              question:
                "Is the Bank Account Verification API suitable for businesses of all sizes?",
              answer:
                "Absolutely. Whether you're a small business or a large enterprise, the Bank Account Verification API scalability ensures that it can accommodate your verification needs and grow with your business.",
            },
            {
              question:
                "How does the Bank Account Verification API handle updates in banking regulations? ",
              answer:
                "Our Bank Account Verification API is built with adaptability in mind. It can be updated to accommodate changes in banking regulations, ensuring that your verification process remains compliant and up-to-date.",
            },
            {
              question:
                "What types of bank account information can be verified using the Bank Account Verification API?",
              answer:
                "The Bank Account Verification API can verify various aspects, including account numbers, account holders' names, account status, and even transaction history, depending on the depth of integration and the services offered.",
            },
            {
              question:
                "How can our business start utilizing the Bank Account Verification API?",
              answer:
                "Getting started is simple. Reach out to our team to discuss your specific needs, and we'll guide you through the integration process of Bank Account Verification API. ",
            },
          ],
        });
        break;
      case 1:
        setMetaDescription({
          title: " UPI Verification API - ZOOPSTACK",
          desc: "Simplify digital payments in India with our UPI Verification API. Ensure secure and seamless UPI transactions and do UPI verification with ease. ",
          keyWords:
            "digital payments in india, upi verification, upi transaction, online money transfer",
        });
        MenuSliderButtonTriggered({
          properties: "MenuSlider UPI Verification Button of Financial Page ",
        });
        newPathname =
          pathname.substring(0, pathname.lastIndexOf("/")) +
          "/upi-verification-api";
        window.history.replaceState({}, "", `${newPathname}`);
        setProductVerifyDetailProps({
          imageSrc: UpiImage,
          heading: "How does UPI Verification API work?",
          points: [
            "Sign up on the ZOOPSTACK dashboard",
            "Subscribe to UPI Verification API in the products section on our dashboard.",
            "Provide UPI ID as API input.",
            "Instant UPI verification is done on a real-time basis.",
          ],
          buttonText: "Explore",
          productHeading: "Integrate UPI Verification API",
          useCaseHeading: "Use cases of UPI Verification API",
        });
        setProductVerifyProps({
          heading: "UPI Verification API",
          subHeading:
            "The National Payment Corporation Of India (NPCI) brought an instant real-time payment system called Unified Payment Interface (UPI). The goal was to promote digital payments in India. For all the digital monetary transactions across banks. merchants and institutions UPI is being used. A unique virtual payment address (VPA) is allotted to every bank account holder who registers for UPI.",
          buttonText: "Get API Keys",
          imgSrc: ProductFinanceBackground,
        });
        setFaqProps({
          faqs: [
            {
              question: "What is a UPI Verification API?",
              answer:
                "UPI Verification API verifies the validity of UPI (Unified Payments Interface) IDs, ensuring seamless payment transactions.",
            },
            {
              question: "How does the UPI Verification API work?",
              answer:
                "The UPI Verification API accepts UPI IDs, connects to UPI's database, validates the information, and returns the verification status.",
            },
            {
              question:
                "Can the UPI Verification API handle bulk verification requests?",
              answer:
                "Yes, UPI Verification API supports bulk verification, making it efficient for processing multiple UPI IDs.",
            },
            {
              question:
                "Is data transmission secure with the UPI Verification API?",
              answer:
                "Yes, UPI Verification API operates over encrypted connections (HTTPS), safeguarding data during UPI ID verification transmission.",
            },
            {
              question:
                "How fast are the responses from the UPI Verification API?",
              answer:
                "The UPI Verification API provides real-time responses, ensuring quick UPI ID verification results.",
            },
            {
              question: "What benefits does the UPI Verification API offer? ",
              answer:
                "The UPI Verification API enhances transaction security, minimizes the risk of incorrect transfers, prevents unauthorized transactions, and improves the overall trustworthiness of your financial ecosystem.",
            },
            {
              question:
                "What industries can benefit most from the UPI Verification API? ",
              answer:
                "Industries that deal with digital payments, e-commerce, banking, fintech, and mobile apps can benefit from the enhanced security and trust the UPI Verification API provides.",
            },
            {
              question:
                "How can businesses access and implement the UPI Verification API?",
              answer:
                "Accessing the UPI Verification API is simple – just reach out to our team. We'll guide you through the implementation process, assist with integration, and ensure a smooth transition.",
            },
            {
              question:
                "Is the use of the UPI Verification API mandatory for financial institutions and businesses?",
              answer:
                "The use of the UPI Verification API may not always be mandatory but is highly recommended for enhancing the security and accuracy of UPI transactions.",
            },
            {
              question:
                "Is the UPI Verification API customizable for different business needs?",
              answer:
                "Yes, the UPI Verification API can often be customized to suit various business use cases and integration requirements.",
            },
          ],
        });
        break;
      case 2:
        setMetaDescription({
          title: "IFSC Code Verification API - Fast & Secure",
          desc: "With ZOOPSTACK's IFSC Verification Code API simply IFSC code verification. Integrate our reliable APIs into system to get real-time IFSC code verification done. Bulk IFSC check is now a matter of seconds. ",
          keyWords:
            "check ifsc code, ifsc check, verify ifsc code, ifsc code verification",
        });
        MenuSliderButtonTriggered({
          properties: "MenuSlider IFSC Verification Button of Financial Page ",
        });
        newPathname =
          pathname.substring(0, pathname.lastIndexOf("/")) +
          "/ifsc-verification-api";
        window.history.replaceState({}, "", `${newPathname}`);
        setProductVerifyDetailProps({
          imageSrc: IfscImage,
          heading: "How does IFSC Verification Code API work?",
          points: [
            "Sign up on the ZOOPSTACK dashboard",
            "Subscribe to IFSC Verification Code API in the products section on our dashboard.",
            "Provide IFSC code as API input.",
            "Instant IFSC verification is done on a real-time basis.",
          ],
          buttonText: "Explore",
          productHeading: "Integrate IFSC Verification Code API",
          useCaseHeading: "Use cases of IFSC Verification Code API",
        });
        setProductVerifyProps({
          heading: "IFSC Code Verification API",
          subHeading:
            "IFSC also known as the Indian Financial System Code is a unique alphanumeric code for different bank branches given by the Reserve Bank of India. It is used for online transactions. Get ZOOPSTACK’s IFSC Verification Code API and track the name of the bank, bank code, branch MICR code, branch location, and supported mode of transfers instantly",
          buttonText: "Get API Keys",
          imgSrc: ProductFinanceBackground,
        });
        setFaqProps({
          faqs: [
            {
              question: "What is an IFSC code verification API?",
              answer:
                "IFSC code verification API verify the authenticity and accuracy of an Indian Financial System Code (IFSC) for a particular bank branch.",
            },
            {
              question: "How does the IFSC code verification API work?",
              answer:
                "The IFSC code verification  API takes an IFSC code as input and returns relevant information about the corresponding bank branch, such as the bank's name, branch name, address, and city.",
            },
            {
              question:
                "What data does the IFSC code verification API return for a given IFSC code?",
              answer:
                "The IFSC code verification API usually returns details such as the bank's name, branch name, address, city, and sometimes the state and contact information for the specific branch.",
            },
            {
              question:
                "Can IFSC code verification API do bulk IFSC code verification?",
              answer:
                "ZOOPSTACK’s IFSC code verification API provides bulk verification options to process multiple IFSC codes simultaneously.",
            },
            {
              question:
                "Is the IFSC code verification API secure to use for financial transactions?",
              answer:
                "ZOOPSTACK’s IFSC code verification implements security measures like encryption and authentication to ensure the safety of data exchanged during API calls.",
            },
            {
              question: "Who can use the IFSC Code Verification API?",
              answer:
                "The IFSC Code Verification API is designed for use by banks, financial institutions, businesses, and developers looking to integrate IFSC validation into their applications or systems.",
            },
            {
              question:
                "What is the purpose of using the IFSC Code Verification API?",
              answer:
                "The primary purpose of the IFSC Code Verification API is to ensure the accuracy and authenticity of IFSC codes used in banking and financial transactions.",
            },
            {
              question: "How does the IFSC Code Verification API work?",
              answer:
                "The IFSC Code Verification API functions by checking the correctness and validity of an IFSC code, ensuring it corresponds to the correct bank and branch.",
            },
            {
              question:
                "Is the use of the IFSC Code Verification API mandatory for financial institutions and businesses?",
              answer:
                "While it is not mandatory, using the IFSC Code Verification API is highly recommended to minimize errors in financial transactions and enhance security.",
            },
            {
              question:
                "Are there any data privacy concerns associated with the IFSC Code Verification API?",
              answer:
                "The IFSC Code Verification API primarily focuses on verifying codes and does not typically involve sensitive personal data, but users should follow data protection regulations.",
            },
          ],
        });
        break;
    }
  };
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>ZOOPSTACK</title>
          <meta name="description" content={metaDescription.desc} />
          <meta name="title" content={metaDescription.title} />
          <meta name="Keywords" content={metaDescription.keyWords} />
        </Helmet>
      </HelmetProvider>
      <div className={SharedStyles.mainContainer}>
        <SiteHeader />
        <div className={Styles.productFinancialBackground}>
          <div className={SharedStyles.menu}>
            <div className={`${Styles.navigation} js-btn-slide-container`}>
              <div
                className={`${Styles.navigation__btn} ${
                  activeButton === 0
                    ? `${Styles["navigation__btn--active"]} ${Styles.selected}`
                    : ""
                } js-btn-slide`}
                onClick={() => setActiveButton(0)}
                role="button"
              >
                <ButtonCarousel
                  buttonImgSrc={activeButton === 0 ? menuIcon1White : menuIcon1}
                  btnText="Bank Account Verification"
                  onButtonClick={() => changeContent(0)}
                />
              </div>

              <div
                className={`${Styles.navigation__btn} ${
                  activeButton === 1
                    ? `${Styles["navigation__btn--active"]} ${Styles.selected}`
                    : ""
                } js-btn-slide`}
                onClick={() => setActiveButton(1)}
                role="button"
              >
                <ButtonCarousel
                  buttonImgSrc={activeButton === 1 ? menuIcon3White : menuIcon3}
                  btnText={"UPI Verification"}
                  onButtonClick={() => changeContent(1)}
                />
              </div>

              <div
                className={`${Styles.navigation__btn} ${
                  activeButton === 2
                    ? `${Styles["navigation__btn--active"]} ${Styles.selected}`
                    : ""
                } js-btn-slide`}
                onClick={() => setActiveButton(2)}
                role="button"
              >
                <ButtonCarousel
                  buttonImgSrc={activeButton === 2 ? menuIcon2White : menuIcon2}
                  btnText={"IFSC Verification"}
                  onButtonClick={() => changeContent(2)}
                />
              </div>
            </div>
          </div>
          <div className={Styles.productVerifySection}>
            <ProductVerify
              heading={productVerifyProps.heading}
              contentText={productVerifyProps.subHeading}
              buttonText={productVerifyProps.buttonText}
              onButtonClick={() =>
                GetAPIKeysTriggered({
                  properties: `GetAPIKeys on ProductFinancial Page for ${productVerifyProps.heading}`,
                })
              }
              imgSrc={productVerifyProps.imgSrc}
              link={"/contactus"}
            />
          </div>
        </div>

        <div className={Styles.productVerify}>
          <ProductVerifyDetail
            imageSrc={productVerifyDetailProps.imageSrc}
            heading={productVerifyDetailProps.heading}
            points={[
              productVerifyDetailProps.points[0],
              productVerifyDetailProps.points[1],
              productVerifyDetailProps.points[2],
              productVerifyDetailProps.points[3],
            ]}
            buttonText={productVerifyDetailProps.buttonText}
            onButtonClick={handleButtonClick}
          />
        </div>

        <BrandMarquee />
        <div className={SharedStyles.section}>
          <div className={SharedStyles.sectionHeading}>
            {productVerifyDetailProps.productHeading}
          </div>
          <div className={SharedStyles.sectionSubHeading}>
            Sign up on our dashboard and test our APIs instantly for free
          </div>
        </div>

        <div className={Styles.businessCards}>
          <BusinessCardComp
            image={businessCardProps[0].image}
            cardTitle={businessCardProps[0].cardTitle}
            cardSubTitle={businessCardProps[0].cardSubTitle}
          />
          <BusinessCardComp
            image={businessCardProps[1].image}
            cardTitle={businessCardProps[1].cardTitle}
            cardSubTitle={businessCardProps[1].cardSubTitle}
          />
          <BusinessCardComp
            image={businessCardProps[2].image}
            cardTitle={businessCardProps[2].cardTitle}
            cardSubTitle={businessCardProps[2].cardSubTitle}
          />
          <BusinessCardComp
            image={businessCardProps[3].image}
            cardTitle={businessCardProps[3].cardTitle}
            cardSubTitle={businessCardProps[3].cardSubTitle}
          />
        </div>

        <div className={SharedStyles.section}>
          <div className={SharedStyles.sectionHeading}>
            {productVerifyDetailProps.useCaseHeading}
          </div>
        </div>
        <UsecaseProduct useCases={useCases} useCaseImages={useCaseImages} />

        <div className={SharedStyles.faq}>
          <h1 className={SharedStyles.sectionHeading}>
            Frequently Asked Questions
          </h1>
          {/* <p style={{ color: "#9A9AA3", marginTop: "8px" }}>
          Sign up on our dashboard and test our APIs instantly for free.
        </p> */}
        </div>

        <div className="container">
          <div style={{ marginBottom: "100px" }}>
            <FaqList
              faqs={FaqProps.faqs}
              visibleFaqs={visibleFaqs}
              onLoadMore={loadMoreFaqs}
            />
          </div>

          <BannerCard
            text={"Ready to take your business to new heights?"}
            button={{ text: "Get free Demo", onClick: handleButtonClick }}
            tickImage={BannerTickWhite}
            backGroundClassName={Styles.bannerBackground}
          />
        </div>
        <SiteFooter />
      </div>
    </>
  );
}
