import React from "react";
import Styles from "./esignBanner.module.scss";
import Button from "../button/button";
import {
  primaryLightButtonHoveredStyle,
  primaryLightTransButtonStyle,
} from "../buttonStyles";
import { MEDIUM } from "../buttonSize";
import EsignBannerImage from "../../assets/images/bannerLines/esign_banner_asset.webp";

function esignBanner() {
  return (
    <>
      <div className={`${Styles.esignBannerContainer}`}>
        <div className={Styles.esignTextContainer}>
          <div className={Styles.esignText}>
            Experience the Future of Signatures - Go Paperless Today!
          </div>
          <a href="https://zoopsign.com/electronic-signature/">
            <Button
              className={Styles.btn}
              // hoveredStyle={primaryLightButtonHoveredStyle}
              size={MEDIUM}
              style={primaryLightTransButtonStyle}
            >
              Explore Zoopsign
            </Button>
          </a>
        </div>
        <div>
          <img className={Styles.esignImage} src={EsignBannerImage} />
        </div>
      </div>
    </>
  );
}

export default esignBanner;
