import React, { useState } from "react";
import Styles from "./button.module.scss";
import { COMPRESSED, LARGE, MEDIUM } from "../buttonSize";

function Button({
  isLoading,
  disabled,
  style,
  hoveredStyle,
  size,
  btnTextClass,
  className,
  ...props
}: any) {
  const [buttonStyle, setButtonStyle] = useState(style);
  return (
    <button
      className={Styles.btn + " " + className || ""}
      style={
        size === COMPRESSED
          ? { ...buttonStyle, minWidth: "110px", width: "100%", height: "30px" }
          : {
              ...buttonStyle,
              width:
                size === LARGE ? "300px" : size === MEDIUM ? "200px" : "100%",
              height:
                size === LARGE ? "60px" : size === MEDIUM ? "50px" : "30px",
            }
      }
      disabled={disabled}
      onMouseOver={() => setButtonStyle(hoveredStyle)}
      onMouseOut={() => setButtonStyle(style)}
      {...props}
    >
      <p
        className={`${Styles.btnText + " " + (btnTextClass || "")} mb-0`}
        style={{
          color: buttonStyle,
          fontSize:
            size === COMPRESSED ? "12px" : size === LARGE ? "20px" : "16px",
        }}
      >
        {props.children}
      </p>
    </button>
  );
}

export default Button;
