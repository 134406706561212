/* eslint-disable quotes */
import React from "react";
import SiteFooter from "../../Shared/site-footer/siteFooter";
import SiteHeader from "../../Shared/site-header/siteHeader";
import { googlePageView } from "../../analytics/google";

import Styles from "./privacyPolicy.module.scss";

export default function privacyPolicy() {
  googlePageView("/privacy-policy", "Privacy Policy");
  const policyText = [
    {
      heading: "Introduction ",
      text: [
        "We value the trust you place in Zoop.one platform of Quagga Tech Private Limited. That’s why we insist upon the highest standards for secure transactions and customer information privacy. Your privacy is extremely important to us. We won’t ask you for personal information unless we truly need it.",
        "   • Our privacy policy describes the ways in which we collect, store, use and protect your personal information and it is important for you to review this privacy policy. By “personal information” we mean information that can be associated with a specific person and can be used to identify that person. ",
        "   • We do not consider anonymized information to constitute personal information, as it cannot be used to identify a specific person. We collect personal information from you when you use Zoop.one services and/or its related sub-domains, mobile applications, sites, services and tools (Henceforth, called Zoop.one or ‘Site’ will also refer to the above)or when you telephone or email our customer support team. ",
        "If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us at dpo@zoop.one",
        "By providing us with your personal information you expressly consent to us processing your personal information in accordance with the terms of our privacy policy. ",
      ],
    },
    {
      heading: "Changes to our Privacy Policy",
      text: [
        "From time to time we may revise this Privacy Policy. If we make changes to this Privacy Policy, including a material change in the way we use your personal information, it will be deemed to be notified to and read and agreed by you on your using the website and/or related services once we post the same on our website. Your continued usage will constitute express consent to us continuing to process your personal information in accordance with the terms of our revised policy.  Our privacy policy covers the following topics: ",
        "   1. Gathering of your personal information",
        "   2. Aggregated Statistics",
        "   3. Use of Personal Information",
        "   4. Links to other websites",
        "   5. Cookies",
        "   6. Advertisements on Zoop.one",
        "   7. Protecting your personal information",
        "   8. Questions",
        "Gathering of your personal information From time to time we may revise this Privacy Policy. If we make changes to this Privacy Policy, including a material change in the way we use your personal information, it will be deemed to be notified to and read and agreed by you on your using the website and/or related services once we post the same on our website. Your continued usage will constitute express consent to us continuing to process your personal information in accordance with the terms of our revised policy.  ",
        "   • We collect personally identifiable information such as your name, physical address, email address, telephone number, date of birth and/or other similar information when you register with our website or mobile application. While you can browse some sections of our website/mobile application without being a registered member, certain activities (placing an order, etc) do require registration. Additionally, in order for us to verify your identity, sometimes we may need to request from you valid proof of identification (e.g. in the form of a copy of your passport, driving licence, national ID card or similar document). ",
        "   • The collected financial information will be used for fulfilment of your orders and collection of payment.",
        "   • Information on your transactional activities within the website/mobile application will be collected and stored and used solely in relation to the transactions undertaken on the website/application.  ",
        "   • Please note that we may use your Internet protocol (or IP) address (which is a unique number assigned to your computer server or your Internet service provider (or ISP)) to analyze user trends and improve the administration of the Site. ",
        "   • We may also collect information about your computer (for example, browser type) and navigation information (for example, the pages you visit on the Site) along with the times that you access the Site. ",
        "   • Finally, we may collect additional information from or about you in other ways not specifically described here. It is your responsibility to keep the personal information provided by you to us on registration up-to-date. Visitors can always refuse to supply personally identifying information within the caveat that it may prevent them from engaging in certain website-related activities.  ",
      ],
    },
    {
      heading: "Aggregated Statistics",
      text: [
        "Zoop.one may collect statistics about the behaviour of visitors to its Site. For instance, Zoop.one may monitor the most popular products/services on the website. Zoop.one may display this information publicly or provide it to others. Where such personal information is aggregated, it shall be anonymized.  ",
      ],
    },
    {
      heading: "Use of Personal Information ",
      text: [
        "   1. We only use your personal information to provide services and customer support to you; to measure and improve our services to you; to prevent illegal activities and implement our user agreement with you (“User Agreement”); troubleshoot problems; collect fees; provide you with promotional emails and verify information you give us with third parties. We may disclose your personal information to those of our employees, contractors, and affiliated organizations that need to know the information in order to process it on our behalf or to provide services available at our website/mobile application and that have agreed not to disclose it to others. Some of these employees, contractors, and affiliated organizations may be located outside of your home country. By using our website/mobile application, you consent to the transfer of such information to them.  ",
        "   2. Though we make every effort to preserve your privacy, we may need to disclose your personal information to law enforcement agencies, government agencies or other third parties where we are compelled so to do by court order or similar legal procedure; where we are required to disclose your personal information to comply with law; where we are cooperating with an ongoing law enforcement investigation or where we have a good faith belief that our disclosure of your personal information is necessary to prevent physical harm or financial loss, to report suspected illegal activity or to investigate a possible violation of our User Agreement. ",
        "   3. Zoop.one and its affiliates will share some or all of your personal information with another business entity should we (or our assets) plan to merge with, or be acquired by that business entity. Should such a transaction occur then the other business entity (or the new combined entity) will be required to follow this privacy policy with respect to your personal information.",
        "   4. We may share your personal information with our other group companies or affiliates so as to provide joint content and services to you, to help detect illegal acts and/or the violations of our policies. ",
        "   5. Additionally, information relating to items you are buying or selling be displayed on the Site. This information can include details of your user ID, feedback ratings and associated comments relating to your use of the Site. Otherwise, we will only disclose your personal information to a third party with your express permission.  ",
        "   6. We do not sell or rent any of your personal information to third parties in the normal course of doing business and will only share your personal information with third parties in accordance with this privacy policy.  ",
        "   7. By registering on the Site, you give us your express consent to receive promotional emails about our services and emails announcing changes to, and new features on, the Site. If, at any time, you decide that you do not wish to receive any such emails, you can opt out of receiving such emails. ",
        "   8. Additionally, we do use comments made by you about the Site for marketing purposes and by making such comments you expressly consent to our using such comments for marketing purposes.  Links to other websites Our site links to other websites that may collect personally identifiable information about you. Zoop.one is not responsible for the privacy practices or the content of those linked websites.  ",
      ],
    },
    {
      heading: "Cookies",
      text: [
        "   1. Like many websites, the Site uses ‘cookie’ technology (small computer files placed on your computer’s hard drive). When you go to particular website pages on the Site, the cookie identifies your browser with a unique, random number.  ",
        "   2. The cookies we use do not reveal any personal information about you. Cookies help us improve your experience of the Site and also help us understand which parts of the Site are most popular. You are always free to decline our cookies if your browser permits, although doing so may interfere with your use of the Site. ",
        "   3. The above privacy policy relating to cookies covers the use of cookies by Zoop.one and does not cover the use of cookies by any advertisers on our website.  ",
      ],
    },
    {
      heading: "Advertisements on Zoop.one",
      text: [
        "We may use third-party advertising companies to serve ads when you visit our website. These companies may use information (not including your name, address, email address, or telephone number) about your visits to this and other websites in order to provide advertisements about goods and services of interest to you.",
      ],
    },

    {
      heading: "Protecting your personal information",
      text: [
        "We hold your personal information on secure servers. By providing us with personal information, you consent to the transfer of your personal information to, and its storage on, our secure servers. We take every precaution to safeguard all your personal information from unauthorized access, use or disclosure. ",
        "However, the Internet is not a secure medium and we cannot guarantee the privacy of your personal information.  You must enter your username and password each time you want to access your account or make transactions on the Site.  Choose your password carefully using unique numbers, letters and special characters. Never share your username and password with anyone.  ",
        "If you are concerned that your username or password has been compromised, please contact our customer support team immediately and ensure you change your password by logging onto the User settings section of the Site.  Questions Questions regarding protection of your personal information can be directed to connect@zoopone-752ba0.ingress-baronn.easywp.com.",
        "Quagga Tech Pvt Ltd",
        "dpo@zoop.one",
        "Vijay Chhuttani",
      ],
    },
    {
      heading: "Data Privacy Policy",
      text: [""],
    },
    {
      heading: "Cookie Policy",
      text: [""],
    },
  ];
  const renderText = (text: string[]) => {
    return text.map((line: string, index: number) => {
      if (index === 0 || !line.startsWith("   •")) {
        return (
          <p className={Styles.paragraphText} key={index}>
            {line}
          </p>
        );
      } else {
        const bulletText = line.substring(4);
        if (!isNaN(Number(bulletText.charAt(0)))) {
          const bulletNumberMatch = bulletText.match(/^\d+/);
          const bulletNumber = bulletNumberMatch ? bulletNumberMatch[0] : "";
          const bulletContent = bulletText.slice(bulletNumber.length).trim();

          // Detect hyperlink syntax [text](URL)
          const hyperlinkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
          const bulletWithLinks = bulletContent.replace(
            hyperlinkRegex,
            (match, text, url) =>
              (
                <a href={url} key={url}>
                  {text}
                </a>
              ).toString() // Cast the JSX element to a string
          );

          return (
            <li key={index} className={Styles.paragraphText}>
              {bulletNumber && <span>{bulletNumber}. </span>}
              {bulletWithLinks}
            </li>
          );
        } else {
          return (
            <li key={index} className={Styles.paragraphText}>
              {bulletText}
            </li>
          );
        }
      }
    });
  };

  const renderPolicyText = () => {
    return policyText.map((item, index) => (
      <div key={index}>
        <h2 className={Styles.paragraphHeading}>{item.heading}</h2>
        {item.heading === "Data Privacy Policy" ? (
          <a href="https://docs.google.com/document/d/1Q35N23-Z85J3hIChFga7h-_JVmqbwcpf/edit?usp=sharing&ouid=105297180449059860580&rtpof=true&sd=true">
            Click here to view Data Privacy Policy
          </a>
        ) : item.heading === "Cookie Policy" ? (
          <a href="https://docs.google.com/document/d/19vN0Ew5zAE4fMJTRIrXU8KHcXDeu9LGH/edit?usp=sharing&ouid=116026168561088416110&rtpof=true&sd=true">
            Click here to view Cookie Policy
          </a>
        ) : (
          renderText(item.text)
        )}
      </div>
    ));
  };

  return (
    <div>
      <SiteHeader />
      <div className={Styles.mainHeader}>
        <div className={Styles.heading}>Privacy Policy</div>
      </div>
      <div className={Styles.mainContainer}>{renderPolicyText()}</div>
      <SiteFooter />
    </div>
  );

  //   return (
  //     <div>
  //       <SiteHeader />
  //       <div className={Styles.mainHeader}>
  //         <div className={Styles.heading}>Terms and Conditions</div>
  //       </div>
  //       <div className={Styles.mainContainer}>
  //         <div>
  //           {/* policyText */}
  //           {policyText.map((section, index) => (
  //             <div key={index}>
  //               <h2 className={Styles.paragraphHeading}>{section.heading}</h2>
  //               {section.text.map((text, idx) => (
  //                 <p className={Styles.paragraphText} key={idx}>
  //                   {text}
  //                 </p>
  //               ))}
  //             </div>
  //           ))}
  //         </div>
  //       </div>
  //       <SiteFooter />
  //     </div>
  //   );
}
