import React, { useState } from "react";
import Styles from "../stack-offering/stackOffering.module.scss";
import SharedStyles from "../../Shared/shared.module.scss";
import { googlePageView, googleEvent } from "../../analytics/google";
import GAEvent from "../../analytics/events";

import { StackOfferingTriggered } from "../../Shared/eventsNames";
interface OfferingProps {
  header: string;
  mobHead: string;
  description: string;
  url: string;
  gaEvent?: string;
}

const offerings: OfferingProps[] = [
  {
    header: "Identity Verification",
    mobHead: "Merchant",
    description:
      "Get real-time check of government issued IDs of the users instantly.",
    url: "/product/identity",
    gaEvent: GAEvent.IdVefifyClick,
  },
  {
    header: "Financial Verification",
    mobHead: "Merchant",
    description:
      "Verify & authenticate your users before starting any monetary transaction.",
    url: "/product/financial",
    gaEvent: GAEvent.FinancialVerifyClick,
  },
  {
    header: "Merchant Verification",
    mobHead: "Merchant",
    description:
      "Do business by knowing authenticity of other party’s business.",
    url: "/product/merchant",
    gaEvent: GAEvent.MerchantVerifyClick,
  },
  {
    header: "Digital Contracting",
    mobHead: "Merchant",
    description:
      "Get contracts signed in a fast, cost-effective, & location-independent way.",
    url: "product/aadhar-esign-api",
    gaEvent: GAEvent.digitalContractClick,
  },
  {
    header: "ML & Ai Solutions",
    mobHead: "Merchant",
    description:
      "Assure higher conversion rates with advanced OCR, Face Analytics & more.",
    url: "/product/utility",
    gaEvent: GAEvent.MLAISolutionClick,
  },
  {
    header: "Persona Verification",
    mobHead: "Merchant",
    description:
      "Facilitate verification of personas your business targets, with enhanced API tools.",
    url: "/persona",
    gaEvent: GAEvent.PesonaVerficationClick,
  },
];

const StackOffering = () => {
  googlePageView("/stack-offering", "StackOfferingPage");
  const [activeOfferingIndex, setActiveOfferingIndex] = useState(0);

  const handleDotClick = (index: number) => {
    setActiveOfferingIndex(index);
  };
  return (
    <div className={Styles.offeringWrapper}>
      <div className={`${SharedStyles.section} m-auto align-items-center`}>
        <div className={SharedStyles.sectionHeadingContainer}>
          <p className={SharedStyles.sectionHeading}>Stack Offerings</p>
          <p className={SharedStyles.sectionSubHeading}>
            A wide range of APIs and SDKs to deliver impeccable customer
            onboarding experience
          </p>
          <h3 className={SharedStyles.sectionMobileSubHeading}>
            Identity • Verify • Authenticate{" "}
          </h3>
        </div>
        <div className={Styles.cardsContainer}>
          {offerings.map((offering, index) => (
            <a
              key={offering.header}
              href={offering.url}
              className={` ${Styles.card} ${
                index === activeOfferingIndex ? `${Styles.activeCard}` : ""
              }`}
              onMouseEnter={() => handleDotClick(index)}
              onClick={() =>
                googleEvent(
                  "Home_Stack_Offerings_Banner_Click",
                  offering?.gaEvent || "",
                  offering?.gaEvent || ""
                )
              }
            >
              <div className={Styles.mask}></div>
              <div className={Styles.contentContainer}>
                <h4 className={Styles.cardHeader}>{offering.header}</h4>
                <h4 className={Styles.mobHead}>{offering.mobHead}</h4>
                <p className={Styles.cardSubHeader}>{offering.description}</p>
              </div>
            </a>
          ))}
        </div>
        <div className={Styles.dotsContainer}>
          {offerings.map((offering, index) => (
            <div
              key={`dot-${index}`}
              className={`${Styles.dot} ${
                index === activeOfferingIndex ? Styles.activeDot : ""
              }`}
              onClick={() => handleDotClick(index)}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StackOffering;
