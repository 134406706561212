import React, { useEffect, useState } from "react";
import Styles from "./digitalContracting.module.scss";
import SiteHeader from "../../Shared/site-header/siteHeader";
import { googlePageView } from "../../analytics/google";
import SiteFooter from "../../Shared/site-footer/siteFooter";
import BannerCard from "../../Shared/banner-card/bannerCard";
import ProductVerify from "../../Shared/product-verify/productVerify";
import UsecaseProduct from "../../Shared/usecase-product/usecaseProduct";
import { FaqList } from "../../Shared/faq/faq";
import ContractManagement from "../../assets/images/contactManagement.svg";
import LendingContract from "../../assets/images/lendingContracts.svg";
import PartnershipContract from "../../assets/images/partnershipContracts.svg";
import vendorAgreement from "../../assets/images/vendorAgreements.svg";
import digitalContractingBG from "../../assets/images/stack-offerings/digital_ contracting.webp";
import AutomateWork from "../../assets/images/automateWork.svg";
import StopFraud from "../../assets/images/stopFraud.svg";
import ReliableVerify from "../../assets/images/reliableVerify.svg";
import RealTimeVerify from "../../assets/images/realTimeVerify.svg";
import SharedStyles from "../../Shared/shared.module.scss";
import BusinessCardComp from "../../Shared/business-card/businessCardComp";
import BannerTickWhite from "../../assets/images/bannerTickWhite.svg";
import AddOns from "../../Shared/add-ons/addOns";
import ProductVerifyDetail from "../../Shared/product-verify-detail/productVerifyDetail";
import AadhaarImage from "../../assets/images/products/digital contracting/aadhaar_esign.webp";
import UseCaseImage1 from "../../assets/images/usecaseImages/contract_management.webp";
import UseCaseImage2 from "../../assets/images/usecaseImages/lending_contracts.webp";
import UseCaseImage3 from "../../assets/images/usecaseImages/partnership_contracts.webp";
import UseCaseImage4 from "../../assets/images/usecaseImages/vendor_agreements.webp";
import BrandMarquee from "../../Shared/brand-marquee/brandMarquee";
import {
  BannerCardButtonTriggered,
  PageScrollTriggered,
} from "../../Shared/eventsNames";
import { Helmet, HelmetProvider } from "react-helmet-async";
import EsignBanner from "../../Shared/esign-banner/esignBanner";
export default function DigitalContracting() {
  googlePageView("/digital-contracting", "DigitalContracting");
  const [visibleFaqs, setVisibleFaqs] = useState(5);
  const loadMoreFaqs = () => {
    setVisibleFaqs(visibleFaqs + 5);
  };
  const [refreshFaqList, setRefreshFaqList] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      PageScrollTriggered({ properties: "Digital Contracting Page Scrolled" });
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [metaDescription, setMetaDescription] = useState({
    title: "eSignature API - India’s #1 Aadhaar eSign verification Platform",
    desc: "Ensure secure & seamless customer onboarding with our Aadhaar eSignature API. Simplify Aadhaar eSign verification for enhanced convenience and compliance.",
    keyWords:
      "aadhaar signature validation online, customer onboarding, aadhaar esign verification",
  });
  const useCases = [
    {
      image: ContractManagement,
      title: "Contract Management",
    },
    {
      image: LendingContract,
      title: "Lending Contracts",
    },
    {
      image: PartnershipContract,
      title: "Partnership Contracts",
    },
    {
      image: vendorAgreement,
      title: "Vendor Agreements",
    },
  ];

  const useCaseImages = [
    {
      mainImage: UseCaseImage1,
      image1: UseCaseImage2,
      image2: UseCaseImage3,
      mainImageClassName: Styles.mainImage,
      image1ClassName: Styles.image1,
      image2ClassName: Styles.image1,
    },
    {
      image1: UseCaseImage4,
      image1ClassName: Styles.image1,
    },
  ];
  const faqs = [
    {
      question: "What is the Aadhar eSign Verification API?",
      answer:
        "The Aadhar eSign Verification API is a robust solution that enables seamless electronic signature verification, ensuring the authenticity and integrity of digital documents.",
    },
    {
      question: "How does the Aadhar eSign Verification API work?",
      answer:
        "The Aadhar eSign Verification API utilizes advanced cryptographic techniques to verify electronic signatures, cross-referencing them with trusted sources to validate their legitimacy.",
    },
    {
      question:
        "What industries can benefit from Aadhar eSign Verification API?",
      answer:
        "Industries such as finance, legal, healthcare, and any sector relying on digital contracts can greatly benefit from the enhanced security and efficiency of Aadhar eSign Verification API.",
    },
    {
      question: "Is the Aadhar eSign verification API process instantaneous?",
      answer:
        "Yes, Aadhar eSign Verification the API offers real-time verification, delivering prompt results to streamline workflows and maintain productivity.",
    },
    {
      question: "How secure is the Aadhar eSign Verification API process?",
      answer:
        "The Aadhar eSign Verification API employs industry-standard security protocols and encryption methods, ensuring that sensitive signature data remains confidential and protected.",
    },
    {
      question: "Who can use the Aadhar eSign Verification API?",
      answer:
        "The Aadhar eSign Verification API is available for use by businesses, government entities, and other organizations that require electronic signature verification services.",
    },
    {
      question:
        "Is the use of the Aadhar eSign Verification API mandatory for eSignature verification?",
      answer:
        "The use of the Aadhar eSign Verification API is not mandatory but highly recommended for organizations seeking a reliable and legally valid method of verifying eSignatures.",
    },
    {
      question:
        "Is the Aadhar eSign Verification API compliant with data privacy regulations?",
      answer:
        "Yes, the Aadhar eSign Verification API is designed to comply with data privacy and security regulations, ensuring the protection of user information.",
    },
    {
      question:
        "What are the key benefits of using the Aadhar eSign Verification API? ",
      answer:
        "The benefits of Aadhar eSign Verification API include quick and secure eSignature verification, reduced paperwork, legal compliance, and a streamlined verification process for organizations.",
    },
    {
      question:
        "How can I access and implement the Aadhar eSign Verification API for my organization?",
      answer:
        "To access and implement the Aadhar eSign Verification API, you can reach out to our team.",
    },
  ];

  const [productVerifyProps, setProductVerifyProps] = useState({
    heading: "Get Aadhar eSign API",
    subHeading:
      "ZOOPSTACK’s Aadhaar e-Sign API helps an Aadhaar holder to sign a document digitally. It is an easier, faster, more convenient and cost-efficient way of signing contracts. It completely nullifies the need for hard copies of application forms and the wet signing of agreements. Most advisory institutions advise online Aadhaar e-Sign (API) systems to ease processing. ZOOPSTACK also offers email-based e-sign options. ",
    buttonText: "Get API Keys",
    imgSrc: digitalContractingBG,
  });

  const [productVerifyDetailProps, setProductVerifyDetailProps] = useState({
    imageSrc: AadhaarImage,
    heading: "How does Aadhaar eSign Verification API work?",
    points: [
      "Sign up on the ZOOPSTACK dashboard",
      "Subscribe to Aadhaar eSign Verification API in the products section on our dashboard.",
      "Provide Mobile number and OTP as API input.",
      "Instant Aadhaar eSign verification is done on a real-time basis.",
    ],
    buttonText: "Explore",
    productHeading: "Integrate Aadhaar eSign Verification API",
    usecaseHeading: "Use cases of Aadhaar eSign Verification API",
  });

  const [businessCardProps, setBusinessCardProps] = useState([
    {
      image: AutomateWork,
      cardTitle: "Automate Workflows",
      cardSubTitle:
        "No more reconciling data between multiple documents manually",
    },
    {
      image: StopFraud,
      cardTitle: "Stop Fraudsters",
      cardSubTitle:
        "Let only authentic customers access your services. Ensure your safety and prevent fraudulent activities",
    },
    {
      image: ReliableVerify,
      cardTitle: "Reliable Verification",
      cardSubTitle:
        "Verifies all the information provided by the customer and onboard the legit and accurate customers",
    },
    {
      image: RealTimeVerify,
      cardTitle: "Real-time Verification",
      cardSubTitle:
        "Save your time and effort by integrating the right APIs. The data is processed in a second, hence no more manual reviewing",
    },
  ]);

  const handleButtonClick = () => {
    BannerCardButtonTriggered({
      properties: "BannerCard Button on Digital Contracting page",
    });
  };
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>ZOOPSTACK</title>
          <meta name="description" content={metaDescription.desc} />
          <meta name="title" content={metaDescription.title} />
          <meta name="Keywords" content={metaDescription.keyWords} />
        </Helmet>
      </HelmetProvider>
      <div className={SharedStyles.mainContainer}>
        <SiteHeader />
        <div className={Styles.digitalContractingBackground}>
          <div className={Styles.productVerifyContainer}>
            <ProductVerify
              heading={productVerifyProps.heading}
              contentText={productVerifyProps.subHeading}
              buttonText={productVerifyProps.buttonText}
              onButtonClick={handleButtonClick}
              imgSrc={productVerifyProps.imgSrc}
              link={"/contactus"}
            />
          </div>
        </div>

        <div className={Styles.productVerify}>
          <ProductVerifyDetail
            imageSrc={productVerifyDetailProps.imageSrc}
            heading={productVerifyDetailProps.heading}
            points={[
              productVerifyDetailProps.points[0],
              productVerifyDetailProps.points[1],
              productVerifyDetailProps.points[2],
              productVerifyDetailProps.points[3],
            ]}
            buttonText={productVerifyDetailProps.buttonText}
            onButtonClick={handleButtonClick}
          />
        </div>
        <div className={""}>
          <BrandMarquee />
        </div>
        <EsignBanner />
        <div className={SharedStyles.section}>
          <div className={SharedStyles.sectionHeading}>
            {productVerifyDetailProps.productHeading}
          </div>
          <div className={SharedStyles.sectionSubHeading}>
            Sign up on our dashboard and test our APIs instantly for free
          </div>
        </div>
        <div className={Styles.businessCards}>
          <BusinessCardComp
            image={businessCardProps[0].image}
            cardTitle={businessCardProps[0].cardTitle}
            cardSubTitle={businessCardProps[0].cardSubTitle}
          />
          <BusinessCardComp
            image={businessCardProps[1].image}
            cardTitle={businessCardProps[1].cardTitle}
            cardSubTitle={businessCardProps[1].cardSubTitle}
          />
          <BusinessCardComp
            image={businessCardProps[2].image}
            cardTitle={businessCardProps[2].cardTitle}
            cardSubTitle={businessCardProps[2].cardSubTitle}
          />
          <BusinessCardComp
            image={businessCardProps[3].image}
            cardTitle={businessCardProps[3].cardTitle}
            cardSubTitle={businessCardProps[3].cardSubTitle}
          />
        </div>

        <div className={SharedStyles.section}>
          <div className={SharedStyles.sectionHeading}>
            {productVerifyDetailProps.usecaseHeading}
          </div>
        </div>
        <UsecaseProduct useCases={useCases} useCaseImages={useCaseImages} />

        <AddOns
          heading={"Additionals"}
          content={
            "ZOOPSTACK’s advanced APIs are optimised for best user experience. Extract data and verify identity of the customers effortlessly."
          }
          features={[
            ["E-Mail based E-Sign", "Custom Branding"],
            ["Multi-Page and Location Support"],
          ]}
        />

        <div className={SharedStyles.faq}>
          <h1 className={SharedStyles.sectionHeading}>
            Frequently Asked Questions
          </h1>
          {/* <p style={{ color: "#9A9AA3", marginTop: "8px" }}>
          Sign up on our dashboard and test our APIs instantly for free.
        </p> */}
        </div>

        <div className="container">
          <div style={{ marginBottom: "100px" }}>
            <FaqList
              faqs={faqs}
              visibleFaqs={visibleFaqs}
              onLoadMore={loadMoreFaqs}
            />
          </div>

          <BannerCard
            text={"Ready to take your business to new heights?"}
            button={{ text: "Get free Demo", onClick: handleButtonClick }}
            tickImage={BannerTickWhite}
            backGroundClassName={Styles.bannerBackground}
          />
        </div>

        <SiteFooter />
      </div>
    </>
  );
}
