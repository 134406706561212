const GAEvent = {
  IdVefifyClick: "identity_verification_clk",
  FinancialVerifyClick: "financial_verification_clk",
  MerchantVerifyClick: "merchant_verification_clk",
  digitalContractClick: "digital_contracting_clk",
  MLAISolutionClick: "ml_ai_solution_clk",
  PesonaVerficationClick: "persona_verification_clk",
  PesonauseCaseClick: "persona_usecase_clk",
  GamingClick: "gaming_clk",
  RetailClick: "retail_clk",
  TravelClick: "travel_clk",
  KnowledgeHubClick: "knowledge_hub_clk",
  ContactUsBTN: "contact_us_btn",
  TryZoopStackBTN: "try_zoopstack_btn",
  ExploreBTN: "explore_dashboard_btn",
  GetDemoBTN: "get_demo_btn",
  ProductPANClick: "pan_clk",
  ProductAadharClick: "aadhar_clk",
  VoterIdClick: "voter_id_clk",
  DrivingLicenseClick: "driving_license_clk",
  PassportClick: "passport_clk",
  EPOFClick: "epof_clk",
  GetAPIKEYS: "get_api_keys_btn",
  DashboardSignupBTN: "dashboard_sign_up_btn",
  DashboardSignInBTN: "dashboard_sign_in_btn",
  BlogClick: "blog_clk",
};

export default GAEvent;
