import React from "react";
import SiteFooter from "../../Shared/site-footer/siteFooter";
import SiteHeader from "../../Shared/site-header/siteHeader";
// import { googlePageView } from "../../analytics/google";

import Styles from "./subProcessor.module.scss";

export default function privacyPolicy() {
  //   googlePageView("/privacy-policy", "Privacy Policy");
  const policyText = [
    {
      heading: " ",
      text: [
        "ZOOPSTACK, Pvt Ltd. (“ZOOPSTACK”) uses certain sub-processors and content delivery networks to assist it in providing the ZOOPSTACK Services as described in the Terms of Service (“Terms”). Defined terms used herein shall have the same meaning as defined in the Terms.",
      ],
    },
    {
      heading: "What is a Subprocessor",
      text: [
        "A subprocessor is a third party data processor engaged by ZOOPSTACK, who has or potentially will have access to or process Service Data (which may contain Personal Data). ZOOPSTACK engages different types of sub-processors to perform various functions as explained in the tables below. ZOOPSTACK refers to third parties that do not have access to or process Service Data but who are otherwise used to provide the Services as “subcontractors” and not subprocessors.",
      ],
    },
    {
      heading: "Due Diligence",
      text: [
        "ZOOPSTACK undertakes to use a commercially reasonable selection process by which it evaluates the security, privacy and confidentiality practices of proposed sub-processors that will or may have access to or process Service Data.",
      ],
    },
    {
      heading: "Contractual Safeguards",
      text: [
        "ZOOPSTACK requires its sub-processors to satisfy equivalent obligations as those required from ZOOPSTACK (as a Data Processor) as set forth in ZOOPSTACK’s Data Processing Agreement (“DPA”), including but not limited to the requirements to:",
        "   1. Process Personal Data in accordance with data controller’s (i.e. Subscriber’s) documented instructions (as communicated in writing to the relevant subprocessor by ZOOPSTACK);",
        "   2. in connection with their subprocessing activities, use only personnel who are reliable and subject to a contractually binding obligation to observe data privacy and security, to the extent applicable, pursuant to applicable data protection laws;",
        "   3. provide regular training in security and data protection to personnel to whom they grant access to Personal Data;",
        "   4. implement and maintain appropriate technical and organizational measures (including measures consistent with those to which ZOOPSTACK is contractually committed to adhere insofar as they are equally relevant to the subprocessor’s processing of Personal Data on ZOOPSTACK’s behalf) and provide an annual certification that evidences compliance with this obligation. In the absence of such certification ZOOPSTACK reserves the right to audit the subprocessor;",
        "   5. promptly inform ZOOPSTACK about any actual or potential security breach;",
        "   6. and cooperate with ZOOPSTACK in order to deal with requests from data controllers, data subjects or data protection authorities, as applicable.",
        "This policy does not give Subscribers any additional rights or remedies and should not be construed as a binding agreement. The information herein is only provided to illustrate ZOOPSTACK’s engagement process for sub-processors as well as to provide the actual list of third party sub-processors and content delivery networks used by ZOOPSTACK as of the date of this policy (which ZOOPSTACK may use in the delivery and support of its Services).",
      ],
    },
    {
      heading: "Process to Engage New Subprocessors",
      text: [
        "For all Subscribers who have executed ZOOPSTACK’s standard DPA, ZOOPSTACK will provide notice via this policy of updates to the list of sub-processors that are utilized or which ZOOPSTACK proposes to utilize to deliver its Services. ZOOPSTACK undertakes to keep this list updated regularly to enable its Subscribers to stay informed of the scope of subprocessing associated with the ZOOPSTACK Services.",
        "Pursuant to the DPA, a Subscriber can object in writing to the processing of its Personal Data by a new subprocessor within thirty (30) days after updating of this policy and shall describe its legitimate reasons to object. If a Subscriber does not object during such time period the new subprocessor(s) shall be deemed accepted.",
        "If a Subscriber objects to the use of a subprocessor pursuant to the process provided under the DPA, ZOOPSTACK shall have the right to cure the objection through one of the following options (to be selected at ZOOPSTACK’s sole discretion):",
        "   (a) ZOOPSTACK will cease to use the subprocessor with regard to Personal Data;",
        "   (b) ZOOPSTACK will take the corrective steps requested by Subscriber in its objection (which remove Subscriber’s objection) and proceed to use the subprocessor to process Personal Data;",
        "   (c) ZOOPSTACK may cease to provide or Subscriber may agree not to use (temporarily or permanently) the particular aspect of a ZOOPSTACK Service that would involve the use of the subprocessor to process Personal Data.",
        "Termination rights, as applicable and agreed, are set forth exclusively in the DPA.",
        "The following is an up-to-date list (as of the date of this policy) of the names and locations of ZOOPSTACK sub-processors and content delivery networks:",
      ],
    },
    {
      heading: "Infrastructure Sub-processors – Service Data Storage",
      text: [
        "ZOOPSTACK owns or controls access to the infrastructure that ZOOPSTACK uses to host Service Data submitted to the Services, other than as set forth below. Currently, the ZOOPSTACK production systems for the Services are located in co-location facilities in the United States. The Subscriber’s Service Data remains in that region, but may be shifted among data centers within a region to ensure performance and availability of the Services. The following table describes the countries and legal entities engaged in the storage of Service Data by ZOOPSTACK.",
      ],
      table: [
        {
          heading: ["Entity Name", "Entity Type", "Entity Country"],
        },
      ],
      content1: ["Google Cloud", "Amazon Web Service"],
      content2: ["Cloud Service Provider", "Cloud service Provider"],
      content3: ["USA", "USA"],
    },
    {
      heading: "Service Specific Subprocessors",
      text: [
        "ZOOPSTACK works with certain third parties to provide specific functionality within the Services. These providers are the Subprocessors set forth below. In order to provide the relevant functionality these Subprocessors access Service Data. Their use is limited to the indicated Services.",
      ],
      table: [
        {
          heading: ["Entity Name", "Entity Type", "Entity Country"],
        },
      ],
      content1: [
        "SendGrid",
        "Sentry",
        "MongoDB Atlas",
        "Redis Enterprise",
        "Fresh desk",
        "Postgres",
        "Google Analytics",
        "Socket.io",
        "Pipedrive",
      ],
      content2: [
        "Cloud-based Email Notification Service",
        "Cloud-based Exception Reporting",
        "Global cloud data base service",
        "Real time data processing with high performance caching",
        "Cloud based ticketing platform",
        "Global cloud data base service",
        "Cloud-based Data Analytics Service",
        "Framework for communication through web sockets",
        "Web-based Sales CRM",
      ],
      content3: ["USA", "USA", "USA", "USA", "USA", "USA", "USA", "USA", "USA"],
    },
    {
      heading: "Content delivery networks",
      text: [
        "As explained above, ZOOPSTACK Services may use content delivery networks (“CDNs”) to provide the Services, for security purposes, and to optimize content delivery. CDNs do not have access to Service Data but are commonly used systems of distributed services that deliver content based on the geographic location of the individual accessing the content and the origin of the content provider. Website content served to website visitors and domain name information may be stored with a CDN to expedite transmission, and information transmitted across a CDN may be accessed by that CDN to enable its functions. The following describes the use of CDNs by ZOOPSTACK Services.",
      ],
      table: [
        {
          heading: ["CDN Provider", "CDN Location", "Entity Country"],
        },
      ],
      content1: ["CloudFlare"],
      content2: ["Global"],
      content3: ["USA"],
    },
  ];
  const renderText = (text: string[]) => {
    return text.map((line: string, index: number) => {
      if (index === 0 || !line.startsWith("   •")) {
        return (
          <p className={Styles.paragraphText} key={index}>
            {line}
          </p>
        );
      } else {
        const bulletText = line.substring(4);
        if (!isNaN(Number(bulletText.charAt(0)))) {
          const bulletNumberMatch = bulletText.match(/^\d+/);
          const bulletNumber = bulletNumberMatch ? bulletNumberMatch[0] : "";
          const bulletContent = bulletText.slice(bulletNumber.length).trim();
          return (
            <li key={index} className={Styles.paragraphText}>
              {bulletNumber && <span>{bulletNumber}. </span>}
              {bulletContent}
            </li>
          );
        } else {
          return (
            <li key={index} className={Styles.paragraphText}>
              {bulletText}
            </li>
          );
        }
      }
    });
  };

  const renderPolicyText = () => {
    return policyText.map((item, index) => (
      <div key={index}>
        <h2 className={Styles.paragraphHeading}>{item.heading}</h2>
        {renderText(item.text)}
        {item.table && (
          <table>
            <thead>
              {item.table?.map((head, index) => (
                <tr>
                  <th key={index}>{head.heading[0]}</th>
                  <th key={index}>{head.heading[1]}</th>
                  <th key={index}>{head.heading[2]}</th>
                </tr>
              ))}
            </thead>
            <tbody>
              {item.content1?.map((content, index) => (
                <tr key={index}>
                  <td>{content}</td>
                  <td>{item.content2[index]}</td>
                  <td>{item.content3[index]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    ));
  };

  return (
    <div>
      <SiteHeader />
      <div className={Styles.mainHeader}>
        <div className={Styles.heading}>Subprocessors</div>
      </div>
      <div className={Styles.mainContainer}>{renderPolicyText()}</div>
      <SiteFooter />
    </div>
  );

  //   return (
  //     <div>
  //       <SiteHeader />
  //       <div className={Styles.mainHeader}>
  //         <div className={Styles.heading}>Terms and Conditions</div>
  //       </div>
  //       <div className={Styles.mainContainer}>
  //         <div>
  //           {/* policyText */}
  //           {policyText.map((section, index) => (
  //             <div key={index}>
  //               <h2 className={Styles.paragraphHeading}>{section.heading}</h2>
  //               {section.text.map((text, idx) => (
  //                 <p className={Styles.paragraphText} key={idx}>
  //                   {text}
  //                 </p>
  //               ))}
  //             </div>
  //           ))}
  //         </div>
  //       </div>
  //       <SiteFooter />
  //     </div>
  //   );
}
