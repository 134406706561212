import React, { useState } from "react";
import Styles from "./persona.module.scss";
import HamburgerMenuicon from "../../../assets/images/icons/hamburgerMenuWhite.svg";
import GradStudenImage from "../../../assets/images/products/persona/grad_student.png";
import { googlePageView } from "../../../analytics/google";
import ZoopStackLogo from "../../../assets/images/products/persona/zoop_stack.png";
import BlueGraphImage from "../../../assets/images/products/persona/blue_graph.svg";
import WhiteGraphImage from "../../../assets/images/products/persona/white_graph.svg";
import StudentId from "../../../assets/images/products/persona/student_id.png";
import MobileStudentId from "../../../assets/images/products/persona/student_id_full.svg";
import IndiaMap from "../../../assets/images/products/persona/indiaMap.png";
import Sidebar from "../../../Shared/side-bar/sidebar";
import { PersonaKnowMoreTriggered } from "../../../Shared/eventsNames";
import logo from "../../../assets/images/zoopStackWhiteLogo.svg";
import useScreen from "../../../utils/useScreen";

const UnlockPersona = () => {
  googlePageView("/products/persona/unlock", "UnlockPersonaPage");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { isMobile } = useScreen();
  return (
    <div>
      <div className={Styles.hambugerMenuIcon}>
        <a href="\home" onClick={() => {}}>
          <img src={logo} alt="logo" />
        </a>
        {!isSidebarOpen && (
          <img
            src={HamburgerMenuicon}
            alt="Menu"
            width="50px"
            height="20px"
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          />
        )}
        {isSidebarOpen && <Sidebar />}
      </div>
      <div
        className={Styles.halfWindow}
        onClick={() => setIsSidebarOpen(false)}
      >
        <div className={`${Styles.packedContent} ${Styles.realPersona}`}>
          <div className={Styles.personaContent}>
            <div className={Styles.headingSubMain}>
              We Unlock
              <br />
              <span className={Styles.headingMain}>Real Personas</span>
            </div>
            <div className={`mt-2 ${Styles.personaTextVerify}`}>
              verify
              <br />
              <span className={Styles.verifyBoldText}> 1.6 Crore </span>students
              <br />
              across india.
            </div>
            <a
              href="/persona/details"
              className={`mb-5 ${Styles.knowMoreText}`}
              onClick={() =>
                PersonaKnowMoreTriggered({
                  properties: "UnlockPersona know more clicked",
                })
              }
            >
              Know More &#8599;
            </a>
          </div>
          {isMobile && <img src={IndiaMap} alt="india map" />}
        </div>
        <div
          className={`${Styles.expandedContent} ${Styles.validationIdentity}`}
        >
          <div className={Styles.gradStudent}>
            <img src={GradStudenImage} alt="Persona Student" />
          </div>
          <div className={Styles.gradStudentContent}>
            by <span className={Styles.boldText}>Validating</span> their{" "}
            <span className={Styles.boldText}>Identities</span>
            <br></br>
            <span className={Styles.plainText}>
              India’s first Persona Verification Platform for Indian Persona.
            </span>
          </div>
        </div>
      </div>
      <div className={Styles.halfWindow}>
        <div className={Styles.expandedContent}>
          <div className={Styles.businessBootstingContent}>
            so your <span className={Styles.boldText}>Business</span> keeps{" "}
            <span className={Styles.boldText}>Boosting</span>
            <br></br>
            <span className={Styles.plainText}>
              Fastest <b>VERIFIED</b> Customer Onboarding.
            </span>
          </div>
          <div className={Styles.zoopLogo}>
            <img src={ZoopStackLogo} alt="" />
          </div>
          <div className={Styles.graphContainer}>
            <img
              src={BlueGraphImage}
              alt=""
              width="100%"
              className={Styles.blueGraph}
            />
            <img
              src={WhiteGraphImage}
              alt=""
              width="100%"
              className={Styles.whiteGraph}
            />
          </div>
        </div>
        <div
          className={`${Styles.packedContent} ${Styles.verificationContent}`}
        >
          <div className={Styles.verificationLevelsContent}>
            through<br></br>
            <span className={Styles.boldText}>Instant</span>
            <br></br>
            <span className={Styles.boldText}>Verification</span>
            <br></br>
            <span className={Styles.plainText}>
              Know more about verification levels.
            </span>
            <a
              href="/persona/details"
              className="mb-5"
              onClick={() =>
                PersonaKnowMoreTriggered({
                  properties: "UnlockPersona know more clicked",
                })
              }
            >
              Know More &#8599;
            </a>
          </div>
          {!isMobile && (
            <div className={Styles.verificationLevelsImage}>
              <img src={isMobile ? MobileStudentId : StudentId} alt="" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UnlockPersona;
