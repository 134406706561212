import React from "react";
import Styles from "./homeInfoCarousel.module.scss";

export interface homeInfoProps {
  heading: string;
  headingClassName: string;
}

export default function HomeInfoCarousel({
  heading,
  headingClassName,
}: homeInfoProps) {
  return (
    <div>
      <div className={Styles.homeInfoMain}>
        <div className={`${Styles.homeInfoMainHeading} ${headingClassName}`}>
          {heading}
        </div>
      </div>
    </div>
  );
}
