import React, { useEffect, useState } from "react";
import Styles from "./cookiePopup.module.scss";
export default function CookiePopup() {
  const [showPopup, setShowPopup] = useState(true);
  const [initialRender, setInitialRender] = useState(true);

  useEffect(() => {
    const hasAccepted = localStorage.getItem("cookieAccepted");
    if (hasAccepted === "true") {
      setShowPopup(false);
    }
    setInitialRender(false);
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieAccepted", "true");
    setShowPopup(false);
  };

  const handleReject = () => {
    setShowPopup(false);
    // Additional logic when the user rejects cookies
  };

  if (initialRender || !showPopup) {
    return null;
  }

  return (
    <div className={`${Styles.cookieMainContainer} row`}>
      <div
        className={`${Styles.cookieContainerText} col-12 col-md-9 col-sm-7 col-lg-10`}
      >
        <p className={Styles.cookieTextHeading}>We value your privacy</p>
        <div className={Styles.cookieTextContent}>
          <span>
            We use cookies to enhance your browsing experience, serve
            personalized ads or content, and analyze our traffic. By clicking
            Accept, you consent to our Terms & Conditions <span> and </span>{" "}
            Privacy Policy. refer to{" "}
          </span>
          <a
            href="https://docs.google.com/document/d/19vN0Ew5zAE4fMJTRIrXU8KHcXDeu9LGH/edit?usp=sharing&ouid=105474712703475313013&rtpof=true&sd=true"
            className={Styles.link}
          >
            Cookie Policy
          </a>
          {"  "}
          <span> for more information</span>
        </div>
      </div>
      <div
        className={`${Styles.buttonContainer} col-12 col-md-3 col-sm-5 col-lg-2`}
      >
        <button className={Styles.button} onClick={handleReject}>
          Not this time
        </button>
        <button className={Styles.button1} onClick={handleAccept}>
          Accept
        </button>
      </div>
    </div>
  );
}
