import React, { ReactNode } from "react";
import Styles from "./impactContainer.module.scss";
import HalfMap from "../../assets/images/half_map.png";
import { googlePageView } from "../../analytics/google";
import ImpactDashboard from "../../assets/images/impact_dashboard.svg";
import ImpactGear from "../../assets/images/impact_gear.svg";
import ImpactService from "../../assets/images/impact_service.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { StatsCardContainer } from "../impact-cards/impactCardComp";
import DropOffRateImage from "../../assets/images/drop_off_rate.svg";
import IncreaseInSales from "../../assets/images/increase_in_sales.svg";
import StpImage from "../../assets/images/stp.svg";
import IsoBanner from "../../Shared/iso-banner/isoBanner";

type BorderedContainerProps = {
  children: ReactNode;
  classNames?: string;
  style?: object;
};

const BorderedContainer = ({
  children,
  classNames = "",
  style,
}: BorderedContainerProps) => {
  return (
    <div
      className={`${Styles.borderedContainer} ${classNames} my-2`}
      style={style}
    >
      {children}
    </div>
  );
};

type ProductivityAndDashboardProps = {
  heading: string;
  src: string;
  subText: string;
};

const ProductivityAndDashboard = ({
  heading,
  subText,
  src,
}: ProductivityAndDashboardProps) => {
  return (
    <BorderedContainer
      classNames={Styles.dashboardAndProdCard}
      style={{ height: "100%" }}
    >
      <img
        src={src}
        className={Styles.borderContainerImage}
        alt={src}
        width={90}
      />
      <p className={Styles.boxHeading}>{heading}</p>
      <p className={Styles.boxSubtext}>{subText}</p>
    </BorderedContainer>
  );
};

const CustomerSatisfaction = () => {
  return (
    <BorderedContainer classNames={`row ${Styles.impactService} w-100 mx-auto`}>
      <div className="col-4">
        <p className={`${Styles.boxHeading} text-start`}>
          Customer Satisfaction Enhanced
        </p>
        <p className={`${Styles.boxSubtext} text-start`}>
          No more physical paperwork, logistics, and manual data entry.
        </p>
      </div>
      <img src={ImpactService} alt="service" style={{ width: "110px" }} />
      <div className="col-4">
        <p className={`${Styles.boxHeading} text-end`}>
          Flexible APIs and SDKS
        </p>
        <p className={`${Styles.boxSubtext} text-end`}>
          Create apps on dashboard, club our products to your requirements.
        </p>
      </div>
    </BorderedContainer>
  );
};

const NumberContainer = () => {
  return (
    <BorderedContainer classNames="my-3">
      <div className={Styles.numberWrapper}>
        <p className={Styles.numberText}>200M+</p>
        <p className={Styles.numberLabel}>Transactions Performed</p>
      </div>
      <div className={Styles.numberContainerDivider} />
      <div className={Styles.numberWrapper}>
        <p className={Styles.numberLabel}>Average Response Time</p>
        <p className={Styles.numberText}>800 ms</p>
      </div>
    </BorderedContainer>
  );
};

export default function ImpactContainer() {
  return (
    <div className={Styles.impactWrapper}>
      <h2 className={Styles.impactWrapper_heading}>our impact</h2>
      <div className={`container ${Styles.impactContainer}`}>
        <div className={`row ${Styles.impactDetailsRow} mb-4`}>
          <div
            className={`col-12 col-lg-6 col-md-12 d-flex flex-column ${Styles.impactDetails}`}
          >
            <div className="row my-4">
              <div className="col-12 col-md-6 col-lg-6">
                <ProductivityAndDashboard
                  src={ImpactGear}
                  heading="Increase productivity, decreased costs"
                  subText="Increase productivity by 75%, reduced operational costs by 60%, & turnaround by 95%."
                />
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <ProductivityAndDashboard
                  src={ImpactDashboard}
                  heading="Personalized Dashboard & Trail Centre"
                  subText="We win your trust first. Get instant access to our APIs and test them."
                />
              </div>
            </div>
            <CustomerSatisfaction />
            <NumberContainer />
          </div>
          <div className="col-12 col-lg-6 col-md-12">
            <LazyLoadImage
              src={HalfMap}
              alt="blog-image"
              effect="blur"
              wrapperClassName="w-100"
            />
          </div>
        </div>
        <IsoBanner />
        <StatsCardContainer
          backgroundClassName={Styles.numberCard}
          impactCardsData={[
            {
              image: StpImage,
              alt: "Success Rate",
              title: "95%",
              subtitle: "Success Rate",
            },
            {
              image: DropOffRateImage,
              alt: "Drop Off Rate",
              title: "72%",
              subtitle: "Reduced Drop-Off Rate",
            },
            {
              image: IncreaseInSales,
              alt: "Service Uptime",
              title: "99.8%",
              subtitle: "Service Uptime",
            },
          ]}
        />
      </div>
    </div>
  );
}
