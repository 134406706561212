import React from "react";
import SiteFooter from "../../Shared/site-footer/siteFooter";
import SiteHeader from "../../Shared/site-header/siteHeader";
import Styles from "./termsAndCondition.module.scss";
import { googlePageView } from "../../analytics/google";

export default function termsAndCondition() {
  googlePageView("/terms-and-condition", "TermsCondition");
  const paragraph = [
    {
      paragraphText: [
        "Welcome to Zoop.one, these are the terms and conditions governing your access to and use of the website Zoop.one platform of Quagga Tech Private Limited and its related sub-domains, mobile applications, sites, services and tools (Henceforth, called Zoop.one or ‘Site’ will also refer to the above).",
        "By accepting these terms and conditions (including the linked information herein), and by using the Site, you represent that you agree to comply with these terms and conditions. This User Agreement is effective upon acceptance. If you do not agree to be bound by this User Agreement please do not access, register with or use this Site.",
        "Before you may become or continue as a member of the Site, you must read, agree with and accept this User Agreement and Zoop.one’s Policy (the “Privacy Policy”). You should read this User Agreement and the Privacy Policy and access and read all further linked information, if any, referred to in this User Agreement, as such information contains further terms and conditions that apply to you as a user of Zoop.one. Such linked information including but not limited to the Privacy Policy is hereby incorporated by reference into this User Agreement.",
        "If you find a Zoop.one page that you believe violates our terms of use, please get in touch with us through any of the ways listed at connect@zoopone-752ba0.ingress-baronn.easywp.com.",
      ],
    },
  ];
  const policyText = [
    {
      heading: "User Agreement",
      text: [
        "The term of this Agreement shall continue and would be in force till the time you access this Application & its services and you agree that you are bound by the terms as mentioned herein. Nothing in these Terms of Service should be construed to confer any rights on you or any third party beneficiaries.",
      ],
    },
    {
      heading: "Eligibility",
      text: [
        "To be eligible for using our Service you must be an individual who is 18 years of age or older and capable of entering into a legally binding contract in the country of your domicile or be a legal entity competent to contract. By using the service, you represent and warrant that you are 18 years or older and that you have the capacity to understand, agree to and comply with these Terms of Service, including the ability to contract on behalf of your organization.",
      ],
    },
    {
      heading: "Changes to Terms and Conditions",
      text: [
        "We reserve the right to add to or change/modify the terms of this Agreement including but not limited to suspend/cancel, or discontinue any or all service at any time without notice, make modifications and alterations in any or all of the content, products, and services contained on the Application without prior notice. You are responsible for regularly reviewing these Terms of Service so that you will be apprised of any changes. You will be deemed to have accepted such change/s if you continue to access the Application subsequent to such changes.",
      ],
    },
    {
      heading: "Information Collected",
      text: [
        "Zoop.one will only collect, store, use, and share your personal information (including email address) as it is defined by our Privacy Policy and as regulated by Indian laws.",
      ],
    },
    {
      heading: "Privacy Policy",
      text: [
        "The User hereby consents, expresses and agrees that he has read and fully understands the Privacy Policy of Zoop.one. You further consent that the terms and contents of such Privacy Policy are acceptable to you. It is our policy to respect your privacy regarding any information we may collect from you within the framework of our privacy policy.",
      ],
    },
    {
      heading: "Appropriate access & conduct of user and content.",
      text: [
        "You may not use the Zoop.one service for any illegal or unauthorized purpose. International users agree to comply with all Indian laws & regulations, including laws regulating the export of data from the United States or your country of residence. You are solely responsible for your conduct and any data, text, information, graphics, that you submit, & post on to Zoop.one service. Other examples of illegal or unauthorized uses include, but are not limited to:",
        "    • Modifying, adapting, translating, or reverse engineering any portion of the service.",
        "    • Removing any copyright, trademark or other proprietary rights notices contained in or on the service.",
        "    • Using any robot, spider, Application search/retrieval application, or other devices to retrieve or index any portion.",
        "    • Creating user accounts by automated means or under false or fraudulent pretenses.",
        "    • Creating or transmitting unwanted electronic communications such as “spam,” or chain letters to or otherwise interfering with other user’s enjoyment of the service by blocking.",
        "    • Submitting data of any third party without such third party’s prior written consent.",
        "    • Submitting data of any third party without such third party’s prior written consent.",
        "    • Submitting Materials that infringe misappropriate or violate the intellectual property, publicity, privacy or other proprietary rights of any party.",
        "    • Transmitting any viruses, worms, defects, Trojan horses or other items of a destructive nature.",
        "    • Violating any laws, regulations or code of conduct, which are in force.",
        "While Zoop.one prohibits such conduct on its Application, you understand and agree that you nonetheless may be exposed to such misuse or blocking by other users and that you use the service at your own risk.",
        "Without the specific approval from Zoop.one , you shall not, directly or indirectly, pass on any material provided by Zoop.one, whether or not through this application, to other organizations or individuals outside your organization. You also agree not to share your access details (login and password) with any other person within or outside the organization.",
        "For using CKYC search and download we are using Central KYC Registry (CKYCR) by Fintelligence Data Science Pvt Ltd, only for the purpose of verification of my identity and address from the database of CKYCR Registry. I understand that my KYC Record includes my KYC Records/Personal information such as my name, address, date of birth, PAN number etc."
      ],
    },
    {
      heading: "Copyright Trademarks and Intellectual Property rights",
      text: [
        "Unless otherwise stated, copyright and all intellectual property rights in all material presented on the Application (including but not limited to text, audio, video or graphical images), trademarks and logos appearing on this Application are the property of Zoop.one, its parent, affiliates and associates and are protected under applicable laws. Any infringement shall be vigorously defended and pursued to the fullest extent permitted by law.",
      ],
    },
    {
      heading: "Copyright Infringement",
      text: [
        "As Zoop.one asks others to respect its intellectual property rights, it respects the intellectual property rights of others. If you believe that material located on or linked to Zoop.one violates your copyright, you are encouraged to notify us. We will respond to all such notices, which may include as required or appropriate removing the infringing material or disabling all links to the infringing material. In case any user infringes the copyrights or other intellectual property rights of Zoop.one or others, Zoop.one may, in its discretion, terminate or deny access to and use of the site. In the case of such termination, Zoop.one will have no obligation to provide a refund of any amounts previously paid to Zoop.one.",
      ],
    },
    {
      heading: "Limited Permission to Copy",
      text: [
        "You are permitted to print or download extracts from these pages for your personal or organization’s internal use only. Any copies of these pages saved to disk or to any other storage medium may only be used for subsequent viewing purposes or to print extracts for personal or organization’s use internally. You may not (whether directly or through the use of any software program) create a database in electronic or structured manual form by regularly or systematically downloading and storing all or any part of the pages from this Application unless specifically for the internal use of the organization. You shall not disseminate any extract from information provided by the Application to any third parties or affiliates or partners without Zoop.one’s prior permission.",
      ],
    },
    {
      heading: "Rights of Zoop.one",
      text: [
        "Zoop.one reserves its right to restrict you from using the services without any prior notice for any violation of the Terms of Use. By submitting or furnishing any information, data or details on or through the application/services, you automatically grant to us non-exclusive, permission & irrevocable right to hold & store your, such personal information, data or details and related information. Our use of your personal information is governed by our Privacy Policy. Zoop.one shall also be allowed to use your organization name and logo in promotional material, including on its website.",
      ],
    },
    {
      heading: "Refund Policy",
      text: [
        "Zoop.one does not entertain any request or demand for refund fees paid towards the subscription for the services. The fees paid towards the subscription shall not be refunded unless such claim arises due the breach in any terms of services by Zoop.one.",
      ],
    },
    {
      heading: "Cookies",
      text: [
        "To personalize your experience on our website/Application, we may assign your computer browser a unique random number called a cookie. “Cookies” enhance website/application performance in important ways like personalizing your experience, or making your visit more convenient. Zoop.one or its affiliates or vendors may use this data to analyze trends and statistics and keep track of the domains from which people visit and also measure visitor activity on website/application, but in doing so Zoop.one shall keep the information anonymous. If you do not want your transaction details used in this manner, you can either disable your cookies or opt-out at the download or request page.",
      ],
    },
    {
      heading: "Indemnification",
      text: [
        "You agree to defend, indemnify, and hold harmless Zoop.one and/or its associate entities, their officers, directors, employees, and agents, from and against any claims, actions or demands, including without limitation reasonable legal and accounting fees, alleging or resulting from your use of the web site/application material or your breach of these Terms and Conditions of Web site/application use.",
      ],
    },
    {
      heading: "Disclaimer of Warranties and Liability",
      text: [
        "All the contents of this Application are only for general information or use. They do not constitute advice and should not be relied upon in making (or refraining from making) any decision. Any specific advice or replies to queries in any part of the Application is/are the personal opinion of such experts/consultants/persons and are not subscribed to by this Application. Zoop.one does not guarantee the accuracy, completeness or timeliness of the data. The information from or through this Application is provided on “AS IS” basis, and all warranties, expressed or implied of any kind, regarding any matter pertaining to any goods, service or channel, including without limitation, the implied warranties of merchantability, fitness for a particular purpose, and non-infringement are disclaimed and excluded.",
        "Zoop.one, its directors, employees, agents, representatives and the authors and its parent, affiliates and associates shall not be liable, at any time for damages (including, without limitation, damages for loss of business projects, or loss of profits) arising in contract, tort or otherwise from the use of or inability to use the Application, or any of its contents, or from any act or omissions as a result of using the Application or any such contents or for any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communications line failure, theft or destruction or unauthorized access to, alteration of, or use of information contained on the Application. No representations, warranties or guarantees whatsoever are made as to the accuracy, adequacy, reliability, completeness, suitability or applicability of the information to a particular situation. Zoop.one, its directors, employees, agents, representatives disclaim all any all direct, indirect, special, consequential damages, loss arising from use the services provided by Zoop.one.",
        "Certain links on the Application lead to resources located on servers maintained by third parties and are external to Zoop.one . You agree and understand that by visiting such Applications you are beyond the Zoop.one web Application. Zoop.one, therefore, neither endorses nor offers any judgment or warranty and accepts no responsibility or liability for the authenticity/availability of any of the goods/services/or for any damage, loss or harm, direct or consequential or any violation of local or international laws that may be incurred by your visit and/or transaction/s on these Applications.",
      ],
    },
    {
      heading: "Termination",
      text: [
        "Zoop.one may terminate your access to all or any part of the site at any time, with or without cause, with or without notice. If you wish to terminate this Agreement or your Zoop.one account (if you have one), you may simply discontinue using Zoop.one. In addition, Zoop.one can terminate the service immediately as part of a general shut down of our service. All provisions of this Agreement, which by their nature shall survive termination, including, without limitation, ownership provisions, intellectual property rights, warranty disclaimers, indemnity and limitations of liability. If you or Zoop.one terminates your use of the site, Zoop.one may delete any content or other materials relating to your use of the Site, its related sub-domains, mobile applications, sites, services and tools and Zoop.one will have no liability to you or any third party for doing so.",
      ],
    },
    {
      heading: "Limitation of Liability",
      text: [
        "In no event will Zoop.one, its directors, officers, employees or agents be liable with respect to any subject matter of this agreement under any contract, negligence, strict liability or other legal or equitable theory for: ",
        "(i) any special, incidental or consequential damages; (ii) the cost of procurement of substitute products or services; (iii) for interruption of use or loss or corruption of data; or (iv) for any amounts that exceed the amounts paid by you to under this agreement in respect of the order under dispute or the amount paid to Zoop.one in respect of the usage for past 6 months whichever is lower. Zoop.one shall have no liability for any failure or delay due to matters beyond our reasonable control. The foregoing shall not apply to the extent prohibited by applicable law.",
      ],
    },
    {
      heading: "General Representation and Warranty",
      text: [
        "You represent and warrant that your use of the site will be in strict accordance with the Zoop.one Privacy Policy, with this Agreement and with all applicable laws and regulations.",
      ],
    },
    {
      heading: "Usage Conduct",
      text: [
        "You shall solely be responsible for maintaining the necessary computer equipments and Internet connections that may be required to access use and transact onZoop.one.",
        "You are also under an obligation to use Zoop.one for reasonable and lawful purposes only, and shall not indulge in any activity that is not envisaged through Zoop.one.",
      ],
    },
    {
      heading: "Electronic Communication",
      text: [
        "You agree that we may communicate with you by email or by posting notices on the Site. You agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing. Zoop.one requires your agreement during the registration process to send you promotional emails to let you know about any new changes, features or promotional activities added to the Site. If, at any time, you decide that you do not wish to receive promotional emails, you can opt out of receiving such promotional emails by clicking on the link at the bottom of any promotional email.",
      ],
    },
    {
      heading: "Force Majeure",
      text: [
        "Zoop.one shall have no liability to you for any interruption or delay in access to the Application irrespective of the cause. Force Majeure event shall be one when there is any cessation, interruption or delay in the performance of Zoop.one’s obligations due to causes beyond its reasonable control, including but not limited to: technical, earthquake, flood, or other natural disaster, act of God, labor controversy, civil disturbance, war (whether or not officially declared) or the inability to obtain sufficient supply of service, transportation, or other essential commodity or service required in the conduct of its business, or any change in or the adoption of any law, regulation, judgment or decree. Without limiting the foregoing, under no circumstances shall Zoop.one be held liable for any delay or failure in performance resulting directly or indirectly from acts of nature, forces, or causes beyond its reasonable control, including, without limitation, Internet failures, computer equipment failures, telecommunication equipment failures, other equipment failures, electrical power failures, strikes, labor disputes, riots, insurrections, civil disturbances, shortages of labor or materials, fires, floods, storms, explosions, acts of God, war, governmental actions, orders of domestic or foreign courts or tribunals, non-performance of third parties, or loss of or fluctuations in heat, light, or air conditioning of your information and or data received and or stored or any of our service/s.",
      ],
    },
    {
      heading: "Governing Law",
      text: [
        "The Agreement shall be governed by the Laws of India. The Courts of law at Pune shall have exclusive jurisdiction over any disputes arising under this agreement.",
      ],
    },
    {
      heading: "Entire Agreement",
      text: [
        "These Terms of Service constitute the entire agreement between the parties with respect to the subject matter hereof and supersedes and replaces all prior or contemporaneous understandings or agreements, written or oral, regarding such subject matter.",
        "There are risks associated with utilizing internet and short messaging system (chat) based information and research dissemination services. Users/subscribers are advised to understand that the services can fail due to failure of hardware, software, and Internet connection. While we ensure that the email/messages are delivered in time to the user/subscribers, the delivery of these email/messages to the customer’s email inbox or message center is the responsibility of the email/messenger’s service provider Network. Email/messages may be delayed and/or not delivered to the user/subscriber’s inbox or chat box on certain days, owing to technical reasons that can only be addressed by the service provider, and Zoop.one who owns Zoop.one and group companies associated with it and/or its employees cannot be held responsible for the same.",
        "Zoop.one hereby expressly disclaims any implied warranties imputed by the laws of any jurisdiction. We consider ourselves and intend to be subject to the jurisdiction only of the courts of the Pune in India. If you don’t agree with any of our terms and conditions and disclaimers please do not read the material on any of our pages. Zoop.one shall have no legal liabilities whatsoever in any laws of any jurisdiction other than India. We reserve the right to make changes to our Application and these disclaimers, terms, and conditions at any time.",
      ],
    },
    {
      heading: "Report Abuse",
      text: [
        "If you come across any abuse or violation of these Terms, please report to connect@zoopone-752ba0.ingress-baronn.easywp.com.",
        "I HAVE CAREFULLY READ AND UNDERSTOOD THE TERMS AND CONDITIONS MENTIONED HEREINABOVE AND HEREBY CONFIRM MY ACCEPTANCE TO THE SAME. I SHALL ADHERE TO ALL THE TERMS AND CONDITIONS MENTIONED IN THIS TERMS OF SERVICE.",
      ],
    },
  ];
  return (
    <div>
      <SiteHeader />
      <div className={Styles.mainHeader}>
        <div className={Styles.heading}>Terms and Conditions</div>
      </div>
      <div className={Styles.mainContainer}>
        <div>
          {/* paragraph */}
          {paragraph.map((section, index) => (
            <div key={index}>
              {section.paragraphText.map((text, idx) => (
                <p className={Styles.paragraphText} key={idx}>
                  {text}
                </p>
              ))}
            </div>
          ))}
        </div>
        <div>
          {/* policyText */}
          {policyText.map((section, index) => (
            <div key={index}>
              <h2 className={Styles.paragraphHeading}>{section.heading}</h2>
              {section.text.map((text, idx) => (
                <p className={Styles.paragraphText} key={idx}>
                  {text}
                </p>
              ))}
            </div>
          ))}
        </div>
      </div>
      <SiteFooter />
    </div>
  );
}
