import * as React from "react";
import Styles from "../impact-cards/impactCards.module.scss";
import SharedStyles from "../../Shared/shared.module.scss";
import "../impact-cards/dancing.css";

interface ImpactCardProps {
  backgroundClassName?: string;
  headingDisplay?: string;
  dancingBarColor?: string;
  impactCardsData: {
    image: string;
    alt: string;
    title: string;
    subtitle: string;
  }[];
}

interface StatsCardContainerProps extends ImpactCardProps {
  isTransparent?: boolean;
}

export const StatsCardContainer = ({
  backgroundClassName,
  dancingBarColor,
  impactCardsData,
  isTransparent,
}: StatsCardContainerProps) => {
  return (
    <div className={`${Styles.statsCardsContainer} row`}>
      {impactCardsData.map((impactCard) => (
        <div
          className={`${backgroundClassName} col-${
            12 / impactCardsData.length
          }`}
        >
          <div
            className={`${Styles.statsCard} ${
              isTransparent ? Styles.statsCardTransparent : ""
            }`}
            key={impactCard.title}
          >
            <div className={Styles.statsCardImage}>
              <img src={impactCard.image} alt={impactCard.alt} />
            </div>
            <h1 className={Styles.statsCardTitle}>{impactCard.title}</h1>
            <p className={Styles.statsCardSubTitle}>{impactCard.subtitle}</p>

            <div
              className={`${Styles.dancingBarsContainer} dancingBarContainer`}
            >
              <ul>
                {Array.from({ length: 20 }).map((_, i) => (
                  <li className={dancingBarColor} key={i}></li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

function ImpactCards({
  backgroundClassName,
  headingDisplay,
  dancingBarColor,
  impactCardsData,
}: ImpactCardProps) {
  return (
    <div>
      <div className={SharedStyles.section}>
        <div
          className={`${headingDisplay} ${SharedStyles.sectionHeadingContainer}`}
        >
          <div className={SharedStyles.sectionHeading}>Our Impacts</div>
        </div>
        <StatsCardContainer
          impactCardsData={impactCardsData}
          backgroundClassName={backgroundClassName}
          dancingBarColor={dancingBarColor}
        />
      </div>
    </div>
  );
}

export default ImpactCards;
