import React from "react";

export default function VerifiedPerson({
  color = "#FFFFFF",
  width = "27",
  height = "27",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.72467 0.578125C5.59321 0.578125 3.86234 2.3012 3.86234 4.42304C3.86234 6.54489 5.59321 8.26796 7.72467 8.26796C9.85613 8.26796 11.587 6.54489 11.587 4.42304C11.587 2.3012 9.85613 0.578125 7.72467 0.578125ZM7.72467 1.67667C9.24753 1.67667 10.4835 2.90705 10.4835 4.42304C10.4835 5.93904 9.24753 7.16941 7.72467 7.16941C6.20181 7.16941 4.96586 5.93904 4.96586 4.42304C4.96586 2.90705 6.20181 1.67667 7.72467 1.67667ZM1.10352 14.8592H7.72467C7.87101 14.8592 8.01135 14.9171 8.11483 15.0201C8.2183 15.1231 8.27643 15.2628 8.27643 15.4085C8.27643 15.5542 8.2183 15.6939 8.11483 15.7969C8.01135 15.8999 7.87101 15.9578 7.72467 15.9578H0.551762C0.405426 15.9578 0.265083 15.8999 0.161607 15.7969C0.0581319 15.6939 0 15.5542 0 15.4085V14.31C0 12.9989 0.523187 11.7415 1.45447 10.8144C2.38575 9.88734 3.64883 9.36651 4.96586 9.36651H7.72467C7.87101 9.36651 8.01135 9.42438 8.11483 9.52739C8.2183 9.6304 8.27643 9.77011 8.27643 9.91578C8.27643 10.0615 8.2183 10.2012 8.11483 10.3042C8.01135 10.4072 7.87101 10.4651 7.72467 10.4651H4.96586C3.9415 10.4651 2.9591 10.8701 2.23478 11.5912C1.51045 12.3123 1.10352 13.2902 1.10352 14.31V14.8592ZM12.4152 9.36651C11.4643 9.36695 10.5525 9.74318 9.88007 10.4125C9.20768 11.0819 8.82974 11.9896 8.8293 12.9362C8.82974 13.8829 9.20768 14.7906 9.88007 15.4599C10.5525 16.1293 11.4643 16.5055 12.4152 16.506C13.3661 16.5055 14.2779 16.1293 14.9503 15.4599C15.6227 14.7906 16.0007 13.8829 16.0011 12.9362C16.0007 11.9896 15.6227 11.0819 14.9503 10.4125C14.2779 9.74318 13.3661 9.36695 12.4152 9.36651ZM12.4152 10.4651C13.0735 10.4653 13.7047 10.7258 14.1702 11.1892C14.6357 11.6525 14.8973 12.2809 14.8976 12.9362C14.8973 13.5915 14.6357 14.2199 14.1702 14.6833C13.7047 15.1467 13.0735 15.4071 12.4152 15.4074C11.7569 15.4071 11.1257 15.1467 10.6602 14.6833C10.1947 14.2199 9.93312 13.5915 9.93282 12.9362C9.93312 12.2809 10.1947 11.6525 10.6602 11.1892C11.1257 10.7258 11.7569 10.4653 12.4152 10.4651Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.186 12.9729L13.3993 11.731C13.4496 11.6784 13.5099 11.6363 13.5766 11.607C13.6434 11.5778 13.7153 11.562 13.7882 11.5606C13.8611 11.5593 13.9336 11.5723 14.0014 11.5989C14.0693 11.6256 14.1311 11.6654 14.1834 11.716C14.2357 11.7666 14.2774 11.827 14.3061 11.8937C14.3348 11.9605 14.3499 12.0322 14.3505 12.1048C14.3512 12.1774 14.3374 12.2494 14.3099 12.3167C14.2825 12.3839 14.2419 12.4451 14.1905 12.4966L12.5816 14.1434C12.5303 14.1962 12.4688 14.2381 12.4008 14.2668C12.3329 14.2955 12.2598 14.3102 12.186 14.3102C12.1122 14.3102 12.0391 14.2955 11.9712 14.2668C11.9032 14.2381 11.8417 14.1962 11.7904 14.1434L10.6405 12.9663C10.5891 12.9147 10.5486 12.8536 10.5211 12.7863C10.4936 12.7191 10.4798 12.6471 10.4805 12.5745C10.4812 12.5019 10.4963 12.4301 10.525 12.3634C10.5536 12.2966 10.5953 12.2362 10.6476 12.1856C10.6999 12.135 10.7618 12.0952 10.8296 12.0686C10.8974 12.0419 10.9699 12.0289 11.0428 12.0303C11.1157 12.0317 11.1876 12.0474 11.2544 12.0767C11.3212 12.1059 11.3814 12.148 11.4317 12.2006L12.186 12.9729Z"
        fill={color}
      />
    </svg>
  );
}
