import React, { FC } from "react";
import Styles from "./addOns.module.scss";

interface AddOnsProps {
  heading: string;
  content: string;
  features: string[][];
}

const AddOns: FC<AddOnsProps> = ({ heading, content, features }) => {
  return (
    <div className={Styles.addOnsMain}>
      <div className={Styles.addOnsHeadingContent}>
        <div className={Styles.addOnsHeading}>{heading}</div>
        <div className={Styles.addOnsContent}>{content}</div>
      </div>
      <div className={Styles.addOnsFeatureContainer}>
        {features.map((featureContent, index) => (
          <div className={Styles.addOnsFeatureContent} key={index}>
            {featureContent.map((feature, i) => (
              <div className={Styles.addOnsFeature} key={i}>
                {feature}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AddOns;
