import React from "react";
import Styles from "./usecaseProduct.module.scss";

// To know how to use this component check productFinancial.tsx page (the arrays are written before return())
// interface for Usecases
interface Usecase {
  image: string;
  title: string;
}

// interface for images
// all images are optional if we want to pass value or not
// if values are not passed then that will not render and not take any space
interface UsecaseImage {
  mainImage?: string;
  image1?: string;
  image2?: string;
  mainImageClassName?: string;
  image1ClassName?: string;
  image2ClassName?: string;
}

// combined both interface as props to use this component easily
interface UsecaseProductProps {
  useCases: Usecase[];
  useCaseImages: UsecaseImage[];
}

export default function UsecaseProduct({
  useCases,
  useCaseImages,
}: UsecaseProductProps) {
  return (
    <div className={Styles.useCaseMainContainer}>
      <div className={Styles.usecaseProductTextContainer}>
        {/* Using map to render usecase ,value of usecase is compulsory(you can pass any number of usecase using array) */}
        {useCases.map((useCase, index) => (
          <div>
            <div className={Styles.usecaseTextContent} key={index}>
              <img src={useCase.image} />
              <div className={Styles.usecaseText}>{useCase.title}</div>
            </div>
            {/* condition to display line between two usecase and not display line at the last usecase */}
            {index < useCases.length - 1 && <div className={Styles.line}></div>}
          </div>
        ))}
      </div>
      <div className={Styles.useCaseProductImageContainer}>
        {/* for rendering the main image of usecase (The big one) */}
        {/* passing the condition if value is not passed then image will not render and take any space */}
        {/* {useCaseImages[0].mainImage && ( */}
        {/* <img
            className={`${useCaseImages[0].mainImageClassName} ${Styles.usecaseImage1}`}
            src={useCaseImages[0]?.mainImage ?? ""}
            alt=""
          /> */}
        {/* )} */}
        {/* using map for rendering other images this are also optional*/}
        {/* {useCaseImages.map((image, index) => ( */}
        {/* <div className={Styles.usecaseImageContent} key={index}> */}
        {/* passing the condition if value is not passed then image will not render and take any space */}
        {/* {image.image1 && ( */}
        {/* <img
                src={image.image1}
                className={`${image.image1ClassName} ${Styles.useCaseImage2}`}
                alt={image.image1}
              /> */}
        {/* )} */}
        {/* passing the condition if value is not passed then image will not render and take any space */}
        {/* {image.image2 && ( */}
        {/* <img
                src={image.image2}
                className={`${image.image2ClassName} ${Styles.useCaseImage2}`}
                alt={image.image2}
              /> */}
        {/* )} */}
        {/* </div> */}
        {/* ))} */}
        <div className={Styles.usecaseProductImageContainers}>
          <img
            className={`${useCaseImages[0].mainImageClassName} ${Styles.usecaseImage1}`}
            src={useCaseImages[0].mainImage}
          />

          <img
            className={`${useCaseImages[0].image1ClassName} ${Styles.useCaseImage2}`}
            src={useCaseImages[0].image1}
          />

          <img
            className={`${useCaseImages[0].image2ClassName} ${Styles.useCaseImage2}`}
            src={useCaseImages[0].image2}
          />

          <img
            className={`${useCaseImages[1].image1ClassName} ${Styles.useCaseImage2}`}
            src={useCaseImages[1].image1}
          />

          <img
            className={`${useCaseImages[1].image2ClassName} ${Styles.useCaseImage2}`}
            src={useCaseImages[1].image2}
          />
        </div>
      </div>
    </div>
  );
}
