import React, { useEffect, useState } from "react";
import Styles from "../site-header/siteHeader.module.scss";
import "./index.scss";
import { primaryLightButtonHoveredStyle } from "../buttonStyles";
import { googleEvent } from "../../analytics/google";
import GAEvent from "../../analytics/events";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../assets/images/zoop_stack_logo_blue.svg";
import WhiteLogo from "../../assets/images/zoopStackWhiteLogo.svg";
import Button from "../button/button";
import { MEDIUM } from "../buttonSize";
import MenuHoverButton from "../menu-hover-button/menuHoverButton";
import HamburgerMenuicon from "../../assets/images/icons/hamburgerMenuWhite.svg";
import HamburgerBlueIcon from "../../assets/images/icons/hamburgerMenuBlue.svg";
import { BsFillPlayFill } from "react-icons/bs";
import {
  TryZoopStackTriggered,
  MenuButtonTriggered,
} from "../../Shared/eventsNames";
import { useLocation } from "react-router-dom";
import Sidebar from "../side-bar/sidebar";
import useScreen from "../../utils/useScreen";
import Biometric from "../../assets/svg/biometric";
import Rupee from "../../assets/svg/rupee";
import Bag from "../../assets/svg/bag";
import Contract from "../../assets/svg/contract";
import FaceScan from "../../assets/svg/faceScan";
import VerifiedPerson from "../../assets/svg/verifiedPerson";
import GameConsole from "../../assets/svg/gameConsole";
import Store from "../../assets/svg/store";
import Travel from "../../assets/svg/travel";
import ColorBiometric from "../../assets/svg/colored/colorBiometric";
import ColorRupee from "../../assets/svg/colored/colorRupee";
import ColorBag from "../../assets/svg/colored/colorBag";
import ColorContract from "../../assets/svg/colored/colorContract";
import ColorFaceScan from "../../assets/svg/colored/colorFaceScan";
import ColorPersonVerified from "../../assets/svg/colored/colorPersonVerified";
import ColorGameConsole from "../../assets/svg/colored/colorGameConsole";
import ColorStore from "../../assets/svg/colored/colorStore";
import ColorTravel from "../../assets/svg/colored/colorTravel";
import SiteHeaderMenu from "../site-header-menu/siteHeaderMenu";

interface siteHeaderProps {
  page?: string;
}

function SiteHeader({ page }: siteHeaderProps) {
  const location = useLocation();
  const { isMobile } = useScreen();
  const [showProductsMenu, setShowProductsMenu] = useState(false);
  const [showIndustryMenu, setShowIndustrysMenu] = useState(false);
  const [activeButton, setActiveButton] = useState(-1);
  const [activeIndustryButton, setActiveIndustryButton] = useState(-1);
  const [addMarginTop, setAddMarginTop] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [isDarkBackground, setIsDarkBackground] = useState(false);

  const [productsMenuButtonPropsList, setProductsMenuButtonPropsList] =
    useState([
      {
        icon: <Rupee />,
        hoverBtnHeading: "Financial Verification 5",
        hoverBtnContent: "Additional information for financial verification.",
        headingClassName: Styles.headingClass,
        link: "abc",
      },
    ]);

  const [industryMenuButtonPropsList, setIndustryMenuButtonPropsList] =
    useState([
      {
        icon: <Rupee />,
        hoverBtnHeading: "Financial Verification 5",
        hoverBtnContent: "Additional information for financial verification.",
        headingClassName: Styles.headingClass,
        link: "",
      },
    ]);
  const isTransparentHeader = () => {
    return (
      page === "gaming" ||
      page === "retail" ||
      page === "home" ||
      page === "blogs"
    );
  };

  const whiteNavColor = () =>
    page === "gaming" || page === "retail" || page === "blogs";

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 400) {
        setShowProductsMenu(false);
        setShowIndustrysMenu(false);
      }
    };

    const handleScroll = () => {
      // whenever the user scrolls, check if the page is scrolled more than 50px
      if (window.pageYOffset > window.innerHeight) {
        setIsScrolled(true);
      } else if (page === "knowledgeHub" && window.pageYOffset > 20) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleProductsMenuEnter = () => {
    if (window.innerWidth > 993) {
      setShowProductsMenu(true);
    }
  };

  const handleIndustryMenuEnter = () => {
    if (window.innerWidth > 993) {
      setShowIndustrysMenu(true);
    }
  };

  const handleProductsMenuLeave = () => {
    if (window.innerWidth > 993) {
      setShowProductsMenu(false);
    }
  };

  const handleIndustryMenuLeave = () => {
    if (window.innerWidth > 993) {
      setShowIndustrysMenu(false);
    }
  };

  const handleClickIndustry = () => {
    setShowIndustrysMenu(!showIndustryMenu);
    setShowProductsMenu(false);
    setIndustryMenuButtonPropsList([
      {
        icon:
          page === "gaming" || page === "retail" ? (
            <GameConsole />
          ) : (
            <ColorGameConsole />
          ),
        hoverBtnHeading: "Gaming",
        hoverBtnContent: "Ensure fraud-free, and secure gaming environment.",
        headingClassName: Styles.headingClass,
        link: "/industry/gaming",
      },
      {
        icon:
          page === "gaming" || page === "retail" ? <Store /> : <ColorStore />,
        hoverBtnHeading: "Retail",
        hoverBtnContent: "Advanced APIs to verify authenticity of businesses.",
        headingClassName: Styles.headingClass,
        link: "/industry/retail",
      },
      {
        icon:
          page === "gaming" || page === "retail" ? <Travel /> : <ColorTravel />,
        hoverBtnHeading: "Travel",
        hoverBtnContent:
          "Increase booking conversions with digital verification.",
        headingClassName: Styles.headingClass,
        link: "/industry/travel",
      },
    ]);
  };
  const handleClickProducts = () => {
    // if (window.innerWidth <= 993) {
    setShowProductsMenu(!showProductsMenu);
    setShowIndustrysMenu(false);
    setProductsMenuButtonPropsList([
      {
        icon:
          page === "gaming" || page === "retail" ? (
            <Biometric />
          ) : (
            <ColorBiometric />
          ),
        hoverBtnHeading: "Identity Verification",
        hoverBtnContent:
          "Get a real-time check of Govt issued IDs of the users instantly.",
        headingClassName: Styles.headingClass,
        link: "/product/identity/pan-verification-api",
      },
      {
        icon:
          page === "gaming" || page === "retail" ? <Rupee /> : <ColorRupee />,
        hoverBtnHeading: "Financial Verification",
        hoverBtnContent:
          "Verify & authenticate your users before starting any monetary transaction.",
        headingClassName: Styles.headingClass,
        link: "/product/financial/bank-account-verification-api",
      },
      {
        icon: page === "gaming" || page === "retail" ? <Bag /> : <ColorBag />,
        hoverBtnHeading: "Merchant Verification",
        hoverBtnContent:
          "Do business by knowing authenticity of other businesses.",
        headingClassName: Styles.headingClass,
        link: "/product/merchant/gst-verification-api",
      },
      {
        icon:
          page === "gaming" || page === "retail" ? (
            <Contract />
          ) : (
            <ColorContract />
          ),
        hoverBtnHeading: "Digital Contracting",
        hoverBtnContent:
          "Contracts signed, fast, cost-effective, & location-independent way.",
        headingClassName: Styles.headingClass,
        link: "/product/aadhar-esign-api",
      },
      {
        icon:
          page === "gaming" || page === "retail" ? (
            <FaceScan />
          ) : (
            <ColorFaceScan />
          ),
        hoverBtnHeading: "ML/AI Solutions",
        hoverBtnContent:
          "Assure higher conversion rates with advanced OCR, Face Match & more.",
        headingClassName: Styles.headingClass,
        link: "/product/utility/ocr-verification-api",
      },
      {
        icon:
          page === "gaming" || page === "retail" ? (
            <VerifiedPerson />
          ) : (
            <ColorPersonVerified />
          ),
        hoverBtnHeading: "Persona Verification",
        hoverBtnContent:
          "Facilitate verification of personas you business targets, with API tools.",
        headingClassName: Styles.headingClass,
        link: "/persona",
      },
    ]);
    setAddMarginTop(!addMarginTop);
    // }
  };
  const changeBackground = (index: number) => {
    setActiveButton(index);
  };
  const changeBackgroundIndustry = (index: number) => {
    setActiveIndustryButton(index);
  };
  const getLogo = () => {
    if (
      (isScrolled && isTransparentHeader()) ||
      showIndustryMenu ||
      showProductsMenu
    ) {
      return logo;
    } else if (isTransparentHeader()) {
      return WhiteLogo;
    } else {
      return logo;
    }
  };

  const containerStyle = {
    backgroundColor:
      showProductsMenu || showIndustryMenu
        ? page === "gaming" || page === "retail"
          ? "#021620"
          : "white"
        : "",
  };
  return (
    <>
      {openSidebar && (
        <Sidebar
          fullScreen
          showLogo={false}
          backgroundColor={page === "home" ? "blue" : "white"}
        />
      )}

      {/* fixed="top" */}
      <Navbar
        expand="lg"
        fixed="top"
        className={`${isTransparentHeader() ? Styles.mainContainerStyle : ""} ${
          Styles.navbarStyle
        } ${isScrolled ? Styles.whiteBackground : Styles.mainContainerStyle}`}
        style={containerStyle}
      >
        <link rel="preload"></link>
        <Container fluid style={{ maxWidth: "1920px" }}>
          <Navbar.Brand
            className={Styles.logo}
            href="\home"
            onClick={() =>
              MenuButtonTriggered({
                properties: "Header Logo Clicked",
              })
            }
          >
            <img
              src={
                page === "gaming" || page === "retail" ? WhiteLogo : getLogo()
              }
              alt="ZOOPSTACK"
            />
          </Navbar.Brand>
          {isMobile && (
            <div className={Styles.hambugerMenuIcon}>
              <img
                src={
                  isScrolled
                    ? HamburgerBlueIcon
                    : isTransparentHeader()
                    ? HamburgerMenuicon
                    : HamburgerBlueIcon
                }
                alt="Menu"
                width="50px"
                height="20px"
                onClick={() => setOpenSidebar(!openSidebar)}
              />
            </div>
          )}
          {!isMobile && (
            <Navbar.Collapse className={Styles.nav} id="navbarScroll">
              <Nav className={Styles.nav} navbarScroll>
                <Nav.Link
                  // style={{ color: "white" }}
                  className={`${Styles.navItems}
                   ${addMarginTop ? Styles.addMarginTop : ""} ${
                    !isScrolled && whiteNavColor()
                      ? `text-white ${Styles["navItemActive__whiteBorder"]}`
                      : ""
                  } ${
                    location.pathname.includes("product")
                      ? Styles.navItemActive
                      : ""
                  }`}
                  // onMouseEnter={handleProductsMenuEnter}
                  // onMouseLeave={handleProductsMenuLeave}
                  onClick={handleClickProducts}
                >
                  Products
                </Nav.Link>
                <Nav.Link
                  // style={{ color: "white" }}
                  className={`${Styles.navItems} ${
                    !isScrolled && whiteNavColor()
                      ? `text-white ${Styles["navItemActive__whiteBorder"]}`
                      : ""
                  } ${
                    location.pathname.includes("industry")
                      ? Styles.navItemActive
                      : ""
                  }`}
                  onClick={handleClickIndustry}
                >
                  Industry
                </Nav.Link>
                <Nav.Link
                  // style={{ color: "white" }}
                  href="\blogs"
                  className={`${Styles.navItems} ${
                    !isScrolled && whiteNavColor()
                      ? `text-white ${Styles["navItemActive__whiteBorder"]}`
                      : ""
                  } ${
                    location.pathname.includes("blogs")
                      ? Styles.navItemActive
                      : ""
                  }`}
                  onClick={() =>
                    MenuButtonTriggered({
                      properties: "Header KnowledgeHub MenuButton",
                    })
                  }
                >
                  Knowledge Hub
                </Nav.Link>
                <Nav.Link
                  className={`${Styles.navItems} ${
                    !isScrolled && whiteNavColor() ? "text-white" : ""
                  } ${
                    location.pathname.includes("contactus")
                      ? Styles.navItemActive
                      : ""
                  }`}
                  href="/contactus"
                  onClick={() =>
                    MenuButtonTriggered({
                      properties: "Header ContactUs MenuButton",
                    })
                  }
                >
                  Contact us
                </Nav.Link>
                <Nav.Link
                  className={Styles.navItems}
                  href="/contactus"
                  onClick={() =>
                    googleEvent(
                      "Button_Click",
                      GAEvent.ContactUsBTN,
                      GAEvent.ContactUsBTN
                    )
                  }
                >
                  <Button
                    btnTextClass={"btnLight"}
                    hoveredStyle={primaryLightButtonHoveredStyle}
                    onClick={() =>
                      googleEvent(
                        "Button_Click",
                        GAEvent.TryZoopStackBTN,
                        GAEvent.TryZoopStackBTN
                      )
                    }
                    size={MEDIUM}
                    // style={primaryLightTransButtonStyle}
                    className={
                      isScrolled
                        ? Styles.blueBtn
                        : whiteNavColor()
                        ? Styles.whiteBtn
                        : Styles.blueBtn
                    }
                    type="button"
                  >
                    <BsFillPlayFill size={24} />
                    <span> Try ZOOPSTACK</span>
                  </Button>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          )}
        </Container>
      </Navbar>
      {showProductsMenu && (
        <SiteHeaderMenu
          menuButtonProps={productsMenuButtonPropsList}
          page={page}
        />
      )}
      {showIndustryMenu && (
        <SiteHeaderMenu
          menuButtonProps={industryMenuButtonPropsList}
          page={page}
        />
      )}
      {!isTransparentHeader() && <div style={{ marginBottom: 80 }} />}
    </>
  );
}

export default SiteHeader;
