import { uniq } from "lodash";
import { BlogDetail } from "../types/blog";

export function makeArrayBlogCategory(array: any) {
  const catArray = array
    .map((category: any) => category.category)
    .join(",")
    .split(",")
    .map((category: any) => category.toLowerCase().trim());
  return uniq(catArray) as string[];
}

export function makeArrayBlogTags(array: any) {
  const tagsArray = array
    .map((blog: BlogDetail) => blog.tags)
    .join(",")
    .split(",")
    .map((tag: any) => tag.toLowerCase().trim());
  return uniq(tagsArray) as string[];
}
