import React, { ChangeEvent, useState } from "react";
import { googleEvent } from "../../analytics/google";
import GAEvent from "../../analytics/events";
import Styles from "../contact-us-form/contactUsForm.module.scss";
import axios from "axios";
import ThankYouPage from "../thank-you/thankYouPage";
import { useLocation } from "react-router-dom";
interface FormData {
  company_name: string;
  full_name: string;
  mobile_number: string;
  email_id: string;
  message: string;
  industry: string;
}
interface FormErrors {
  company_name?: string;
  full_name?: string;
  mobile_number?: string;
  email_id?: string;
  message?: string;
  industry?: string;
  checkbox?: string;
}
export default function ContactUsForm() {
  const options = [
    "Choose industry",
    "Bank",
    "General Insurance",
    "Healthcare Loan",
    "Mutual Fund",
    "System Integrator",
    "IT Consultant",
    "Shared Economy",
    "Payment Gateways",
    "HR",
    "Others",
    "Payday Loans",
    "SME Loan",
    "NBFC",
    "Personal Loan",
    "MFI",
    "Life Insurance",
    "Stock Broker",
    "Neo Bank",
    "Reseller",
    "HealthCare",
    "Hospitality",
    "Gaming",
    "Logistics",
    "Merchant On-Boarding",
    "Background Verification",
  ];
  const [selectedOption, setSelectedOption] = useState("");
  const [formKey, setFormKey] = useState(0);
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [formData, setFormData] = useState({
    company_name: "",
    full_name: "",
    mobile_number: "",
    email_id: "",
    message: "",
    industry: "",
  });
  const renderOptions = () => {
    return options.map((option, index) => (
      <option key={index} value={option}>
        {option}
      </option>
    ));
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const errors: FormErrors = { ...formErrors };

    switch (name) {
      case "company_name":
        errors.company_name = value ? "" : "Company name is required";
        break;
      case "full_name":
        errors.full_name = value ? "" : "Full name is required";
        break;
      case "mobile_number":
        errors.mobile_number = value ? "" : "Mobile number is required";
        break;
      case "email_id":
        errors.email_id = value ? "" : "Email address is required";
        break;
      case "message":
        errors.message = value ? "" : "Message is required";
        break;
      case "industry":
        setSelectedOption(value);
        break;
      default:
        break;
    }
    if (name === "email_id" && value) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(value)) {
        errors.email_id = "Invalid email address";
      }
    }

    // Validate mobile number format
    if (name === "mobile_number" && value) {
      const mobileNumberPattern = /^[0-9]{10}$/;
      if (!mobileNumberPattern.test(value)) {
        errors.mobile_number = "Invalid mobile number";
      }
    }

    setFormErrors(errors);

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsFormSubmitted(true);
    googleEvent("Button_Click", GAEvent.ContactUsBTN, GAEvent.ContactUsBTN);
    const errors: FormErrors = {};

    // Perform form validation
    if (!formData.company_name) {
      errors.company_name = "Company name is required";
    }
    if (!formData.full_name) {
      errors.full_name = "Full name is required";
    }
    if (!formData.mobile_number) {
      errors.mobile_number = "Mobile number is required";
    } else {
      const mobileNumberPattern = /^[0-9]{10}$/;
      if (!mobileNumberPattern.test(formData.mobile_number)) {
        errors.mobile_number = "Invalid mobile number";
      }
    }
    if (!formData.email_id) {
      errors.email_id = "Email address is required";
    } else {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(formData.email_id)) {
        errors.email_id = "Invalid email address";
      }
    }
    if (!formData.message) {
      errors.message = "Message is required";
    }
    if (!formData.industry || formData.industry === "Choose industry") {
      errors.industry = "Industry is required";
    }
    if (!isCheckboxChecked) {
      errors.checkbox = "Please accept the Terms & Conditions";
    }
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      try {
        setLoading(true);
        const { data } = await axios.post(
          `${process.env.REACT_APP_BASE_URL}customer/contact-us`,
          formData
        );
        if (data.status === 200) {
          setLoading(false);
          setFormData({
            company_name: "",
            full_name: "",
            mobile_number: "",
            email_id: "",
            message: "",
            industry: "",
          });

          const generateRandomString = (length: any) => {
            const characters =
              "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
            let result = "";
            for (let i = 0; i < length; i++) {
              const randomIndex = Math.floor(Math.random() * characters.length);
              result += characters.charAt(randomIndex);
            }
            return result;
          };

          const randomString = generateRandomString(20);

          localStorage.setItem("randomString", randomString);
          window.location.href = `/contactus/thank-you?token=${encodeURIComponent(
            randomString
          )}`;
          setSelectedOption("");
          setFormErrors({});
          setIsFormSubmitted(false);
        }
      } catch (error: any) {
        setLoading(false);
        console.error(error.response);
      }
      setFormKey((prevKey) => prevKey + 1);
    }
  };

  return (
    <div className={Styles.inputContainer}>
      <p className={Styles.contactUsHeading}>Let's Talk Business</p>

      <div className={Styles.inputMain}>
        <form key={formKey} action="#" onSubmit={handleSubmit}>
          <input
            style={{ marginRight: "20px" }}
            className={Styles.inputBoxText}
            type="text"
            placeholder="Company Name"
            name="company_name"
            onChange={handleInputChange}
          />
          {formErrors.company_name && (
            <div className={Styles.error}>{formErrors.company_name}</div>
          )}
          <input
            className={Styles.inputBoxText}
            type="text"
            placeholder="Full Name"
            name="full_name"
            onChange={handleInputChange}
          />
          {formErrors.full_name && (
            <div className={`${Styles.error1} ${Styles.error}`}>
              {formErrors.full_name}
            </div>
          )}
          <input
            style={{ marginRight: "20px" }}
            className={Styles.inputBoxText}
            type="text"
            placeholder="Mobile Number"
            name="mobile_number"
            onChange={handleInputChange}
          />
          {formErrors.mobile_number && (
            <div className={Styles.error}>{formErrors.mobile_number}</div>
          )}
          <input
            className={Styles.inputBoxText}
            type="text"
            placeholder="Business Email ID"
            name="email_id"
            onChange={handleInputChange}
          />
          {formErrors.email_id && (
            <div className={`${Styles.error1} ${Styles.error}`}>
              {formErrors.email_id}
            </div>
          )}
          <textarea
            className={Styles.inputBoxTextArea}
            rows={10}
            cols={50}
            placeholder="Write a Message"
            name="message"
            onChange={handleInputChange}
          ></textarea>
          {formErrors.message && (
            <div className={Styles.error}>{formErrors.message}</div>
          )}
          <select
            className={Styles.dropDown}
            value={selectedOption}
            placeholder="Industry(optional)"
            onChange={handleInputChange}
            name="industry"
          >
            {renderOptions()}
          </select>
          {isFormSubmitted && formErrors.industry && (
            <div className={Styles.error}>{formErrors.industry}</div>
          )}
          <br />
          <div className={Styles.acceptPolicy}>
            <input
              type="checkbox"
              className="mr-2"
              checked={isCheckboxChecked}
              onChange={(e) => setIsCheckboxChecked(e.target.checked)}
            />
            Accept{" "}
            <a href="https://zoop.one/privacy-policy-2/">Privacy Policy</a> &{" "}
            <a href="https://zoop.one/terms-conditions/">Terms & conditions</a>
            {isFormSubmitted && formErrors.checkbox && (
              <div className={Styles.error}>{formErrors.checkbox}</div>
            )}
          </div>
          <div className={Styles.submitButton}>
            <button
              className={Styles.submitBtn}
              type="button"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? "Loading..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
