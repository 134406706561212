import React, { useState } from "react";
import Button from "../../Shared/button/button";
import Styles from "../banner-card/bannerCard.module.scss";
import { googleEvent } from "../../analytics/google";
import GAEvent from "../../analytics/events";
import CalendlyPopup from "../calendly-popup/calendlyPopup";
import {
  primaryLightButtonHoveredStyle,
  primaryLightTransButtonStyle,
} from "../buttonStyles";
import { MEDIUM } from "../buttonSize";
import { AiFillCloseCircle } from "react-icons/ai";
interface ButtonProps {
  text: string;
  onClick?: () => void;
}

interface BannerCardProps {
  tickImage: string;
  text: string;
  button: ButtonProps;
  backGroundClassName?: string;
  headingClassName?: string;
  tickMarkTextClassName?: string;
}
function BannerCard({
  text,
  backGroundClassName,
  headingClassName,
  tickMarkTextClassName,
  tickImage,
}: BannerCardProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    googleEvent("Button_Click", GAEvent.GetDemoBTN, GAEvent.GetDemoBTN);
    document.body.style.overflow = "hidden"; // Prevent scrolling on the body when modal is open
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = ""; // Restore scrolling on the body when modal is closed
  };
  return (
    <div className={Styles.bannerContainer}>
      <div className={`${backGroundClassName} ${Styles.mainBanner}`}>
        <div className={Styles.textBanner}>
          <div className={`${headingClassName} ${Styles.headBanner}`}>
            {text}
          </div>
          <div className={Styles.buttonContainer}>
            <a>
              <Button
                className={Styles.btn}
                hoveredStyle={primaryLightButtonHoveredStyle}
                size={MEDIUM}
                style={primaryLightTransButtonStyle}
                onClick={openModal}
              >
                Get a Free Demo
              </Button>
            </a>
          </div>
          <div className={`${tickMarkTextClassName} ${Styles.Btext}`}>
            <div>
              <img src={tickImage} className={Styles.svgBanner} />
              High API Success Rate​
            </div>
            <div>
              <img src={tickImage} className={Styles.svgBanner} />
              Simplified Pricing
            </div>
            <div>
              <img src={tickImage} className={Styles.svgBanner} />
              Enriched data
            </div>
            <div>
              <img src={tickImage} className={Styles.svgBanner} />
              Negligible Latency
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className={Styles.modalOverlay}>
          <div className={Styles.modalContent}>
            <button className={Styles.closeButton} onClick={closeModal}>
              <AiFillCloseCircle size={25} />
            </button>
            <CalendlyPopup />
          </div>
        </div>
      )}
    </div>
  );
}

export default BannerCard;
