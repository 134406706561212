import React, { useEffect, useMemo, useState } from "react";
import Styles from "./siteHeaderMenu.module.scss";
import BookDemo from "../../assets/images/BookDemo.png";
import MenuHoverButton from "../menu-hover-button/menuHoverButton";
import { getBlogs } from "../../api/blog";
import { BlogDetail } from "../../types/blog";
import { useNavigate, useSearchParams } from "react-router-dom";
import useBlogStore from "../../store/blog";
import { makeArrayBlogCategory } from "../../utils/helper";
import { Link } from "react-router-dom";

interface MenuButtonProps {
  icon: JSX.Element;
  hoverBtnHeading: string;
  hoverBtnContent: string;
  headingClassName: string;
}
interface SiteHeaderMenuProps {
  menuButtonProps: MenuButtonProps[];
  page?: any;
}

export default function SiteHeaderMenu(props: SiteHeaderMenuProps) {
  const { menuButtonProps } = props;
  const navigate = useNavigate();
  const {
    blogs,
    setBlogs,
    categories,
    setCategories,
    blogLoading,
    setBlogLoading,
  } = useBlogStore();

  const [searchParams, setSearchParams] = useSearchParams(location.search);
  const [selectedCategory, setSelectedCategory] = useState(
    searchParams.get("category") || "all"
  );
  const fetchBlogs = async () => {
    setBlogLoading(true);
    try {
      const res = await getBlogs();
      if (res.data) {
        setBlogs(res.data.zoopStackBlogs);
        const categories = makeArrayBlogCategory(res.data.zoopStackBlogs);
        setCategories(["All", ...categories]);
      }
    } catch (err: any) {
      console.log(err);
    } finally {
      setBlogLoading(false);
    }
  };
  useEffect(() => {
    if (blogs?.length === 0) fetchBlogs();
  }, []);

  const filteredBlog = useMemo(() => {
    if (selectedCategory === "all") return blogs;
    return blogs?.filter((blog) =>
      blog.category
        .toLowerCase()
        .includes(selectedCategory.replaceAll("_", " "))
    );
  }, [selectedCategory, blogs]);
  const recentblogs = filteredBlog.slice(0, 5);
  const pages = props.page;

  function handleBookDemo() {
    navigate("/contactus");
  }

  return (
    <div
      className={
        props.page === "gaming" || props.page === "retail"
          ? `${Styles.SiteHeaderDarkMenuContainer}`
          : props.page === "blogLanding"
          ? `${Styles.landing}`
          : `${Styles.SiteHeaderMenuMainContainer}`
      }
      style={{ maxWidth: "1920px" }}
    >
      <div
        className={Styles.siteHeaderContact}
        onClick={handleBookDemo}
        style={{ cursor: "pointer" }}
      >
        <div className={Styles.headerMenuHeading}>Book a Demo</div>
        <div
          className={
            props.page === "gaming" || props.page === "retail"
              ? `${Styles.partialBorderWhite}`
              : `${Styles.partialBorder}`
          }
        ></div>
        <div
          className={
            props.page === "gaming" || props.page === "retail"
              ? `${Styles.bookDemoDarkContainer}`
              : `${Styles.bookDemoContainer}`
          }
        >
          <img src={BookDemo} width={188} height={168} />
          <div className={Styles.bookDemoContent}>
            Engage with our knowledgeable experts to explore our product's
            features and benefits, gaining insights and making informed
            decisions for your specific needs.
          </div>
        </div>
      </div>

      <div className={Styles.siteHeaderContact}>
        <div>
          <div className={Styles.headerMenuHeading}>Offerings</div>
          <div
            className={
              props.page === "gaming" || props.page === "retail"
                ? `${Styles.partialBorderWhite}`
                : `${Styles.partialBorder}`
            }
          ></div>
          <div className={Styles.subMenuContainer}>
            {menuButtonProps.map((props: any, index: any) => (
              <div className={Styles.offeringContainer} key={index}>
                <MenuHoverButton
                  menuHoverClass={
                    pages === "gaming" || pages === "retail"
                      ? Styles.menuHoverClasses
                      : ""
                  }
                  {...(props as {
                    icon: JSX.Element;
                    hoverBtnHeading: string;
                    hoverBtnContent: string;
                    headingClassName: string;
                    link: string;
                  })}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={Styles.siteHeaderContact}>
        <div className={Styles.headerMenuHeading}>
          Recent Blogs
          <div
            className={
              pages === "gaming" || pages === "retail"
                ? `${Styles.partialBorderWhite}`
                : `${Styles.partialBorder}`
            }
          ></div>
          <div className={Styles.otherRecentBlogs}>
            {recentblogs.map((blog: BlogDetail, index: number) => (
              <div className={Styles.otherDetailRecentBlogs}>
                <div
                  key={blog.id}
                  className={`${Styles.otherRecentBlogs}`}
                  onClick={() => {
                    navigate(
                      `/blogs/${blog.id}/${blog.title
                        .trim()
                        .toLowerCase()
                        .replaceAll(" ", "-")}`
                    );
                  }}
                >
                  <div
                    className={`p-2 ${Styles["blogCard__card-body"]}`}
                    style={{ cursor: "pointer" }}
                  >
                    <p className={`${Styles.blogsTitle}`}>{blog.title}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
