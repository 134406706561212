import React, { useEffect, useState } from "react";
import Styles from "./thankYouPage.module.scss";
import ThankYouImage from "../../assets/images/thank_you_asset.webp";
import { useLocation, useNavigate } from "react-router-dom";
export default function thankyouPage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const encodedRandomString = queryParams.get("token");
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(5);
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");
    const storedRandomString = localStorage.getItem("randomString");

    if (token != storedRandomString) {
      window.location.replace("/home");
    } else {
      const countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      setTimeout(() => {
        clearInterval(countdownInterval);
        navigate("/home");
      }, 5000);

      return () => {
        clearInterval(countdownInterval);
      };
    }
  }, []);
  return (
    <div className={Styles.thankYouContainer}>
      <img src={ThankYouImage} />
      <div className={Styles.textContainer}>
        <p>
          We have received your request and one of our Business Manager will
          connect with you shortly.
        </p>
      </div>
      <div>
        <a href="/home">Back to home</a>
        <p className={Styles.redirectText}>Redirecting in {countdown}...</p>
      </div>
    </div>
  );
}
