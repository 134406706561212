import React, { useEffect, useState } from "react";
import Styles from "../faq/faq.module.scss";
import { HiChevronDown } from "react-icons/hi";

interface FaqProps {
  question: string;
  answer: string;
}

export default function Faq({ question, answer }: FaqProps) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAnswer = (index: number) => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={Styles.faqMain}>
      <div
        className={`${Styles.faqQuestionContent} ${
          isOpen ? Styles.active : ""
        }`}
        onClick={() => toggleAnswer(1)}
      >
        <div className={Styles.faqQuestion}>{question}</div>
        <div className={`${Styles.faqPlus} ${isOpen ? Styles.rotate : ""}`}>
          <HiChevronDown />
        </div>
      </div>
      <div
        className={`${Styles.faqAnswer} ${isOpen ? Styles.show : Styles.hide}`}
      >
        {answer}
      </div>
      <hr className={Styles.faqHr} />
    </div>
  );
}

export const FaqList: React.FC<{
  faqs: FaqProps[];
  visibleFaqs: number;
  onLoadMore: () => void;
}> = ({ faqs, visibleFaqs, onLoadMore }) => {
  return (
    <div>
      {faqs.slice(0, visibleFaqs).map((faq, index) => (
        <Faq key={index} question={faq.question} answer={faq.answer} />
      ))}
      {visibleFaqs < faqs.length && (
        <div className={Styles.loadMoreMain}>
          <button onClick={onLoadMore} className={Styles.loadMoreButton}>
            Load More
          </button>
        </div>
      )}
    </div>
  );
};
