import React from "react";

export default function Bag({
  color = "#FFFFFF",
  width = "23",
  height = "25",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1333 4.1758H9.06667V3.64488C9.06667 2.18111 7.8704 0.990234 6.4 0.990234H5.33333C3.86293 0.990234 2.66667 2.18111 2.66667 3.64488V6.29952C2.66667 6.59312 2.9056 6.83045 3.2 6.83045C3.4944 6.83045 3.73333 6.59312 3.73333 6.29952V5.23766H6.93333C7.22773 5.23766 7.46667 5.00034 7.46667 4.70673C7.46667 4.41313 7.22773 4.1758 6.93333 4.1758H3.73333V3.64488C3.73333 2.76672 4.4512 2.05209 5.33333 2.05209H6.4C7.28213 2.05209 8 2.76672 8 3.64488V6.29952C8 6.59312 8.23893 6.83045 8.53333 6.83045C8.82773 6.83045 9.06667 6.59312 9.06667 6.29952V5.23766H10.1333C10.4272 5.23766 10.6667 5.47552 10.6667 5.76859V15.3253C10.6667 15.6184 10.4272 15.8562 10.1333 15.8562H1.6C1.30613 15.8562 1.06667 15.6184 1.06667 15.3253V5.76859C1.06667 5.47552 1.30613 5.23766 1.6 5.23766C1.8944 5.23766 2.13333 5.00034 2.13333 4.70673C2.13333 4.41313 1.8944 4.1758 1.6 4.1758C0.717867 4.1758 0 4.89043 0 5.76859V15.3253C0 16.2035 0.717867 16.9181 1.6 16.9181H10.1333C11.0155 16.9181 11.7333 16.2035 11.7333 15.3253V5.76859C11.7333 4.89043 11.0155 4.1758 10.1333 4.1758Z"
        fill={color}
      />
      <path
        d="M5.17616 10.1723C4.96762 9.96471 4.63056 9.96471 4.42203 10.1723C4.21349 10.3799 4.21349 10.7154 4.42203 10.923L5.48869 11.9849C5.59269 12.0884 5.72923 12.1405 5.86576 12.1405C6.00229 12.1405 6.13883 12.0884 6.24283 11.9849L8.90949 9.33026C9.11803 9.12266 9.11803 8.78712 8.90949 8.57952C8.70096 8.37193 8.36389 8.37193 8.15536 8.57952L5.86576 10.8588L5.17616 10.1723Z"
        fill={color}
      />
      <path
        d="M5.86615 7.36133C3.80748 7.36133 2.13281 9.02897 2.13281 11.0778C2.13281 13.1267 3.80748 14.7943 5.86615 14.7943C7.92481 14.7943 9.59948 13.1267 9.59948 11.0778C9.59948 10.7842 9.36055 10.5469 9.06615 10.5469C8.77175 10.5469 8.53281 10.7842 8.53281 11.0778C8.53281 12.5416 7.33655 13.7325 5.86615 13.7325C4.39575 13.7325 3.19948 12.5416 3.19948 11.0778C3.19948 9.61406 4.39575 8.42318 5.86615 8.42318C6.16055 8.42318 6.39948 8.18586 6.39948 7.89226C6.39948 7.59865 6.16055 7.36133 5.86615 7.36133Z"
        fill={color}
      />
    </svg>
  );
}
