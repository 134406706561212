import React from "react";

export default function Rupee({
  color = "#FFFFFF",
  width = "27",
  height = "30",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99032 0.554731C4.57775 0.554731 1 4.11635 1 8.51251C1 10.6215 1.84182 12.6468 3.33957 14.1378C6.45432 17.2524 11.5123 17.2664 14.6411 14.1657C17.7698 11.065 17.7839 6.02985 14.6691 2.91518C14.6586 2.9047 14.6481 2.89423 14.6411 2.88724C13.1468 1.38578 11.1124 0.547747 8.99032 0.554731ZM8.99032 15.7684C4.97061 15.7684 1.69801 12.5141 1.69801 8.51251C1.69801 4.51092 4.97061 1.25309 8.99032 1.25309C13.01 1.25309 16.2791 4.50743 16.2791 8.50902C16.2791 12.5106 13.01 15.7684 8.99032 15.7684Z"
        fill={color}
        stroke={color}
        stroke-width="0.4"
      />
      <path
        d="M11.7138 5.86713C11.7138 5.64802 11.5337 5.46875 11.3136 5.46875H7.26372C7.04362 5.46875 6.86353 5.64802 6.86353 5.86713C6.86353 6.08624 7.04362 6.26551 7.26372 6.26551H8.42426C9.00453 6.2695 9.51677 6.63601 9.70086 7.18179H7.26372C7.04362 7.18179 6.86353 7.36107 6.86353 7.58018C6.86353 7.79929 7.04362 7.97856 7.26372 7.97856H9.70086C9.63683 8.18572 9.52077 8.37694 9.3687 8.53231C9.12059 8.78727 8.78043 8.93069 8.42426 8.93467H7.26372C7.05162 8.92671 6.87154 9.09403 6.86353 9.30517C6.85953 9.41672 6.90355 9.52826 6.98759 9.60396L9.88494 12.3289C10.045 12.4803 10.3011 12.4723 10.4492 12.309C10.5973 12.1576 10.5933 11.9146 10.4412 11.7672C10.4372 11.7632 10.4332 11.7632 10.4332 11.7592L8.26819 9.73144H8.42026C8.98852 9.72746 9.53278 9.50038 9.93297 9.09801C10.2411 8.79524 10.4492 8.40483 10.5212 7.97856H11.3136C11.5337 7.97856 11.7138 7.79929 11.7138 7.58018C11.7138 7.36107 11.5337 7.18179 11.3136 7.18179H10.5212C10.4652 6.84317 10.3131 6.52446 10.085 6.26551H11.3096C11.5337 6.26551 11.7138 6.08624 11.7138 5.86713Z"
        fill={color}
        stroke={color}
        stroke-width="0.4"
      />
    </svg>
  );
}
