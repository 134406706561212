import React from "react";
import Styles from "../business-cards/businessCards.module.scss";
import BusinessCardImg from "../../assets/images/india.png";
import Gear from "../../assets/images/gearWhite.svg";
import Dashboard2 from "../../assets/images/dashboard2White.svg";
import HandShake from "../../assets/images/handshakeWhite.svg";
import Timer from "../../assets/images/timerWhite.svg";
import BusinessCardComp from "../../Shared/business-card/businessCardComp";

const businessCards = () => {
  return (
    <div className={Styles.mainDetails}>
      <div className={`${Styles.businessCardsParentContainer}`}>
        <div className={Styles.businessMainHeading}>Why choose us?</div>
        <div className={`${Styles.businessCardsContainer}`}>
          <BusinessCardComp
            image={Gear}
            cardTitle={"Flexible APIs and SDKs"}
            cardSubTitle={
              "Create apps on dashboard, club our products to your requirements."
            }
            link="#"
            linkText="Know More"
            headClassName={Styles.businessCardheading}
            subHeadClassName={Styles.businessCardsubHeading}
            flatCard={Styles.flatCard}
          />
          <BusinessCardComp
            image={Dashboard2}
            cardTitle={"Personalised Dashboard and Trail Centre"}
            cardSubTitle={
              "We win your trust first. Get instant access to our APIs and test them."
            }
            link="#"
            linkText="Know More"
            headClassName={Styles.businessCardheading}
            subHeadClassName={Styles.businessCardsubHeading}
            flatCard={Styles.flatCard}
          />
        </div>
        <br />
        <div className={`${Styles.businessCardsContainer}`}>
          <BusinessCardComp
            image={HandShake}
            cardTitle={"Customer Satisfaction Enhanced"}
            cardSubTitle={
              "No more physical paperwork, logistics, and manual data entry."
            }
            link="#"
            linkText="Know More"
            headClassName={Styles.businessCardheading}
            subHeadClassName={Styles.businessCardsubHeading}
            flatCard={Styles.flatCard}
          />
          <BusinessCardComp
            image={Timer}
            cardTitle={"Increased productivity, decreased costs, and time"}
            cardSubTitle={
              "Increase productivity by 75%, reduced operational costs by 60%, and turnaround time by 95%."
            }
            link="#"
            linkText="Know More"
            headClassName={Styles.businessCardheading}
            subHeadClassName={Styles.businessCardsubHeading}
            flatCard={Styles.flatCard}
          />
        </div>
      </div>
      <div className={Styles.imgDetail}>
        <img src={BusinessCardImg} />
      </div>
    </div>
  );
};
export default businessCards;
