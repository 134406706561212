import React, { useEffect, useState } from "react";
import Styles from "./mlAiSolutions.module.scss";
import SiteHeader from "../../Shared/site-header/siteHeader";
import { googlePageView } from "../../analytics/google";
import SiteFooter from "../../Shared/site-footer/siteFooter";
import ProductVerify from "../../Shared/product-verify/productVerify";
import ButtonCarousel from "../../Shared/button-carousel/buttonCarousel";
import ProductVerifyDetail from "../../Shared/product-verify-detail/productVerifyDetail";
import SharedStyles from "../../Shared/shared.module.scss";
import BusinessCardComp from "../../Shared/business-card/businessCardComp";
import AutomateWork from "../../assets/images/automateWork.svg";
import StopFraud from "../../assets/images/stopFraud.svg";
import ReliableVerify from "../../assets/images/reliableVerify.svg";
import RealTimeVerify from "../../assets/images/realTimeVerify.svg";
import Faq, { FaqList } from "../../Shared/faq/faq";
import BannerCard from "../../Shared/banner-card/bannerCard";
import UsecaseProduct from "../../Shared/usecase-product/usecaseProduct";
import BannerTickWhite from "../../assets/images/bannerTickWhite.svg";
import UtilityBackground from "../../assets/images/stack-offerings/utility.webp";
import VideoKYC from "../../assets/images/videoKyc.svg";
import FacialAnalysis from "../../assets/images/facialAnalysis.svg";
import BgVerification from "../../assets/images/bg_verification.svg";
import FraudDetection from "../../assets/images/fraudDetect.svg";
import OcrImage from "../../assets/images/products/ml-ai solutions/ocr.webp";
import FaceLivelinessImage from "../../assets/images/products/ml-ai solutions/face_liveness.webp";
import FaceMatchImage from "../../assets/images/products/ml-ai solutions/face_match.webp";
import UseCaseImage1 from "../../assets/images/usecaseImages/video_kyc.png";
import UseCaseImage2 from "../../assets/images/usecaseImages/facial_analysis.webp";
import UseCaseImage3 from "../../assets/images/usecaseImages/fraud_detection.webp";
import UseCaseImage4 from "../../assets/images/usecaseImages/image 13.webp";
import menuIcon1 from "../../assets/images/menu_slider_icons/blackIcons/utility/ocr.svg";
import menuIcon2 from "../../assets/images/menu_slider_icons/blackIcons/utility/face-match.svg";
import menuIcon3 from "../../assets/images/menu_slider_icons/blackIcons/utility/face-liveness.svg";
import menuIcon1White from "../../assets/images/menu_slider_icons/whiteIcons/utility/ocr.svg";
import menuIcon2White from "../../assets/images/menu_slider_icons/whiteIcons/utility/face-match.svg";
import menuIcon3White from "../../assets/images/menu_slider_icons/whiteIcons/utility/face-liveness.svg";
import BrandMarquee from "../../Shared/brand-marquee/brandMarquee";

import {
  BannerCardButtonTriggered,
  GetAPIKeysTriggered,
  MenuSliderButtonTriggered,
  PageScrollTriggered,
} from "../../Shared/eventsNames";
import { Helmet, HelmetProvider } from "react-helmet-async";
import AddOns from "../../Shared/add-ons/addOns";

export default function MlAiSolutions() {
  googlePageView("/ml-ai-solutions", "ML-AI Solutions");
  let newPathname = null;
  const pathname = window.location.pathname;
  const [visibleFaqs, setVisibleFaqs] = useState(5);
  const loadMoreFaqs = () => {
    setVisibleFaqs(visibleFaqs + 5);
  };
  const [refreshFaqList, setRefreshFaqList] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      PageScrollTriggered({ properties: "ML-AI Solution page Scrolled" });
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const useCases = [
    {
      image: VideoKYC,
      title: "Business Verification",
    },
    {
      image: FacialAnalysis,
      title: "Facial Analysis ",
    },
    {
      image: FraudDetection,
      title: "Fraud Detection & Prevention",
    },
    {
      image: BgVerification,
      title: "Document Verification",
    },
  ];
  const [metaDescription, setMetaDescription] = useState({
    title: "Extract text from image | Extract words from image",
    desc: "Effortlessly extract text from image with our OCR Verification API. Instantly extract text from images online, enabling efficient data processing. Easily extract words from images for enhanced convenience and accuracy in various applications.",
    keyWords:
      "ocr api, extract text from image, extract text from image online, extract words from image",
  });
  const useCaseImages = [
    {
      mainImage: UseCaseImage1,
      image1: UseCaseImage2,
      image2: UseCaseImage3,
      mainImageClassName: Styles.mainImage,
      image1ClassName: Styles.image1,
      image2ClassName: Styles.image1,
    },
    {
      image1: UseCaseImage4,
      image1ClassName: Styles.mainImage,
    },
  ];
  const [FaqProps, setFaqProps] = useState({
    faqs: [
      {
        question: "What is OCR API?",
        answer:
          "OCR API stands for Optical Character Recognition Application Programming Interface, which is a software tool that uses computer algorithms to convert scanned images or PDF documents into machine-readable text.",
      },
      {
        question: "How does OCR API work?",
        answer:
          "OCR API uses various image processing techniques, such as binarization, segmentation, and feature extraction, to identify characters in an image or document. It then uses machine learning algorithms to recognize the characters and convert them into text.",
      },
      {
        question: "What is the primary purpose of the OCR API? ",
        answer:
          "The primary purpose of the OCR API is to convert printed or handwritten text within images or documents into machine-readable text for analysis, search, and other applications.",
      },
      {
        question: "What are the benefits of using OCR API?",
        answer:
          "OCR API can automate the process of converting scanned images or PDF documents into editable text, which saves time and effort. It can also reduce errors and improve accuracy, as it can recognize text in multiple languages and fonts.",
      },
      {
        question: "What types of documents can OCR API recognize?",
        answer:
          "OCR API can recognize various types of documents, including government issued Indian ID cards - Aadhaar, PAN, Driving License, Voter Id & Passport. It can also recognize Business documents such as GST, CIN, Udyam Aadhaar and TDS Certificate.",
      },
      {
        question: "How accurate is OCR API?",
        answer:
          "The accuracy of OCR API depends on various factors, such as the quality of the scanned image, the clarity of the text, and the font style. However, most OCR APIs have an accuracy rate of around 95% to 99%.",
      },
      {
        question: "Who can use the OCR API?",
        answer:
          "The OCR API is typically used by developers, businesses, and organizations seeking to automate text extraction from images, enhance data digitization, and improve document management.",
      },
      {
        question: "What is the primary purpose of the OCR API?",
        answer:
          "The primary purpose of the OCR API is to convert printed or handwritten text within images or documents into machine-readable text for analysis, search, and other applications.",
      },
      {
        question: "What are the key applications of the OCR API?",
        answer:
          "The OCR API is used in various applications, including document digitization, text extraction for search engines, automatic data entry, and content analysis.",
      },
      {
        question:
          "Is the use of the OCR API subject to data privacy regulations?",
        answer:
          "The use of the OCR API may be subject to data privacy and compliance regulations depending on the specific use case and location.",
      },
    ],
  });

  const [productVerifyProps, setProductVerifyProps] = useState({
    heading: "Extract OCR",
    subHeading:
      "The only requirement for the process of OCR API is pictures of the documents of the user. In order to acknowledge the credibility of the customer the regulatory bodies advise using OCR API systems. Also, fraudulent activities can be controlled with the same. ZOOPSTACK’s OCR API helps you scan and extract data from documents with reliability and ease. ZOOPSTACK’s OCR API can verify Cheques, Government-issued ID cards, and Merchant Documents.",
    buttonText: "Get API Keys",
    imgSrc: UtilityBackground,
  });

  const [productVerifyDetailProps, setProductVerifyDetailProps] = useState({
    imageSrc: OcrImage,
    heading: "How does OCR Service work?",
    points: [
      "Sign up on the ZOOPSTACK dashboard",
      "Subscribe to OCR Service in the products section on our dashboard.",
      "Provide Card type and picture of ID as API input.",
      "Instant OCR is performed on a real-time basis.",
    ],
    buttonText: "Explore",
    productHeading: "Integrate OCR Service",
    useCaseHeading: "Uses cases of OCR Service",
  });

  const [businessCardProps, setBusinessCardProps] = useState([
    {
      image: AutomateWork,
      cardTitle: "Automate Workflows",
      cardSubTitle:
        "No more reconciling data between multiple documents manually",
    },
    {
      image: StopFraud,
      cardTitle: "Stop Fraudsters",
      cardSubTitle:
        "Let only authentic customers access your services. Ensure your safety and prevent fraudulent activities",
    },
    {
      image: ReliableVerify,
      cardTitle: "Reliable Verification",
      cardSubTitle:
        "Verifies all the information provided by the customer and onboard the legit and accurate customers",
    },
    {
      image: RealTimeVerify,
      cardTitle: "Real-time Verification",
      cardSubTitle:
        "Save your time and effort by integrating the right APIs. The data is processed in a second, hence no more manual reviewing",
    },
  ]);

  const handleButtonClick = () => {
    BannerCardButtonTriggered({
      properties: "BannerCard Button on Ml-Ai solution Page",
    });
  };

  const [activeButton, setActiveButton] = useState(0);

  useEffect(() => {
    if (pathname.includes("ocr")) {
      setActiveButton(0);
      changeContent(0);
    } else if (pathname.includes("face-match")) {
      setActiveButton(1);
      changeContent(1);
    } else if (pathname.includes("face-liveness")) {
      setActiveButton(2);
      changeContent(2);
    }
  }, []);

  const changeContent = (index: number) => {
    switch (index) {
      case 0:
        setMetaDescription({
          title: "OCR Verification API - Get your Trial API Key",
          desc: "ZOOPSTACK extracts text from image with its OCR Verification API instantly extract text, enabling efficient data processing. Extracting words from images for enhanced convenience and accuracy in various applications was never this easy.",
          keyWords:
            "ocr api, extract text from image, extract text from image online, extract words from image",
        });
        MenuSliderButtonTriggered({
          properties: "MenuSlider OCR Button of ML-AI Page ",
        });
        newPathname =
          pathname.substring(0, pathname.lastIndexOf("/")) +
          "/ocr-verification-api";
        window.history.replaceState({}, "", `${newPathname}`);
        setProductVerifyDetailProps({
          imageSrc: OcrImage,
          heading: "How does OCR API work?",
          points: [
            "Sign up on the ZOOPSTACK dashboard",
            "Subscribe to OCR API in the products section on our dashboard.",
            "Provide Card type and picture of ID as API input.",
            "Instant OCR API is performed on a real-time basis.",
          ],
          buttonText: "Explore",
          productHeading: "Integrate OCR API",
          useCaseHeading: "Use cases of OCR API",
        });
        setProductVerifyProps({
          heading: "OCR API",
          subHeading:
            "The only requirement for the process of OCR API is pictures of the documents of the user. In order to acknowledge the credibility of the customer the regulatory bodies advise using OCR API systems. Also, fraudulent activities can be controlled with the same. ZOOPSTACK’s OCR API helps you scan and extract data from documents with reliability and ease. ZOOPSTACK’s OCR API can verify Cheques, Government-issued ID cards, and Merchant Documents.",
          buttonText: "Get API Keys",
          imgSrc: UtilityBackground,
        });
        setFaqProps({
          faqs: [
            {
              question: "What is OCR API?",
              answer:
                "OCR API stands for Optical Character Recognition Application Programming Interface, which is a software tool that uses computer algorithms to convert scanned images or PDF documents into machine-readable text.",
            },
            {
              question: "How does OCR API work?",
              answer:
                "OCR API uses various image processing techniques, such as binarization, segmentation, and feature extraction, to identify characters in an image or document. It then uses machine learning algorithms to recognize the characters and convert them into text.",
            },
            {
              question: "What is the primary purpose of the OCR API? ",
              answer:
                "The primary purpose of the OCR API is to convert printed or handwritten text within images or documents into machine-readable text for analysis, search, and other applications.",
            },
            {
              question: "What are the benefits of using OCR API?",
              answer:
                "OCR API can automate the process of converting scanned images or PDF documents into editable text, which saves time and effort. It can also reduce errors and improve accuracy, as it can recognize text in multiple languages and fonts.",
            },
            {
              question: "What types of documents can OCR API recognize?",
              answer:
                "OCR API can recognize various types of documents, including government issued Indian ID cards - Aadhaar, PAN, Driving License, Voter Id & Passport. It can also recognize Business documents such as GST, CIN, Udyam Aadhaar and TDS Certificate.",
            },
            {
              question: "How accurate is OCR API?",
              answer:
                "The accuracy of OCR API depends on various factors, such as the quality of the scanned image, the clarity of the text, and the font style. However, most OCR APIs have an accuracy rate of around 95% to 99%.",
            },
            {
              question: "Who can use the OCR API?",
              answer:
                "The OCR API is typically used by developers, businesses, and organizations seeking to automate text extraction from images, enhance data digitization, and improve document management.",
            },
            {
              question: "What is the primary purpose of the OCR API?",
              answer:
                "The primary purpose of the OCR API is to convert printed or handwritten text within images or documents into machine-readable text for analysis, search, and other applications.",
            },
            {
              question: "What are the key applications of the OCR API?",
              answer:
                "The OCR API is used in various applications, including document digitization, text extraction for search engines, automatic data entry, and content analysis.",
            },
            {
              question:
                "Is the use of the OCR API subject to data privacy regulations?",
              answer:
                "The use of the OCR API may be subject to data privacy and compliance regulations depending on the specific use case and location.",
            },
          ],
        });
        break;
      case 1:
        setMetaDescription({
          title: "Face Match API - ZOOPSTACK",
          desc: "Ensure secure identity verification with our Face Match API. Fast and secure face check id with ZOOPSTACK for seamless onboarding.",
          keyWords:
            "face match api, online face match, face match verification, facecheck id, face check.id, face check id ",
        });
        MenuSliderButtonTriggered({
          properties: "MenuSlider Face-Match Button of ML-AI Page ",
        });
        newPathname =
          pathname.substring(0, pathname.lastIndexOf("/")) + "/face-match-api";
        window.history.replaceState({}, "", `${newPathname}`);
        setProductVerifyDetailProps({
          imageSrc: FaceMatchImage,
          heading: "How does Face Match API work?",
          points: [
            "Sign up on the ZOOPSTACK dashboard",
            "Subscribe to Face Match API in the products section on our dashboard.",
            "Provide photo as API input.",
            "Instant Face match API verification is done on a real-time basis.",
          ],
          buttonText: "Explore",
          productHeading: "Integrate Face Match API",
          useCaseHeading: "Use cases of Face Match API",
        });
        setProductVerifyProps({
          heading: "Face Match API",
          subHeading:
            "The Face Match API Verification is done to ensure the identities of customers and to know if they are who they claim to be. For the successful completion of this process images or videos of the customer obtained in real-time and the scans of valid OVDs are required. ZOOPSTACK’s Face Match Verification API is quick, and reliable and makes the process hassle-free.",
          buttonText: "Get API Keys",
          imgSrc: UtilityBackground,
        });
        setFaqProps({
          faqs: [
            {
              question: " What is the Face Match API?",
              answer:
                "The Face Match API is a service that allows developers to compare two facial images and determine their similarity or match score. It utilizes advanced facial recognition algorithms to analyze and compare facial features, providing a reliable method for identifying matching faces.",
            },
            {
              question: "How does the Face Match API work?",
              answer:
                "The Face Match API works by extracting unique facial features from the provided images, such as key points, landmarks, and texture details. It then computes a similarity score based on the extracted features, indicating how closely the two faces match. Higher similarity scores indicate a higher likelihood of a match.",
            },
            {
              question:
                "What are the typical use cases for the Face Match API?",
              answer:
                "The Face Match API is commonly used in various applications, including identity verification, user authentication, facial recognition systems, finding similar faces in a database, and matching faces for forensic purposes.",
            },
            {
              question: "What kind of images does the Face Match API support?",
              answer:
                "The Face Match API generally supports common image formats such as JPEG, PNG, and BMP. It is recommended to use high-quality images with good lighting and clear facial visibility for accurate results.",
            },
            {
              question:
                "Is the Face Match API suitable for real-time applications?",
              answer:
                "The real-time performance of the Face Match API depends on various factors, including network speed, server load, and image size. While it can work in real-time scenarios, the response time may vary based on these factors.",
            },
            {
              question: "How accurate is the Face Match API?",
              answer:
                "The accuracy of the Face Match API depends on the quality of the provided images, the clarity of facial features, and the effectiveness of the recognition algorithms. Generally, it provides reliable results with a high degree of accuracy.",
            },
            {
              question: "Who can use the Face Match API?",
              answer:
                "The Face Match API is typically used by developers, businesses, and organizations to integrate facial recognition technology into their applications and systems.",
            },
            {
              question:
                "Is the use of the Face Match API subject to privacy regulations?",
              answer:
                "Yes, the use of the Face Match API is subject to privacy and data protection regulations, and users should adhere to applicable laws and guidelines.",
            },
            {
              question:
                "Is there a need for user consent when using the Face Match API for facial recognition?",
              answer:
                "Yes, obtaining user consent is generally required when using the Face Match API for facial recognition, in line with privacy and consent regulations.",
            },
            {
              question:
                "How can I access and integrate the Face Match API into my application or system?",
              answer:
                "To access and integrate the Face Match API, you can connect with our team.",
            },
          ],
        });
        break;
      case 2:
        setMetaDescription({
          title:
            "Face Liveness Detection API - Simplest Customer Identification Procedure ",
          desc: "Enhance your customer identification procedure. Streamline customer identification processes and enhance security with ZOOPSTACK's face liveness verification.",
          keyWords:
            "face liveness verification, customer identification procedure, customer identification, face verification",
        });
        MenuSliderButtonTriggered({
          properties: "MenuSlider Face-Liveliness Button of ML-AI Page ",
        });
        newPathname =
          pathname.substring(0, pathname.lastIndexOf("/")) +
          "/face-liveness-verification";
        window.history.replaceState({}, "", `${newPathname}`);
        setProductVerifyDetailProps({
          imageSrc: FaceLivelinessImage,
          heading: "How does Face Liveness Detection API work?",
          points: [
            "Sign up on the ZOOPSTACK dashboard",
            "Subscribe to Face Liveness Detection API in the products section on our dashboard.",
            "Provide photo as API input.",
            "Instant Face Liveness is done on a real-time basis.",
          ],
          buttonText: "Explore",
          productHeading: "Integrate Face Liveness Detection API",
          useCaseHeading: "Use cases of Face Liveness Detection API",
        });
        setProductVerifyProps({
          heading: "Face Liveness Detection API",
          subHeading:
            "The Face Liveness Detection API Verification is done to ensure the identities of customers and to know if they are who they claim to be. For the successful completion of this process images or videos of the customer obtained in real-time and the scans of valid OVDs are required. ZOOPSTACK’s Face Match Verification API is quick, and reliable and makes the process hassle-free.",
          buttonText: "Get API Keys",
          imgSrc: UtilityBackground,
        });
        setFaqProps({
          faqs: [
            {
              question: "What is Face Liveness Detection API?",
              answer:
                "The Face Liveness Detection API is a powerful tool that allows developers to verify the authenticity of a face by determining whether it belongs to a live person or a spoof attempt, such as a photo or video.",
            },
            {
              question: "How does Face Liveness Detection API work? ",
              answer:
                "The Face Liveness Detection API utilizes advanced computer vision algorithms to analyze facial recognition, movements, and characteristics in real-time. It distinguishes live facial movements from static images or pre-recorded videos, ensuring higher security and accuracy.",
            },
            {
              question:
                "What are the benefits of using Face Liveness Detection API?",
              answer:
                "By incorporating Face Liveness Detection API into your applications, you can significantly enhance security measures, prevent fraudulent activities, and protect sensitive data. It offers an added layer of biometric verification for a wide range of use cases, including user authentication and identity verification.",
            },
            {
              question:
                "Is Face Liveness Detection API compatible with multiple platforms?",
              answer:
                "Yes, most Face Liveness Detection APIs are designed to be platform-agnostic, enabling seamless integration and facial recognition across different platforms and operating systems, including web applications, mobile apps, and desktop applications.",
            },
            {
              question: "How fast is the Face Liveness Detection API process? ",
              answer:
                "Face Liveness Detection API is optimized for real-time processing, providing quick and efficient results within milliseconds. The speed ensures a smooth user experience and enables swift decision-making in various applications.",
            },
            {
              question: "Who can use the Face Liveness Detection API?",
              answer:
                "The Face Liveness Detection API is designed for use by businesses, organizations, and developers who want to enhance security by verifying that the face in front of the camera is real and not a spoof.",
            },
            {
              question:
                "What is the significance of using the Face Liveness Detection API?",
              answer:
                "The primary purpose of the Face Liveness Detection API is to prevent fraudulent attempts to use static images or videos for unauthorized access or identity verification.",
            },
            {
              question:
                "What are the key applications of the Face Liveness Detection API?",
              answer:
                "The Face Liveness Detection API is used in access control, identity verification, financial transactions, and any scenario where facial recognition security is critical.",
            },
            {
              question:
                "Are there privacy concerns associated with the use of the Face Liveness Detection API?",
              answer:
                "The Face Liveness Detection API focuses on verifying liveness and doesn't typically capture or store personal data. However, users should adhere to data privacy regulations.",
            },
          ],
        });
        break;
    }
  };
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>ZOOPSTACK</title>
          <meta name="description" content={metaDescription.desc} />
          <meta name="title" content={metaDescription.title} />
          <meta name="Keywords" content={metaDescription.keyWords} />
        </Helmet>
      </HelmetProvider>
      <div className={SharedStyles.mainContainer}>
        <SiteHeader />
        <div className={Styles.utilityBackground}>
          <div className={SharedStyles.menu}>
            <div className={`${Styles.navigation} js-btn-slide-container`}>
              <div
                className={`${Styles.navigation__btn} ${
                  activeButton === 0
                    ? `${Styles["navigation__btn--active"]} ${Styles.selected}`
                    : ""
                } js-btn-slide`}
                onClick={() => setActiveButton(0)}
                role="button"
              >
                <ButtonCarousel
                  buttonImgSrc={activeButton === 0 ? menuIcon1White : menuIcon1}
                  btnText="OCR"
                  onButtonClick={() => changeContent(0)}
                />
              </div>

              <div
                className={`${Styles.navigation__btn} ${
                  activeButton === 1
                    ? `${Styles["navigation__btn--active"]} ${Styles.selected}`
                    : ""
                } js-btn-slide`}
                onClick={() => setActiveButton(1)}
                role="button"
              >
                <ButtonCarousel
                  buttonImgSrc={activeButton === 1 ? menuIcon2White : menuIcon2}
                  btnText={"Face Match"}
                  onButtonClick={() => changeContent(1)}
                />
              </div>

              <div
                className={`${Styles.navigation__btn} ${
                  activeButton === 2
                    ? `${Styles["navigation__btn--active"]} ${Styles.selected}`
                    : ""
                } js-btn-slide`}
                onClick={() => setActiveButton(2)}
                role="button"
              >
                <ButtonCarousel
                  buttonImgSrc={activeButton === 2 ? menuIcon3White : menuIcon3}
                  btnText={"Face Liveliness"}
                  onButtonClick={() => changeContent(2)}
                />
              </div>
            </div>
          </div>
          <div className={`${Styles.productVerifySection} mt-5`}>
            <ProductVerify
              heading={productVerifyProps.heading}
              contentText={productVerifyProps.subHeading}
              buttonText={productVerifyProps.buttonText}
              onButtonClick={() =>
                GetAPIKeysTriggered({
                  properties: `GetAPIKeys on Ml-AISolution Page for ${productVerifyProps.heading}`,
                })
              }
              imgSrc={productVerifyProps.imgSrc}
              link={"/contactus"}
            />
          </div>
        </div>

        <div style={{ marginTop: "10px", marginBottom: "80px" }}>
          <ProductVerifyDetail
            imageSrc={productVerifyDetailProps.imageSrc}
            heading={productVerifyDetailProps.heading}
            points={[
              productVerifyDetailProps.points[0],
              productVerifyDetailProps.points[1],
              productVerifyDetailProps.points[2],
              productVerifyDetailProps.points[3],
            ]}
            buttonText={productVerifyDetailProps.buttonText}
            onButtonClick={handleButtonClick}
          />
        </div>

        <div className={""}>
          <BrandMarquee />
        </div>

        <div className={SharedStyles.section}>
          <div className={SharedStyles.sectionHeading}>
            {productVerifyDetailProps.productHeading}
          </div>
          <div className={SharedStyles.sectionSubHeading}>
            Sign up on our dashboard and test our APIs instantly for free
          </div>
        </div>

        <div className={Styles.businessCards}>
          <BusinessCardComp
            image={businessCardProps[0].image}
            cardTitle={businessCardProps[0].cardTitle}
            cardSubTitle={businessCardProps[0].cardSubTitle}
          />
          <BusinessCardComp
            image={businessCardProps[1].image}
            cardTitle={businessCardProps[1].cardTitle}
            cardSubTitle={businessCardProps[1].cardSubTitle}
          />
          <BusinessCardComp
            image={businessCardProps[2].image}
            cardTitle={businessCardProps[2].cardTitle}
            cardSubTitle={businessCardProps[2].cardSubTitle}
          />
          <BusinessCardComp
            image={businessCardProps[3].image}
            cardTitle={businessCardProps[3].cardTitle}
            cardSubTitle={businessCardProps[3].cardSubTitle}
          />
        </div>

        <div className={SharedStyles.section}>
          <div className={SharedStyles.sectionHeading}>
            {productVerifyDetailProps.useCaseHeading}
          </div>
        </div>
        <UsecaseProduct useCases={useCases} useCaseImages={useCaseImages} />

        {!pathname.includes("face-match") &&
          !pathname.includes("face-liveness") && (
            <AddOns
              heading={"Supported Documents"}
              content={
                "ZOOPSTACK’s OCR API helps you scan and extract data from documents with reliability and ease. ZOOPSTACK’s OCR API can verify: "
              }
              features={[
                ["Government Issued Identity Cards", "Merchant Documents"],
                ["Cheque", ""],
              ]}
            />
          )}

        <div className={SharedStyles.faq}>
          <h1 className={SharedStyles.sectionHeading}>
            Frequently Asked Questions
          </h1>
          {/* <p style={{ color: "#9A9AA3", marginTop: "8px" }}>
          Sign up on our dashboard and test our APIs instantly for free.
        </p> */}
        </div>

        <div className="container">
          <div style={{ marginBottom: "100px" }}>
            <FaqList
              faqs={FaqProps.faqs}
              visibleFaqs={visibleFaqs}
              onLoadMore={loadMoreFaqs}
            />
          </div>

          <BannerCard
            text={"Ready to take your business to new heights?"}
            button={{ text: "Get free Demo", onClick: handleButtonClick }}
            tickImage={BannerTickWhite}
            backGroundClassName={Styles.bannerBackground}
          />
        </div>
        <SiteFooter />
      </div>
    </>
  );
}
