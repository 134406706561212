import React, { useEffect, useMemo, useState } from "react";
import Styles from "../site-footer/siteFooter.module.scss";
import ZoopStackLogo from "../../assets/images/zoopStackLogo.svg";
import ZoopStackLogoText from "../../assets/images/zoopStackLogoText.svg";
import Youtube from "../../assets/images/youtube.svg";
import Linkedin from "../../assets/images/linkedin.svg";
import Twitter from "../../assets/images/twitter.svg";
import Instagram from "../../assets/images/instagram.svg";
import ZoopLogo from "../../assets/images/zoopOneLogo.svg";
import iso1 from "../../assets/svg/ISO27001_2022.svg";
import iso2 from "../../assets/svg/ISO27017_2015.svg";
import iso3 from "../../assets/svg/ISO27018_2019.svg";
import iso4 from "../../assets/svg/ISO27701_2019.svg";
import { MenuButtonTriggered } from "../../Shared/eventsNames";
import { getBlogs } from "../../api/blog";
import useBlogStore from "../../store/blog";
import { useNavigate, useSearchParams } from "react-router-dom";
import { makeArrayBlogCategory } from "../../utils/helper";
import { BlogDetail } from "../../types/blog";
interface siteFooterProps {
  footerBackground?: string;
}
function siteFooter({ footerBackground }: siteFooterProps) {
  const navigate = useNavigate();
  const {
    blogs,
    setBlogs,
    categories,
    setCategories,
    blogLoading,
    setBlogLoading,
  } = useBlogStore();

  const [searchParams, setSearchParams] = useSearchParams(location.search);
  const [selectedCategory, setSelectedCategory] = useState(
    searchParams.get("category") || "all"
  );
  const fetchBlogs = async () => {
    setBlogLoading(true);
    try {
      const res = await getBlogs();
      if (res.data) {
        setBlogs(res.data.zoopStackBlogs);
        const categories = makeArrayBlogCategory(res.data.zoopStackBlogs);
        setCategories(["All", ...categories]);
      }
    } catch (err: any) {
      console.log(err);
    } finally {
      setBlogLoading(false);
    }
  };
  useEffect(() => {
    if (blogs?.length === 0) fetchBlogs();
  }, []);

  const filteredBlog = useMemo(() => {
    if (selectedCategory === "all") return blogs;
    return blogs?.filter((blog) =>
      blog.category
        .toLowerCase()
        .includes(selectedCategory.replaceAll("_", " "))
    );
  }, [selectedCategory, blogs]);
  const recentblogs = filteredBlog.slice(0, 5);
  return (
    <div className={`${footerBackground} ${Styles.footerMain}`}>
      <div className={Styles.footerMainContent}>
        <div>
          <img className="mr-1" src={ZoopStackLogo} />
          <img src={ZoopStackLogoText} />
          <div className={Styles.footerZoopTextMain}>
            <div className={Styles.footerZoopText}>Vertical of</div>
            <div>
              <img className="ml-2" src={ZoopLogo} />
            </div>
          </div>
          <div className={Styles.partialBorder}></div>
          <p className={Styles.footerZoopTextMain}>
            Tower B, Panchsheel Business Park,
            <br />
            Clover Park, Viman Nagar,
            <br />
            Pune, Maharashtra 411014
            <br />
            <br />
            sales@zoop.one
          </p>
          <div>
            <a
              href="https://twitter.com/zoop_one"
              onClick={() =>
                MenuButtonTriggered({
                  properties: "Footer Twitter MenuButton",
                })
              }
            >
              <img className="m-2" src={Twitter} />
            </a>
            <a
              href="https://www.instagram.com/zoopwallet/"
              onClick={() =>
                MenuButtonTriggered({
                  properties: "Footer Instagram MenuButton",
                })
              }
            >
              <img className="m-2" src={Instagram} />
            </a>
            <a
              href="https://www.linkedin.com/company/zoopone/mycompany/verification/"
              onClick={() =>
                MenuButtonTriggered({
                  properties: "Footer LinkedIn MenuButton",
                })
              }
            >
              <img className="m-2" src={Linkedin} />
            </a>
          </div>
          <div className={Styles.zoopVertical}>
            <a
              href="#"
              className={`${Styles.footerFeatureName} ${Styles.footerLink}`}
              onClick={() =>
                MenuButtonTriggered({
                  properties: "Footer ZoopStack Clicked",
                })
              }
            >
              <p>ZOOPSTACK</p>
            </a>
            <a
              href="https://wallet.zoop.one"
              className={`${Styles.footerFeatureName} ${Styles.footerLink}`}
              onClick={() =>
                MenuButtonTriggered({
                  properties: "Footer ZoopWallet Clicked",
                })
              }
            >
              <p>Zoop Wallet</p>
            </a>
            <a
              href="https://zoopsign.com"
              className={`${Styles.footerFeatureName} ${Styles.footerLink}`}
              onClick={() =>
                MenuButtonTriggered({
                  properties: "Footer ZoopSign Clicked",
                })
              }
            >
              <p>Zoop Sign</p>
            </a>
          </div>
          <div>
            <div className={`mt-5 ${Styles.footerDetailHeading}`}>
              ISO Certified
            </div>
            <div className={Styles.partialBorder}></div>
            <div className={`mb-5 opacity-75 ${Styles.isoImages}`}>
              <img className="mr-2" src={iso1} height={50} width={50} />
              <img className="m-2" src={iso2} height={50} width={50} />
              <img className="m-2" src={iso3} height={50} width={50} />
              <img className="m-2" src={iso4} height={50} width={50} />
            </div>
          </div>
        </div>

        <div className={Styles.footerDetailsContainer}>
          <div className={Styles.footerMenu}>
            <div className={Styles.footerDetailContent}>
              <div className={Styles.footerDetailHeading}>
                Identity Verification
              </div>
              <div className={Styles.partialBorder}></div>
              <a
                href="/product/identity/pan-verification-api"
                className={Styles.footerLink}
                onClick={() =>
                  MenuButtonTriggered({
                    properties: "Footer Identity-PAN MenuButton",
                  })
                }
              >
                <p>PAN</p>
              </a>

              <a
                href="/product/identity/compliancecheckforsection206ab&206cca"
                className={Styles.footerLink}
                onClick={() =>
                  MenuButtonTriggered({
                    properties: "Footer Identity-PAN206 MenuButton",
                  })
                }
              >
                <p>PAN 206AB & 206CCA</p>
              </a>

              <a
                href="/product/identity/aadhaar-api-verification"
                className={Styles.footerLink}
                onClick={() =>
                  MenuButtonTriggered({
                    properties: "Footer Identity-Aadhaar MenuButton",
                  })
                }
              >
                <p>Aadhaar</p>
              </a>

              <a
                href="/product/identity/voterid-verification-api"
                className={Styles.footerLink}
                onClick={() =>
                  MenuButtonTriggered({
                    properties: "Footer Identity-VoterID MenuButton",
                  })
                }
              >
                <p>Voter ID</p>
              </a>

              <a
                href="/product/identity/driving-license-verification-api"
                className={Styles.footerLink}
                onClick={() =>
                  MenuButtonTriggered({
                    properties: "Footer Identity-DrivingLicense MenuButton",
                  })
                }
              >
                <p>Driving License</p>
              </a>

              <a
                href="/product/identity/passport-verification-api"
                className={Styles.footerLink}
                onClick={() =>
                  MenuButtonTriggered({
                    properties: "Footer Identity-Passport MenuButton",
                  })
                }
              >
                <p>Passport</p>
              </a>
              <a
                href="/product/identity/epfo-verification-api"
                className={Styles.footerLink}
                onClick={() =>
                  MenuButtonTriggered({
                    properties: "Footer Identity-EPFO MenuButton",
                  })
                }
              >
                <p>EPFO</p>
              </a>
            </div>

            <div>
              <div
                className={`${Styles.footerDetailContent} ${Styles.nestedMenu}`}
              >
                <div className={Styles.footerDetailHeading}>
                  Financial Verification
                </div>
                <div className={Styles.partialBorder}></div>
                <a
                  href="/product/financial/bank-account-verification-api"
                  className={Styles.footerLink}
                  onClick={() =>
                    MenuButtonTriggered({
                      properties: "Footer Financial-Bank Account MenuButton",
                    })
                  }
                >
                  <p>Bank Account</p>
                </a>

                <a
                  href="/product/financial/upi-verification-api"
                  className={Styles.footerLink}
                  onClick={() =>
                    MenuButtonTriggered({
                      properties: "Footer Financial-UPI MenuButton",
                    })
                  }
                >
                  <p>UPI</p>
                </a>

                <a
                  href="/product/financial/ifsc-verification-api"
                  className={Styles.footerLink}
                  onClick={() =>
                    MenuButtonTriggered({
                      properties: "Footer Financial-IFSC MenuButton",
                    })
                  }
                >
                  <p>IFSC</p>
                </a>
              </div>
              <div className={Styles.footerDetailContent}>
                <div className={Styles.footerDetailHeading}>
                  Digital Contracting
                </div>
                <div className={Styles.partialBorder}></div>
                <a
                  href="/product/aadhar-esign-api"
                  className={Styles.footerLink}
                  onClick={() =>
                    MenuButtonTriggered({
                      properties:
                        "Footer Digital Contracting-Aadhaar Esign MenuButton",
                    })
                  }
                >
                  <p>Aadhaar eSign</p>
                </a>
              </div>
            </div>

            <div className={Styles.footerDetailContent}>
              <div className={Styles.footerDetailHeading}>
                Merchant Verification
              </div>
              <div className={Styles.partialBorder}></div>
              <a
                href="/product/merchant/gst-verification-api"
                className={Styles.footerLink}
                onClick={() =>
                  MenuButtonTriggered({
                    properties: "Footer Merchant-GST MenuButton",
                  })
                }
              >
                <p>GST</p>
              </a>

              <a
                href="/product/merchant/business-profiling-verification-api"
                className={Styles.footerLink}
                onClick={() =>
                  MenuButtonTriggered({
                    properties: "Footer Merchant-Business Profiling MenuButton",
                  })
                }
              >
                <p>Business Profiling</p>
              </a>

              <a
                href="/product/merchant/cin-verification-api"
                className={Styles.footerLink}
                onClick={() =>
                  MenuButtonTriggered({
                    properties: "Footer Merchant-CIN MenuButton",
                  })
                }
              >
                <p>CIN</p>
              </a>

              <a
                href="/product/merchant/fssai-verification-api"
                className={Styles.footerLink}
                onClick={() =>
                  MenuButtonTriggered({
                    properties: "Footer Merchant-FSSAI MenuButton",
                  })
                }
              >
                <p>FSSAI</p>
              </a>

              <a
                href="/product/merchant/udyam-aadhaar-verification-api"
                className={Styles.footerLink}
                onClick={() =>
                  MenuButtonTriggered({
                    properties: "Footer Merchant-Udyam Aadhaar MenuButton",
                  })
                }
              >
                <p>Udyam Aadhaar</p>
              </a>
            </div>

            <div>
              <div
                className={`${Styles.footerDetailContent} ${Styles.nestedMenu}`}
              >
                <div className={Styles.footerDetailHeading}>
                  ML & AI Solutions
                </div>
                <div className={Styles.partialBorder}></div>
                <a
                  href="/product/utility/ocr-verification-api"
                  className={Styles.footerLink}
                  onClick={() =>
                    MenuButtonTriggered({
                      properties: "Footer Utility-OCR MenuButton",
                    })
                  }
                >
                  <p>OCR</p>
                </a>

                <a
                  href="/product/utility/face-match-api"
                  className={Styles.footerLink}
                  onClick={() =>
                    MenuButtonTriggered({
                      properties: "Footer Utility-Face Match MenuButton",
                    })
                  }
                >
                  <p>Face Match</p>
                </a>

                <a
                  href="/product/utility/face-liveness-verification"
                  className={Styles.footerLink}
                  onClick={() =>
                    MenuButtonTriggered({
                      properties: "Footer Utility-Face Liveness MenuButton",
                    })
                  }
                >
                  <p>Face Liveness</p>
                </a>
              </div>
              <div className={Styles.footerDetailContent}>
                <div className={Styles.footerDetailHeading}>
                  Persona Verification
                </div>
                <div className={Styles.partialBorder}></div>
                <a
                  href="/persona/details"
                  className={Styles.footerLink}
                  onClick={() =>
                    MenuButtonTriggered({
                      properties: "Footer Persona-Student MenuButton",
                    })
                  }
                >
                  <p>Student</p>
                </a>
              </div>
            </div>
          </div>

          <div className={Styles.footerMenu}>
            <div className={Styles.footerDetailContent}>
              <div className={Styles.footerDetailHeading}>Products</div>
              <div className={Styles.partialBorder}></div>
              <a
                href="/product/identity"
                className={Styles.footerLink}
                onClick={() =>
                  MenuButtonTriggered({
                    properties: "Footer Identity MenuButton",
                  })
                }
              >
                <p>Identity Verification</p>
              </a>

              <a
                href="/product/financial"
                className={Styles.footerLink}
                onClick={() =>
                  MenuButtonTriggered({
                    properties: "Footer Financial MenuButton",
                  })
                }
              >
                <p> Financial Verification</p>
              </a>

              <a
                href="/product/merchant"
                className={Styles.footerLink}
                onClick={() =>
                  MenuButtonTriggered({
                    properties: "Footer Merchant MenuButton",
                  })
                }
              >
                <p> Merchant Verification</p>
              </a>

              <a
                href="/product/aadhar-esign-api"
                className={Styles.footerLink}
                onClick={() =>
                  MenuButtonTriggered({
                    properties: "Footer Digital Contracting MenuButton",
                  })
                }
              >
                <p> Digital Contacting</p>
              </a>

              <a
                href="/product/utility"
                className={Styles.footerLink}
                onClick={() =>
                  MenuButtonTriggered({
                    properties: "Footer Utility MenuButton",
                  })
                }
              >
                Utility Services
              </a>

              <a
                href="/persona"
                className={Styles.footerLink}
                onClick={() =>
                  MenuButtonTriggered({
                    properties: "Footer Persona MenuButton",
                  })
                }
              >
                Persona Verification
              </a>
            </div>
            {/* Industry */}
            <div className={Styles.footerDetailContent}>
              <div className={Styles.footerDetailHeading}>Industry</div>
              <div className={Styles.partialBorder}></div>
              <a
                href="/industry/gaming"
                className={Styles.footerLink}
                onClick={() =>
                  MenuButtonTriggered({
                    properties: "Footer Gaming MenuButton",
                  })
                }
              >
                <p> Gaming</p>
              </a>

              <a
                href="/industry/retail"
                className={Styles.footerLink}
                onClick={() =>
                  MenuButtonTriggered({
                    properties: "Footer Retail MenuButton",
                  })
                }
              >
                <p> Retail</p>
              </a>

              <a
                href="/industry/travel"
                className={Styles.footerLink}
                onClick={() =>
                  MenuButtonTriggered({
                    properties: "Footer Travel MenuButton",
                  })
                }
              >
                <p> Travel</p>
              </a>
            </div>

            {/* Blogs */}
            <div
              className={`${Styles.footerDetailContent} ${Styles.footerDefinedWidth}`}
            >
              <div className={Styles.footerDetailHeading}>Knowledge Hub</div>
              <div className={Styles.partialBorder}></div>
              {/* <div className={Styles.linkDiv}>
                <a
                  href="https://stack.zoop.one/blogs/64cf9a47f20ff60025b4b9e9/regtech:-revolutionizing-risk-mitigation-and-compliance-in-business"
                  className={Styles.footerLink}
                  onClick={() =>
                    MenuButtonTriggered({
                      properties: "Footer Blog MenuButton",
                    })
                  }
                >
                  Regtech: Revolutionizing Risk Mitigation and Compliance in
                  Business
                </a>
              </div>
              <div className={Styles.linkDiv}>
                <a
                  href="https://stack.zoop.one/blogs/64c93e0cf20ff60025b4b9bd/aadhar-based-esign:-a-complaint-way-of-signing-digital-loan-documents"
                  className={Styles.footerLink}
                  onClick={() =>
                    MenuButtonTriggered({
                      properties: "Footer Blog MenuButton",
                    })
                  }
                >
                  Aadhar-based eSign: A Complaint way of signing digital loan
                  documents
                </a>
              </div>
              <div className={Styles.linkDiv}>
                <a
                  href="https://stack.zoop.one/blogs/64c93e94f20ff60025b4b9be/how-online-gaming-is-turning-into-an-easy-opportunity-for-fraudsters"
                  className={Styles.footerLink}
                  onClick={() =>
                    MenuButtonTriggered({
                      properties: "Footer Blog MenuButton",
                    })
                  }
                >
                  How Online Gaming is turning into an easy opportunity for
                  Fraudsters
                </a>
              </div>
              <div className={Styles.linkDiv}>
                <a
                  href="https://stack.zoop.one/blogs/64c93bddf20ff60025b4b9bb/what-is-bank-account-verification?-the-methods-used-for-bank-account-verification"
                  className={Styles.footerLink}
                  onClick={() =>
                    MenuButtonTriggered({
                      properties: "Footer Blog MenuButton",
                    })
                  }
                >
                  What is Bank Account Verification? The Methods Used for Bank
                  Account Verification
                </a>
              </div> */}
              {recentblogs.map((blog: BlogDetail, index: number) => (
                <div className={Styles.otherDetailRecentBlogs}>
                  <div
                    key={blog.id}
                    className={`${Styles.otherRecentBlogs}`}
                    onClick={() => {
                      navigate(
                        `/blogs/${blog.id}/${blog.title
                          .trim()
                          .toLowerCase()
                          .replaceAll(" ", "-")}`
                      );
                    }}
                  >
                    <div
                      className={`p-2 ${Styles["blogCard__card-body"]}`}
                      style={{ cursor: "pointer" }}
                    >
                      <p className={`${Styles.blogsTitle}`}>{blog.title}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className={Styles.footerDetailContent}>
              <div className={Styles.footerDetailHeading}>Company</div>
              <div className={Styles.partialBorder}></div>
              <a
                href="/contactus"
                className={Styles.footerLink}
                onClick={() =>
                  MenuButtonTriggered({
                    properties: "Footer ContactUs MenuButton",
                  })
                }
              >
                <p> Contact Us</p>
              </a>
              <a
                href="/terms-and-conditions"
                className={Styles.footerLink}
                onClick={() =>
                  MenuButtonTriggered({
                    properties: "Footer Terms and Condition MenuButton",
                  })
                }
              >
                <p> Terms and conditions</p>
              </a>
              <a
                href="/privacy-policy"
                className={Styles.footerLink}
                onClick={() =>
                  MenuButtonTriggered({
                    properties: "Footer Privacy Policy MenuButton",
                  })
                }
              >
                <p> Privacy Policy</p>
              </a>
              <a
                href="/subprocessors"
                className={Styles.footerLink}
                onClick={() =>
                  MenuButtonTriggered({
                    properties: "Footer subprocessors MenuButton",
                  })
                }
              >
                <p>Subprocessors</p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={Styles.footerCompanyContent}>
        <a className={`${Styles.footerCompanyName} ${Styles.footerLink}`}>
          ©Quagga Tech Pvt. Ltd. All rights reserved.
        </a>
      </div>
    </div>
  );
}

export default siteFooter;
