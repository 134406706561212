import React, { ReactNode } from "react";
import Styles from "../menu-hover-button/menuHoverButton.module.scss";

type menuHoverButtonProps = {
  icon: ReactNode;
  hoverBtnHeading: any;
  hoverBtnContent: string;
  headingClassName?: string;
  link?: string;
  menuHoverClass?: string;
};
export default function menuHoverButton(props: menuHoverButtonProps) {
  const {
    icon,
    hoverBtnHeading,
    hoverBtnContent,
    headingClassName,
    link,
    menuHoverClass,
  } = props;
  return (
    <a className={Styles.menuLink} href={link}>
      <div className={`${menuHoverClass} ${Styles.menuHover}`}>
        {icon}
        <div className={Styles.submenuHeading}>
          <div className={headingClassName}>{hoverBtnHeading}</div>
          <div className={Styles.subMenuHeadingContent}>{hoverBtnContent}</div>
        </div>
      </div>
    </a>
  );
}
