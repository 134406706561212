import React from "react";
import Styles from "./trustedPartner.module.scss";
import SharedStyles from "../../Shared/shared.module.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { logoImagesTop, logoImagesMiddle, logoImagesBottom } from "../constant";
import MobileView from "./mobileView";
import useScreen from "../../utils/useScreen";

type ImageCardTypes = {
  src: string;
};

const ImageCard = ({ src }: ImageCardTypes) => {
  return (
    <div className={Styles.partnerImgCard}>
      <img src={src} height="50px" alt={src} />
    </div>
  );
};

export default function TrustedPartner() {
  const { isTablet } = useScreen();
  const settings = {
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 6,
    autoplay: true,
    speed: 20000,
    autoplaySpeed: 8,
    easing: "ease-in-out",
    pauseOnHover: false,
  };
  const settings1 = {
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 6,
    autoplay: true,
    speed: 20000,
    autoplaySpeed: 8,
    easing: "ease-in-out",
    pauseOnHover: false,
  };
  return (
    <div className={Styles.partnerContainer}>
      {isTablet ? (
        <MobileView />
      ) : (
        <>
          <p className={SharedStyles.sectionHeading}>Trusted Partners</p>
          <div className={`${Styles.partnerImageContainer}`}>
            <Slider {...settings} className={Styles.imagesContainer}>
              {logoImagesTop.map((logo, index) => (
                <div key={index}>
                  <ImageCard src={logo} />
                </div>
              ))}
            </Slider>
            <div className={Styles.middleCarousel}>
              <Slider {...settings1} className={Styles.imagesContainer}>
                {logoImagesMiddle.map((logo, index) => (
                  <div key={index}>
                    <ImageCard src={logo} />
                  </div>
                ))}
              </Slider>
            </div>
            <Slider {...settings} className={Styles.imagesContainer}>
              {logoImagesBottom.map((logo, index) => (
                <div key={index}>
                  <ImageCard src={logo} />
                </div>
              ))}
            </Slider>
          </div>
        </>
      )}
    </div>
  );
}
