import React, { useEffect } from "react";
import DashboardRouter from "./DashboardRouter";
import Hotjar from "@hotjar/browser";
import ReactGA from "react-ga";
import { GA4Init } from "../src/analytics/google";
import "./Shared/common.module.scss";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-loading-skeleton/dist/skeleton.css";

const App: React.FC = () => {
  const siteId = 3615993;
  const hotjarVersion = 6;
  GA4Init();
  Hotjar.init(siteId, hotjarVersion);
  useEffect(() => {
    // if(trackingId){
    ReactGA.initialize(`${process.env.REACT_APP_TRACKED_ID}`);
    ReactGA.pageview(window.location.pathname + window.location.search);
    // }
  }, []);

  return (
    <div>
      <head>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-K9TD64051Z"
        ></script>
        <script>
          <script
            dangerouslySetInnerHTML={{
              __html: `window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', ${process.env.REACT_APP_TRACKED_ID}, { page_path: window.location.pathname });
      `,
            }}
          />
        </script>
      </head>
      <DashboardRouter />
    </div>
  );
};

export default App;
