import React from "react";
import Skeleton from "react-loading-skeleton";
import Styles from "./blogLandingPage.module.scss";

export default function BlogPageSkeleton() {
  return (
    <div className={Styles.section1}>
      <div className={Styles.headerImage}>
        <Skeleton height={500} width="100%" borderRadius={20} />
        <div style={{ marginTop: 20 }}>
          <Skeleton height={20} />
          <div style={{ marginTop: 15 }}>
            {" "}
            <Skeleton count={10} />
          </div>
          <div style={{ marginTop: 25 }}>
            <Skeleton count={10} />
          </div>
        </div>
      </div>
      <div className={Styles.categoryContainer}>
        <div className={Styles.headingContainer}>
          <Skeleton height={30} width={200} />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Skeleton count={5} width={130} style={{ marginTop: 15 }} />
          <Skeleton count={5} width={20} style={{ marginTop: 15 }} />
        </div>
        <div style={{ marginTop: 45 }}>
          <Skeleton height={30} width={200} />
        </div>
        <div
          className={Styles.tagsDetailContainer}
          style={{ gap: 10, marginTop: 10 }}
        >
          {new Array(7).fill(0).map(() => (
            <Skeleton width={130} />
          ))}
        </div>
      </div>
    </div>
  );
}
