import React from "react";
import Skeleton from "react-loading-skeleton";
import styles from "./knowledgeHub.module.scss";

export default function BlogGridSekeleton() {
  return (
    <>
      <div className={styles.blogsCategory}>
        <div>
          {new Array(5).fill(0).map(() => (
            <Skeleton width={100} />
          ))}
        </div>
      </div>
      <div className={styles.grid}>
        {new Array(3).fill(0).map(() => (
          <div style={{ width: "20rem" }}>
            <Skeleton height={240} borderRadius={20} />
            <div className={styles["blogCard__card-body"]}>
              <Skeleton count={3} />
              <Skeleton height={10} width={150} />
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
