import React, { useEffect, useRef, useState } from "react";
import Styles from "./retail.module.scss";
import SiteHeader from "../../../Shared/site-header/siteHeader";
import SiteFooter from "../../../Shared/site-footer/siteFooter";
import { googlePageView, googleEvent } from "../../../analytics/google";
import GAEvent from "../../../analytics/events";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";
import HomeInfoCarousel from "../../../Shared/home-info-carousel/homeInfoCarousel";
import BrandMarquee from "../../../Shared/brand-marquee/brandMarquee";
import DividerImage from "../../../assets/images/industry/gaming/divider.svg";
import Clients from "../../../assets/images/clients.svg";
import DocumentVerified from "../../../assets/images/documentVerified.svg";
import IncreaseInSales from "../../../assets/images/increase_in_sales.svg";
import FraudLossesImage from "../../../assets/images/fraud_losses.svg";
import RetailDisplayImage1 from "../../../assets/images/industry/retail/retail_asset1.webp";
import RetailDisplayImage2 from "../../../assets/images/industry/retail/retail_asset2.webp";
import RetailDisplayImage3 from "../../../assets/images/industry/retail/retail_asset3.webp";
import RetailDisplayImage4 from "../../../assets/images/industry/retail/retail_asset4.webp";
import RetailDisplayImage5 from "../../../assets/images/industry/retail/retail_asset5.webp";
import BannerCard from "../../../Shared/banner-card/bannerCard";
import BannerTickMark from "../../../assets/images/bannerTickWhite.svg";
import ImpactCards, {
  StatsCardContainer,
} from "../../impact-cards/impactCardComp";
import SharedStyles from "../../../Shared/shared.module.scss";
import Button from "../../../Shared/button/button";
import { LARGE } from "../../../Shared/buttonSize";
import {
  primaryLightButtonHoveredStyle,
  primaryLightTransButtonStyle,
} from "../../../Shared/buttonStyles";
import {
  GetAPIKeysTriggered,
  BannerCardButtonTriggered,
  PageScrollTriggered,
} from "../../../Shared/eventsNames";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function Retail() {
  googlePageView("/retail", "Retail");
  const sliderRef = useRef<Slider>(null);
  const [activeDotIndex, setActiveDotIndex] = useState<number>(0);
  const handleBeforeChange = (currentSlide: number, nextSlide: number) => {
    setActiveDotIndex(nextSlide);
  };
  useEffect(() => {
    const handleScroll = () => {
      PageScrollTriggered({ properties: "Retail Page Scrolled" });
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleButtonClick = () => {
    BannerCardButtonTriggered({
      properties: "BannerCard Button on Retail Page",
    });
  };
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    beforeChange: handleBeforeChange,
  };

  const slidesData = [
    {
      heading: "Retail",
      headingClassName: Styles.heading1,
    },
  ];

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>ZOOPSTACK</title>
          <meta
            name="description"
            content="Safeguard your retail business with ZOOPSTACK's advanced fraud detection & prevention solutions. Ensure trust through business verification processes"
          />
          <meta
            name="title"
            content="Easy Detection and Prevention of Fraud - ZOOPSTACK"
          />
          <meta
            name="Keywords"
            content="detection and prevention of fraud, business verify, customer verification"
          />
        </Helmet>
      </HelmetProvider>

      <div className={SharedStyles.mainContainer}>
        <SiteHeader page="retail" />
        <div className={Styles.fullBleedContainer}>
          {/** Primary Container */}
          <div className={Styles.homeInfoMainContainer}>
            <Slider {...settings} ref={sliderRef}>
              {slidesData.map((slide, index) => (
                <HomeInfoCarousel
                  key={index}
                  heading={slide.heading}
                  headingClassName={slide.headingClassName}
                />
              ))}
            </Slider>
            <div className={` ${Styles.homeInfoContent}`}>
              Assure increased conversion rates and reduced checkout abandonment
              with advanced API created to foster the verification process. Zoop
              Stack offers a wide range of solutions to boost the retail
              ecosystem
            </div>
            <a style={{ marginTop: "50px" }} href="/contactus">
              <Button
                hoveredStyle={primaryLightButtonHoveredStyle}
                size={LARGE}
                style={primaryLightTransButtonStyle}
                onCllck={() =>
                  googleEvent(
                    "Button_Click",
                    GAEvent.GetAPIKEYS,
                    GAEvent.GetAPIKEYS
                  )
                }
              >
                Get API Keys
              </Button>
            </a>
          </div>
        </div>
        <div className={Styles.brandMarqueeContainer}>
          <BrandMarquee
            colorState={Styles.retailMarqueecolor}
            faderColorL={Styles.retailMarqueeL}
            faderColorR={Styles.retailMarqueeR}
            // page="retail"
          />
        </div>
        <div className={Styles.gamingBackground}>
          <div className={Styles.retailMainContainer}>
            <div className={Styles.retailSection1}>
              <div className={Styles.retailSectionTextContainer}>
                <div className={Styles.retailTextHeading}>
                  Verify Businesses & the People Behind
                </div>
                <div className={Styles.retailTextContent}>
                  Now identify businesses instantly with ZOOPSTACK’s API.
                  Acknowledge the authenticity of the business willing to
                  partner with you by verifying CIN, PAN, GST, FSSAI, and Udhyog
                  Aadhar.
                </div>
              </div>
              <div className={Styles.businessImage}>
                <img src={RetailDisplayImage1} className={Styles.image1} />
              </div>
            </div>
            <div className="row flex-column-reverse flex-md-row align-items-center mt-5">
              <div className={`col-12 col-md-6 ${Styles.retailSection2}`}>
                <img src={RetailDisplayImage2} className={Styles.image2} />
              </div>

              <div className={`col-12 col-md-6 ${Styles.retailSection2}`}>
                <div className={Styles.retailSectionTextContainer2}>
                  <div className={Styles.retailTextHeading}>
                    Save Time, Say Goodbye to Paperwork
                  </div>
                  <div className={Styles.retailTextContent}>
                    With ZOOPSTACK’s Advanced APIs verifying business,
                    distributors are super easy. The APIs cancel out the need
                    for the old-school methodologies of manually filling in the
                    forms with the GST, CIN details etc. to verify the
                    businesses
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${Styles.retailSection1} ${Styles.retailSection3}`}
            >
              <div className={`mt-5 ${Styles.retailSectionTextContainer}`}>
                <div className={Styles.retailTextHeading}>
                  Conversion Optimization
                </div>
                <div
                  className={`${Styles.retailTextContent2} ${Styles.retailTextContent}`}
                >
                  Instantly and neatly populate business data  (from their GST,
                  CIN) into the checkout pages increasing conversion rates and
                  reducing abandonment. Verifying businesses helps create secure
                  and healthy ties amongst both the parties involved in the
                  business.
                </div>
              </div>
              <div className={Styles.retailSection3Image}>
                <img className={Styles.image3} src={RetailDisplayImage3} />
              </div>
            </div>
            <div className={`row ${Styles.retailSection4}`}>
              <div className="col-12 col-md-6">
                <img className={Styles.image4} src={RetailDisplayImage4} />
              </div>
              <div
                className={`col-12 col-md-6 ${Styles.retailSectionTextContainer}`}
              >
                <div className={Styles.retailTextHeading}>Fraud Prevention</div>
                <div className={Styles.retailTextContent}>
                  Conducting due diligence checks on companies, and knowing the
                  financial and non-financial information before entering into
                  business relations or partnerships is essential to prevent
                  fraud. Your business should align with only authentic
                  businesses, ensuring growth in all horizons.
                </div>
              </div>
            </div>
            <div className={Styles.retailTextImage}>
              <img className={Styles.image5} src={RetailDisplayImage5} />
            </div>
            <div className={`${Styles.retailImpactCard} container`}>
              <StatsCardContainer
                backgroundClassName={"col-12 col-sm-6 col-md-3 gy-3 mb-5"}
                headingDisplay={Styles.impactHeading}
                isTransparent
                impactCardsData={[
                  {
                    image: Clients,
                    alt: "Drop Off Rate",
                    title: "300+",
                    subtitle: "Number of Clients",
                  },
                  {
                    image: DocumentVerified,
                    alt: "Reduction in Turn Around Time",
                    title: "27M+",
                    subtitle: "Document Verified",
                  },
                  {
                    image: IncreaseInSales,
                    alt: "Saved In Fraud Losses",
                    title: "99.8%",
                    subtitle: "Success Rate",
                  },
                  {
                    image: FraudLossesImage,
                    alt: "Increase In Productivity of Sales",
                    title: "46%",
                    subtitle: "Increase in Productivity of Sales",
                  },
                ]}
              />
            </div>
            <div className="container">
              <BannerCard
                text={"Ready to take your business to new heights?"}
                button={{ text: "Get free Demo", onClick: handleButtonClick }}
                backGroundClassName={Styles.bannerBackGroundcolor}
                headingClassName={Styles.bannerHeading}
                tickMarkTextClassName={Styles.bannerTickText}
                tickImage={BannerTickMark}
              />
            </div>
          </div>
        </div>
        <SiteFooter footerBackground={Styles.footeBackgroundColor} />
      </div>
    </>
  );
}
