import React from "react";

export default function DownArrow({
  color = "#0A0971",
  width = "12",
  height = "8",
  classNames = "",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames}
    >
      <path
        id="np_caret-down_1916333_000000 copy 7"
        d="M6.00048 7.13281C5.77301 7.13338 5.55477 7.04105 5.39441 6.87639L0.251295 1.62697C-0.0836264 1.28533 -0.0837852 0.731251 0.25094 0.389408C0.585665 0.0475637 1.12852 0.0474015 1.46344 0.389045L6.00048 5.01986L10.5368 0.389045C10.8715 0.0474015 11.4142 0.0474015 11.749 0.389045C12.0837 0.730689 12.0837 1.2846 11.749 1.62625L6.60584 6.87566C6.44548 7.04032 6.22724 7.13266 5.99977 7.13209L6.00048 7.13281Z"
        fill={color}
      />
    </svg>
  );
}
