import React from "react";

export default function Biometric({
  color = "#FFFFFF",
  width = "27",
  height = "30",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.60841 2.14833C3.65406 2.12141 8.2354 -0.515498 12.9946 2.15191C13.0441 2.17963 13.0982 2.19285 13.1513 2.19285C13.2633 2.19285 13.3718 2.13449 13.4306 2.03051C13.5175 1.87705 13.4628 1.68267 13.3086 1.59628C8.2329 -1.24904 3.33105 1.5709 3.28197 1.59986C3.12999 1.6895 3.07963 1.885 3.1697 2.03643C3.25946 2.18786 3.45582 2.23846 3.60841 2.14833Z"
        fill={color}
        stroke={color}
        stroke-width="0.4"
      />
      <path
        d="M1.05313 6.02567C0.955568 6.17244 0.996218 6.37026 1.14352 6.46738C1.19792 6.50303 1.2592 6.52015 1.31986 6.52015C1.42367 6.52015 1.52563 6.46986 1.58724 6.37712C3.0275 4.20805 5.32583 2.92571 7.89341 2.85909C10.6117 2.78485 13.1558 4.07575 14.8734 6.3908C14.9785 6.53229 15.1789 6.56279 15.3212 6.45772C15.4635 6.35314 15.4938 6.15359 15.3888 6.01196C13.5448 3.52712 10.8109 2.14562 7.87651 2.22174C5.09725 2.29395 2.61006 3.68042 1.05313 6.02567Z"
        fill={color}
        stroke={color}
        stroke-width="0.4"
      />
      <path
        d="M14.5189 11.6566C15.6446 10.1628 14.2409 7.59775 14.17 7.47153C13.0295 5.70124 11.2356 4.52832 9.24873 4.25329C7.27405 3.9806 5.32656 4.58729 3.76495 5.96273C0.679247 8.67979 2.30027 13.4137 2.37095 13.614C2.42941 13.7799 2.61173 13.868 2.77902 13.8093C2.94599 13.751 3.03357 13.5692 2.97508 13.403C2.95946 13.358 1.42255 8.87637 4.18898 6.44023C5.60892 5.1901 7.37537 4.63789 9.16055 4.88473C10.962 5.13408 12.5915 6.20241 13.6202 7.79791C13.6327 7.82081 14.8839 10.1098 14.0067 11.2741C13.5608 11.8658 12.8307 12.1724 12.1428 12.0549C11.4986 11.9446 11.0223 11.4966 10.8016 10.7934C10.3547 9.36929 9.85068 8.19046 8.189 8.19046H8.18836C7.28968 8.22875 5.86534 8.81677 5.85597 10.2302C5.82845 10.5712 5.57986 14.9627 10.5852 16.2447C10.6118 16.2515 10.6387 16.2548 10.6652 16.2548C10.8078 16.2548 10.9379 16.1594 10.9751 16.0154C11.0192 15.845 10.916 15.6712 10.7447 15.6274C6.10924 14.44 6.47666 10.4476 6.4945 10.2787C6.49574 10.2674 6.49638 10.2557 6.49638 10.2442C6.49638 9.22922 7.65491 8.85132 8.20213 8.82765C9.33161 8.82765 9.71341 9.46316 10.1906 10.9836C10.482 11.913 11.154 12.5324 12.0342 12.6833C12.9545 12.8408 13.9304 12.438 14.5189 11.6566Z"
        fill={color}
        stroke={color}
        stroke-width="0.4"
      />
      <path
        d="M8.33054 9.90429C8.15074 9.88483 7.99719 10.0135 7.97874 10.1885C7.85367 11.3907 8.39306 12.6371 9.42185 13.5224C10.2583 14.2421 11.283 14.6284 12.2921 14.6284C12.6082 14.6284 12.9228 14.5906 13.2296 14.5131C13.4009 14.4698 13.5047 14.2964 13.4613 14.1257C13.4175 13.9549 13.2427 13.8517 13.072 13.8952C11.9891 14.1692 10.7808 13.8491 9.84087 13.04C8.97001 12.2908 8.51222 11.2494 8.61603 10.2542C8.63417 10.0791 8.50627 9.92251 8.33054 9.90429Z"
        fill={color}
        stroke={color}
        stroke-width="0.4"
      />
      <path
        d="M8.27234 6.87971H8.46936C10.2373 6.87971 11.768 8.19257 12.0294 9.93361L12.1095 10.4659C12.1357 10.6399 12.2952 10.7613 12.4737 10.7339C12.6485 10.7079 12.7689 10.5456 12.743 10.3716L12.6629 9.83927C12.3549 7.78868 10.5519 6.24219 8.46936 6.24219H8.27234C6.83456 6.24219 5.50623 6.89218 4.62785 8.0254C3.74888 9.15944 3.45399 10.6047 3.81922 11.9907C4.2742 13.7178 5.18571 15.0376 6.52844 15.9133C6.58253 15.9486 6.64351 15.9654 6.70354 15.9654C6.80798 15.9654 6.91024 15.9147 6.97183 15.8213C7.06847 15.674 7.02719 15.4765 6.87927 15.38C5.6732 14.5934 4.85206 13.3987 4.43867 11.829C4.1241 10.6357 4.378 9.39133 5.13475 8.41483C5.89085 7.43924 7.03438 6.87971 8.27234 6.87971Z"
        fill={color}
        stroke={color}
        stroke-width="0.4"
      />
    </svg>
  );
}
