import React from "react";
import Styles from "../brand-marquee/brandMarquee.module.scss";
import { logoImages } from "../constant";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
interface brandMarqueeProps {
  colorState?: string;
  faderColorL?: string;
  faderColorR?: string;
  page?: string;
}
export default function BrandMarquee({
  colorState,
  faderColorL,
  faderColorR,
  page,
}: brandMarqueeProps) {
  type ImageCardTypes = {
    src: string;
  };
  const ImageCard = ({ src }: ImageCardTypes) => {
    return (
      <div className={Styles.partnerImgCard}>
        <img src={src} height="50px" alt={src} />
      </div>
    );
  };
  const settings = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    autoplay: true,
    speed: 12000,
    autoplaySpeed: 1,
    cssEase: "linear",
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3, // Adjust the number of logos shown at different breakpoints
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className={`${Styles.slider} ${colorState} `}>
      <div className={`${Styles.fader} ${Styles.L} ${faderColorL}`}></div>
      <div className={`${Styles.partnerImageContainer}`}>
        <Slider {...settings}>
          {logoImages.map((logo, index) => (
            <div key={index}>
              <ImageCard src={logo} />
            </div>
          ))}
        </Slider>
      </div>
      <div className={`${Styles.fader} ${Styles.R} ${faderColorR}`}></div>
    </div>
  );
}
