import React, { useEffect, useState } from "react";
import Styles from "./mechantVerification.module.scss";
import { googlePageView } from "../../analytics/google";
import SiteHeader from "../../Shared/site-header/siteHeader";
import SiteFooter from "../../Shared/site-footer/siteFooter";
import ProductVerify from "../../Shared/product-verify/productVerify";
import ButtonCarousel from "../../Shared/button-carousel/buttonCarousel";
import ProductVerifyDetail from "../../Shared/product-verify-detail/productVerifyDetail";
import SharedStyles from "../../Shared/shared.module.scss";
import BusinessCardComp from "../../Shared/business-card/businessCardComp";
import AutomateWork from "../../assets/images/automateWork.svg";
import StopFraud from "../../assets/images/stopFraud.svg";
import ReliableVerify from "../../assets/images/reliableVerify.svg";
import RealTimeVerify from "../../assets/images/realTimeVerify.svg";
import { FaqList } from "../../Shared/faq/faq";
import BannerCard from "../../Shared/banner-card/bannerCard";
import UsecaseProduct from "../../Shared/usecase-product/usecaseProduct";
import BannerTickWhite from "../../assets/images/bannerTickWhite.svg";
import ProductMerchantBackground from "../../assets/images/stack-offerings/merchant.webp";
import BusinessVerification from "../../assets/images/businessVerification.svg";
import MerchantOnboarding from "../../assets/images/merchantOnboarding.svg";
import LineOfCredit from "../../assets/images/lineOfCredit.svg";
import UseCaseImage1 from "../../assets/images/usecaseImages/business_verification.webp";
import UseCaseImage2 from "../../assets/images/usecaseImages/merchant_onboarding1.webp";
import UseCaseImage3 from "../../assets/images/usecaseImages/line_of_credit.webp";
import GstImage from "../../assets/images/products/merchant/gst.png";
import BusinessReportingImage from "../../assets/images/products/merchant/business_reporting.png";
import CinImage from "../../assets/images/products/merchant/cin.png";
import FssaiImage from "../../assets/images/products/merchant/fssai.png";
import menuIcon1 from "../../assets/images/menu_slider_icons/blackIcons/merchant/gst.svg";
import menuIcon2 from "../../assets/images/menu_slider_icons/blackIcons/merchant/business_report.svg";
import menuIcon3 from "../../assets/images/menu_slider_icons/blackIcons/merchant/cin.svg";
import menuIcon4 from "../../assets/images/menu_slider_icons/blackIcons/merchant/fssai.svg";
import menuIcon5 from "../../assets/images/menu_slider_icons/blackIcons/merchant/udyam_aadhaar.svg";
import menuIcon1White from "../../assets/images/menu_slider_icons/whiteIcons/merchant/gst.svg";
import menuIcon2White from "../../assets/images/menu_slider_icons/whiteIcons/merchant/business_report.svg";
import menuIcon3White from "../../assets/images/menu_slider_icons/whiteIcons/merchant/cin.svg";
import menuIcon4White from "../../assets/images/menu_slider_icons/whiteIcons/merchant/fssai.svg";
import menuIcon5White from "../../assets/images/menu_slider_icons/whiteIcons/merchant/udyam_aadhaar.svg";
import UdyamAadharImage from "../../assets/images/products/merchant/udyam_aadhaar.png";
import BrandMarquee from "../../Shared/brand-marquee/brandMarquee";
import {
  BannerCardButtonTriggered,
  GetAPIKeysTriggered,
  MenuSliderButtonTriggered,
  PageScrollTriggered,
} from "../../Shared/eventsNames";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function MerchantVerification() {
  googlePageView("/merchant-verification", "MerchantVerification");
  let newPathname = null;
  const pathname = window.location.pathname;
  const [visibleFaqs, setVisibleFaqs] = useState(5);
  const loadMoreFaqs = () => {
    setVisibleFaqs(visibleFaqs + 5);
  };
  const [refreshFaqList, setRefreshFaqList] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      PageScrollTriggered({
        properties: "Merchant Verification Page Scrolled",
      });
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const useCases = [
    {
      image: BusinessVerification,
      title: "Business Verification",
    },
    {
      image: MerchantOnboarding,
      title: "Merchant Onboarding",
    },
    {
      image: LineOfCredit,
      title: "Line of Credit",
    },
  ];
  const [metaDescription, setMetaDescription] = useState({
    title: "Quick GST Verification with ZOOPSTACK",
    desc: "Do GST verification | GST verification online with our GST Verification API. Ensure accuracy by conducting real-time GST verification for businesses. ",
    keyWords:
      "gst verification online, gst verification, verify gst, gst no check, gst number verification, gst no verification, gst check online, gst no check online",
  });
  const useCaseImages = [
    {
      mainImage: UseCaseImage1,
      image1: UseCaseImage2,
      image2: UseCaseImage3,
      mainImageClassName: Styles.mainImage,
      image1ClassName: Styles.image1,
      image2ClassName: Styles.image1,
    },
    {},
  ];
  const [FaqProps, setFaqProps] = useState({
    faqs: [
      {
        question: "What is a GST verification API?",
        answer:
          "A GST verification API is an application programming interface that enables businesses to verify the validity of a GST registration number in real-time using an online database of the GST network.",
      },
      {
        question: "How does a GST verification API work?",
        answer:
          "A GST verification API works by receiving a GST registration number as input and sending a request to the GST network database to verify its validity. It then returns a response indicating whether the registration number is valid or not.",
      },
      {
        question: "What are the benefits of using a GST verification API?",
        answer:
          "Using a GST verification API can help businesses to streamline their GST compliance processes, reduce the risk of fraud and errors, and improve the accuracy of their GST returns.",
      },
      {
        question:
          "How can businesses integrate a GST verification API into their systems?",
        answer:
          "Businesses can integrate a GST verification API into their systems by using the API's documentation and programming instructions to build custom integrations or by using pre-built software integrations.",
      },
      {
        question:
          "What types of businesses can benefit from a GST verification API?",
        answer:
          "Any business that is registered under GST or deals with GST-registered entities can benefit from a GST verification API, including e-commerce platforms, logistics providers, and financial institutions.",
      },
    ],
  });

  const [productVerifyProps, setProductVerifyProps] = useState({
    heading: "GST Verification API",
    subHeading:
      "GST verification API helps in onboarding genuine merchants and ensures they are active GSTIN holders. ZOOPSTACK’s GST verification API automates the verification process and onboard merchants seamlessly and in a frictionless manner. It cross-verifies the nature of the business activity the merchant is authorized on.  Most regulatory bodies advise using online GST verification API in commercial interactions to determine credibility.",
    buttonText: "Get API Keys",
    imgSrc: ProductMerchantBackground,
  });

  const [productVerifyDetailProps, setProductVerifyDetailProps] = useState({
    imageSrc: GstImage,
    heading: "How does GST Verification API work?",
    points: [
      "Sign up on the ZOOPSTACK dashboard",
      "Select the GST Verification API in the products section on our dashboard.",
      "Provide GST number as API input",
      "Instant GST verification is done and ready for transactions",
    ],
    buttonText: "Explore",
    productHeading: "Integrate GST Verification API",
    useCaseHeading: "Use cases of GST Verification API",
  });

  const [businessCardProps, setBusinessCardProps] = useState([
    {
      image: AutomateWork,
      cardTitle: "Automate Workflows",
      cardSubTitle:
        "No more reconciling data between multiple documents manually",
    },
    {
      image: StopFraud,
      cardTitle: "Stop Fraudsters",
      cardSubTitle:
        "Let only authentic customers access your services. Ensure your safety and prevent fraudulent activities",
    },
    {
      image: ReliableVerify,
      cardTitle: "Reliable Verification",
      cardSubTitle:
        "Verifies all the information provided by the customer and onboard the legit and accurate customers",
    },
    {
      image: RealTimeVerify,
      cardTitle: "Real-time Verification",
      cardSubTitle:
        "Save your time and effort by integrating the right APIs. The data is processed in a second, hence no more manual reviewing",
    },
  ]);

  const handleButtonClick = () => {
    BannerCardButtonTriggered({
      properties: "BannerCard Button on Merchant-Verification",
    });
  };

  const [activeButton, setActiveButton] = useState(0);

  const changeContent = (index: number) => {
    switch (index) {
      case 0:
        setMetaDescription({
          title: "Get GST Verification API Free - ZOOPSTACK",
          desc: "ZOOPSTACK's advanced GST verification API ensures accuracy by conducting real-time GST verification for businesses. With our GST Verification API, you can confidently onboard merchants knowing that they are genuine and are active GSTIN holders. ",
          keyWords:
            "gst verification online, gst verification, verify gst, gst no check, gst number verification, gst no verification, gst check online, gst no check online",
        });
        MenuSliderButtonTriggered({
          properties: "MenuSlider GST Button of Merchant Page ",
        });
        newPathname =
          pathname.substring(0, pathname.lastIndexOf("/")) +
          "/gst-verification-api";
        window.history.replaceState({}, "", `${newPathname}`);
        setProductVerifyDetailProps({
          imageSrc: GstImage,
          heading: "How does GST Verification API work?",
          points: [
            "Sign up on the ZOOPSTACK dashboard",
            "Select the GST Verification API in the products section on our dashboard.",
            "Provide GST number as API input",
            "Instant GST verification is done and ready for transactions",
          ],
          buttonText: "Explore",
          productHeading: "Integrate GST Verification API",
          useCaseHeading: "Uses cases of GST Verification API",
        });
        setProductVerifyProps({
          heading: "GST Verification API",
          subHeading:
            "GST verification API helps in onboarding genuine merchants and ensures they are active GSTIN holders. ZOOPSTACK’s GST verification API automates the verification process and onboard merchants seamlessly and in a frictionless manner. It cross-verifies the nature of the business activity the merchant is authorized on.  Most regulatory bodies advise using online GST verification API in commercial interactions to determine credibility.",
          buttonText: "Get API Keys",
          imgSrc: ProductMerchantBackground,
        });
        setFaqProps({
          faqs: [
            {
              question: "What is a GST verification API?",
              answer:
                "A GST verification API is an application programming interface that enables businesses to verify the validity of a GST registration number in real-time using an online database of the GST network.",
            },
            {
              question: "How does a GST verification API work?",
              answer:
                "A GST verification API works by receiving a GST registration number as input and sending a request to the GST network database to verify its validity. It then returns a response indicating whether the registration number is valid or not.",
            },
            {
              question:
                "What are the benefits of using a GST verification API?",
              answer:
                "Using a GST verification API can help businesses to streamline their GST compliance processes, reduce the risk of fraud and errors, and improve the accuracy of their GST returns.",
            },
            {
              question:
                "How can businesses integrate a GST verification API into their systems?",
              answer:
                "Businesses can integrate a GST verification API into their systems by using the API's documentation and programming instructions to build custom integrations or by using pre-built software integrations.",
            },
            {
              question:
                "What types of businesses can benefit from a GST verification API?",
              answer:
                "Any business that is registered under GST or deals with GST-registered entities can benefit from a GST verification API, including e-commerce platforms, logistics providers, and financial institutions.",
            },
          ],
        });
        break;
      case 1:
        setMetaDescription({
          title: "",
          desc: "",
          keyWords: "",
        });
        MenuSliderButtonTriggered({
          properties: "MenuSlider Report-Generation Button of Merchant Page ",
        });
        newPathname =
          pathname.substring(0, pathname.lastIndexOf("/")) +
          "/business-profiling-verification-api";
        window.history.replaceState({}, "", `${newPathname}`);
        setProductVerifyDetailProps({
          imageSrc: BusinessReportingImage,
          heading: "How does Business Profiling Verification API work?",
          points: [
            "Sign up on the ZOOPSTACK dashboard",
            "Select the Business Profiling Verification API in the products section on our dashboard.",
            "Provide PAN number as API input",
            "Instant Business Profiling verification is done and ready for transactions",
          ],
          buttonText: "Explore",
          productHeading: "Integrate Business Profiling Verification API",
          useCaseHeading: "Use cases of Business Profiling Verification API",
        });
        setProductVerifyProps({
          heading: "Business Profiling Verification API",
          subHeading:
            "Business Profiling Verification API is essential to know the credibility of the business you are willing to move forward with or become partners. ZOOPSTACK’s advanced API cuts the requirement of the laborious task of verifying the business details manually. All that is required for instant verification of businesses is the PAN details and the entire task can be performed in a fraction of a second. Do easy and quick Business Profiling with ZOOPSTACK’s advanced API",
          buttonText: "Get API Keys",
          imgSrc: ProductMerchantBackground,
        });
        setFaqProps({
          faqs: [
            {
              question: "What is merchant onboarding?",
              answer:
                "Merchant onboarding refers to the process of bringing a new merchant, which involves gathering information about the merchant, verifying their identity, and setting up their account.",
            },
            {
              question: "Why is merchant verification important?",
              answer:
                "Merchant verification is essential for ensuring that the merchant is legitimate, minimising the risk of fraud or money laundering, and complying with regulatory requirements.",
            },
            {
              question:
                "What documents are required for merchant verification?",
              answer:
                "Typically, merchants must provide government-issued identification, proof of address, and business documentation such as a business licence or tax ID.",
            },
            {
              question:
                "What is KYC and why is it important for merchant verification?",
              answer:
                "KYC (Know Your Customer) is a regulatory requirement that involves verifying the identity of customers or clients. It is important for merchant verification because it helps prevent financial crime and ensures compliance with regulations.",
            },
            {
              question: "What is a high-risk merchant?",
              answer:
                "A high-risk merchant is a business that is deemed to have a higher risk of chargebacks, fraud, or other financial issues.",
            },
          ],
        });
        break;
      case 2:
        setMetaDescription({
          title: "CIN Verification API | MCA Data API | ZOOPSTACK",
          desc: "ZOOPSTACK's advanced CIN verification API validate company or LLP details for identification of companies. Get a smarter, faster and safer way of verifying CIN numbers to understand the authenticity of companies.",
          keyWords:
            "cin number check, cin verification, cin number, cin number registration",
        });
        MenuSliderButtonTriggered({
          properties: "MenuSlider CIN Button of Merchant Page ",
        });
        newPathname =
          pathname.substring(0, pathname.lastIndexOf("/")) +
          "/cin-verification-api";
        window.history.replaceState({}, "", `${newPathname}`);
        setProductVerifyDetailProps({
          imageSrc: CinImage,
          heading: "How does CIN Verification API work?",
          points: [
            "Sign up on the ZOOPSTACK dashboard",
            "Select the CIN Verification API in the products section on our dashboard.",
            "Provide CIN number as API input",
            "Instant CIN verification is done and ready for transactions",
          ],
          buttonText: "Explore",
          productHeading: "Integrate CIN Verification API",
          useCaseHeading: "Use cases of CIN Verification API",
        });
        setProductVerifyProps({
          heading: "CIN Verification API",
          subHeading:
            "CIN Verification API is used to verify Corporate Identification Number. CIN is a 21 digits alphanumeric code which is allotted to companies formed in India across various states. This number is used to track basic information about the company. ZOOPSTACK’s CIN verification API is quick, feasible and delivers accurate results. It saves time, money and resources. Try ZOOPSTACK’s CIN verification API and ensure of the authenticity provided CIN.",
          buttonText: "Get API Keys",
          imgSrc: ProductMerchantBackground,
        });
        setFaqProps({
          faqs: [
            {
              question: "What is a CIN Verification API?",
              answer:
                "The CIN Verification API is an application programming interface that allows developers and businesses to programmatically verify the authenticity of a company by validating its Company Identification Number (CIN). It helps in confirming the legitimacy of a company and retrieving essential information about it.",
            },
            {
              question:
                "What information can be retrieved using the CIN Verification API?",
              answer:
                "The CIN Verification API typically provides information about a company, such as its name, registration date, type of company, registered address, and status of registration.",
            },
            {
              question: "Who can use the CIN Verification API?",
              answer:
                "The CIN Verification API can be used by businesses, financial institutions, government agencies, and developers who require accurate and real-time verification of a company's identity for various purposes like onboarding clients, conducting due diligence, and compliance checks.",
            },
            {
              question:
                "Is the CIN Verification API specific to a particular country?",
              answer:
                "Yes, the CIN Verification API is specifically designed for companies registered in India. The Company Identification Number is unique to each registered Indian company, and the API relies on data from the Indian Registrar of Companies.",
            },
            {
              question: "Is the CIN Verification API secure? ",
              answer:
                "Yes, ZOOPSTACK CIN Verification API implement security measures to ensure the privacy and integrity of the data exchanged through the API. It is essential to choose a trusted API provider that follows industry-standard security practices, such as encryption and authentication, to protect sensitive information.",
            },
            {
              question: "What are the use cases for the CIN Verification API?",
              answer:
                "The CIN Verification API can be used in various scenarios, including client onboarding, vendor verification, compliance checks, legal and financial due diligence, and any other situation where validating the authenticity of a company is necessary.",
            },
            {
              question:
                "What is the primary purpose of using the CIN Verification API?",
              answer:
                "The primary purpose of the CIN Verification API is to confirm the validity of CIN details for compliance, due diligence, and business transactions.",
            },
            {
              question: "How does the CIN Verification API work?",
              answer:
                "The CIN Verification API functions by checking the provided CIN against government databases to validate the company's registration information.",
            },
            {
              question:
                "What are the key benefits of using the CIN Verification API for businesses and organizations?",
              answer:
                "Benefits include accurate due diligence, compliance with regulations, reduced fraud risk, and improved confidence in business transactions.",
            },
            {
              question:
                "Is the use of the CIN Verification API mandatory for businesses and financial institutions?",
              answer:
                "While it may not always be mandatory, using the CIN Verification API is highly recommended to ensure the accuracy of company information and adherence to regulatory requirements.",
            },
            {
              question:
                "Are there data privacy concerns associated with using the CIN Verification API?",
              answer:
                "Data privacy concerns may arise if sensitive company data is processed, and users should follow data protection regulations when handling such information.",
            },
          ],
        });
        break;
      case 3:
        setMetaDescription({
          title: "FSSAI Verification API -  FSSAI License Check Online ",
          desc: "Verify FSSAI license check online effortlessly with our FSSAI Verification API. Conduct real-time FSSAI license check with ZOOPSTACK APIs. ",
          keyWords:
            "fssai license check, fssai verification, fssai license check online, fssai license verification",
        });
        MenuSliderButtonTriggered({
          properties: "MenuSlider FSSAI Button of Merchant Page ",
        });
        newPathname =
          pathname.substring(0, pathname.lastIndexOf("/")) +
          "/fssai-verification-api";
        window.history.replaceState({}, "", `${newPathname}`);
        setProductVerifyDetailProps({
          imageSrc: FssaiImage,
          heading: "How does FSSAI Verification API work?",
          points: [
            "Sign up on the ZOOPSTACK dashboard",
            "Select the FSSAI Verification API in the products section on our dashboard.",
            "Provide FSSAI number as API input",
            "Instant FSSAI verification is done and ready for transactions",
          ],
          buttonText: "Explore",
          productHeading: "Integrate FSSAI Verification API",
          useCaseHeading: "Use cases of FSSAI Verification API",
        });
        setProductVerifyProps({
          heading: "FSSAI Verification API",
          subHeading:
            "With ZOOPSTACK’s FSSAI verification API, make merchants’ onboarding process safer, faster, and smarter. It helps in verifying the legitimacy of merchants operating in the food business industry. FSSAI verification API ensures that merchants are authorized to partake in the manufacture, storage, distribution, or export of food products, as well as which products the merchant/ entity is authorized to operate on.",
          buttonText: "Get API Keys",
          imgSrc: ProductMerchantBackground,
        });
        setFaqProps({
          faqs: [
            {
              question: "What is the FSSAI Verification API? ",
              answer:
                "The FSSAI Verification API is a digital interface provided by the Food Safety and Standards Authority of India (FSSAI) that allows developers and businesses to programmatically verify the authenticity of food product licenses and registrations.",
            },
            {
              question: "How does the FSSAI Verification API work?",
              answer:
                "The FSSAI Verification API integrates into applications and systems, enabling real-time verification of FSSAI license or registration numbers. This helps ensure the validity of food businesses and products, enhancing consumer trust and safety.",
            },
            {
              question:
                "Can the FSSAI Verification API handle a high volume of verifications? ",
              answer:
                "Certainly, our FSSAI Verification API is equipped to handle both individual verifications and high volumes, making it suitable for businesses of varying sizes and compliance needs.",
            },
            {
              question:
                "What information can be obtained through the FSSAI Verification API?",
              answer:
                "The FSSAI Verification API provides essential details such as the validity status of an FSSAI license or registration, the type of license, and basic information about the food business operator.",
            },
            {
              question:
                "Who can benefit from using the FSSAI Verification API?",
              answer:
                "Food businesses, developers, retailers, and regulatory bodies can benefit from the FSSAI Verification API. Businesses can integrate it into their platforms to offer transparency to customers, while regulators can use it for compliance checks.",
            },
            {
              question: "What benefits does the FSSAI Verification API offer?",
              answer:
                "The FSSAI Verification API streamlines food safety compliance, reduces manual errors, speeds up verification processes, and enhances accuracy in record-keeping. It becomes your trusted ally in maintaining adherence to FSSAI standards.",
            },
            {
              question:
                "How secure is the data processed by the FSSAI Verification API?",
              answer:
                "Data security is paramount, our FSSAI Verification API employs robust encryption protocols to ensure the confidentiality of sensitive compliance information during transfer and processing.",
            },
            {
              question:
                "How does the FSSAI Verification API stay up-to-date with changes in FSSAI regulations?",
              answer:
                "Our FSSAI Verification API is designed to be adaptable. It can be updated to align with the latest FSSAI regulations, ensuring that your compliance verification remains accurate and up-to-date.",
            },
            {
              question:
                "How can our business start utilizing the FSSAI Verification API?",
              answer:
                "Initiating the utilization of the FSSAI Verification API is easy. Reach out to our team to discuss your business's compliance needs, and we'll guide you through the integration process. Say goodbye to manual compliance checks and embrace the future of streamlined food safety compliance.",
            },
          ],
        });
        break;
      case 4:
        setMetaDescription({
          title: "Udyam Aadhar Verification API | Secure Merchant Onboarding",
          desc: "ZOOPSTACK's can do Udyam Aadhar Verification API effortlessly. With our Udyam Aadhaar Verification API simplify merchant onboarding & streamline business verification. ",
          keyWords:
            "aadhar udyam verification, merchant onboarding, verify my business, company verification, business verification",
        });
        MenuSliderButtonTriggered({
          properties: "MenuSlider Udyam Aadhaar Button of Merchant Page ",
        });
        newPathname =
          pathname.substring(0, pathname.lastIndexOf("/")) +
          "/udyam-aadhaar-verification-api";
        window.history.replaceState({}, "", `${newPathname}`);
        setProductVerifyDetailProps({
          imageSrc: UdyamAadharImage,
          heading: "How does Udyam Aadhar Verification API work?",
          points: [
            "Sign up on the ZOOPSTACK dashboard",
            "Select the Udyam Aadhar Verification API in the products section on our dashboard.",
            "Provide Udyam Aadhar number as API input",
            "Instant Udyam Aadhar verification is done and ready for transactions",
          ],
          buttonText: "Explore",
          productHeading: "Integrate Udyam Aadhar Verification API",
          useCaseHeading: "Uses cases of Udyam Aadhar Verification API",
        });
        setProductVerifyProps({
          heading: "Udyam Aadhar Verification API",
          subHeading:
            "Udyam Aadhar Verification API is the legitimacy of the MSME merchants operating in the manufacturing or service sectors and make your onboarding process safer, faster, and smarter. ZOOPSTACK’s Udyam Aadhar verification API helps in onboarding genuine merchants who are authorized to partake in the manufacturing or service sector by the Ministry of MSME.",
          buttonText: "Get API Keys",
          imgSrc: ProductMerchantBackground,
        });
        setFaqProps({
          faqs: [
            {
              question: "What is the Udyam Aadhar Verification API?",
              answer:
                "The Udyam Aadhar Verification API is an application programming interface that allows businesses and developers to programmatically verify the Udyam Aadhar registration status of a Micro, Small, or Medium Enterprise (MSME) in India.",
            },
            {
              question: "What is Udyam Aadhar?",
              answer:
                "Udyam Aadhar is a unique 12-digit registration number issued by the Ministry of Micro, Small, and Medium Enterprises (MSME) to small businesses. It replaces the earlier system of EM-I/II and Udyog Aadhaar registration.",
            },
            {
              question: "Who can use the Udyam Aadhar Verification API? ",
              answer:
                "The Udyam Aadhar Verification API can be used by banks, financial institutions, government agencies, and other businesses to verify the Udyam Aadhar registration status of an MSME for various purposes, including lending, vendor validation, and compliance checks.",
            },
            {
              question:
                "What information can be verified using the Udyam Aadhar Verification API? ",
              answer:
                "The Udyam Aadhar Verification API typically provides information about the MSME, such as its Udyam Aadhar registration number, business name, address, date of registration, and category (Micro, Small, or Medium Enterprise).",
            },
            {
              question:
                "Is the Udyam Aadhar Verification API secure for merchant onboarding? ",
              answer:
                "ZOOPSTACK’s Udyam Aadhar Verification API implement security measures to ensure the privacy and integrity of the data exchanged through the API. It is essential to choose a trusted API provider that follows industry-standard security practices, such as encryption and authentication, to protect sensitive information.",
            },
            {
              question:
                "Is the Udyam Aadhar Verification API specific to a particular country? ",
              answer:
                "Yes, the Udyam Aadhar Verification API is specifically designed for verifying MSMEs registered under the Ministry of Micro, Small, and Medium Enterprises (MSME) in India. It relies on data from the Indian government's Udyam Registration portal.",
            },
            {
              question:
                "Can Udyam Aadhar Verification API be used for personal use?",
              answer:
                "The Udyam Aadhar Verification API is primarily intended for business and commercial use by banks, financial institutions, and enterprises. ",
            },
            {
              question:
                "What is the primary purpose of using the Udyam Aadhar Verification API?",
              answer:
                "The primary purpose of the Udyam Aadhar Verification API is to confirm the legitimacy of Udyam Aadhar registrations and facilitate compliance with government schemes and benefits.",
            },
            {
              question: "How does the Udyam Aadhar Verification API work?",
              answer:
                "The Udyam Aadhar Verification API works by cross-referencing the provided Udyam Aadhar number with the official government database to verify the registration status.",
            },
            {
              question:
                "Is the use of the Udyam Aadhar Verification API mandatory for businesses and government entities?",
              answer:
                "While it is not always mandatory, using the Udyam Aadhar Verification API is highly recommended for organizations to ensure the credibility of Udyam Aadhar registrations.",
            },
            {
              question:
                "Are there data privacy concerns associated with using the Udyam Aadhar Verification API?",
              answer:
                "Data privacy concerns may arise if sensitive information is collected, and users should adhere to data protection regulations when handling such data.",
            },
          ],
        });
        break;
    }
  };

  useEffect(() => {
    if (pathname.includes("gst")) {
      setActiveButton(0);
      changeContent(0);
    } else if (pathname.includes("profiling")) {
      setActiveButton(1);
      changeContent(1);
    } else if (pathname.includes("cin")) {
      setActiveButton(2);
      changeContent(2);
    } else if (pathname.includes("fssai")) {
      setActiveButton(3);
      changeContent(3);
    } else if (pathname.includes("udyam")) {
      setActiveButton(4);
      changeContent(4);
    } else {
      setActiveButton(0);
      changeContent(0);
    }
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>ZOOPSTACK</title>
          <meta name="description" content={metaDescription.desc} />
          <meta name="title" content={metaDescription.title} />
          <meta name="Keywords" content={metaDescription.keyWords} />
        </Helmet>
      </HelmetProvider>
      <div className={SharedStyles.mainContainer}>
        <SiteHeader />
        <div className={Styles.productFinancialBackground}>
          <div className={SharedStyles.menu}>
            <div className={`${Styles.navigation} js-btn-slide-container`}>
              <div
                className={`${Styles.navigation__btn} ${
                  activeButton === 0
                    ? `${Styles["navigation__btn--active"]} ${Styles.selected}`
                    : ""
                } js-btn-slide`}
                onClick={() => setActiveButton(0)}
                role="button"
              >
                <ButtonCarousel
                  buttonImgSrc={activeButton === 0 ? menuIcon1White : menuIcon1}
                  btnText="GST Verification"
                  onButtonClick={() => changeContent(0)}
                />
              </div>

              <div
                className={`${Styles.navigation__btn} ${
                  activeButton === 1
                    ? `${Styles["navigation__btn--active"]} ${Styles.selected}`
                    : ""
                } js-btn-slide`}
                onClick={() => setActiveButton(1)}
                role="button"
              >
                <ButtonCarousel
                  buttonImgSrc={activeButton === 1 ? menuIcon2White : menuIcon2}
                  btnText={"Business Profiling"}
                  onButtonClick={() => changeContent(1)}
                />
              </div>

              <div
                className={`${Styles.navigation__btn} ${
                  activeButton === 2
                    ? `${Styles["navigation__btn--active"]} ${Styles.selected}`
                    : ""
                } js-btn-slide`}
                onClick={() => setActiveButton(2)}
                role="button"
              >
                <ButtonCarousel
                  buttonImgSrc={activeButton === 2 ? menuIcon3White : menuIcon3}
                  btnText={"CIN Verification"}
                  onButtonClick={() => changeContent(2)}
                />
              </div>

              <div
                className={`${Styles.navigation__btn} ${
                  activeButton === 3
                    ? `${Styles["navigation__btn--active"]} ${Styles.selected}`
                    : ""
                } js-btn-slide`}
                onClick={() => setActiveButton(3)}
                role="button"
              >
                <ButtonCarousel
                  buttonImgSrc={activeButton === 3 ? menuIcon4White : menuIcon4}
                  btnText={"FSSAI Verification"}
                  onButtonClick={() => changeContent(3)}
                />
              </div>

              <div
                className={`${Styles.navigation__btn} ${
                  activeButton === 4
                    ? `${Styles["navigation__btn--active"]} ${Styles.selected}`
                    : ""
                } js-btn-slide`}
                onClick={() => setActiveButton(4)}
                role="button"
              >
                <ButtonCarousel
                  buttonImgSrc={activeButton === 4 ? menuIcon5White : menuIcon5}
                  btnText={"Udyam Aadhaar Verification"}
                  onButtonClick={() => changeContent(4)}
                />
              </div>
            </div>
          </div>

          <div className={Styles.verifySection}>
            <ProductVerify
              heading={productVerifyProps.heading}
              contentText={productVerifyProps.subHeading}
              buttonText={productVerifyProps.buttonText}
              onButtonClick={handleButtonClick}
              imgSrc={productVerifyProps.imgSrc}
              link={"/contactus"}
            />
          </div>
        </div>

        <div className={Styles.productVerify}>
          <ProductVerifyDetail
            imageSrc={productVerifyDetailProps.imageSrc}
            heading={productVerifyDetailProps.heading}
            points={[
              productVerifyDetailProps.points[0],
              productVerifyDetailProps.points[1],
              productVerifyDetailProps.points[2],
              productVerifyDetailProps.points[3],
            ]}
            buttonText={productVerifyDetailProps.buttonText}
            onButtonClick={() =>
              GetAPIKeysTriggered({
                properties: `GetAPIKeys on MerchantVerification Page for ${productVerifyProps.heading}`,
              })
            }
          />
        </div>

        <div className={"mt-5"}>
          <BrandMarquee />
        </div>

        <div className={SharedStyles.section}>
          <div className={SharedStyles.sectionHeading}>
            {productVerifyDetailProps.productHeading}
          </div>
          <div className={SharedStyles.sectionSubHeading}>
            Sign up on our dashboard and test our APIs instantly for free
          </div>
        </div>

        <div className={Styles.businessCards}>
          <BusinessCardComp
            image={businessCardProps[0].image}
            cardTitle={businessCardProps[0].cardTitle}
            cardSubTitle={businessCardProps[0].cardSubTitle}
          />
          <BusinessCardComp
            image={businessCardProps[1].image}
            cardTitle={businessCardProps[1].cardTitle}
            cardSubTitle={businessCardProps[1].cardSubTitle}
          />
          <BusinessCardComp
            image={businessCardProps[2].image}
            cardTitle={businessCardProps[2].cardTitle}
            cardSubTitle={businessCardProps[2].cardSubTitle}
          />
          <BusinessCardComp
            image={businessCardProps[3].image}
            cardTitle={businessCardProps[3].cardTitle}
            cardSubTitle={businessCardProps[3].cardSubTitle}
          />
        </div>

        <div className={SharedStyles.section}>
          <div className={SharedStyles.sectionHeading}>
            {productVerifyDetailProps.useCaseHeading}
          </div>
        </div>
        <UsecaseProduct useCases={useCases} useCaseImages={useCaseImages} />

        <div className={SharedStyles.faq}>
          <h1 className={SharedStyles.sectionHeading}>
            Frequently Asked Questions
          </h1>
          {/* <p style={{ color: "#9A9AA3", marginTop: "8px" }}>
          Sign up on our dashboard and test our APIs instantly for free.
        </p> */}
        </div>

        <div className="container">
          <div style={{ marginBottom: "100px" }}>
            <FaqList
              faqs={FaqProps.faqs}
              visibleFaqs={visibleFaqs}
              onLoadMore={loadMoreFaqs}
            />
          </div>

          <BannerCard
            text={"Ready to take your business to new heights?"}
            button={{ text: "Get free Demo", onClick: handleButtonClick }}
            tickImage={BannerTickWhite}
            backGroundClassName={Styles.bannerBackground}
          />
        </div>
        <SiteFooter />
      </div>
    </>
  );
}
