import React, { useState } from "react";
import Styles from "./sidebar.module.scss";
import logo from "../../assets/images/zoop_stack_logo_blue.svg";
import MenuHoverButton from "../menu-hover-button/menuHoverButton";

import { MenuButtonTriggered } from "../eventsNames";
import Biometric from "../../assets/svg/biometric";
import Rupee from "../../assets/svg/rupee";
import Bag from "../../assets/svg/bag";
import Contract from "../../assets/svg/contract";
import FaceScan from "../../assets/svg/faceScan";
import VerifiedPerson from "../../assets/svg/verifiedPerson";
import GameConsole from "../../assets/svg/gameConsole";
import Travel from "../../assets/svg/travel";
import Store from "../../assets/svg/store";
import DownArrow from "../../assets/svg/downArrow";

type SidebarProps = {
  classNames?: string;
  fullScreen?: boolean;
  showLogo?: boolean;
  backgroundColor?: "blue" | "white";
};

export default function Sidebar({
  classNames = "",
  fullScreen,
  showLogo = true,
  backgroundColor = "white",
}: SidebarProps) {
  const [showProducts, setShowProducts] = useState(false);
  const [showIndustry, setShowIndustry] = useState(false);
  const [activeButton, setActiveButton] = useState(-1);
  const [activeIndustryButton, setActiveIndustryButton] = useState(-1);

  const toggleProducts = () => {
    setShowProducts(!showProducts);
  };
  const toggleIndustry = () => {
    setShowIndustry(!showIndustry);
  };
  const changeBackground = (index: number) => {
    setActiveButton(index);
  };
  const changeIndustryBackground = (index: number) => {
    setActiveButton(index);
  };

  return (
    <div
      className={`${Styles.sidebarMain} ${
        fullScreen ? Styles.fullScreen : ""
      } ${classNames} ${
        backgroundColor === "blue" ? Styles.blueBackground : ""
      }`}
    >
      {showLogo && (
        <a
          href="\home"
          onClick={() =>
            MenuButtonTriggered({
              properties: "Sidebar Logo Clicked",
            })
          }
        >
          <img src={logo} alt="logo" />
        </a>
      )}
      <div
        className={showProducts ? Styles.sidebarMenuActive : Styles.sidebarMenu}
        onClick={() => toggleProducts()}
      >
        <div
          onClick={() =>
            MenuButtonTriggered({
              properties: "Sidebar Product MenuButton",
            })
          }
        >
          Products{" "}
        </div>
        <DownArrow
          color={backgroundColor === "blue" ? "white" : "#5D546A"}
          classNames={showProducts ? Styles.menuOpen : ""}
        />
      </div>
      {showProducts && (
        <div className={Styles.sidebarDropdown}>
          <a
            className={Styles.menuHoveLink}
            href="/product/identity"
            onClick={() =>
              MenuButtonTriggered({
                properties: "Sidebar Identity SubMenuButton",
              })
            }
          >
            <div
              className={`${activeButton === 1 ? Styles.selected : ""} ?  ${
                Styles.sidebarSubMenu
              }`}
              onMouseEnter={() => changeBackground(1)}
            >
              <MenuHoverButton
                icon={
                  <Biometric
                    color={backgroundColor === "blue" ? "white" : "#5D546A"}
                  />
                }
                hoverBtnHeading={"Identity Verification"}
                hoverBtnContent={
                  "Get a real-time check of Govt issued IDs of the users instantly."
                }
                headingClassName={Styles.headingClass}
              />
            </div>
          </a>
          <a
            className={Styles.menuHoveLink}
            href="/product/financial"
            onClick={() =>
              MenuButtonTriggered({
                properties: "Sidebar Financial SubMenuButton",
              })
            }
          >
            <div
              className={`${activeButton === 2 ? Styles.selected : ""} ?  ${
                Styles.sidebarSubMenu
              }`}
              onMouseEnter={() => changeBackground(2)}
            >
              <MenuHoverButton
                icon={
                  <Rupee
                    color={backgroundColor === "blue" ? "white" : "#5D546A"}
                  />
                }
                hoverBtnHeading={"Financial Verification"}
                hoverBtnContent={
                  "Verify & authenticate your users before starting any monetary transaction."
                }
                headingClassName={Styles.headingClass}
              />
            </div>
          </a>
          <a
            className={Styles.menuHoveLink}
            href="/product/merchant"
            onClick={() =>
              MenuButtonTriggered({
                properties: "Sidebar Merchant SubMenuButton",
              })
            }
          >
            <div
              className={`${activeButton === 3 ? Styles.selected : ""} ?  ${
                Styles.sidebarSubMenu
              }`}
              onMouseEnter={() => changeBackground(3)}
            >
              <MenuHoverButton
                icon={
                  <Bag
                    color={backgroundColor === "blue" ? "white" : "#5D546A"}
                  />
                }
                hoverBtnHeading={"Merchant Verification"}
                hoverBtnContent={
                  "Do business by knowing authenticity of other party’s business."
                }
                headingClassName={Styles.headingClass}
              />
            </div>
          </a>
          <a
            className={Styles.menuHoveLink}
            href="/product/aadhar-esign-api"
            onClick={() =>
              MenuButtonTriggered({
                properties: "Sidebar Digital Contracting SubMenuButton",
              })
            }
          >
            <div
              className={`${activeButton === 4 ? Styles.selected : ""} ?  ${
                Styles.sidebarSubMenu
              }`}
              onMouseEnter={() => changeBackground(4)}
            >
              <MenuHoverButton
                icon={
                  <Contract
                    color={backgroundColor === "blue" ? "white" : "#5D546A"}
                  />
                }
                hoverBtnHeading={"Digital Contracting"}
                hoverBtnContent={
                  "Get contracts signed in a fast, cost-effective, & location-independent way."
                }
                headingClassName={Styles.headingClass}
              />
            </div>
          </a>
          <a
            className={Styles.menuHoveLink}
            href="/product/utility"
            onClick={() =>
              MenuButtonTriggered({
                properties: "Sidebar Utility/ML-AI SubMenuButton",
              })
            }
          >
            <div
              className={`${activeButton === 5 ? Styles.selected : ""} ?  ${
                Styles.sidebarSubMenu
              }`}
              onMouseEnter={() => changeBackground(5)}
            >
              <MenuHoverButton
                icon={
                  <FaceScan
                    color={backgroundColor === "blue" ? "white" : "#5D546A"}
                  />
                }
                hoverBtnHeading={"ML/Ai Solutions"}
                hoverBtnContent={
                  "Do business by knowing authenticity of other party’s business."
                }
                headingClassName={Styles.headingClass}
              />
            </div>
          </a>
          <a
            className={Styles.menuHoveLink}
            href="/persona"
            onClick={() =>
              MenuButtonTriggered({
                properties: "Sidebar Persona SubMenuButton",
              })
            }
          >
            <div
              className={`${activeButton === 6 ? Styles.selected : ""} ?  ${
                Styles.sidebarSubMenu
              }`}
              onMouseEnter={() => changeBackground(6)}
            >
              <MenuHoverButton
                icon={
                  <VerifiedPerson
                    color={backgroundColor === "blue" ? "white" : "#5D546A"}
                  />
                }
                hoverBtnHeading={"Persona Verification"}
                hoverBtnContent={
                  "Facilitate verification of personas your business targets, with enhanced API tools."
                }
                headingClassName={Styles.headingClass}
              />
            </div>
          </a>
        </div>
      )}
      <div
        className={showIndustry ? Styles.sidebarMenuActive : Styles.sidebarMenu}
        onClick={() => toggleIndustry()}
      >
        <div
          onClick={() =>
            MenuButtonTriggered({
              properties: "Sidebar Industry MenuButton",
            })
          }
        >
          Industry{" "}
        </div>
        <DownArrow
          color={backgroundColor === "blue" ? "white" : "#5D546A"}
          classNames={showIndustry ? Styles.menuOpen : ""}
        />
      </div>
      {showIndustry && (
        <div className={Styles.sidebarDropdown}>
          <a
            className={Styles.menuHoveLink}
            href="/industry/gaming"
            onClick={() =>
              MenuButtonTriggered({
                properties: "Sidebar Gaming SubMenuButton",
              })
            }
          >
            <div
              className={`${
                activeIndustryButton === 1 ? Styles.selected : ""
              } ?  ${Styles.sidebarSubMenu}`}
              onMouseEnter={() => changeIndustryBackground(1)}
            >
              <MenuHoverButton
                icon={
                  <GameConsole
                    color={backgroundColor === "blue" ? "white" : "#5D546A"}
                  />
                }
                hoverBtnHeading={"Gaming"}
                hoverBtnContent={
                  "Ensure fraud-free, and secure gaming environment."
                }
                headingClassName={Styles.headingClass}
              />
            </div>
          </a>
          <a
            className={Styles.menuHoveLink}
            href="/industry/retail"
            onClick={() =>
              MenuButtonTriggered({
                properties: "Sidebar Retail SubMenuButton",
              })
            }
          >
            <div
              className={`${
                activeIndustryButton === 2 ? Styles.selected : ""
              } ?  ${Styles.sidebarSubMenu}`}
              onMouseEnter={() => changeIndustryBackground(2)}
            >
              <MenuHoverButton
                icon={
                  <Store
                    color={backgroundColor === "blue" ? "white" : "#5D546A"}
                  />
                }
                hoverBtnHeading={"Retail"}
                hoverBtnContent={
                  "Advanced APIs to verify authenticity of businesses."
                }
                headingClassName={Styles.headingClass}
              />
            </div>
          </a>
          <a
            className={Styles.menuHoveLink}
            href="/industry/travel"
            onClick={() =>
              MenuButtonTriggered({
                properties: "Sidebar Travel SubMenuButton",
              })
            }
          >
            <div
              className={`${
                activeIndustryButton === 3 ? Styles.selected : ""
              } ?  ${Styles.sidebarSubMenu}`}
              onMouseEnter={() => changeIndustryBackground(3)}
            >
              <MenuHoverButton
                icon={
                  <Travel
                    color={backgroundColor === "blue" ? "white" : "#5D546A"}
                  />
                }
                hoverBtnHeading={"Travel"}
                hoverBtnContent={
                  "Increase booking conversions with digital verification."
                }
                headingClassName={Styles.headingClass}
              />
            </div>
          </a>
        </div>
      )}
      <a
        href="/blogs"
        className={Styles.menuHoveLink}
        onClick={() =>
          MenuButtonTriggered({
            properties: "Sidebar Knowledge-Hub MenuButton",
          })
        }
      >
        <div className={Styles.sidebarMenu}>Knowledge Hub</div>
      </a>
      <a
        href="/contactus"
        className={Styles.menuHoveLink}
        onClick={() =>
          MenuButtonTriggered({
            properties: "Sidebar ContactUs MenuButton",
          })
        }
      >
        <div className={Styles.sidebarMenu}>Contact Us</div>
      </a>
    </div>
  );
}
