import React from "react";

export default function Contract({
  color = "#FFFFFF",
  width = "27",
  height = "27",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9091 16.0509H5.09091C4.92364 16.0466 4.86909 16.0241 4.77527 15.9792C4.32945 15.7656 4.22982 15.0474 4.66982 14.7361C4.792 14.65 4.94182 14.6073 5.09091 14.6029H10.9091C10.9229 14.6036 10.936 14.6036 10.9498 14.6044C11.4829 14.6485 11.848 15.3674 11.4516 15.8091C11.3331 15.9408 11.1658 16.027 10.9905 16.0465C10.9542 16.0502 10.9455 16.0502 10.9091 16.0509ZM15.2727 13.1549H0.727273C0.336727 13.1383 0.0167273 12.8204 0 12.4309V0.84704C0.0167273 0.458256 0.336 0.139699 0.727273 0.123047H15.2727C15.2938 0.123771 15.3149 0.125219 15.336 0.125943C15.6989 0.173003 15.984 0.477803 16 0.84704V9.53496C15.9978 9.5907 15.9985 9.60518 15.9891 9.66093C15.8895 10.2242 14.9789 10.4761 14.6429 9.89695C14.5847 9.79704 14.5542 9.74057 14.5455 9.53496V1.57103H1.45455V11.7069H15.2727C15.2735 11.7069 15.6313 11.7301 15.8298 11.9654C16.0604 12.2391 16.0436 12.6872 15.7869 12.9428C15.6822 13.047 15.5455 13.118 15.3993 13.1441C15.3433 13.1535 15.3287 13.1528 15.2727 13.1549Z"
        fill={color}
      />
      <path
        d="M3.63546 7.36257H6.54455C6.58673 7.36112 6.59764 7.36184 6.63982 7.35605C6.85146 7.32854 7.05873 7.15044 7.05873 7.15044L7.79982 6.41269L8.80346 8.41018C8.82382 8.44856 8.82818 8.45869 8.85291 8.49489C8.98236 8.68313 9.20564 8.80476 9.43473 8.81055C9.71618 8.81779 9.96782 8.59842 9.96782 8.59842L11.2093 7.36257H12.346C12.3896 7.36112 12.4013 7.36184 12.4449 7.35605C12.5598 7.34012 12.6704 7.29668 12.7649 7.23007C13.1904 6.93107 13.114 6.21938 12.6805 5.99567C12.5838 5.94571 12.5293 5.9211 12.346 5.91458H10.9082C10.866 5.91603 10.8551 5.9153 10.8129 5.9211C10.6013 5.94861 10.394 6.12671 10.394 6.12671L9.65291 6.86446L8.64927 4.86696C8.52927 4.64614 8.31327 4.50134 8.06673 4.46949C7.80709 4.45429 7.48491 4.67872 7.48491 4.67872L6.24346 5.91458H3.63546C3.59109 5.91603 3.58018 5.9153 3.53655 5.9211C3.42164 5.93702 3.31109 5.98046 3.21582 6.04707C2.77582 6.35621 2.86818 7.05849 3.30091 7.28148C3.39764 7.33143 3.45146 7.35605 3.63546 7.36257Z"
        fill={color}
      />
    </svg>
  );
}
