import React, { useEffect } from "react";
import Button from "../../Shared/button/button";
import {
  primaryLightButtonHoveredStyle,
  primaryLightTransButtonStyle,
} from "../buttonStyles";
import { MEDIUM } from "../buttonSize";
import Styles from "./calendlyPopup.module.scss";
import useScreen from "../../utils/useScreen";
export default function CalendlyPopup() {
  const { isMobile } = useScreen();
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div className={Styles.mainCelendlyContainer}>
      {isMobile && (
        <div className={Styles.textCalendly}>
          <div className={Styles.calendlyHeading}>Let's Talk Business</div>
          <div className={Styles.calendlyContent}>
            Want to know more?
            <br /> Get in touch with us and schedule a demo.
          </div>
          <a href="/contactus">
            <Button
              className={Styles.btn}
              hoveredStyle={primaryLightTransButtonStyle}
              size={MEDIUM}
              style={primaryLightButtonHoveredStyle}
            >
              Contact us
            </Button>
          </a>
        </div>
      )}
      {!isMobile && (
        <div className={Styles.bookingContainer}>
          <div className={Styles.bookingText}>Book a Demo</div>
          <div className={Styles.calendlyCalendar}>
            <div
              className="calendly-inline-widget"
              data-url="https://calendly.com/zoopsales/30min"
              style={{ minWidth: "1000px", height: "680px" }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
