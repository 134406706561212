import { useMediaQuery } from "react-responsive";

export default function useScreen() {
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const isTablet = useMediaQuery({
    query: "(max-width: 1024px)",
  });

  const isDesktop = useMediaQuery({
    query: "(min-width: 1025px)",
  });

  const isUltraWide = useMediaQuery({
    query: "(min-width: 1440px)",
  });

  const isExtraWide = useMediaQuery({
    query: "(min-width: 1800px)",
  });

  return {
    isMobile,
    isTablet,
    isDesktop,
    isUltraWide,
    isExtraWide,
  };
}
