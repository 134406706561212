import axios from "axios";

export const getBlogs = async () => {
  try {
    const resp = await axios("https://cms.zoop.one/graphql", {
      method: "post",
      data: {
        query: `query {
          zoopStackBlogs(sort: "published_at:desc") {
            title
            id
            published_at
            seo_title
            seo_description
            seo_keywords
            description
            cover_banner {
              id
              url
            }
            tags
            category
          }
        }`,
      },
    });
    return resp.data;
  } catch (err) {
    throw err;
  }
};
