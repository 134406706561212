import React from "react";

export default function ColorPersonVerified() {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.72467 0C5.59321 0 3.86234 1.73088 3.86234 3.86234C3.86234 5.99379 5.59321 7.72467 7.72467 7.72467C9.85613 7.72467 11.587 5.99379 11.587 3.86234C11.587 1.73088 9.85613 0 7.72467 0ZM7.72467 1.10352C9.24754 1.10352 10.4835 2.33947 10.4835 3.86234C10.4835 5.3852 9.24754 6.62115 7.72467 6.62115C6.20181 6.62115 4.96586 5.3852 4.96586 3.86234C4.96586 2.33947 6.20181 1.10352 7.72467 1.10352ZM1.10352 14.3458H7.72467C7.87101 14.3458 8.01135 14.4039 8.11483 14.5074C8.2183 14.6109 8.27643 14.7512 8.27643 14.8976C8.27643 15.0439 8.2183 15.1843 8.11483 15.2877C8.01135 15.3912 7.87101 15.4493 7.72467 15.4493H0.551762C0.405426 15.4493 0.265083 15.3912 0.161607 15.2877C0.0581319 15.1843 0 15.0439 0 14.8976V13.7941C0 12.477 0.523187 11.2139 1.45447 10.2827C2.38575 9.35138 3.64883 8.8282 4.96586 8.8282H7.72467C7.87101 8.8282 8.01135 8.88633 8.11483 8.9898C8.2183 9.09328 8.27643 9.23362 8.27643 9.37996C8.27643 9.52629 8.2183 9.66664 8.11483 9.77011C8.01135 9.87359 7.87101 9.93172 7.72467 9.93172H4.96586C3.9415 9.93172 2.9591 10.3386 2.23478 11.063C1.51045 11.7873 1.10352 12.7697 1.10352 13.7941V14.3458ZM12.4152 8.8282C11.4643 8.82863 10.5525 9.20657 9.88007 9.87897C9.20768 10.5514 8.82974 11.4632 8.8293 12.4141C8.82974 13.365 9.20768 14.2768 9.88007 14.9492C10.5525 15.6216 11.4643 15.9996 12.4152 16C13.3661 15.9996 14.2779 15.6216 14.9503 14.9492C15.6227 14.2768 16.0007 13.365 16.0011 12.4141C16.0007 11.4632 15.6227 10.5514 14.9503 9.87897C14.2779 9.20657 13.3661 8.82863 12.4152 8.8282ZM12.4152 9.93172C13.0735 9.93201 13.7047 10.1936 14.1702 10.6591C14.6357 11.1246 14.8973 11.7558 14.8976 12.4141C14.8973 13.0724 14.6357 13.7036 14.1702 14.1691C13.7047 14.6346 13.0735 14.8962 12.4152 14.8965C11.7569 14.8962 11.1257 14.6346 10.6602 14.1691C10.1947 13.7036 9.93312 13.0724 9.93282 12.4141C9.93312 11.7558 10.1947 11.1246 10.6602 10.6591C11.1257 10.1936 11.7569 9.93201 12.4152 9.93172Z"
        fill="#00006E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.186 12.45L13.3993 11.2024C13.4496 11.1496 13.5099 11.1073 13.5766 11.078C13.6434 11.0486 13.7153 11.0328 13.7882 11.0314C13.8611 11.03 13.9336 11.043 14.0014 11.0698C14.0693 11.0966 14.1311 11.1365 14.1834 11.1874C14.2357 11.2382 14.2774 11.2989 14.3061 11.366C14.3348 11.433 14.3499 11.5051 14.3505 11.578C14.3512 11.6509 14.3374 11.7233 14.3099 11.7908C14.2825 11.8584 14.2419 11.9198 14.1905 11.9716L12.5816 13.6258C12.5303 13.6788 12.4688 13.721 12.4008 13.7498C12.3329 13.7786 12.2598 13.7934 12.186 13.7934C12.1122 13.7934 12.0391 13.7786 11.9712 13.7498C11.9032 13.721 11.8417 13.6788 11.7904 13.6258L10.6405 12.4433C10.5891 12.3916 10.5486 12.3301 10.5211 12.2626C10.4936 12.195 10.4798 12.1227 10.4805 12.0498C10.4812 11.9768 10.4963 11.9048 10.525 11.8377C10.5536 11.7707 10.5953 11.71 10.6476 11.6591C10.6999 11.6083 10.7618 11.5683 10.8296 11.5416C10.8974 11.5148 10.9699 11.5017 11.0428 11.5031C11.1157 11.5045 11.1876 11.5203 11.2544 11.5497C11.3212 11.5791 11.3814 11.6214 11.4317 11.6742L12.186 12.45Z"
        fill="#0075F3"
      />
    </svg>
  );
}
