import React from "react";
import Styles from "../product-verify-detail/productVerifyDetail.module.scss";
import Button from "../../Shared/button/button";
import { MEDIUM } from "../../Shared/buttonSize";
import {
  primaryLightButtonHoveredStyle,
  primaryLightButtonStyle,
} from "../../Shared/buttonStyles";
import SharedStyles from "../shared.module.scss";
import useScreen from "../../utils/useScreen";
export interface ProductVerifyDetailProps {
  imageSrc: string;
  heading: string;
  points: string[];
  buttonText: string;
  onButtonClick: () => void;
}

export default function ProductVerifyDetail({
  imageSrc,
  heading,
  points,
  buttonText,
  onButtonClick,
}: ProductVerifyDetailProps) {
  return (
    <div className="container">
      <div className={`row ${Styles.mainProductVerify}`}>
        <div className={`m-4 mb-5 ${SharedStyles.sectionHeading}`}>
          {heading}
        </div>
        <div
          className={`col-12 col-sm-12 col-md-6 ${Styles.productVerifyImage}`}
        >
          <img src={imageSrc} />
        </div>
        <div
          className={`col-12 col-sm-12 col-md-6 ${Styles.productVerifyTextMain}`}
        >
          <div>
            <br />
            <div
              className={`${SharedStyles.sectionSubHeading} ${Styles.subHeading}`}
            >
              {points.map((point, index) => (
                <div className={Styles.points}>
                  <span className={Styles.productVerifyBullet}>
                    {index + 1}.
                  </span>
                  <p className={Styles.productVerifyPoints} key={index}>
                    {point}
                  </p>
                  <br />
                </div>
              ))}
            </div>
            <br />
            <a href="https://dashboard.zoop.one">
              <Button
                hoveredStyle={primaryLightButtonHoveredStyle}
                size={MEDIUM}
                style={primaryLightButtonStyle}
                type="button"
                onClick={onButtonClick}
              >
                {buttonText}
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
