import React from "react";
// import Head from "next/head";
import { siteMetaData } from "../../Shared/constant";

interface Props {
  title: string;
  description: string;
  keyWords: string;
}

const HeadSeo: React.FC<Props> = ({ title, description, keyWords }) => {
  return (
    <head>
      <title>{title}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="description" content={description} />
      <meta name="Keywords" content={keyWords} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:site_name" content={siteMetaData.companyName} />
    </head>
  );
};

export default HeadSeo;
