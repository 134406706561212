export const BACKGROUNDCOLOR = "#f5f5f5";
export const LINKHOVERBACKGROUND = "#e2f3ff";
export const AUTHSIDEBARGRADIENT = "linear-gradient(to top, #202020, #6a6a6a)";
export const LINKCOLOR = "#202020";
export const WHITE = "#fff";
export const PRIMARYCOLOR = "#202020";
export const SECONDARYCOLOR = "#747474";
export const ACCENTCOLOR = "#0075f3";
export const SUCCESS = "#0F9D58";
export const DANGER = "#D64747";
export const WARNING = "#f4a63b";
export const ACCENTDARKCOLOR = "#004F9F";
export const SUCCESSDARKCOLOR = "#008B47";
export const DANGERDARKCOLOR = "#B6393A";
export const BORDERCOLOR = "rgba(112, 112, 112, 0.2)";
export const DISABLEDCOLOR = "rgba(147, 147, 147, 0.1)";
export const GRAY = "#747474";
export const DISABLEDCOLORDARK = "#969696";
export const BLACKCOLOR = "#000000";

export const DARKBLUE = "#00006E";
export const DARKGREY = "#9A9AA3";
export const DARKPURPLE = "#363648";
