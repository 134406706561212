import brand1 from "../assets/images/brands/colorLogos/1.webp";
import brand2 from "../assets/images/brands/colorLogos/2.webp";
import brand3 from "../assets/images/brands/colorLogos/3.webp";
import brand4 from "../assets/images/brands/colorLogos/4.webp";
import brand5 from "../assets/images/brands/colorLogos/5.webp";
import brand6 from "../assets/images/brands/colorLogos/6.webp";
import brand7 from "../assets/images/brands/colorLogos/7.webp";
import brand8 from "../assets/images/brands/colorLogos/8.webp";
import brand9 from "../assets/images/brands/colorLogos/9.webp";
import brand10 from "../assets/images/brands/colorLogos/10.webp";
import brand11 from "../assets/images/brands/colorLogos/11.webp";
import brand12 from "../assets/images/brands/colorLogos/12.webp";
import brand13 from "../assets/images/brands/colorLogos/13.webp";
import brand14 from "../assets/images/brands/colorLogos/14.webp";
import brand15 from "../assets/images/brands/colorLogos/15.webp";
import brand16 from "../assets/images/brands/colorLogos/16.webp";
import brand17 from "../assets/images/brands/colorLogos/17.webp";
import brand18 from "../assets/images/brands/colorLogos/18.webp";
import brand19 from "../assets/images/brands/colorLogos/19.webp";
import brand20 from "../assets/images/brands/colorLogos/20.webp";
import brand21 from "../assets/images/brands/colorLogos/21.webp";
import brand22 from "../assets/images/brands/colorLogos/22.webp";
import brand23 from "../assets/images/brands/colorLogos/23.webp";
import brand24 from "../assets/images/brands/colorLogos/24.webp";
import brand25 from "../assets/images/brands/colorLogos/25.webp";
import brand26 from "../assets/images/brands/colorLogos/26.webp";
import brand27 from "../assets/images/brands/colorLogos/27.webp";
import brand28 from "../assets/images/brands/colorLogos/28.webp";
import brand29 from "../assets/images/brands/colorLogos/29.webp";
import brand30 from "../assets/images/brands/colorLogos/30.webp";
import brand31 from "../assets/images/brands/colorLogos/31.webp";
import brand32 from "../assets/images/brands/colorLogos/32.webp";
import brand33 from "../assets/images/brands/colorLogos/33.webp";
import brand34 from "../assets/images/brands/colorLogos/34.webp";

export const siteMetaData = {
  companyName: "ZoopOne",
  siteUrl: "https://stack.zoop.one/",
  siteLogo: "../public/images/zoop",
  email: "sales@zoop.one",
};

export const logoImages = [
  brand1,
  brand2,
  brand3,
  brand4,
  brand5,
  brand6,
  brand7,
  brand8,
  brand9,
  brand10,
  brand11,
  brand12,
  brand13,
  brand14,
  brand15,
  brand16,
  brand17,
  brand18,
  brand19,
  brand20,
  brand21,
  brand22,
  brand23,
  brand24,
  brand25,
  brand26,
  brand27,
  brand28,
  brand29,
  brand30,
  brand31,
  brand32,
  brand33,
  brand34,
];

export const logoImagesTop = [
  brand1,
  brand2,
  brand3,
  brand4,
  brand5,
  brand6,
  brand18,
  brand19,
  brand20,
  brand21,
  brand22,
  brand23,
];
export const logoImagesMiddle = [
  brand7,
  brand8,
  brand9,
  brand10,
  brand11,
  brand24,
  brand25,
  brand26,
  brand27,
  brand28,
];
export const logoImagesBottom = [
  brand12,
  brand13,
  brand14,
  brand15,
  brand16,
  brand17,
  brand29,
  brand30,
  brand31,
  brand32,
  brand33,
  brand34,
];
