import React from "react";

export default function ColorBag() {
  return (
    <svg
      width="23"
      height="25"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1333 3.2H9.06667V2.66667C9.06667 1.19627 7.8704 0 6.4 0H5.33333C3.86293 0 2.66667 1.19627 2.66667 2.66667V5.33333C2.66667 5.62827 2.9056 5.86667 3.2 5.86667C3.4944 5.86667 3.73333 5.62827 3.73333 5.33333V4.26667H6.93333C7.22773 4.26667 7.46667 4.02827 7.46667 3.73333C7.46667 3.4384 7.22773 3.2 6.93333 3.2H3.73333V2.66667C3.73333 1.78453 4.4512 1.06667 5.33333 1.06667H6.4C7.28213 1.06667 8 1.78453 8 2.66667V5.33333C8 5.62827 8.23893 5.86667 8.53333 5.86667C8.82773 5.86667 9.06667 5.62827 9.06667 5.33333V4.26667H10.1333C10.4272 4.26667 10.6667 4.5056 10.6667 4.8V14.4C10.6667 14.6944 10.4272 14.9333 10.1333 14.9333H1.6C1.30613 14.9333 1.06667 14.6944 1.06667 14.4V4.8C1.06667 4.5056 1.30613 4.26667 1.6 4.26667C1.8944 4.26667 2.13333 4.02827 2.13333 3.73333C2.13333 3.4384 1.8944 3.2 1.6 3.2C0.717867 3.2 0 3.91787 0 4.8V14.4C0 15.2821 0.717867 16 1.6 16H10.1333C11.0155 16 11.7333 15.2821 11.7333 14.4V4.8C11.7333 3.91787 11.0155 3.2 10.1333 3.2Z"
        fill="#00006E"
      />
      <path
        d="M5.17713 9.2232C4.9686 9.01466 4.63153 9.01466 4.423 9.2232C4.21447 9.43173 4.21447 9.7688 4.423 9.97733L5.48967 11.044C5.59367 11.148 5.7302 11.2003 5.86673 11.2003C6.00327 11.2003 6.1398 11.148 6.2438 11.044L8.91047 8.37733C9.119 8.1688 9.119 7.83173 8.91047 7.6232C8.70194 7.41466 8.36487 7.41466 8.15633 7.6232L5.86673 9.9128L5.17713 9.2232Z"
        fill="#0075F3"
      />
      <path
        d="M5.86712 6.40039C3.80846 6.40039 2.13379 8.07559 2.13379 10.1337C2.13379 12.1919 3.80846 13.8671 5.86712 13.8671C7.92579 13.8671 9.60046 12.1919 9.60046 10.1337C9.60046 9.83879 9.36152 9.60039 9.06712 9.60039C8.77272 9.60039 8.53379 9.83879 8.53379 10.1337C8.53379 11.6041 7.33752 12.8004 5.86712 12.8004C4.39672 12.8004 3.20046 11.6041 3.20046 10.1337C3.20046 8.66332 4.39672 7.46706 5.86712 7.46706C6.16152 7.46706 6.40046 7.22866 6.40046 6.93372C6.40046 6.63879 6.16152 6.40039 5.86712 6.40039Z"
        fill="#0075F3"
      />
    </svg>
  );
}
