import React, { useEffect, useRef, useState } from "react";
import Styles from "./home.module.scss";
import { useTitle } from "../../Shared/useTitle";
import { googlePageView, googleEvent } from "../../analytics/google";
import GAEvent from "../../analytics/events";
import SiteHeader from "../../Shared/site-header/siteHeader";
import { motion } from "framer-motion";
import StackOffering from "../stack-offering/stackOffering";
import SiteFooter from "../../Shared/site-footer/siteFooter";
import ImpactCards from "../impact-cards/impactCardComp";
import BusinessCards from "../../components/business-cards/businessCards";
import BannerCard from "../../Shared/banner-card/bannerCard";
import HomeInfoCarousel from "../../Shared/home-info-carousel/homeInfoCarousel";
import CarouselSlider from "../../Shared/carousel/carouselSlider";
import BannerTickMark from "../../assets/images/bannerTickWhite.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SharedStyles from "../../Shared/shared.module.scss";
import Button from "../../Shared/button/button";
import {
  primaryLightButtonHoveredStyle,
  primaryLightTransButtonStyle,
} from "../../Shared/buttonStyles";
import { LARGE, MEDIUM } from "../../Shared/buttonSize";
import {
  GetAPIKeysTriggered,
  BannerCardButtonTriggered,
  PageScrollTriggered,
} from "../../Shared/eventsNames";
import DropOffRateImage from "../../assets/images/drop_off_rate.svg";
import FraudLossesImage from "../../assets/images/fraud_losses.svg";
import IncreaseInSales from "../../assets/images/increase_in_sales.svg";
import StpImage from "../../assets/images/stp.svg";
import { Helmet, HelmetProvider } from "react-helmet-async";
import CookiePopup from "../../Shared/cookie-popup/cookiePopup";
import TrustedPartner from "../../Shared/trusted-partner/trustedPartner";
import useScreen from "../../utils/useScreen";
import ImpactContainer from "../impact-container/impactContainer";
import IsoBanner from "../../Shared/iso-banner/isoBanner";

const poppingEffect = {
  offscreen: {
    y: 50,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,

    transition: {
      duration: 0.3,
    },
  },
};

const MainSlider = () => {
  const [activeDotIndex, setActiveDotIndex] = useState<number>(0);
  googlePageView("/home", "Home");
  googleEvent("Home", "HomeClick", "Home Page");
  const handleBeforeChange = (currentSlide: number, nextSlide: number) => {
    setActiveDotIndex(nextSlide);
  };

  const handleDotClick = (index: number) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  };

  const sliderRef = useRef<Slider>(null);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    beforeChange: handleBeforeChange,
  };

  const slidesData = [
    {
      heading: "Individuals",
      headingClassName: Styles.heading1,
    },
    {
      heading: "Business",
      headingClassName: Styles.heading2,
    },
    {
      heading: "Persona",
      headingClassName: Styles.heading3,
    },
  ];

  return (
    <motion.div
      viewport={{ once: true }}
      initial="offscreen"
      whileInView="onscreen"
      className={Styles.fullBleedContainer}
    >
      <motion.div
        variants={poppingEffect}
        className={Styles.homeInfoMainContainer}
      >
        <div className={Styles.homeInfoSubHeading}>
          New-Age Verifications for
        </div>

        <Slider {...settings} ref={sliderRef}>
          {slidesData.map((slide, index) => (
            <HomeInfoCarousel
              key={index}
              heading={slide.heading}
              headingClassName={slide.headingClassName}
            />
          ))}
        </Slider>
        <div className={Styles.dotsMain}>
          {slidesData.map((slide, index) => (
            <div
              key={index}
              className={`${
                index === activeDotIndex ? Styles.activeDot : Styles.dotWrapper
              }`}
              onClick={() => handleDotClick(index)}
            >
              <div className={Styles.dots}></div>
            </div>
          ))}
        </div>
        <div className={` ${Styles.homeInfoContent}`}>
          The fastest and most advanced platform promising reduced customer
          drop-offs, frictionless, and successful onboarding journeys. Execute
          business in a fraud-proof way.
        </div>
        <a
          style={{ marginTop: "50px" }}
          href="/contactus"
          onClick={() =>
            GetAPIKeysTriggered({ properties: "GetAPIKeys on Home Page" })
          }
        >
          <Button
            hoveredStyle={primaryLightButtonHoveredStyle}
            size={window.innerWidth > 400 ? LARGE : MEDIUM}
            style={primaryLightTransButtonStyle}
            onCllck={() =>
              googleEvent(
                "Button_Click",
                GAEvent.GetAPIKEYS,
                GAEvent.GetAPIKEYS
              )
            }
          >
            Get API Keys
          </Button>
        </a>
      </motion.div>
    </motion.div>
  );
};

export default function Home() {
  const { isTablet, isMobile } = useScreen();

  useEffect(() => {
    const handleScroll = () => {
      PageScrollTriggered({ properties: "Homepage Scrolled" });
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleButtonClick = () => {
    BannerCardButtonTriggered({ properties: "BannerCard Button on Homepage" });
  };
  useTitle("ZOOPSTACK");

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta
            name="Keywords"
            content="customer onboarding, pan card verification, document verification, driving licence check, gst verification online, aadhaar verify, aadhar card verification, sign pdf online, face recognize, esign aadhaar, cin number check, passport number online check, passport verification, location tracking"
          />
        </Helmet>
      </HelmetProvider>
      <div className={SharedStyles.mainContainer}>
        <SiteHeader page="home" />

        <MainSlider />

        <motion.div
          viewport={{ once: true }}
          initial="offscreen"
          whileInView="onscreen"
        >
          <motion.div
            variants={poppingEffect}
            className={isTablet ? "mt-4" : "mt-5"}
          >
            <TrustedPartner />
          </motion.div>
        </motion.div>

        <motion.div
          viewport={{ once: true }}
          initial="offscreen"
          whileInView="onscreen"
        >
          <motion.div variants={poppingEffect}>
            <StackOffering />
          </motion.div>
        </motion.div>

        <motion.div
          viewport={{ once: true }}
          initial="offscreen"
          whileInView="onscreen"
        >
          <motion.div variants={poppingEffect}>
            <CarouselSlider />
          </motion.div>
        </motion.div>

        {isMobile ? (
          <>
            <motion.div
              viewport={{ once: true }}
              initial="offscreen"
              whileInView="onscreen"
            >
              <motion.div variants={poppingEffect}>
                <ImpactCards
                  backgroundClassName={"col-6 gy-3"}
                  headingDisplay={Styles.impactHeading}
                  impactCardsData={[
                    {
                      image: FraudLossesImage,
                      alt: "Saved In Fraud Losses",
                      title: "350cr",
                      subtitle: "Saved in Fraud Losses",
                    },
                    {
                      image: StpImage,
                      alt: "Reduction in Turn Around Time",
                      title: "67%",
                      subtitle: "Straight Through Processing",
                    },
                    {
                      image: DropOffRateImage,
                      alt: "Drop Off Rate",
                      title: "72%",
                      subtitle: "Reduced Drop-Off Rate",
                    },
                    {
                      image: IncreaseInSales,
                      alt: "Increase In Productivity of Sales",
                      title: "46%",
                      subtitle: "Increase in Productivity of Sales",
                    },
                  ]}
                />
              </motion.div>
            </motion.div>
            <motion.div
              viewport={{ once: true }}
              initial="offscreen"
              whileInView="onscreen"
            >
              <motion.div
                variants={poppingEffect}
                className={Styles.businessBackground}
              >
                <BusinessCards />
              </motion.div>
            </motion.div>
          </>
        ) : (
          <motion.div
            viewport={{ once: true }}
            initial="offscreen"
            whileInView="onscreen"
          >
            <motion.div variants={poppingEffect}>
              <ImpactContainer />
            </motion.div>
          </motion.div>
        )}

        <div className={Styles.bannerBackgroundMain}>
          <div className="container">
            <BannerCard
              text={"Ready to take your business to new heights?"}
              button={{ text: "Get free Demo", onClick: handleButtonClick }}
              backGroundClassName={Styles.bannerBackGroundcolor}
              headingClassName={Styles.bannerHeading}
              tickMarkTextClassName={Styles.bannerTickText}
              tickImage={BannerTickMark}
            />
          </div>
        </div>
        <div className={Styles.cookiePopup}>
          <CookiePopup />
        </div>
        <SiteFooter />
      </div>
    </>
  );
}
