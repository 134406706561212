import React from "react";

export default function FaceScan({
  color = "#FFFFFF",
  width = "27",
  height = "30",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.33532 12.5407L7.27333 13.2461C7.24362 13.5843 7.11609 13.9066 6.90617 14.1742C6.69625 14.4417 6.41295 14.643 6.09053 14.7535L2.61133 15.947M15.3931 15.947L11.9139 14.7535C11.5915 14.6429 11.3082 14.4417 11.0983 14.1742C10.8884 13.9066 10.7608 13.5843 10.7311 13.2461L10.6691 12.5407M12.1008 9.33266L11.8726 11.0963C11.8509 11.3397 11.7698 11.5742 11.6365 11.7793C11.5031 11.9845 11.3214 12.1541 11.1072 12.2734L9.66094 13.0793C9.48067 13.1797 9.27755 13.2324 9.07098 13.2324C8.86441 13.2324 8.66129 13.1797 8.48103 13.0793L7.03478 12.2734C6.82058 12.1541 6.63889 11.9845 6.50551 11.7793C6.37212 11.5742 6.29107 11.3397 6.26939 11.0963L6.03964 9.3206M5.89843 8.22924L5.82408 7.65479C5.66249 5.83978 7.09899 4.27734 8.92939 4.27734H9.21258C11.043 4.27734 12.4795 5.83975 12.3179 7.65479L12.2468 8.20393"
        stroke={color}
        stroke-width="1.3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.21413 1.64062H1V4.84026M17 4.84026V1.64062H13.7859M13.7859 17.5685H17V14.3688M1 14.3688V17.5685H4.21413"
        stroke={color}
        stroke-width="1.3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1 9.25195H17"
        stroke={color}
        stroke-width="1.3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
