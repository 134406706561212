import ReactGA from "react-ga4";

const GA4_ID = "G-K9TD64051Z";

const GA4Init = async () => {
  try {
    ReactGA.initialize(GA4_ID);
  } catch (error) {
    console.log("*** GA4Init Error ***", error);
  }
};

const googlePageView = (path: string, title: string) => {
  try {
    if (!ReactGA.ga) {
      ReactGA.initialize(GA4_ID);
    }
    ReactGA.send({ hitType: "pageview", page: path, title: title });
  } catch (error) {
    console.log("*** googlePageView Error ***", error);
  }
};

const googleEvent = (category: string, action: string, label: string) => {
  try {
    if (!ReactGA.ga) {
      ReactGA.initialize(GA4_ID);
    }
    ReactGA.event({
      category: category,
      action: action || "Clicked",
      label: label || "Clicked",
    });
  } catch (error) {
    console.log("*** googleEvent Error ***", error);
  }
};

export { GA4Init, googlePageView, googleEvent };
