import React from "react";

export default function ColorFaceScan() {
  return (
    <svg
      width="27"
      height="30"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.78454 11.9375L7.72255 12.6461C7.69284 12.9858 7.56531 13.3096 7.35539 13.5784C7.14547 13.8471 6.86216 14.0493 6.53975 14.1604L3.06055 15.3592M15.8423 15.3592L12.3631 14.1604C12.0407 14.0493 11.7574 13.8471 11.5475 13.5784C11.3376 13.3096 11.21 12.9858 11.1803 12.6461L11.1183 11.9375M12.55 8.71494L12.3218 10.4866C12.3001 10.7311 12.2191 10.9666 12.0857 11.1727C11.9523 11.3788 11.7706 11.5491 11.5564 11.669L10.1102 12.4785C9.92989 12.5794 9.72677 12.6324 9.5202 12.6324C9.31363 12.6324 9.11051 12.5794 8.93025 12.4785L7.484 11.669C7.2698 11.5491 7.08811 11.3788 6.95473 11.1727C6.82134 10.9666 6.74029 10.7311 6.71861 10.4866L6.48886 8.70282M6.34765 7.60652L6.2733 7.02946C6.11171 5.20624 7.54821 3.63672 9.3786 3.63672H9.6618C11.4922 3.63672 12.9287 5.2062 12.7671 7.02946L12.696 7.58109"
        stroke="#0075F3"
        stroke-width="1.3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.66335 0.988281H1.44922V4.20241M17.4492 4.20241V0.988281H14.2351M14.2351 16.9883H17.4492V13.7742M1.44922 13.7742V16.9883H4.66335"
        stroke="#00006E"
        stroke-width="1.3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.44922 8.63477H17.4492"
        stroke="#0075F3"
        stroke-width="1.3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
