// Connecting all auth,onboarding and application components together.
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import NotFound404 from "./Shared/notFound404";
import OnBoardingSidebarContextProvider from "./utils/onboardingSidebarContext";
import Home from "./components/home/home";
import ContactUs from "./components/contact-us/contactUs";
import ProductIdentity from "./components/product-identity/productIdentity";
import ProductFinancial from "./components/product-financial/productFinancial";
import DigitalContracting from "./components/digital-contracting/digitalContracting";
import MerchantVerification from "./components/merchant-verification/merchantVerification";
import MlAiSolutions from "./components/ml-ai-solutions/mlAiSolution";
import Gaming from "./components/industry/gaming/gaming";
import Retail from "./components/industry/retail/retail";
import Travel from "./components/industry/travel/travel";
import Persona from "./components/products/persona/persona";
import UnlockPersona from "./components/products/persona/unlockPersona";
import PersonaDetails from "./components/products/persona/personaDetails";
import KnowledgeHub from "./components/knowledge-hub";
import BlogLandingPage from "./components/blog-landing-page/blogLandingPage";
import TermsAndCondition from "./components/terms-and-condition/termsAndCondition";
import PrivacyPolicy from "./components/privacy-policy/privacyPolicy";
import ThankYouPage from "./Shared/thank-you/thankYouPage";
import Subprocessor from "./components/sub-processor/subProcessor";
class DashboardRouter extends React.Component {
  render() {
    return (
      <OnBoardingSidebarContextProvider>
        <Router>
          <Routes>
            <Route path={"/"} element={<Navigate to={"/home"} />} />
            <Route path={"/home"} element={<Home />} />
            <Route path={"/contactus"} element={<ContactUs />} />

            <Route path={"/product/identity"} element={<ProductIdentity />} />
            <Route
              path={"/product/identity/pan-verification-api"}
              element={<ProductIdentity />}
            />
            <Route
              path={"/product/identity/compliancecheckforsection206ab&206cca"}
              element={<ProductIdentity />}
            />
            <Route
              path={"/product/identity/aadhaar-api-verification"}
              element={<ProductIdentity />}
            />
            <Route
              path={"/product/identity/voterid-verification-api"}
              element={<ProductIdentity />}
            />
            <Route
              path={"/product/identity/driving-license-verification-api"}
              element={<ProductIdentity />}
            />
            <Route
              path={"/product/identity/passport-verification-api"}
              element={<ProductIdentity />}
            />
            <Route
              path={"/product/identity/epfo-verification-api"}
              element={<ProductIdentity />}
            />

            <Route path={"/product/financial"} element={<ProductFinancial />} />
            <Route
              path={"/product/financial/bank-account-verification-api"}
              element={<ProductFinancial />}
            />
            <Route
              path={"/product/financial/upi-verification-api"}
              element={<ProductFinancial />}
            />
            <Route
              path={"/product/financial/ifsc-verification-api"}
              element={<ProductFinancial />}
            />

            <Route path={"/industry/gaming"} element={<Gaming />} />
            <Route path={"/industry/retail"} element={<Retail />} />
            <Route path={"/industry/travel"} element={<Travel />} />
            <Route
              path={"/product/aadhar-esign-api"}
              element={<DigitalContracting />}
            />
            <Route
              path={"/product/merchant"}
              element={<MerchantVerification />}
            />
            <Route
              path={"/product/merchant/gst-verification-api"}
              element={<MerchantVerification />}
            />
            <Route
              path={"/product/merchant/cin-verification-api"}
              element={<MerchantVerification />}
            />
            <Route
              path={"/product/merchant/fssai-verification-api"}
              element={<MerchantVerification />}
            />
            <Route
              path={"/product/merchant/udyam-aadhaar-verification-api"}
              element={<MerchantVerification />}
            />
            <Route
              path={"/product/merchant/business-profiling-verification-api"}
              element={<MerchantVerification />}
            />

            <Route path={"/product/utility"} element={<MlAiSolutions />} />
            <Route
              path={"/product/utility/ocr-verification-api"}
              element={<MlAiSolutions />}
            />
            <Route
              path={"/product/utility/face-match-api"}
              element={<MlAiSolutions />}
            />
            <Route
              path={"/product/utility/face-liveness-verification"}
              element={<MlAiSolutions />}
            />

            <Route path={"/persona"} element={<Persona />} />
            <Route path={"/persona/unlock"} element={<UnlockPersona />} />
            <Route path={"/persona/details"} element={<PersonaDetails />} />
            <Route path={"/blogs"} element={<KnowledgeHub />} />
            <Route path={"/blogs/:id/:title"} element={<BlogLandingPage />} />
            <Route
              path={"/terms-and-conditions"}
              element={<TermsAndCondition />}
            />
            <Route path={"/privacy-policy"} element={<PrivacyPolicy />} />
            <Route path={"/contactus/thank-you"} element={<ThankYouPage />} />
            <Route element={<NotFound404 />} />
            <Route path={"/subprocessors"} element={<Subprocessor />} />
          </Routes>
        </Router>
      </OnBoardingSidebarContextProvider>
    );
  }
}

export default DashboardRouter;
